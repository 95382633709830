import MaterialButton from '@mui/material/Button';
import { ButtonProps } from '@mui/material/Button/Button';
import React, { ReactNode, SyntheticEvent } from 'react';
import { classNameCombiner } from 'utils';

import styles from './Button.module.scss';

export type Props = {
  className?: string;
  onClick?: (event?: SyntheticEvent) => void;
  children: any;
  disabled?: boolean;
  href?: string;
  download?: string;
  variant?: 'contained' | 'outlined';
  fullWidth?: boolean;
  size?: 'normal' | 'small' | 'medium';
  testId?: string;
  endIcon?: ReactNode;
  startIcon?: ReactNode;
  color?: ButtonProps['color'];
  component?: React.ElementType;
  disableElevation?: boolean;
  disableRipple?: boolean;
};

export function Button(props: Props) {
  const {
    variant = 'contained',
    fullWidth = true,
    size = 'normal',
    component = 'button',
    className = '',
    disableElevation,
    disableRipple,
    href,
    download,
    disabled,
    onClick,
    startIcon,
    endIcon,
    testId,
    children,
    color = 'primary',
  } = props;

  return (
    <MaterialButton
      component={component}
      fullWidth={fullWidth}
      variant={variant}
      color={color}
      href={href ?? ''}
      download={download}
      disabled={disabled}
      onClick={onClick}
      disableElevation={disableElevation}
      disableRipple={disableRipple}
      className={classNameCombiner([
        styles.button,
        variant === 'outlined' ? styles.outlined : '',
        size === 'small' ? styles.small : '',
        size === 'medium' ? styles.medium : '',
        className,
      ])}
      data-testid={testId}
      endIcon={endIcon}
      startIcon={startIcon}
    >
      {children}
    </MaterialButton>
  );
}
