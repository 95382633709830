import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { TableCell, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { NoteAction } from 'app/appointments/types';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { V2Table as Table } from 'shared-components/table/V2Table';
import { V2TableBody as TableBody } from 'shared-components/table/V2TableBody';
import { V2TableHead as TableHead } from 'shared-components/table/V2TableHead';
import { NoteRoutes, patientNoteRoute } from 'app/top-nav/Routes';
import { formatStatus } from 'app/notes-ui/utils';
import {
  NonAppointmentNotes,
  OutOfSessionOrTerminationNote,
} from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import React from 'react';

import styles from './NotesTable.module.scss';
import { NotesTableActionButton } from './NoteTableActionButton';

interface Column {
  key: string;
  label: string;
}

const tableColumns: Column[] = [
  { key: 'date', label: 'Date' },
  { key: 'clinicianName', label: 'Clinician' },
  { key: 'noteType', label: 'Note Type' },
  { key: 'noteStatus', label: 'Note Status' },
  { key: 'noteUpdatedAt', label: 'Note Last Modified' },
  { key: 'clinicalNote', label: 'Actions' },
];

export type Props = {
  memberId: string;
  notes: NonAppointmentNotes | null;
  navigateTo: (url: string) => void;
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  clinicianId: string;
};

export function NotesTable(props: Props) {
  const { notes, navigateTo, signedLockedAction } = props;
  const { allowedNoteActions } = useFeatureFlags();

  const columns = tableColumns.map(({ label, key }) => (
    <TableCell key={key}>{label}</TableCell>
  ));

  const { outOfSessionOrTerminationNotes } = notes ?? {};

  return (
    <Paper className={styles.appointments}>
      <Table dataTestId="notes-appointment-table">
        <TableHead>{columns}</TableHead>
        <NotesTableBody
          signedLockedAction={signedLockedAction}
          allowedNoteActions={allowedNoteActions}
          memberId={props.memberId}
          notes={outOfSessionOrTerminationNotes ?? []}
          navigateTo={navigateTo}
          clinicianId={props.clinicianId}
        />
      </Table>
    </Paper>
  );
}

type NotesTableBodyProps = {
  memberId: string;
  notes: OutOfSessionOrTerminationNote[];
  navigateTo: (url: string) => void;
  allowedNoteActions: NoteAction[];
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  clinicianId: string;
};

function NotesTableBody(props: NotesTableBodyProps) {
  const {
    notes,
    navigateTo,
    allowedNoteActions,
    memberId,
    signedLockedAction,
  } = props;
  const rows = notes.map((note, i) => {
    const { label, path } = getNoteTypeLabelAndPath(note);
    const onClick = () => {
      navigateTo(patientNoteRoute(props.memberId, path, note.vaultItemId));
    };

    return (
      <TableRow key={note.vaultItemId}>
        <TableCell data-testid={`date-${i}`} key={`date-${i}`}>
          {note.date || note.createdAt}
        </TableCell>

        <TableCell
          data-testid={`clinicianName-${i}`}
          key={`clinicianName-${i}`}
        >
          {note.authorName}
        </TableCell>

        <TableCell data-testid={`noteType-${i}`} key={`noteType-${i}`}>
          {label}
        </TableCell>

        <TableCell data-testid={`noteStatus-${i}`} key={`noteStatus-${i}`}>
          {formatStatus(note.status)}
        </TableCell>

        <TableCell
          data-testid={`noteUpdatedAt-${i}`}
          key={`noteUpdatedAt-${i}`}
        >
          {note.updatedAt}
        </TableCell>

        <TableCell data-testid={`actionBar-${i}`} key={`actionBar-${i}`}>
          <NotesTableActionButton
            note={note}
            memberId={memberId}
            signedLockedAction={signedLockedAction}
            allowedNoteActions={allowedNoteActions}
            onClick={onClick}
            clinicianId={props.clinicianId}
          />
        </TableCell>
      </TableRow>
    );
  });

  return <TableBody>{rows}</TableBody>;
}

function getNoteTypeLabelAndPath(
  note: OutOfSessionOrTerminationNote,
): { label: string; path: NoteRoutes } {
  switch (note.contents.schema) {
    case VaultItem_SchemaType.vault_clinical_notes_out_of_session: {
      return { label: 'Out of Session', path: NoteRoutes.OUT_OF_SESSION };
    }

    case VaultItem_SchemaType.vault_clinical_notes_termination: {
      return { label: 'Termination', path: NoteRoutes.TERMINATION };
    }
  }
}
