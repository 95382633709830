import {
  arrayField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  SubstanceUse,
  SubstanceUse_CurrentSubstanceUse,
  SubstanceUse_PastSubstanceUse,
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  SubstanceAbuseSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { OnChangeHandler } from 'app/notes-ui/shared/substance-abuse/SubstanceTableRow';
import { SubstanceUseContainerV2 } from 'app/notes-ui/shared/substance-abuse/SubstanceUseContainerV2';
import { useResetFormWithValue } from 'app/notes-ui/UseResetFormWithValue';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import React, { useRef, useState } from 'react';

type Props = {
  appointmentId: string;
  initialValue?: SubstanceUse;
  readOnly?: boolean;
  onSubmit: (data: SubstanceAbuseSection) => void;
  disabled?: boolean;
  enabledAI?: boolean;
  aiIconState?: AiMagicIconState;
};

export const formFieldDefs: FieldDefinitions<SubstanceUse> = {
  anySubstanceUsed: field<BooleanOption>({
    default: BooleanOption.yes,
    rules: [],
  }),
  appointmentId: field(),
  pastEtohOrBenzoWithdrawal: {
    description: stringField({ default: '', rules: [] }),
    isPresent: field({ rules: [] }),
  },
  pastSubstanceUseTreatment: {
    description: stringField({ default: '', rules: [] }),
    isPresent: field({ rules: [] }),
  },
  substancesCurrentlyUsed: arrayField<SubstanceUse_CurrentSubstanceUse>({
    default: [],
  }),
  substancesPreviouslyUsed: arrayField<SubstanceUse_PastSubstanceUse>({
    default: [],
  }),
  version: field<SubstanceUse_Version>({ default: SubstanceUse_Version.v0 }),
};

function getNotEndorsedStatus(value: BooleanOption | undefined) {
  return value === BooleanOption.no;
}

function getBooleanOption(value: boolean) {
  return value ? BooleanOption.no : BooleanOption.yes;
}

export function SubstanceAbuseFormV2(props: Props) {
  const {
    initialValue,
    disabled,
    enabledAI,
    aiIconState,
    onSubmit,
    appointmentId,
  } = props;
  const currentUseRows = useSubstanceItems();

  const [notEndorsedChecked, setNotEndorsedChecked] = useState<boolean>(
    getNotEndorsedStatus(initialValue?.anySubstanceUsed),
  );

  const form = useForm<SubstanceUse>(
    formFieldDefs,
    { ...initialValue, version: SubstanceUse_Version.v0 } as SubstanceUse,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => onStateChange(),
    },
  );

  const {
    fields: anySubstanceUseField,
    getValue: anySubstanceUseFieldValue,
  } = form;
  useResetFormWithValue(initialValue, form);

  const getSectionData = () => {
    const anySubstanceUsedValue = anySubstanceUseFieldValue();
    const substancesUsed = currentUseRows
      .getRows()
      .map((row) => ({ substance: row.item }));
    const section: SubstanceUse = {
      anySubstanceUsed: anySubstanceUsedValue.anySubstanceUsed,
      appointmentId,
      pastEtohOrBenzoWithdrawal: {
        description: '',
        isPresent: false,
      },
      pastSubstanceUseTreatment: {
        description: '',
        isPresent: false,
      },
      substancesCurrentlyUsed: substancesUsed,
      substancesPreviouslyUsed: [],
      version: initialValue?.version || SubstanceUse_Version.v0,
    };
    return section;
  };

  const onStateChange = async () => {
    if (disabled) return;

    const data = {
      ...getSectionData(),
      anySubstanceUsed: getBooleanOption(notEndorsedChecked),
    };
    onSubmit({
      data,
      name: TherapyIntakeSectionName.SUBSTANCE_ABUSE,
    });
  };

  return (
    <>
      <NoteFormControlLabel
        showAiIcon={enabledAI}
        aiIconState={aiIconState}
        label="Does the member currently or have they in the past used any substances?"
      >
        <div className={styles.formGroupV2} data-testid="currentlyUsed">
          <Checkbox
            testId="endorsedState"
            checked={notEndorsedChecked}
            disabled={disabled}
            onChange={() => {
              anySubstanceUseField.anySubstanceUsed.setValue(
                getBooleanOption(!notEndorsedChecked),
              );
              setNotEndorsedChecked((checked) => !checked);
            }}
            label="Not Endorsing any current or past substance use"
          />
          <SubstanceUseContainerV2
            includeStoppedUse={false}
            onChange={currentUseRows.onChange}
            onSubmit={onStateChange}
            initialValue={initialValue?.substancesCurrentlyUsed}
            disabled={disabled}
            notEndorsed={notEndorsedChecked}
          />
        </div>
      </NoteFormControlLabel>
    </>
  );
}

type SubstanceUseItems = Record<
  SubstanceUse_SubstanceType,
  SubstanceUseItem | undefined
>;

type SubstanceUseItem = {
  item: SubstanceUse_SubstanceUseLineItem;
  isChecked: boolean;
  isValid: boolean;
};

/** Custom hook that keeps the state of SubstanceRows  */
function useSubstanceItems(): {
  isValid: boolean;
  onChange: OnChangeHandler;
  getRows: () => SubstanceUseItem[];
} {
  const items = useRef<SubstanceUseItems>({} as SubstanceUseItems);
  const [isValid, setIsValid] = useState(true);

  const onChange: OnChangeHandler = ({ item, isChecked, isValid: valid }) => {
    if (isChecked) {
      items.current[item.substanceType] = { isChecked, isValid: valid, item };
    } else {
      delete items.current[item.substanceType];
    }

    const currentItem = Object.values(items.current);

    setIsValid(
      currentItem.length === 0 ||
        currentItem.every((_) => _ !== undefined && _.isValid),
    );
  };

  return {
    getRows: () =>
      Object.values(items.current).filter(
        (_) => _ !== undefined,
      ) as SubstanceUseItem[],
    isValid,
    onChange,
  };
}
