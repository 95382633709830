import {
  useVaultAPI,
  VaultAuditLogEvent,
  VaultAuditLogEventType,
} from '@ginger.io/vault-ui';
import { useAppState } from 'app/state';
import { useOnMount } from 'hooks/useOnMount';
import React, { ReactElement, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { ErrorState } from 'shared-components/error-state/ErrorState';
import { formatEnumValue } from 'utils';
import { formatDate } from 'utils/dateTime';

import styles from './AuditLogEntryView.module.scss';

export type AuditLog = {
  id: string;
  name: string;
  timestamp: string;
  eventType: VaultAuditLogEventType;
};

export type Props = {
  vaultItemId: string;
  vaultGroupId: string;
};

export function AuditLogEntry(props: { logEntry: AuditLog; timezone: string }) {
  const { logEntry, timezone } = props;
  return (
    <li className={styles.log}>
      <div data-testid="title" className={styles.title}>
        {logEntry.name}
      </div>
      <div className={styles.detail}>
        <span data-testid="eventType">
          {formatEnumValue(logEntry.eventType)}
        </span>
        <span data-testid="timestamp">
          {formatDate(logEntry.timestamp, timezone)}
        </span>
      </div>
    </li>
  );
}

export function AuditLogEntryView(props: Props) {
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const timezone = useAppState((_) => _.user.timezone!);
  const api = useVaultAPI();
  const loadingRef = useRef<boolean>(false);

  useOnMount(() => {
    loadingRef.current = true;
    const callback = async () => {
      try {
        setLoading(true);
        const logs: VaultAuditLogEvent[] = await api.getAuditLogForVaultItem(
          props.vaultItemId,
          {
            groupId: props.vaultGroupId,
          },
        );

        const entries: AuditLog[] = logs.map((event) => ({
          ...event,
          name: event.actorName ?? '',
        }));
        if (loadingRef.current) setAuditLogs(entries);
      } catch (e) {
        if (loadingRef.current) setError(e);
      } finally {
        if (loadingRef.current) setLoading(false);
      }
    };

    void callback();
    return () => {
      loadingRef.current = false;
    };
  });

  let component: ReactElement;
  if (loading) {
    component = (
      <div className={styles.loader}>
        <ClipLoader color="#4379b6" size={75} />
      </div>
    );
  } else if (error !== null) {
    component = <ErrorState error={new Error(error)} />;
  } else {
    component = (
      <ul data-testid={props.vaultItemId} className={styles.auditLogs}>
        {auditLogs.map((logEntry) => (
          <AuditLogEntry
            timezone={timezone}
            data-testid={`audit-log-${logEntry.id}`}
            key={logEntry.id}
            logEntry={logEntry}
          />
        ))}
      </ul>
    );
  }

  return (
    <div className={styles.root}>
      <h2>Audit Logs</h2>
      {component}
    </div>
  );
}
