import { clinicianTodaysInbox } from '@headspace/carehub-graphql/dist/queries/CareProviderInbox';
import {
  ClinicianTodaysInbox,
  ClinicianTodaysInboxVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/ClinicianTodaysInbox';
import { assertResponseHasNoError } from 'app/inbox/assertResponseHasNoError';
import { InboxItem, InboxSections } from 'app/inbox/types';
import {
  CLINICIAN_INBOX_MAX_ITEMS_PER_PAGE,
  getClinicianTodaysInboxVariables,
  gqlTasksResponseToInboxItem,
  gqlTodaysAppointmentResponseToInboxItem,
} from 'app/inbox/utils';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';

import {
  refreshTodaysInboxTotalCount,
  stopInboxPolling,
  updateInboxItems,
  updateTabSection,
} from '../actions';
import { removeConvoStats } from '../utils';
import { CLINICIAN_TODAYS_INBOX_SECTIONS } from './constants';
import { ActionHandler } from './types';

export const onRefreshClinicianTodaysMemberList = async ({
  action,
  redux,
  context,
}: ActionHandler<{ sections?: InboxSections[] }>) => {
  const { logger } = context.services;

  const getPaginationInput = (ids: string[]) => ({
    cursor: null,
    maxItemsPerPage:
      ids.length < CLINICIAN_INBOX_MAX_ITEMS_PER_PAGE
        ? CLINICIAN_INBOX_MAX_ITEMS_PER_PAGE
        : ids.length,
  });
  const { sections = CLINICIAN_TODAYS_INBOX_SECTIONS } = action.payload;
  const {
    user: { userId: authUserId, timezone, role },
    inbox: { tabSections },
  } = redux.getState();

  logger.debug("refreshing today's member list section: ", { sections });

  const sectionSet = new Set(sections);
  try {
    const response = await context.services.apollo.query<
      ClinicianTodaysInbox,
      ClinicianTodaysInboxVariables
    >({
      fetchPolicy: 'network-only',
      query: clinicianTodaysInbox,
      variables: getClinicianTodaysInboxVariables({
        activeTasksPagination: getPaginationInput(
          Array.from(tabSections[InboxSections.TASKS].ids),
        ),
        appointmentPagination: getPaginationInput(
          Array.from(tabSections[InboxSections.APPOINTMENTS].ids),
        ),
        includeActiveTasks: sectionSet.has(InboxSections.TASKS),
        includeAppointments: sectionSet.has(InboxSections.APPOINTMENTS),
        includeCompletedTasks: sectionSet.has(InboxSections.COMPLETED),
        includeRiskTasks: sectionSet.has(InboxSections.RISKS),
        riskTasksPagination: getPaginationInput(
          Array.from(tabSections[InboxSections.RISKS].ids),
        ),
      }),
    });
    assertResponseHasNoError(response, logger, { query: clinicianTodaysInbox });
    const { errors, data } = response;

    if (errors) {
      logger.error(
        new Error(
          "Received partial response while refreshing today's member list",
        ),
        {
          authUserId,
          errors,
          role,
          sections,
        },
      );
    }

    let updatedInboxItems: InboxItem[] = [];

    const updatedTabSections = { ...tabSections };

    if (data?.appointments) {
      const { appointments } = data.appointments;
      const {
        items: appointmentsItem,
        cursor,
        hasMore,
      } = gqlTodaysAppointmentResponseToInboxItem(
        appointments?.items,
        appointments?.pagination,
        timezone ?? 'UTC',
      );
      updatedTabSections[InboxSections.APPOINTMENTS] = {
        cursor,
        hasMore,
        ids: new Set(appointmentsItem.map((_) => _.id)),
      };
      updatedInboxItems = updatedInboxItems.concat(appointmentsItem);
    }

    if (data?.activeTasks) {
      const { items, pagination } = data.activeTasks;
      const {
        items: activeTasksItem,
        cursor,
        hasMore,
      } = gqlTasksResponseToInboxItem(items, pagination, timezone ?? 'UTC');
      updatedTabSections[InboxSections.TASKS] = {
        cursor,
        hasMore,
        ids: new Set(activeTasksItem.map((_) => _.id)),
      };
      updatedInboxItems = updatedInboxItems.concat(activeTasksItem);
    }

    if (data?.riskTasks) {
      const { items, pagination } = data.riskTasks;
      const {
        items: riskTasksItem,
        cursor,
        hasMore,
      } = gqlTasksResponseToInboxItem(
        items,
        pagination,
        timezone ?? 'UTC',
        true,
      );
      updatedTabSections[InboxSections.RISKS] = {
        cursor,
        hasMore,
        ids: new Set(riskTasksItem.map((_) => _.id)),
      };
      updatedInboxItems = updatedInboxItems.concat(riskTasksItem);
    }

    if (data?.completedTasks) {
      const items = gqlTasksResponseToInboxItem(
        data.completedTasks ?? [],
        undefined,
        timezone ?? 'UTC',
      );
      updatedTabSections[InboxSections.COMPLETED] = {
        cursor: null,
        hasMore: false,
        ids: new Set(items.items.map((_) => _.id)),
      };
      updatedInboxItems = updatedInboxItems.concat(items.items);
    }
    logger.debug('refresh results', {
      sections,
      tabSections,
      updatedTabSections,
    });
    redux.dispatch(updateTabSection({ tabSections: updatedTabSections }));
    const updatedItemsWithoutConvoStats = updatedInboxItems.map(
      removeConvoStats,
    );
    redux.dispatch(updateInboxItems(updatedItemsWithoutConvoStats));
    redux.dispatch(refreshTodaysInboxTotalCount({}));
  } catch (e) {
    if (isGraphQLAuthenticationError(e)) {
      redux.dispatch(stopInboxPolling());
      redux.dispatch(updateSessionState(SessionState.EXPIRED));
    } else {
      logger.error(new Error(`Unable to refresh today's member list`), {
        error: e,
        sections,
      });
    }
  }
};
