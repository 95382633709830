import { stringField, useForm } from '@ginger.io/react-use-form';
import { Amendment as AmendmentType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Amendment';
import { Button } from '@mui/material';
import { Width } from 'types/StyleTypes';
import { TextAreaField } from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/SaveButton.module.scss';
import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import { LockedAmendments } from './LockedAmendments';
import { AmendmentsSection, AmendmentWithAuditLog } from './types';

type Props<T extends AmendmentsSection> = {
  appointmentId: string;
  sectionName: T['name'];
  onSubmit: (section: { name: T['name']; data: T['data'] }) => void;
  previousValues?: AmendmentWithAuditLog[];
  disabled?: boolean;
  savingNote?: boolean;
};

export function AmendmentsFormV2<T extends AmendmentsSection>(props: Props<T>) {
  const {
    sectionName,
    appointmentId,
    disabled,
    savingNote,
    previousValues = [],
  } = props;

  const newAmendment = useForm<AmendmentType>({
    appointmentId: stringField({ default: appointmentId }),
    text: stringField(),
  });

  const onSubmit = async () => {
    const amendmentIsValid = await newAmendment.validate();

    if (amendmentIsValid) {
      props.onSubmit({
        data: newAmendment.getValue(),
        name: sectionName,
      });
    }
  };

  const component = savingNote ? (
    <BeatLoader size={12} color="#ffffff" loading={true} />
  ) : (
    'Save'
  );

  return (
    <>
      <TextAreaField
        rows={1}
        disabled={disabled}
        testId="newAmendment"
        label="Amendment details"
        field={newAmendment.fields.text}
        autoExpand={true}
        width={Width.FULL}
      />

      <Button
        disabled={savingNote}
        className={styles.saveButton}
        data-testid="submitButton"
        aria-haspopup="true"
        variant="contained"
        onClick={onSubmit}
      >
        {component}
      </Button>
      <LockedAmendments amendmentsWithAuditLogs={previousValues} />
    </>
  );
}
