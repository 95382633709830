import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { InboxVersion } from 'app/inboxV2/types';

export const isInboxV2Enabled = ({
  userRole,
  flagEnabled,
}: {
  userRole: UserRole | null;
  flagEnabled: boolean;
}): boolean => {
  const isCoach =
    userRole === UserRole.COACH || userRole === UserRole.COACH_SUPERVISOR;
  return flagEnabled && isCoach;
};

export const getInboxVersion = ({
  userRole,
  flagEnabled,
}: {
  userRole: UserRole | null;
  flagEnabled: boolean;
}): InboxVersion => {
  return isInboxV2Enabled({ flagEnabled, userRole }) ? 'v2' : 'v1';
};
