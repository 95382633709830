import { ChiefComplaint } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/ChiefComplaint';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { PsychiatrySectionName } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { GetAppointmentById_getAppointmentById as Appointment } from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import { useLogger } from 'app/state/log/useLogger';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { formatSurveyDetailFromClinicalNote } from 'app/vault/data/utils';
import { usePsychiatryIntakeNotesAPI } from 'app/vault/hooks/useClinicalNotesAPI';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useCallback } from 'react';

export function useInitializePsychiatryIntakeNote() {
  const logger = useLogger();
  const api = usePsychiatryIntakeNotesAPI();
  const {
    enable_supervisor_sign_and_lock_notes_for_user: allowGroupWriteAccess,
    enable_prefill_phqgad_answer_in_note: prefillNoteWithSurvey,
  } = useFeatureFlags().transientFeatureFlags;
  return useCallback(
    async ({
      appointmentId,
      userId,
    }: {
      appointmentId: string;
      userId: string;
    }): Promise<{ appointment: Appointment; note: PsychiatryIntakeNote }> => {
      const [appointment, note] = await Promise.all([
        api.getAppointment(appointmentId),
        api.getNote(appointmentId),
      ]);
      const shouldPrefillSurveyScore =
        note.metadata.status === Metadata_NoteStatus.undefined_note_status &&
        prefillNoteWithSurvey;

      if (!shouldPrefillSurveyScore) return { appointment, note };

      const phqGadSurvey = await api.getPHQGADSurvey({
        memberId: appointment.member.id,
      });
      const {
        phq9s: [phq9],
        gad7s: [gad7],
      } = phqGadSurvey;
      const surveyScoreSummary = formatSurveyDetailFromClinicalNote({
        gad7s: [gad7],
        phq9s: [phq9],
      });

      if (!surveyScoreSummary) {
        logger.warning(
          'useInitializePsychiatryIntakeNote: no survey score summary generated',
          {
            gad7,
            phq9,
          },
        );
        return { appointment, note };
      }
      const updatedNote = {
        ...note,
        [PsychiatrySectionName.CHIEF_COMPLAINT]: ChiefComplaint.fromPartial({
          historyOfPresentIllness: surveyScoreSummary,
        }),
        metadata: {
          ...note.metadata,
          status: Metadata_NoteStatus.draft_note,
        },
      };

      await api.createDraftNoteSections(
        userId,
        appointment,
        [
          {
            data: updatedNote.CHIEF_COMPLAINT!,
            name: PsychiatrySectionName.CHIEF_COMPLAINT,
          },
        ],
        updatedNote.metadata,
        allowGroupWriteAccess,
      );
      return {
        appointment,
        note: updatedNote,
      };
    },
    [allowGroupWriteAccess, prefillNoteWithSurvey],
  );
}
