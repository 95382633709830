import { ConversationStateMap } from 'app/coach/chat/types';
import { updateMessagesStatuses } from 'app/coach/chat/utils';
import {
  MessagesMap,
  MessageStatus,
  MessageToDisplay,
} from 'app/coach/pubnub/types';
import { markLastMessageSeenByMember } from 'app/coach/pubnub/utils';
import { InboxTab } from 'app/inbox/types';
import { isLocal } from 'app/Stage';
import memberChartStorage, {
  MemberChartState,
} from 'app/state/member-tabs/memberChartStorage';
import { createReducer } from 'redux-reloaded';

import {
  addChannelMemberMap,
  addConversationStateMap,
  addMessage,
  addUnreadByListenerMessage,
  insertNewMessagesAndUpdateStatuses,
  makeSentMessageDelivered,
  markSentMessageByError,
  maybeUpdateConversationsState,
  onCallerReadMessageRPC,
  readAllMessages,
  restoreDrawerState,
  setCurrentChannel,
  setInboxPollingID,
  setInboxRefreshInProgress,
  setMessages,
  setSelectedTab,
  setUnreadMessages,
  toggleDrawer,
  updateConversationPresence,
  updateConversationState,
  updateInboxItems,
  updateStatusesOfMessages,
  updateTabSection,
  updateTodaysInboxTotalCount,
} from './actions';
import { getInitialInboxState, State } from './schema';
import {
  displayableMessagesToString,
  updateStateIfChangesDetected,
} from './utils';

export const reducer = createReducer<State>(getInitialInboxState());

reducer.on(setCurrentChannel, (state, { payload }) => {
  const { currentChannelId, currentMemberId } = payload;
  return {
    ...state,
    currentChannelId,
    currentMemberId,
  };
});

reducer.on(setMessages, (state, { payload }) => {
  const { messages, channelId } = payload;
  return {
    ...state,
    messagesMap: {
      ...state.messagesMap,
      [channelId]: messages,
    },
  };
});

reducer.on(addMessage, (state, { payload }) => {
  const { message } = payload;
  const messageChannelId = message.channel;
  const { messagesMap } = state;
  let existingMessages: MessageToDisplay[];

  if (messageChannelId in messagesMap) {
    existingMessages = [...messagesMap[messageChannelId]];
  }
  // this may happen when we didn't load the messages for this channelId because there was 0 unread messages when we initialized the inbox
  else {
    existingMessages = [];
  }
  // avoid adding the same message twice
  if (!existingMessages.some((m) => m.id === message.id)) {
    existingMessages.push(message);
  }
  // this log is for debugging purposes, we can remove it later
  if (!isLocal()) {
    // eslint-disable-next-line no-undef
    R7Insight.info('addMessage', {
      additionalData: {
        careHubVersion: window.careHubVersion,
        channelId: messageChannelId,
        messageId: message.id,
        path: window.location.pathname,
        updatedMessages: displayableMessagesToString(existingMessages),
      },
    });
  }
  return {
    ...state,
    messagesMap: {
      ...messagesMap,
      [messageChannelId]: existingMessages,
    },
  };
});
// when this action is dispatched we need to update messages statuses
reducer.on(onCallerReadMessageRPC, (state, { payload }) => {
  const { channel } = payload;
  const { messagesMap } = state;

  const updatedMessages: MessageToDisplay[] | null =
    channel in messagesMap
      ? markLastMessageSeenByMember(messagesMap[channel])
      : null;

  const updatedState: State = {
    ...state,
    messagesMap: updatedMessages
      ? {
          ...messagesMap,
          [channel]: updatedMessages,
        }
      : state.messagesMap,
  };

  return updatedState;
});

reducer.on(makeSentMessageDelivered, (state, { payload }) => {
  const { messagesMap } = state;
  const { messageId, channelId, timetoken } = payload;
  const updatedMessages = messagesMap[channelId].map((message) =>
    message.id === messageId
      ? { ...message, status: MessageStatus.DELIVERED, timetoken }
      : message,
  );
  return {
    ...state,
    messagesMap: {
      ...state.messagesMap,
      [channelId]: updatedMessages,
    },
  };
});

reducer.on(markSentMessageByError, (state, { payload }) => {
  const { messageId, channelId } = payload;
  const { messagesMap } = state;
  const updatedMessages = messagesMap[channelId].map((message) =>
    message.id === messageId
      ? { ...message, status: MessageStatus.ERROR }
      : message,
  );
  return {
    ...state,
    messagesMap: {
      ...state.messagesMap,
      [channelId]: updatedMessages,
    },
  };
});

reducer.on(updateInboxItems, (state, { payload }) => {
  const { inboxItems } = payload;
  const updatedItems = { ...state.inboxItems };
  inboxItems.forEach(({ id, ...item }) => {
    if (updatedItems[id]) {
      updatedItems[id] = { ...updatedItems[id], ...item };
    } else {
      updatedItems[id] = { id, ...item };
    }
  });
  return {
    ...state,
    inboxItems: updatedItems,
  };
});

reducer.on(restoreDrawerState, (state) => {
  const item: MemberChartState = memberChartStorage.get();
  return {
    ...state,
    selectedTab: item.activeInboxMenuTab ?? InboxTab.TODAYS,
    showInboxDrawer: item.inboxMenuOpen ?? true,
  };
});

reducer.on(toggleDrawer, (state) => {
  memberChartStorage.set({ inboxMenuOpen: !state.showInboxDrawer });
  return {
    ...state,
    showInboxDrawer: !state.showInboxDrawer,
  };
});

reducer.on(readAllMessages, (state, { payload }) => {
  const { channelId } = payload;

  // this log is for debugging purposes, we can remove it later
  if (!isLocal()) {
    // eslint-disable-next-line no-undef
    R7Insight.info(`readAllMessages ${channelId}`, {
      additionalData: {
        careHubVersion: window.careHubVersion,
        channelId,
        memberId: state.channelIdMemberIdMap[channelId],
        messages: displayableMessagesToString(
          state.messagesMap[channelId] ?? [],
        ),
        path: window.location.pathname,
      },
    });
  }

  return {
    ...state,
    unreadMessagesMap: {
      ...state.unreadMessagesMap,
      [channelId]: [],
    },
  };
});

reducer.on(addUnreadByListenerMessage, (state, { payload }) => {
  const { unreadMessage, channelId } = payload;
  const currentlyUnreadMessages: MessageToDisplay[] = [
    ...(state.unreadMessagesMap[channelId] ?? []),
  ];
  const unreadMessages = Array.isArray(unreadMessage)
    ? unreadMessage
    : [unreadMessage];
  // avoid adding the same message twice
  const set = new Set(currentlyUnreadMessages.map((m) => m.id));
  currentlyUnreadMessages.push(...unreadMessages.filter((m) => !set.has(m.id)));

  return {
    ...state,
    unreadMessagesMap: {
      ...state.unreadMessagesMap,
      [channelId]: currentlyUnreadMessages,
    },
  };
});

reducer.on(updateConversationPresence, (state, { payload }) => {
  const { presences } = payload;

  const presenceByChannelId = { ...state.presenceByChannelId };

  Object.entries(presences).forEach(([channelId, presence]) => {
    const prevPresence = state.presenceByChannelId[channelId]
      ? state.presenceByChannelId[channelId]
      : {
          isOnline: false,
          isTyping: false,
          timerId: null,
        };
    presenceByChannelId[channelId] = { ...prevPresence, ...presence };
  });

  return {
    ...state,
    presenceByChannelId,
  };
});

reducer.on(updateStatusesOfMessages, (state, { payload }) => {
  const { messagesMap } = state;
  const updatedConversations = payload.reduce<MessagesMap>(
    (accumulator, current) => {
      const { channel, lastMemberReadTimeToken } = current;
      if (!(channel in messagesMap) || !lastMemberReadTimeToken)
        return accumulator;

      const currentMessages = messagesMap[channel];
      const updatedMessagesWithStatuses = updateMessagesStatuses(
        lastMemberReadTimeToken,
        currentMessages,
      );
      accumulator[channel] = updatedMessagesWithStatuses;
      return accumulator;
    },
    {},
  );

  return {
    ...state,
    messagesMap: {
      ...state.messagesMap,
      ...updatedConversations,
    },
  };
});

reducer.on(insertNewMessagesAndUpdateStatuses, (state, { payload }) => {
  const { messagesMap } = state;
  const updatedConversations = payload.reduce<MessagesMap>(
    (accumulator, current) => {
      const {
        channel,
        newMessagesFromRefetch,
        lastMemberReadTimeToken,
      } = current;
      if (!(channel in messagesMap)) return accumulator;

      const existingMessages = messagesMap[channel];
      const updatedMessages = [
        ...existingMessages,
        ...newMessagesFromRefetch,
      ].sort((m1, m2) => +m1.timetoken - +m2.timetoken);
      // a scenario when we don't have the lastMemberReadTimetoken to update statuses
      if (!lastMemberReadTimeToken) {
        accumulator[channel] = updatedMessages;
      }
      // re-set statuses if there's a lastMemberReadTimetoken
      else {
        const updatedMessagesWithStatuses = updateMessagesStatuses(
          lastMemberReadTimeToken,
          updatedMessages,
        );
        accumulator[channel] = updatedMessagesWithStatuses;
      }
      return accumulator;
    },
    {},
  );

  // to-do: update unreadMessagesMap

  return {
    ...state,
    messagesMap: {
      ...state.messagesMap,
      ...updatedConversations,
    },
  };
});

reducer.on(updateTabSection, (state, { payload }) => ({
  ...state,
  search: { ...state.search, ...payload.search },
  tabSections: { ...state.tabSections, ...payload.tabSections },
}));

reducer.on(updateTodaysInboxTotalCount, (state, { payload }) => {
  const { todaysMemberCount } = payload;
  return {
    ...state,
    todaysMemberCount,
  };
});

reducer.on(setSelectedTab, (state, { payload }) => {
  memberChartStorage.set({ activeInboxMenuTab: payload.inboxTab });
  return {
    ...state,
    selectedTab: payload.inboxTab,
  };
});

reducer.on(addChannelMemberMap, (state, { payload }) => ({
  ...state,
  channelIdMemberIdMap: {
    ...state.channelIdMemberIdMap,
    ...payload.channelMemberMap,
  },
}));

reducer.on(addConversationStateMap, (state, { payload }) => ({
  ...state,
  conversationStateMap: {
    ...state.conversationStateMap,
    ...payload.conversationStateMap,
  },
}));

reducer.on(updateConversationState, (state, { payload }) => {
  const { channelId, updatedState } = payload;
  const { conversationStateMap } = state;
  const updatedConversationState =
    channelId in conversationStateMap
      ? {
          ...conversationStateMap[channelId],
          ...updatedState,
        }
      : updatedState;
  return {
    ...state,
    conversationStateMap: {
      ...state.conversationStateMap,
      [channelId]: updatedConversationState,
    },
  };
});

reducer.on(setUnreadMessages, (state, { payload }) => ({
  ...state,
  unreadMessagesMap: {
    ...state.unreadMessagesMap,
    ...payload.unreadMessagesMap,
  },
}));

reducer.on(setInboxPollingID, (state, { payload }) => {
  return { ...state, pollingID: payload };
});

reducer.on(maybeUpdateConversationsState, (state, { payload }) => {
  const { updatesOfStateMap } = payload;
  const { conversationStateMap } = state;
  // compare the payload for each convo with the current state and update only if it differes
  const channelIdsWithPossibleChanges = Object.keys(updatesOfStateMap);
  const updatedStateMap: ConversationStateMap = channelIdsWithPossibleChanges.reduce<
    ConversationStateMap
  >((stateMap, channelId) => {
    const currentState = conversationStateMap[channelId];
    if (!currentState) {
      stateMap[channelId] = updatesOfStateMap[channelId];
    } else {
      const updatedState = updateStateIfChangesDetected(
        currentState,
        updatesOfStateMap[channelId],
      );
      if (updatedState) stateMap[channelId] = updatedState;
    }
    return stateMap;
  }, {});
  return {
    ...state,
    conversationStateMap: {
      ...state.conversationStateMap,
      ...updatedStateMap,
    },
  };
});

reducer.on(setInboxRefreshInProgress, (state, { payload }) => {
  return { ...state, isInboxRefreshing: payload.inProgress };
});
