import { useMutation } from '@apollo/client';
import { removeHomeworkFromMember as removeHomeworkFromMemberMutation } from '@headspace/carehub-graphql/dist/patients-tabs-content/ContentQueries';
import {
  removeHomeworkFromMember,
  removeHomeworkFromMemberVariables,
} from '@headspace/carehub-graphql/dist/patients-tabs-content/generated/removeHomeworkFromMember';
import { usePubNubAPI } from 'app/coach/pubnub/PubNubContextProvider';
import { ContentRPC, MessageRPC } from 'app/coach/pubnub/types';
import { useAppState } from 'app/state';
import { unassignButtonClicked } from 'app/state/amplitude/actions/content';
import { unassignHomework } from 'app/state/content/actions';
import { selectCurrentPubnubChannelId } from 'app/state/inbox/selectors';
import { useLogger } from 'app/state/log/useLogger';
import { useDispatch } from 'react-redux';
import { v4 as uuidv4 } from 'uuid';

export function useUnassignHomeworkMutation(
  memberId: string,
): (contentId: string) => Promise<boolean> {
  const dispatch = useDispatch();
  const logger = useLogger();
  const api = usePubNubAPI();
  const {
    user: { listenerId, coachinghubUsername },
  } = useAppState((_) => _);
  const currentChannelId = selectCurrentPubnubChannelId();
  const { publishMessage } = api;

  const [unassignHomeworkMutation] = useMutation<
    removeHomeworkFromMember,
    removeHomeworkFromMemberVariables
  >(removeHomeworkFromMemberMutation);

  const sendUnassignHomeworkRPC = async (contentId: string) => {
    if (!listenerId) {
      return;
    }
    const unassignHomeworkRPC: MessageRPC = {
      extra_params: {
        content_id: contentId,
      },
      id: uuidv4(),
      oncall_listener_id: null,
      rpc: ContentRPC.GIO_DEEPLINK_UNASSIGNED,
      senderId: listenerId,
      senderType: 'listener',
      username: coachinghubUsername ?? '',
    };
    try {
      void (await publishMessage({
        channel: currentChannelId,
        message: unassignHomeworkRPC,
      }));
    } catch (e) {
      logger.error(
        new Error(
          `Could not publish ${ContentRPC.GIO_DEEPLINK_UNASSIGNED} rpc.`,
        ),
        {
          channelId: currentChannelId,
          error: e,
          listenerId,
          status: e.status,
        },
      );
    }
  };

  const removeHomework = async (contentId: string) => {
    try {
      const res = await unassignHomeworkMutation({
        variables: {
          input: {
            memberId: Number(memberId),
            taskId: contentId,
          },
        },
      });
      if (!res.data?.removeHomeworkFromMember?.success) {
        throw new Error(
          'Something went wrong, the homework could not be unassigned.',
        );
      }
      await sendUnassignHomeworkRPC(contentId);
      dispatch(
        unassignHomework({
          contentId,
          userId: Number(memberId),
        }),
      );
      dispatch(
        unassignButtonClicked({
          contentId,
          userId: Number(memberId),
        }),
      );

      return true;
    } catch (e) {
      // TODO: Display an error message somehow.
      // https://headspace.atlassian.net/browse/CARE-3354
      logger.error(
        new Error(
          `Mutation removeHomeworkFromMember failed, homework has not been unassigned.`,
        ),
        {
          contentId,
          memberId,
        },
      );
    }

    return false;
  };
  return removeHomework;
}
