import { GetMemberSurvey_getMember_surveyResponses } from '@headspace/carehub-graphql/dist/queries/generated/GetMemberSurvey';
import CSSRSLineChart from 'app/charts/CSSRSLineChart';
import { Gad7LineChart } from 'app/charts/Gad7LineChart';
import { Phq9LineChart } from 'app/charts/Phq9LineChart';
import PSSLineChart from 'app/charts/PSSLineChart';
import RISC10LineChart from 'app/charts/RISC10LineChart';
import { viewSurveyTabAction } from 'app/state/amplitude/actions/surveys';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMemberChartIndicators } from 'hooks/useMemberChartIndicators';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from 'shared-components/Box';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { NavigationTabs } from 'shared-components/tabs/NavigationTabs';
import { classNameCombiner } from 'utils';

import IncompleteSurveyWarning from './IncompleteSurveyWarning';
import Mugs from './Mugs.png';
import styles from './SurveysTabV3All.module.scss';
import { formatCSSRS } from './SurveysV3CSSRS';
import { formatGAD7 } from './SurveysV3GAD';
import { formatPHQ9 } from './SurveysV3PHQ';
import { formatPSS } from './SurveysV3PSS';
import { formatRISC10 } from './SurveysV3RISC';
import { useTabLinksForSurveyV3 } from './utils';

interface Props {
  id: string;
  pathPrefix: string;
  surveyResponses: GetMemberSurvey_getMember_surveyResponses;
  coachingPaths?: boolean;
}

const SurveysTabV3All = (props: Props) => {
  const { id, pathPrefix, surveyResponses, coachingPaths = false } = props;
  const tabs = useTabLinksForSurveyV3(pathPrefix, id, coachingPaths);
  const {
    enable_adaptability_surveys_v3,
    enable_cssrs_score_tab,
  } = useFeatureFlags().transientFeatureFlags;

  const phq9s = formatPHQ9(surveyResponses);
  const gad7s = formatGAD7(surveyResponses);
  const risc10s = formatRISC10(surveyResponses);
  const pssSurveys = formatPSS(surveyResponses);
  const cssrsSurveys = formatCSSRS(surveyResponses);

  const {
    updateSurveyScoreIndicator,
    showSurveyScoreIndicator,
  } = useMemberChartIndicators(id);

  const updateIndicator = async () => {
    await updateSurveyScoreIndicator?.();
  };

  useEffect(() => {
    if (showSurveyScoreIndicator) {
      void updateIndicator();
    }
  }, [showSurveyScoreIndicator]);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSurveyTabAction());
  }, [dispatch]);

  const mugsContent = (
    <>
      <img src={Mugs} alt="Mugs" />
      <p className={styles.mugsText}>
        Member has not completed any surveys yet
      </p>
    </>
  );
  let content;
  if (
    !phq9s.length &&
    !gad7s.length &&
    !risc10s.length &&
    !pssSurveys.length &&
    !cssrsSurveys.length
  ) {
    content = mugsContent;
  } else {
    content = (
      <Grid>
        <Columns widths={[COLUMN_WIDTH.FULL]}>
          <Box>
            <NavigationTabs links={tabs} preserveQueries={true} />
            <div
              data-testid="phq-line-chart"
              className={classNameCombiner([styles.graph, styles.border])}
            >
              {phq9s.length >= 1 ? (
                <>
                  <Phq9LineChart responses={phq9s} />
                </>
              ) : (
                <IncompleteSurveyWarning />
              )}
            </div>
            <div
              data-testid="gad-line-chart"
              className={classNameCombiner([styles.graph, styles.border])}
            >
              {gad7s.length >= 1 ? (
                <>
                  <Gad7LineChart responses={gad7s} />
                </>
              ) : (
                <IncompleteSurveyWarning />
              )}
            </div>
            <div
              data-testid="pss-line-chart"
              className={classNameCombiner([styles.graph, styles.border])}
            >
              {pssSurveys.length > 0 ? (
                <>
                  <PSSLineChart responses={pssSurveys} />
                </>
              ) : (
                <IncompleteSurveyWarning />
              )}
            </div>
            {enable_cssrs_score_tab ? (
              <div
                data-testid="cssrs-line-chart"
                className={classNameCombiner([styles.graph, styles.border])}
              >
                {cssrsSurveys.length > 0 ? (
                  <>
                    <CSSRSLineChart responses={cssrsSurveys} />
                  </>
                ) : (
                  <IncompleteSurveyWarning />
                )}
              </div>
            ) : null}
            {enable_adaptability_surveys_v3 ? (
              <div data-testid="risc10-line-chart" className={styles.graph}>
                {risc10s.length >= 1 ? (
                  <>
                    <RISC10LineChart responses={risc10s} />
                  </>
                ) : (
                  <IncompleteSurveyWarning />
                )}
              </div>
            ) : null}
          </Box>
        </Columns>
      </Grid>
    );
  }
  return (
    <div className={styles.body}>
      <div className={styles.content}>{content}</div>
    </div>
  );
};

export default SurveysTabV3All;
