import CloseIcon from '@mui/icons-material/Close';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
} from '@mui/material';
import { Button } from 'shared-components/button/Button';
import React, { ReactNode, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import styles from './ActionModal.module.scss';

type DeleteNoteDialogProps = {
  isOpen: boolean;
  isConfirmDisabled?: boolean;
  title: string;
  onClose: () => void;
  onConfirm: (() => Promise<void>) | (() => void);
  children?: ReactNode;
  actionLabel?: string;
  variation?: 'default' | 'modernWarning';
};

export function ActionModal(props: DeleteNoteDialogProps) {
  const {
    isOpen,
    actionLabel = 'Confirm',
    isConfirmDisabled,
    variation = 'default',
  } = props;
  const [processing, setProcessing] = useState(false);
  const variationStyle = {
    default: {
      actionBtn: styles.actionBtn,
      btn: styles.btn,
      buttonContainer: styles.buttonContainer,
      cancelBtn: styles.btn,
      closeIcon: '',
      modalBody: styles.modalBody,
      modalTitle: styles.modalTitle,
      root: styles.root,
      titleText: styles.titleText,
    },
    modernWarning: {
      actionBtn: styles.modernWarningActionBtn,
      btn: styles.modernWarningBtn,
      buttonContainer: styles.buttonContainer,
      cancelBtn: styles.cancelBtn,
      closeIcon: styles.closeIcon,
      modalBody: styles.modalBody,
      modalTitle: styles.modalTitle,
      root: styles.root,
      titleText: styles.titleText,
    },
  };
  const style = variationStyle[variation];

  const onConfirm = async () => {
    setProcessing(true);
    await props.onConfirm();
    setProcessing(false);
  };

  const onClose = () => {
    if (!processing) props.onClose();
  };
  return (
    <Dialog
      data-testid="action-modal"
      open={isOpen}
      onClose={onClose}
      className={style.root}
      maxWidth="lg"
      classes={{ paper: style.root }}
    >
      <DialogTitle className={style.modalTitle}>
        <span className={style.titleText}>{props.title}</span>
        <IconButton aria-label="close" onClick={onClose} size="medium">
          <CloseIcon className={style.closeIcon} />
        </IconButton>
      </DialogTitle>
      <DialogContent className={style.modalBody}>
        {props.children}
      </DialogContent>
      <DialogActions className={style.buttonContainer}>
        <Button
          className={style.cancelBtn}
          disabled={processing}
          size="medium"
          onClick={onClose}
          variant="outlined"
          fullWidth={false}
        >
          Cancel
        </Button>
        <Button
          disabled={processing || isConfirmDisabled}
          size="medium"
          className={style.actionBtn}
          onClick={onConfirm}
          fullWidth={false}
          testId="confirm-btn"
        >
          {processing && (
            <>
              <ClipLoader color="#fff" size={12} />
              &nbsp;
            </>
          )}
          {actionLabel}
        </Button>
      </DialogActions>
    </Dialog>
  );
}
