import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  PsychiatryIntakeSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { NoteType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetAppointmentById_getAppointmentById as Appointment,
  GetAppointmentById_getAppointmentById,
} from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import { ShareableClinicianNoteType } from 'app/coach/coach-notes/CoachNotesTypes';
import { ClinicalNoteUploadData } from 'app/notes-ui/qa-checks/types';
import { useUploadClinicalNoteForQaChecks } from 'app/notes-ui/qa-checks/useUploadClinicalNoteForQaChecks';
import { useAppState } from 'app/state';
import {
  deletedDraftClinicalNote,
  signedAndLockedClinicalNote,
  viewedClinicalNote,
  wroteClinicalNote,
} from 'app/state/amplitude/actions/notes';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import {
  PsychiatryIntakeNote,
  PsychiatryIntakeNotesAPI,
} from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { SubsectionType } from 'app/vault/api/ShareableSubsectionTypes';
import { useInitializePsychiatryIntakeNote } from 'app/vault/hooks/useInitializePsychiatryIntakeNote';
import { validatePsychiatryIntakeNote } from 'app/vault/validations/psychiatryIntake';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import Messages from 'i18n/en/vault.json';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTimezone } from 'utils/dateTime';
import { CareProviderNotesLabel } from 'utils/notes';

import { usePsychiatryIntakeNotesAPI } from './useClinicalNotesAPI';
import {
  isItemAlreadyExistsError,
  StateController,
  useStateSlice,
  UseTherapyNoteResult,
} from './utils';

export function usePsychiatryIntakeNote(
  userId: string,
  appointmentId: string,
): UseTherapyNoteResult<PsychiatryIntakeNote, PsychiatryIntakeSection> {
  const logger = useLogger();
  const api = usePsychiatryIntakeNotesAPI();
  const dispatch = useDispatch();
  const noteState = useStateSlice<PsychiatryIntakeNote>();
  const draftNoteState = useStateSlice<PsychiatryIntakeNote>();
  const [appointment, setAppointment] = useState<Appointment>();
  const timezone = useAppState((_) => getTimezone(_.user.timezone));
  const initialNote = useInitializePsychiatryIntakeNote();
  const {
    transientFeatureFlags: {
      enable_supervisor_sign_and_lock_notes_for_user: allowGroupWriteAccess,
    },
  } = useFeatureFlags();
  const { uploadClinicalNoteForQaChecks } = useUploadClinicalNoteForQaChecks();

  const isLockable = validatePsychiatryIntakeNote(noteState.state.current.data);

  useOnMount(() => {
    const analyticsEventData = {
      appointmentId,
      clinicianId: userId,
      label: CareProviderNotesLabel.NOTE_VIEWED,
      noteType: ShareableClinicianNoteType.PSYCHIATRY_INTAKE,
    };
    const callback = async () => {
      try {
        noteState.setLoading();
        const { appointment: appointmentData, note } = await initialNote({
          appointmentId,
          userId,
        });
        noteState.setData(note);
        draftNoteState.setData(note);
        setAppointment(appointmentData);
        logger.info(
          'usePsychiatryIntakeNote onMount succeeded',
          analyticsEventData,
        );
      } catch (e) {
        logger.error(e, {
          message: 'usePsychiatryIntakeNote onMount failed',
          ...analyticsEventData,
        });
        noteState.setError(e);
      }
    };
    callback();
    dispatch(
      viewedClinicalNote({
        ...analyticsEventData,
        label: CareProviderNotesLabel.NOTE_VIEWED,
      }),
    );
  });

  const createOrUpdateSection = useCallback(
    createOrUpdateSectionHandler(
      userId,
      appointment,
      api,
      noteState,
      allowGroupWriteAccess,
      dispatch,
      logger,
    ),
    [userId, appointment, api, noteState.state.current, allowGroupWriteAccess],
  );

  const updateDraftNoteState = useCallback(updateDraftState(draftNoteState), [
    draftNoteState.state.current,
  ]);

  const lockNote = useCallback(
    lockNoteHandler(
      appointment,
      api,
      noteState,
      allowGroupWriteAccess,
      dispatch,
      logger,
      uploadClinicalNoteForQaChecks,
    ),
    [
      appointment,
      api,
      noteState.state.current,
      timezone,
      userId,
      allowGroupWriteAccess,
    ],
  );

  const deleteDraft = useCallback(
    appointment && noteState.state.current.data
      ? deleteDraftHandler(
          userId,
          appointment,
          api,
          noteState.state.current.data,
          dispatch,
          logger,
        )
      : () => Promise.resolve({} as DeleteVaultItemsMutation),
    [appointment, api, noteState.state.current],
  );

  return {
    appointment,
    createOrUpdateSection,
    deleteDraft,
    draftNote: draftNoteState.state,
    isLockable,
    lockNote,
    note: noteState.state,
    updateDraftNoteState,
  };
}

export const createOrUpdateSectionHandler = (
  userId: string,
  appointment: Appointment | undefined,
  api: PsychiatryIntakeNotesAPI,
  noteState: StateController<PsychiatryIntakeNote>,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
  onCreateDraftNoteSectionHandler = createDraftNoteSectionHandler,
  onUpdateDraftNoteSectionHandler = updateDraftNoteSectionHandler,
): ((section: PsychiatryIntakeSection) => Promise<void>) => {
  return async (section: PsychiatryIntakeSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `${Messages.failureToCreateOrUpdateNoteSection}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(Messages.appointmentNotDefined);
    }

    if (api.amendmentSectionName === section.name) {
      return createAmendment(
        userId,
        appointment,
        api,
        noteState,
        section,
        dispatch,
        logger,
      );
    }

    if (note[section.name] === null) {
      try {
        await onCreateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      } catch (e) {
        if (!isItemAlreadyExistsError(e)) {
          logger.info(
            'usePsychiatryIntakeNote: onCreateDraftNoteSectionHandler skipping retry',
            { error: e },
          );
          throw e;
        }
        logger.info(
          `usePsychiatryIntakeNote: onCreateDraftNoteSectionHandler failed for section ${section.name}, retrying as update...`,
          {
            appointmentId: appointment.id,
            clinicianId: userId,
            error: e,
            label: CareProviderNotesLabel.NOTE_CREATED,
            noteType: ShareableClinicianNoteType.PSYCHIATRY_INTAKE,
            section: section.name,
          },
        );
        return onUpdateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      }
    } else if (note[section.name] !== null) {
      await onUpdateDraftNoteSectionHandler({
        allowGroupWriteAccess,
        api,
        appointment,
        dispatch,
        logger,
        note,
        noteState,
        section,
        userId,
      });
    }
  };
};

export type createOrUpdateDraftNoteSectionProps = {
  note: PsychiatryIntakeNote;
  section: PsychiatryIntakeSection;
  api: PsychiatryIntakeNotesAPI;
  userId: string;
  appointment: GetAppointmentById_getAppointmentById;
  allowGroupWriteAccess: boolean;
  noteState: StateController<PsychiatryIntakeNote>;
  dispatch: ReturnType<typeof useDispatch>;
  logger: ILogger;
};

export const createDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const amplitudePayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_CREATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.PSYCHIATRY_INTAKE,
    section: section.name,
  };
  const needToCreateMetadata =
    note.metadata.status === Metadata_NoteStatus.undefined_note_status;

  const updatedNote: PsychiatryIntakeNote = {
    ...note,
    metadata: {
      ...note.metadata,
      status: Metadata_NoteStatus.draft_note,
    },
    [section.name]: section.data,
  };

  await api.createDraftNoteSection(
    userId,
    appointment,
    section,
    needToCreateMetadata ? updatedNote.metadata : undefined,
    allowGroupWriteAccess,
  );
  logger.info(
    `usePsychiatryIntakeNote: createOrUpdateSectionHandler successfully created draft section ${section.name}`,
    { ...amplitudePayload },
  );

  noteState.setData(updatedNote);
  dispatch(
    wroteClinicalNote({
      ...amplitudePayload,
      success: true,
    }),
  );
};

export const updateDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const amplitudePayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_UPDATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.PSYCHIATRY_INTAKE,
    section: section.name,
  };
  const updatedNote = { ...note, [section.name]: section.data };

  try {
    await api.updateDraftNoteSection(
      userId,
      appointment,
      section,
      undefined,
      allowGroupWriteAccess,
    );
    logger?.info(
      `usePsychiatryIntakeNote: createOrUpdateSectionHandler successfully updated draft section ${section.name}`,
      { ...amplitudePayload },
    );
  } catch (e) {
    logger?.error(e, {
      message: 'usePsychiatryIntakeNote: failed to updateDraftNoteSection',
      ...amplitudePayload,
    });
    dispatch(
      wroteClinicalNote({
        ...amplitudePayload,
        success: false,
      }),
    );
    throw e;
  }
  noteState.setData(updatedNote);
  dispatch(
    wroteClinicalNote({
      ...amplitudePayload,
      success: true,
    }),
  );
};

function updateDraftState(
  noteState: StateController<PsychiatryIntakeNote>,
): (section: PsychiatryIntakeSection) => void {
  return (section: PsychiatryIntakeSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `${Messages.failureToUpdateDraftState}: ${noteState.state.current.status}`,
      );
    }

    if (section.name === PsychiatrySectionName.AMENDMENTS) return;

    noteState.setData({ ...note, [section.name]: section.data });
  };
}

function lockNoteHandler(
  appointment: GetAppointmentById_getAppointmentById | undefined,
  api: PsychiatryIntakeNotesAPI,
  noteState: StateController<PsychiatryIntakeNote>,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
  uploadClinicalNoteForQaChecks: (
    props: ClinicalNoteUploadData,
  ) => Promise<void>,
): () => Promise<void> {
  return async () => {
    if (noteState.state.current.data === null) {
      throw new Error(
        `${Messages.failureToLockNote}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(Messages.appointmentNotDefined);
    }

    const appointmentId = appointment.id;
    const analyticsEventData = {
      appointmentId,
      clinicianId: appointment.clinician.userId,
      label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.PSYCHIATRY_INTAKE,
      safetyRiskEndorsed:
        noteState.state.current.data.SAFETY?.anyChangesOrRisks ===
        BooleanOption.yes,
    };
    const updatedNote: PsychiatryIntakeNote = {
      ...noteState.state.current.data,
      metadata: {
        appointmentId,
        status: Metadata_NoteStatus.signed_and_locked_note,
      },
    };

    try {
      await api.updateNoteMetadata(
        updatedNote.metadata,
        appointment.member.id,
        allowGroupWriteAccess,
      );
      logger.info(
        'usePsychiatryIntakeNote: lockNoteHandler successfully updateNoteMetadata',
        { ...analyticsEventData },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'usePsychiatryIntakeNote: lockNoteHandler failed to updateNoteMetadata',
        ...analyticsEventData,
      });
      dispatch(
        signedAndLockedClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
          success: false,
        }),
      );
      throw e;
    }

    try {
      await api.lockNoteSections(appointment);
      logger.info(
        'usePsychiatryIntakeNote: lockNoteHandler successfully lockNoteSections',
        { ...analyticsEventData },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'usePsychiatryIntakeNote: lockNoteHandler failed to lockNoteSections',
        ...analyticsEventData,
      });
      dispatch(
        signedAndLockedClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
          success: false,
        }),
      );
      throw e;
    }

    try {
      await api.createShareableSubsections(
        appointment,
        NoteType.PSYCHIATRY_INTAKE,
        {
          [SubsectionType.SAFETY_PLAN]: updatedNote.SAFETY?.safetyPlan,
          [SubsectionType.TREATMENT_PLAN_GOALS]:
            updatedNote.TREATMENT_PLAN?.goal,
          [SubsectionType.MESSAGE_TO_CARE_TEAM]:
            updatedNote.TREATMENT_PLAN?.messageToCareTeam,
        },
      );
      logger.info(
        'usePsychiatryIntakeNote: lockNoteHandler successfully createShareableSubsections',
        {
          ...analyticsEventData,
        },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'usePsychiatryIntakeNote: lockNoteHandler failed to createShareableSubsections. Continuing anyway.',
        ...analyticsEventData,
      });
      dispatch(
        signedAndLockedClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
          success: false,
        }),
      );
    }

    noteState.setData(updatedNote);

    dispatch(
      signedAndLockedClinicalNote({
        ...analyticsEventData,
        label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
        success: true,
      }),
    );
    // usePsychiatryIntakeNote: Submit the signed and locked note for Brellium QA checks
    await uploadClinicalNoteForQaChecks({
      appointmentMetadata: appointment,
      clinicalNote: updatedNote,
      noteType: NoteType.PSYCHIATRY_INTAKE,
    });
  };
}

async function createAmendment(
  userId: string,
  appointment: Appointment,
  api: PsychiatryIntakeNotesAPI,
  noteState: StateController<PsychiatryIntakeNote>,
  section: PsychiatryIntakeSection,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
) {
  const note = noteState.state.current.data;
  if (note === null) {
    throw new Error(
      `${Messages.failureToCreateAmendmentSection}: ${noteState.state.current.status}`,
    );
  }

  const analyticsEventData = {
    appointmentId: appointment.id,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_AMENDED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.PSYCHIATRY_INTAKE,
    section: section.name,
  };

  try {
    await api.createAmendment(userId, appointment.id, section);
  } catch (e) {
    logger.error(e, {
      message: `usePsychiatryIntakeNote: failed to create amendment`,
      ...analyticsEventData,
    });
    dispatch(
      wroteClinicalNote({
        ...analyticsEventData,
        success: false,
      }),
    );
    throw e;
  }

  const updatedNote: PsychiatryIntakeNote = {
    ...note,
    [section.name]: section.data,
  };
  noteState.setData(updatedNote);
  dispatch(
    wroteClinicalNote({
      ...analyticsEventData,
      success: true,
    }),
  );
}

function deleteDraftHandler(
  userId: string,
  appointment: GetAppointmentById_getAppointmentById,
  api: PsychiatryIntakeNotesAPI,
  note: PsychiatryIntakeNote,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
): () => Promise<DeleteVaultItemsMutation> {
  return async () => {
    const analyticsEventData = {
      appointmentId: note.metadata.appointmentId,
      clinicianId: userId,
      label: CareProviderNotesLabel.NOTE_DELETED,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.PSYCHIATRY_INTAKE,
    };

    try {
      const deletePromise = await api.deleteDraftNote(
        userId,
        appointment,
        note,
      );
      dispatch(
        deletedDraftClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_DELETED,
          success: true,
        }),
      );
      logger.info('usePsychiatryIntakeNote: deleted draft note', {
        ...analyticsEventData,
      });
      return deletePromise;
    } catch (e) {
      logger.error(e, {
        ...analyticsEventData,
        message: 'usePsychiatryIntakeNote: failed to delete draft note',
      });
      dispatch(
        deletedDraftClinicalNote({
          ...analyticsEventData,
          label: CareProviderNotesLabel.NOTE_DELETED,
          success: false,
        }),
      );
      throw e;
    }
  };
}
