import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { SerializedError } from '@reduxjs/toolkit';
import { AdditionalLogData } from 'app/state/log/actions';
import { InboxVersion } from 'app/inboxV2/types';

export type UserInfo = {
  role: UserRole;
  userId: string;
  listenerId?: string;
  inboxVersion: InboxVersion;
};

export enum LogLevel {
  DEBUG = 0,
  INFO = 1,
  WARNING = 2,
  ERROR = 3,
}

export interface ILogger {
  debug(message: string, additionalData?: AdditionalLogData): void;

  info(message: string, additionalData?: AdditionalLogData): void;

  warning(message: string, additionalData?: AdditionalLogData): void;

  error(
    error: Error | SerializedError,
    additionalData?: AdditionalLogData,
  ): void;

  setUser(user: UserInfo): void;

  setLogLevel(logLevel: LogLevel): void;

  log(
    logLevel: LogLevel,
    message: string,
    additionalData?: AdditionalLogData,
  ): void;
}
