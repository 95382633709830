import { RootState } from 'app/state/hooks/baseTypedHooks';

export const selectSideBar = (state: RootState) => state.sideBar;

export const getShowInbox = (state: RootState) => state.sideBar.showInbox;

export const getShowInboxDrawer = (state: RootState) =>
  state.sideBar.showInboxDrawer;

export const getSelectedTab = (state: RootState) => state.sideBar.selectedTab;
