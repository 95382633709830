import {
  PsychiatryProgressSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import {
  ClinicalNote,
  ClinicalNotesScreenV2,
  Section,
} from 'app/notes-ui/ClinicalNotesScreenV2';
import {
  AssessmentFormV2,
  validate as validateAssessment,
} from 'app/notes-ui/psychiatry/progress/assessment/AssessmentFormV2';
import {
  ChiefComplaintFormV2,
  validate as validateChiefComplaint,
} from 'app/notes-ui/psychiatry/shared/chief-complaint/ChiefComplaintFormV2';
import {
  MedicalHistoryFormV2,
  validate as validateMedicalHistory,
} from 'app/notes-ui/psychiatry/shared/medical-history/MedicalHistoryFormV2';
import {
  MedicalReviewOfSystemFormV2,
  validate as validateMedicalReviewOfSystem,
} from 'app/notes-ui/psychiatry/shared/medical-ros/MedicalReviewOfSystemFormV2';
import {
  MentalStatusExamFormV2,
  validate as validateMentalStatusExam,
} from 'app/notes-ui/psychiatry/shared/mental-status-exam/MentalStatusExamFormV2';
import {
  PsychReviewOfSystemFormV2,
  validate as validatePsychReviewOfSystem,
} from 'app/notes-ui/psychiatry/shared/psych-ros/PsychReviewOfSystemFormV2';
import {
  PsychiatricHistoryFormV2,
  validate as validatePsychiatricHistory,
} from 'app/notes-ui/psychiatry/shared/psychiatric-history/PsychiatricHistoryFormV2';
import {
  SocialHistoryFormV2,
  validate as validateSocialHistory,
} from 'app/notes-ui/psychiatry/shared/social-history/SocialHistoryFormV2';
import { SubstanceAbuseFormV2 } from 'app/notes-ui/psychiatry/shared/substance-abuse/SubstanceAbuseFormV2';
import {
  TreatmentPlanFormV2,
  validate as validateTreatmentPlan,
} from 'app/notes-ui/psychiatry/shared/treatment-plan/TreatmentPlanFormV2';
import { validatePsychiatry as validateSafety } from 'app/notes-ui/shared/safety-intake/schema';
import { validateV2 as validateSubstanceUsed } from 'app/notes-ui/shared/substance-abuse/schema';
import { NoteHeaderItems } from 'app/notes-ui/therapy/TherapyIntakeNoteContainerv2';
import { getPsychiatryV2SectionLabel } from 'app/notes-ui/utils';
import { Status } from 'app/state/status/types/StateSlice';
import { PsychiatryProgressIds } from 'app/vault/api/PsychiatryProgressIds';
import { PsychiatryProgressNote } from 'app/vault/api/PsychiatryProgressNotesAPI';
import React, { ElementType } from 'react';

import {
  ClinicianChecklistFormV2,
  validate as validateClinicianChecklist,
} from '../shared/clinician-checklist/ClinicianChecklistFormV2';
import { SafetyFormV2 } from './safety/SafetyFormV2';

export type Props = {
  appointmentId: string;
  memberId?: string;
  status: Status;
  note: PsychiatryProgressNote;
  onSubmit: (section: PsychiatryProgressSection) => Promise<void>;
  readOnly: boolean;
  noteheader: NoteHeaderItems;
};

function section<T extends PsychiatryProgressSection>(
  name: T['name'],
  form: ElementType<{
    appointmentId: string;
    noteLastUpdatedAt?: string;
    updateDraftNoteState: (section: T) => void;
    onSubmit: (section: T) => void;
    initialValue: T['data'];
  }>,
  validator: (data: T['data']) => boolean,
): Section<T> {
  return [name, getPsychiatryV2SectionLabel(name), form, validator];
}

const sections = [
  section(
    PsychiatrySectionName.CLINICIAN_CHECKLIST,
    ClinicianChecklistFormV2,
    validateClinicianChecklist,
  ),
  section(
    PsychiatrySectionName.CHIEF_COMPLAINT,
    ChiefComplaintFormV2,
    validateChiefComplaint,
  ),
  section(
    PsychiatrySectionName.PSYCH_ROS,
    PsychReviewOfSystemFormV2,
    validatePsychReviewOfSystem,
  ),
  section(
    PsychiatrySectionName.MEDICAL_ROS,
    MedicalReviewOfSystemFormV2,
    validateMedicalReviewOfSystem,
  ),
  section(PsychiatrySectionName.SAFETY, SafetyFormV2, validateSafety),
  section(
    PsychiatrySectionName.PSYCH_HISTORY,
    PsychiatricHistoryFormV2,
    validatePsychiatricHistory,
  ),
  section(
    PsychiatrySectionName.MEDICAL_HISTORY,
    MedicalHistoryFormV2,
    validateMedicalHistory,
  ),
  section(
    PsychiatrySectionName.SOCIAL_HISTORY,
    SocialHistoryFormV2,
    validateSocialHistory,
  ),
  section(
    PsychiatrySectionName.SUBSTANCE_ABUSE,
    SubstanceAbuseFormV2,
    validateSubstanceUsed,
  ),
  section(
    PsychiatrySectionName.MENTAL_STATUS_EXAM,
    MentalStatusExamFormV2,
    validateMentalStatusExam,
  ),
  section(
    PsychiatrySectionName.ASSESSMENT,
    AssessmentFormV2,
    validateAssessment,
  ),
  section(
    PsychiatrySectionName.TREATMENT_PLAN,
    TreatmentPlanFormV2,
    validateTreatmentPlan,
  ),
];

export function PsychiatryProgressNoteScreenV2(props: Props) {
  const {
    appointmentId,
    note,
    status,
    memberId,
    onSubmit,
    noteheader,
    readOnly,
  } = props;

  return (
    <ClinicalNotesScreenV2
      appointmentId={appointmentId}
      status={status}
      onSubmit={onSubmit}
      memberId={memberId}
      sections={sections}
      ids={PsychiatryProgressIds}
      note={note as ClinicalNote<PsychiatryProgressSection>}
      readOnly={readOnly}
      noteHeader={noteheader}
    />
  );
}
