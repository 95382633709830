import { ApolloClient } from '@apollo/client';
import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  InboxConvQuery,
  InboxConvQueryVariables,
} from '@headspace/carehub-graphql/dist/inbox/generated/InboxConvQuery';
import {
  UserQuery,
  UserQueryVariables,
} from '@headspace/carehub-graphql/dist/inbox/generated/UserQuery';
import {
  clinicalMemberInboxItems,
  coachingMemberInboxItems,
} from '@headspace/carehub-graphql/dist/inbox/queries';
import {
  coachInboxQuery,
  coachTodaysInbox,
} from '@headspace/carehub-graphql/dist/queries/CareProviderInbox';
import {
  CoachInbox,
  CoachInboxVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/CoachInbox';
import {
  CoachTodaysInbox,
  CoachTodaysInbox_closedConversations_items,
  CoachTodaysInbox_completedTasks,
  CoachTodaysInboxVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/CoachTodaysInbox';
import { InboxItemFragment } from '@headspace/carehub-graphql/dist/queries/generated/InboxItemFragment';
import { assertResponseHasNoError } from 'app/inbox/assertResponseHasNoError';
import { InboxItem, Results } from 'app/inbox/types';
import {
  getCoachTodaysInboxVariables,
  gqlAllMemberResponseToInboxItem,
  gqlTasksResponseToInboxItem,
  gqlTodaysCoachingConvoResponseToInboxItem,
  mergeClosedConversationAndCompletedTaskAndSort,
} from 'app/inbox/utils';
import { ILogger } from 'app/state/log/Logger';
import memberChartStorage from 'app/state/member-tabs/memberChartStorage';
import { isClinicianOrSupervisor, isCoachOrSupervisor } from 'utils';

export type GetCoachTodaysMember = Awaited<
  ReturnType<typeof getCoachTodaysMembers>
>;

export type GetCoachAllMember = Awaited<ReturnType<typeof getCoachAllMembers>>;

export async function getCoachTodaysMembers(params: {
  apollo: ApolloClient<object>;
  timezone: string;
  logger: ILogger;
  variables: CoachTodaysInboxVariables;
}) {
  const { apollo, timezone, logger, variables } = params;

  const response = await apollo.query<
    CoachTodaysInbox,
    CoachTodaysInboxVariables
  >({
    errorPolicy: 'all',
    fetchPolicy: 'network-only',
    query: coachTodaysInbox,
    variables,
  });

  assertResponseHasNoError(response, logger, { query: coachTodaysInbox });
  const { data } = response;

  const coachingConversations = data.openConversations;
  const riskAlerts = data.riskTasks;
  const { completedTasks } = data;
  const { scheduledSessions } = data;
  const closedConvo = data.closedConversations;

  let activeTasksSection: Results | undefined;

  const scheduledSessionSection = gqlTodaysCoachingConvoResponseToInboxItem(
    scheduledSessions?.items ?? [],
    scheduledSessions?.pagination,
    timezone,
  );

  const riskAlertSection = gqlTasksResponseToInboxItem(
    riskAlerts?.items ?? [],
    riskAlerts?.pagination,
    timezone,
    true,
  );

  const pagination = coachingConversations?.pagination;
  const convosSection = gqlTodaysCoachingConvoResponseToInboxItem(
    coachingConversations?.items ?? [],
    pagination,
    timezone,
  );
  const shouldLoadMoreFromTaskEndpoint = Boolean(
    coachingConversations &&
      !pagination.hasNextPage &&
      pagination.totalCount < pagination.maxItemsPerPage,
  );
  const excludeMemberIds = [
    ...convosSection.items.map((_) => _.id),
    ...scheduledSessionSection.items.map((_) => _.id),
  ];

  if (shouldLoadMoreFromTaskEndpoint) {
    const coachTodaysInboxVariables: CoachTodaysInboxVariables = getCoachTodaysInboxVariables(
      timezone,
      {
        activeTasksPagination: {
          cursor: null,
          maxItemsPerPage: pagination.maxItemsPerPage - pagination.totalCount,
        },
        excludeMemberIds,
        includeActiveTasks: true,
      },
    );
    const { data: queryData, errors } = await apollo.query<
      CoachTodaysInbox,
      CoachTodaysInboxVariables
    >({
      errorPolicy: 'all',
      fetchPolicy: 'network-only',
      query: coachTodaysInbox,
      variables: coachTodaysInboxVariables,
    });

    if (errors)
      logger.error(
        new Error(`getCoachTodaysMembers::Unable to fetch Task items`),
        { errors },
      );
    const activeTasks = queryData?.activeTasks;
    activeTasksSection = gqlTasksResponseToInboxItem(
      activeTasks?.items ?? [],
      activeTasks?.pagination,
      timezone,
    );
  }
  const excludeMemberIdsSet = new Set(excludeMemberIds);

  const closedConvoItems = (closedConvo?.items ?? []).filter(
    excludeMemberIdPredicate,
  );
  const completedTasksItems = (completedTasks ?? []).filter(
    excludeMemberIdPredicate,
  );
  const completedSection = {
    cursor: null,
    hasMore: false,
    items: mergeClosedConversationAndCompletedTaskAndSort(
      closedConvoItems,
      completedTasksItems,
      timezone,
    ),
  };
  return {
    activeTasksSection,
    completedSection,
    convosSection,
    response,
    riskAlertSection,
    scheduledSessionSection,
  };
  function excludeMemberIdPredicate(
    item:
      | CoachTodaysInbox_closedConversations_items
      | CoachTodaysInbox_completedTasks,
  ) {
    return !item.member?.id || !excludeMemberIdsSet.has(item.member?.id);
  }
}

export async function getCoachAllMembers(
  apollo: ApolloClient<object>,
  timezone: string,
  logger: ILogger,
  query?: string,
) {
  let input;
  if (query) input = { query };
  const response = await apollo.query<CoachInbox, CoachInboxVariables>({
    errorPolicy: 'all',
    query: coachInboxQuery,
    variables: {
      input,
      isCoach: true,
    },
  });

  assertResponseHasNoError(response, logger, { query: coachInboxQuery });
  const { data, errors } = response;
  if (errors) {
    logger.error(
      new Error('CoachAllMembers returned partial data with an error'),
      { errors, hasInputQuery: !!query },
    );
  }

  const { scheduled, closed, past } = data.getCoachingMembersForMe;
  const scheduledSection = gqlAllMemberResponseToInboxItem(
    scheduled.items,
    scheduled.pagination,
  );
  const pastSection = gqlAllMemberResponseToInboxItem(
    past.items,
    past.pagination,
  );
  const closedSection = gqlAllMemberResponseToInboxItem(
    closed.items,
    closed.pagination,
  );

  return { closedSection, data, pastSection, scheduledSection };
}

export async function loadOpenTabInboxItems(params: {
  inboxItems: InboxItem[];
  apollo: ApolloClient<object>;
  logger: ILogger;
  role: UserRole;
}) {
  const { inboxItems, apollo, logger, role } = params;
  const { openTabs } = memberChartStorage.get();
  const idsWithoutConvoStats = new Set<string>();

  if (isCoachOrSupervisor(role)) {
    inboxItems
      .filter((_) => !_.conversationStats)
      .forEach((_) => idsWithoutConvoStats.add(_.id));
  }

  // load inbox conversation data for ids in the openTabs state but not in the all or today's member results
  // also load conversation stats for members with active tasks & risk alert, since these members are coming from Web
  const idSet = new Set(inboxItems.map((_) => _.id));
  const memberIds = openTabs
    .filter((id) => !idSet.has(id))
    .concat(Array.from(idsWithoutConvoStats));

  let openTabsItems: InboxItem[] = [];
  try {
    const itemLoader = isClinicianOrSupervisor(role)
      ? loadClinicalMemberInboxItems
      : loadCoachingMemberInboxItems;
    const openTabMaps = await itemLoader(apollo, memberIds, logger);
    ({ items: openTabsItems } = gqlAllMemberResponseToInboxItem(openTabMaps));
  } catch (error) {
    logger.error(
      new Error('Unable fetch member details the open tab member ids'),
      { error, memberIds },
    );
  }
  return openTabsItems;
}

async function loadCoachingMemberInboxItems(
  apollo: ApolloClient<object>,
  ids: string[],
  logger: ILogger,
): Promise<InboxItemFragment[]> {
  if (ids.length === 0) return [];

  const response = await apollo.query<InboxConvQuery, InboxConvQueryVariables>({
    query: coachingMemberInboxItems,
    variables: { memberIds: ids },
  });

  assertResponseHasNoError(response, logger, {
    query: coachingMemberInboxItems,
  });
  const { data } = response;
  return data.getInboxConversationByIds as InboxItemFragment[];
}

async function loadClinicalMemberInboxItems(
  apolloClient: ApolloClient<object>,
  ids: string[],
  logger: ILogger,
): Promise<InboxItemFragment[]> {
  if (ids.length === 0) return [];
  const response = await apolloClient.query<UserQuery, UserQueryVariables>({
    query: clinicalMemberInboxItems,
    variables: { ids },
  });
  assertResponseHasNoError(response, logger, {
    query: clinicalMemberInboxItems,
  });
  const { data } = response;
  return data.getMembersByIds.map(
    (member) => ({ member } as InboxItemFragment),
  );
}
