import { InboxSectionHeaderColor } from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';

import { InboxSectionItem } from '../InboxSectionItem';
import { InboxSectionWrapper } from '../InboxSectionWrapper';
import { MainContentText, SubContentText } from '../SectionContentItems';

const dummyResponse = [
  {
    additionalContent: 'Follow-up @ 10:00 AM PST',
    name: 'Test',
  },

  {
    additionalContent: 'Follow-up @ 1:00 PM PST',
    name: 'Test 2',
  },

  {
    additionalContent: 'Follow-up @ 3:00 PM PST',
    name: 'Test 3',
  },
];

export const ScheduledSection = () => {
  return (
    <InboxSectionWrapper
      headerColor={InboxSectionHeaderColor.LIGHT_CLOUD}
      sectionTitle="Scheduled"
      section={InboxSections.SCHEDULED}
      sectionItemCount={dummyResponse.length}
    >
      {dummyResponse.map(({ name, additionalContent }) => (
        <InboxSectionItem
          key={`${name}-scheduled-item`}
          topLeft={<MainContentText label={name} />}
          bottomLeft={<SubContentText label={additionalContent} />}
          onClick={() => console.log('clicked')}
        />
      ))}
    </InboxSectionWrapper>
  );
};
