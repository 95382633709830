import {
  TodaysMemberChannels,
  TodaysMemberChannels_conversations_items,
  TodaysMemberChannels_scheduled_items,
} from '@headspace/carehub-graphql/dist/state/generated/TodaysMemberChannels';
import {
  TodaysMemberTaskChannels,
  TodaysMemberTaskChannels_tasks_items,
  TodaysMemberTaskChannelsVariables,
} from '@headspace/carehub-graphql/dist/state/generated/TodaysMemberTaskChannels';
import {
  todaysMemberChannels,
  todaysMemberTaskChannels,
} from '@headspace/carehub-graphql/dist/state/InboxActionHandlersQueries';

import { triggerPubnubChannelSubscription } from '../actions';
import { ActionHandler } from './types';

export const onBulkSubscribeToTodaysMemberChannels = async ({
  action,
  redux,
  context,
}: ActionHandler<string[]>) => {
  const { logger } = context.services;

  const fallbackChannels = action.payload;
  const channels: string[] = [...fallbackChannels];
  const timezone = redux.getState().user.timezone!;
  const memberWithNoChannelId: Record<string, any> = {};

  try {
    const { data, errors } = await context.services.apollo.query<
      TodaysMemberChannels
    >({
      fetchPolicy: 'network-only',
      query: todaysMemberChannels,
    });
    if (data) {
      // get members with active tasks and closed conversation
      const { scheduled, conversations } = data;
      const memberIds: string[] = [];

      scheduled.items.forEach((item: TodaysMemberChannels_scheduled_items) => {
        if (item.conversationStats?.memberCoachChannelId) {
          channels.push(item.conversationStats?.memberCoachChannelId);
          memberIds.push(item.member.id);
        } else {
          memberWithNoChannelId[item.member.id] = item.conversationStats;
        }
      });

      conversations.items.forEach(
        (item: TodaysMemberChannels_conversations_items) => {
          if (item.conversationStats?.memberCoachChannelId) {
            channels.push(item.conversationStats?.memberCoachChannelId);
            memberIds.push(item.member.id);
          } else {
            memberWithNoChannelId[item.member.id] = item.conversationStats;
          }
        },
      );

      const results = await context.services.apollo.query<
        TodaysMemberTaskChannels,
        TodaysMemberTaskChannelsVariables
      >({
        fetchPolicy: 'network-only',
        query: todaysMemberTaskChannels,
        variables: { excludeMemberIds: memberIds, timezone },
      });
      if (results.data) {
        results.data.tasks.items.forEach(
          (item: TodaysMemberTaskChannels_tasks_items) => {
            if (item.conversationStats?.memberCoachChannelId) {
              channels.push(item.conversationStats?.memberCoachChannelId);
            } else if (item.member)
              memberWithNoChannelId[item.member.id] = item.conversationStats;
          },
        );
      }
    } else if (errors) {
      logger.error(
        new Error(
          `bulkSubscribeToTodaysMemberChannels: unable to load channels`,
        ),
        {
          errors,
          fallbackChannels,
        },
      );
    }
  } catch (error) {
    logger.error(
      new Error('bulkSubscribeToTodaysMemberChannels: unable to load channels'),
      { error, fallbackChannels },
    );
  }

  if (Object.keys(memberWithNoChannelId).length > 0) {
    logger.warning(
      'BulkSubscribe::found some members without a valid channelId',
      { memberWithNoChannelId },
    );
  }

  redux.dispatch(triggerPubnubChannelSubscription({ channelIds: channels }));
};
