import {
  GetMemberCareTeam_getMember,
  GetMemberCareTeam_getMember_clinicalCareTeam_current_psychiatrists,
  GetMemberCareTeam_getMember_clinicalCareTeam_current_therapists,
  GetMemberCareTeam_getMember_coachingCareTeam_current_coaches,
  GetMemberCareTeam_getMember_coachingCareTeam_current_ms,
  GetMemberCareTeam_getMember_coachingCareTeam_past_coaches,
} from '@headspace/carehub-graphql/dist/care-team/generated/GetMemberCareTeam';
import { CoachCareTeamRoleEnum } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetMemberCoachingTeam_getMember_coachingCareTeam_past_coaches } from '@headspace/carehub-graphql/dist/queries/generated/GetMemberCoachingTeam';

export type CareMemberItem = {
  id: string;
  role: 'coach' | 'si_coach' | 'therapist' | 'psychiatrist' | 'member_support';
  name: string;
  picture?: string;
  shiftRole?: string;
  status?: string;
  leadCoach?: boolean;
  specializations?: string[];
  totalMessagesExchanged?: number;
  sessionsCovered?: number;
  nextScheduledSession?: string;
  lastSession?: string;
  lastMessageExchanged?: string;
};

export type CareTeam = GetMemberCareTeam_getMember;

export interface CareMemberRoleLabels {
  [key: string]: string;
}

// These are types that represents the coaching care team
export type Coach =
  | GetMemberCareTeam_getMember_coachingCareTeam_current_coaches
  | GetMemberCareTeam_getMember_coachingCareTeam_past_coaches;
export type MS = GetMemberCareTeam_getMember_coachingCareTeam_current_ms;

export type CurrentCoach = GetMemberCareTeam_getMember_coachingCareTeam_current_coaches | null;

export type PastCoach = GetMemberCoachingTeam_getMember_coachingCareTeam_past_coaches | null;

// These are types that represents the clinical care team
export type Therapist = GetMemberCareTeam_getMember_clinicalCareTeam_current_therapists;
export type Psychiatrist = GetMemberCareTeam_getMember_clinicalCareTeam_current_psychiatrists;

// Coaches that are playing temporary roles to the member.
export const temporaryCoachCareTeamRoles: Set<CoachCareTeamRoleEnum> = new Set([
  CoachCareTeamRoleEnum.SI_ASSESSMENT,
  CoachCareTeamRoleEnum.BACKUP,
  CoachCareTeamRoleEnum.DROPIN,
]);
