import { ClinicalAppointmentType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { ClinicalNotes } from 'app/notes-ui/pdf/ClinicalNotePDF';
import {
  AiMagicIcon,
  AiMagicIconState,
} from 'app/sortable-table/note-header/AiMagicIcon';
import {
  Status,
  usePollAiNoteDraft,
} from 'app/sortable-table/note-header/usePollAiNoteDraft';
import { ReactComponent as CancelIcon } from 'assets/cancel.svg';
import { ReactComponent as CheckCircleIcon } from 'assets/check-circle.svg';
import {
  aiContentAdded as aiContentAddedText,
  buttonText,
  errorText,
  tooltipPending,
  tooltipReady,
} from 'i18n/en/aiNoteDraft.json';
import React from 'react';
import Loader from 'react-spinners/ClipLoader';
import { Button } from 'shared-components/button/Button';
import Tooltip from 'shared-components/tooltip/Tooltip';
import { classNameCombiner } from 'utils';

import styles from './AddAiContentButton.module.scss';

export const AUTO_DISMISS_TEXT_DELAY =
  process.env.NODE_ENV !== 'test' ? 5_000 : 0;

type Props = {
  appointmentId: string;
  memberId: string;
  noteType: ClinicalAppointmentType;
  onClick?: (content: ClinicalNotes) => void;
};

export function AddAiContentButton({
  onClick,
  memberId,
  appointmentId,
  noteType,
}: Readonly<Props>) {
  const draftNote = usePollAiNoteDraft({ appointmentId, memberId, noteType });
  const disabled = draftNote.status !== Status.Ready;
  const [aiContentAdded, setAiContentAdded] = React.useState(false);
  if (draftNote.status === Status.Loading) {
    return (
      <span
        data-testid="loading"
        className={classNameCombiner([styles.root, styles.flexCenter])}
      >
        <Loader size={16} loading={true} />
      </span>
    );
  }
  const handleClick = () => {
    if (draftNote.status === Status.Ready) {
      setAiContentAdded(true);
      onClick?.(draftNote.content);
      setTimeout(() => {
        setAiContentAdded(false);
      }, AUTO_DISMISS_TEXT_DELAY);
    }
  };

  return (
    <div
      data-testid="add-ai-note-btn"
      className={classNameCombiner([styles.root, styles.flexCenter])}
    >
      {draftNote.status === Status.Failed && (
        <span className={styles.flexCenter}>
          <CancelIcon className={styles.iconSize} />
          {errorText}
        </span>
      )}
      {draftNote.status === Status.Ready && aiContentAdded && (
        <span className={`${styles.successState} ${styles.flexCenter}`}>
          <CheckCircleIcon className={styles.iconSize} />
          {aiContentAddedText}
        </span>
      )}

      <Tooltip
        title={getTooltipTitle(draftNote.status)}
        enterDelay={0}
        enterTouchDelay={0}
        placement="top"
      >
        <span className={styles.btnContainer}>
          <Button
            testId="ai-button"
            className={styles.aiButton}
            variant="outlined"
            size="small"
            onClick={handleClick}
            disabled={disabled}
            color="inherit"
            startIcon={
              <AiMagicIcon
                state={
                  disabled ? AiMagicIconState.INACTIVE : AiMagicIconState.ACTIVE
                }
              />
            }
          >
            {buttonText}
          </Button>
        </span>
      </Tooltip>
    </div>
  );
}

function getTooltipTitle(status: Status): string | null {
  if (status === Status.NotReady) {
    return tooltipPending;
  }
  if (status === Status.Ready) {
    return tooltipReady;
  }
  return null;
}
