import {
  FieldDefinitions,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import styles from 'app/inbox/styles/InboxSearchBox.module.scss';
import { TextBoxField } from 'app/notes-ui/forms/fields';
import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

type InboxSearchBoxProps = {
  onSearch: (query: string) => Promise<void>;
  searchDefaultValue: string;
};

type FieldType = {
  input: string;
};
export function InboxSearchBox({
  onSearch,
  searchDefaultValue,
}: InboxSearchBoxProps) {
  const [loading, setLoading] = useState(false);

  const fieldDefs: FieldDefinitions<FieldType> = {
    input: stringField({ rules: [] }),
  };
  const { fields } = useForm<FieldType>(
    fieldDefs,
    { input: searchDefaultValue },
    {
      onStateChange: ({ input }) => {
        setLoading(true);
        onSearch(input).finally(() => setLoading(false));
      },
    },
  );
  return (
    <>
      <div className={styles.searchBoxWrapper}>
        <TextBoxField
          className={styles.searchBox}
          testId="clinicianInboxSearch"
          placeholder="Name or ID"
          label=""
          field={fields.input}
        />
        {loading && (
          <div className={styles.loader}>
            <ClipLoader size={12} color="#9e9e9e" />
          </div>
        )}
      </div>
    </>
  );
}
