import { ReportProblemOutlined as WarningIcon } from '@mui/icons-material';
import { Dialog, DialogContent, DialogTitle } from '@mui/material';
import { DialogMessage } from 'app/appointments/types';
import { useLogger } from 'app/state/log/useLogger';
import { classNameCombiner } from 'utils';
import { useSnackNotification } from 'hooks/useSnackNotification';
import React, { useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';
import { Button } from 'shared-components/button/Button';
import { MarkdownContent } from 'shared-components/markdown-content/MarkdownContent';

import styles from './ActionDialog.module.scss';

type ActionDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  actionHandler: (arg0?: any) => Promise<any>;
  dialogMessage: DialogMessage;
  showWarningIcon?: boolean;
  showNotifications?: boolean;
  className?: string;
  bodyClass?: string;
  cancelBtnClass?: string;
  confirmBtnClass?: string;
  titleText?: string;
  cancelLabel?: string;
  confirmLabel?: string;
  'data-testid'?: string;
};

export function ActionDialog({
  actionHandler,
  isOpen,
  setIsOpen,
  dialogMessage,
  showWarningIcon,
  showNotifications,
  className = '',
  bodyClass = '',
  cancelBtnClass = '',
  confirmBtnClass = '',
  titleText,
  cancelLabel = 'Cancel',
  confirmLabel = 'Confirm',
  'data-testid': testId = 'actionDialog',
}: ActionDialogProps) {
  const logger = useLogger();
  const [working, setWorking] = useState(false);
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();

  const onModalCancel = () => {
    setIsOpen(false);
  };

  const onModalConfirm = async () => {
    try {
      setWorking(true);
      await actionHandler();
      if (showNotifications) {
        showSuccessNotification(dialogMessage.success!);
      }
      setIsOpen(false);
    } catch (error) {
      logger.error(new Error('ActionDialog: Unable to complete action'), {
        displayedNotificationMessage: dialogMessage.error!,
        error,
      });
      if (showNotifications) {
        showErrorNotification(dialogMessage.error!);
      }
    }
    setWorking((prevState) => !prevState);
  };

  const _onClose = () => {
    if (!working) setIsOpen(false);
  };

  return (
    <Dialog
      data-testid={testId}
      open={isOpen}
      onClose={_onClose}
      classes={{ paper: classNameCombiner([styles.container, className]) }}
    >
      <DialogTitle>
        {showWarningIcon && <WarningIcon />}
        <span>{titleText}</span>
      </DialogTitle>
      <DialogContent data-testid="contentBody" className={styles.modalBody}>
        <MarkdownContent
          className={classNameCombiner([styles.modalText, bodyClass])}
        >
          {dialogMessage.message}
        </MarkdownContent>
        <div className={styles.buttonContainer}>
          <Button
            disabled={working}
            size="medium"
            onClick={onModalCancel}
            variant="outlined"
            className={classNameCombiner([cancelBtnClass])}
            fullWidth={false}
          >
            {cancelLabel}
          </Button>
          <Button
            testId="confirm"
            disabled={working}
            size="medium"
            className={classNameCombiner([styles.confirmBtn, confirmBtnClass])}
            onClick={onModalConfirm}
            fullWidth={false}
          >
            {working && <ClipLoader color="#fff" size={12} />} {confirmLabel}
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
}
