import {
  AppointmentType,
  ClinicalAppointmentStatus,
  ClinicalNoteStatus,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { RoleType } from 'app/coach/coach-notes/CoachNotesTypes';
import { useAppState } from 'app/state';
import { useFilters } from 'hooks/useFilters';
import { useState } from 'react';
import { formatAppointmentStatus } from 'utils';

const RolesAllowedToEditAllClinicalAppointments = new Set([
  UserRole.MEMBER_SUPPORT,
  UserRole.THERAPIST_SUPERVISOR,
  UserRole.PSYCHIATRIST_SUPERVISOR,
  UserRole.CLINICAL_SUPERVISOR, // I'm not sure this is used in this context
]);

export const useAppointmentsAndNotesFilters = () => {
  const [unreadFilter, toggleUnreadFilter] = useFilters([
    { checked: true, key: 'Unread', label: 'Unread' },
  ]);

  const [typeFilters, toggleTypeFilters] = useFilters([
    { checked: true, key: AppointmentType.THERAPY, label: 'Therapy' },
    { checked: true, key: AppointmentType.PSYCHIATRY, label: 'Psychiatry' },
    { checked: true, key: RoleType.COACHING, label: 'Coaching' },
  ]);

  const [appStatusFilters, toggleAppStatusFilters] = useFilters([
    {
      checked: true,
      key: ClinicalAppointmentStatus.Confirmed,
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Confirmed),
    },
    {
      checked: true,
      key: ClinicalAppointmentStatus.Complete,
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Complete),
    },
    {
      checked: true,
      key: ClinicalAppointmentStatus.Tentative,
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Tentative),
    },
    {
      checked: true,
      key: ClinicalAppointmentStatus.NoShow,
      label: formatAppointmentStatus(ClinicalAppointmentStatus.NoShow),
    },
    {
      checked: true,
      key: ClinicalAppointmentStatus.ProviderNoShow,
      label: formatAppointmentStatus(ClinicalAppointmentStatus.ProviderNoShow),
    },
    {
      checked: true,
      key: ClinicalAppointmentStatus.Cancelled,
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Cancelled),
    },
    {
      checked: true,
      key: ClinicalAppointmentStatus.CancelledClinician,
      label: formatAppointmentStatus(
        ClinicalAppointmentStatus.CancelledClinician,
      ),
    },
    {
      checked: true,
      key: ClinicalAppointmentStatus.Rescheduled,
      label: formatAppointmentStatus(ClinicalAppointmentStatus.Rescheduled),
    },
    {
      checked: true,
      key: ClinicalAppointmentStatus.LateCancelled,
      label: formatAppointmentStatus(ClinicalAppointmentStatus.LateCancelled),
    },
  ]);

  const [noteStatusFilters, toggleNoteStatusFilters] = useFilters([
    {
      checked: true,
      key: ClinicalNoteStatus.DRAFT,
      label: 'Draft',
    },
    {
      checked: true,
      key: ClinicalNoteStatus.NOT_STARTED,
      label: 'Not Started',
    },
    {
      checked: true,
      key: ClinicalNoteStatus.SIGNED_AND_LOCKED,
      label: 'Signed & Locked',
    },
  ]);

  const activeTypeFilters = typeFilters
    .filter((_) => _.checked)
    .map((_) => _.key as AppointmentType);
  const activeAppStatusFilters = appStatusFilters
    .filter((_) => _.checked)
    .map((_) => _.key as ClinicalAppointmentStatus);
  const activeNoteStatusFilters = noteStatusFilters
    .filter((_) => _.checked)
    .map((_) => _.key as ClinicalNoteStatus);

  return {
    activeAppStatusFilters,
    activeNoteStatusFilters,
    activeTypeFilters,
    appStatusFilters,
    noteStatusFilters,
    toggleAppStatusFilters,
    toggleNoteStatusFilters,
    toggleTypeFilters,
    toggleUnreadFilter,
    typeFilters,
    unreadFilter,
  };
};

export const useAppointmentWarnings = () => {
  const [showAppointmentInPastWarning, setAppointmentInPastWarning] = useState(
    false,
  );
  const [showAvailabilityWarning, setAvailabilityWarning] = useState(false);
  const [showMissingCredictCardWarning, setMissingCreditCardWarning] = useState(
    false,
  );
  const [showNoEAPSessionsWarning, setNoEAPSessionsWarning] = useState(false);
  const [showPreviousClinicianWarning, setPreviousClinicianWarning] = useState(
    false,
  );

  const closeAll = () => {
    setAppointmentInPastWarning(false);
    setAvailabilityWarning(false);
    setMissingCreditCardWarning(false);
    setNoEAPSessionsWarning(false);
    setPreviousClinicianWarning(false);
  };

  return {
    modalActions: {
      closeAll,
      setAppointmentInPastWarning,
      setAvailabilityWarning,
      setMissingCreditCardWarning,
      setNoEAPSessionsWarning,
      setPreviousClinicianWarning,
    },
    modalStatus: {
      showAppointmentInPastWarning,
      showAvailabilityWarning,
      showMissingCredictCardWarning,
      showNoEAPSessionsWarning,
      showPreviousClinicianWarning,
    },
  };
};

export const useAppointmentScreenHelpers = (
  userRole: UserRole | null,
): {
  allowEditOthersAppointments: boolean;
  timezoneMode: 'user' | 'member';
} => {
  const effectiveUserRole = userRole ?? useAppState(({ user }) => user.role);

  const allowEditOthersAppointments = effectiveUserRole
    ? RolesAllowedToEditAllClinicalAppointments.has(effectiveUserRole)
    : false;

  // If the user has the MEMBER_SUPPORT role, they should view the appointment in the member's timezone. Otherwise,
  // the user is a provider and should view the appointment in their own timezone.
  const timezoneMode =
    effectiveUserRole === UserRole.MEMBER_SUPPORT ? 'member' : 'user';

  return {
    allowEditOthersAppointments,
    timezoneMode,
  };
};
