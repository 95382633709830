import { GetTeenDependantSurveyResponse_survey_intakeSurvey_guardianResponse as GuardianResponse } from '@headspace/carehub-graphql/dist/patients-tabs-intake/generated/GetTeenDependantSurveyResponse';
import { MemberChartTab } from 'app/coach/member-chart/MemberChartTab';
import { IntakeCard } from 'app/patients/tabs/intake/IntakeTabV2Card';
import Labels from 'i18n/en/dependent-intake.json';
import React from 'react';

import { formatDate, formatYesNoValue } from './common';
import styles from './DependentReport.module.scss';

interface Prop {
  guardianResponse: GuardianResponse;
}
export function GuardianResponseTab({ guardianResponse }: Readonly<Prop>) {
  const {
    completedAt,
    additionalInformationForClinician,
    areasOfTeensLifeImpacted,
    reasonsForSeekingClinicalServices,
    teenMotivationLevelForCare,
    whoRecommendedCare,
    guardianPersonalFamilyGoals,
    guardianGoalsForTeen,
    teenGoals,
    teenStrength,
    preExistingMedicalConditions,
    thoughtsOfHarm,
  } = guardianResponse;
  return (
    <div className={styles.dependentReport}>
      <div className={styles.completedBy}>
        Completed by guardian&nbsp;
        {formatDate(completedAt)}
      </div>
      <MemberChartTab>
        <IntakeCard
          testId="care-card"
          title={Labels.care}
          terms={{
            [Labels.seekingServicesReason]: formatYesNoValue(
              reasonsForSeekingClinicalServices,
            ),
            [Labels.additionalConcern]: formatYesNoValue(
              additionalInformationForClinician,
            ),
            [Labels.impactArea]: formatYesNoValue(areasOfTeensLifeImpacted),
            [Labels.whoRecommendedCare]: formatYesNoValue(whoRecommendedCare),
            [Labels.teenMotivationLevelForCare]: formatYesNoValue(
              teenMotivationLevelForCare,
            ),
          }}
        />

        <IntakeCard
          testId="goals-card"
          title={Labels.goalsAndStrength}
          terms={{
            [Labels.teenGoals]: formatYesNoValue(teenGoals),
            [Labels.teenStrength]: formatYesNoValue(teenStrength),
            [Labels.guardianGoalsForTeen]: formatYesNoValue(
              guardianGoalsForTeen,
            ),
            [Labels.guardianPersonalFamilyGoals]: formatYesNoValue(
              guardianPersonalFamilyGoals,
            ),
          }}
        />
        <IntakeCard
          testId="mental-health-history-card"
          title={Labels.mentalHistory}
          terms={{
            [Labels.preExistingMentalHealthDiagnosis]: formatYesNoValue(
              preExistingMedicalConditions,
            ),
            [Labels.thoughtsOfHarm]: formatYesNoValue(thoughtsOfHarm),
          }}
        />
      </MemberChartTab>
    </div>
  );
}
