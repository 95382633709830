import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import styles from './Loader.module.scss';

type Props = {
  topMargin?: boolean;
  size?: number;
  className?: string;
};

export function Loader(props: Props) {
  const { topMargin = true, size = 15 } = props;
  const className = [
    styles.loader,
    props.className ?? '',
    topMargin ? styles.topMargin : '',
  ].join(' ');
  return (
    <div data-testid="loading" className={className}>
      <BeatLoader size={size} color="#dea531" loading={true} />
    </div>
  );
}
