import {
  arrayField,
  booleanField,
  field,
  FieldDefinitions,
  numberField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  PsychiatricHistory,
  PsychiatricHistory_PsychotropicMedication as PsychotropicMedication,
  PsychiatricHistory_SuicideAttempt as SuicideAttempt,
  PsychiatricHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychiatricHistory';
import {
  PsychiatricHistorySection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { EnumCheckboxField, TextAreaField } from 'app/notes-ui/forms/fields';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React, { useEffect } from 'react';
import { FormError } from 'shared-components/FormError';
import { Width } from 'types/StyleTypes';

import { Labels } from './constants';
import { PsychiatricHistoryTableV2 } from './PsychiatricHistoryTableV2';
import { ColumnDefinition } from './PsychiatryHistoryRowV2';
import { useFormTable } from './useFormTable';

const suicideAttemptDef: FieldDefinitions<SuicideAttempt> = {
  clinicalIntervention: field({ rules: [] }),
  comments: stringField({ rules: [] }),
  method: field({ rules: [] }),
  timeframe: field(),
};

const psychotropicMedicationDef: FieldDefinitions<PsychotropicMedication> = {
  adverseReaction: stringField({ rules: [] }),
  clinicalResponse: stringField({ rules: [] }),
  comments: stringField({ rules: [] }),
  dosage: numberField({ rules: [] }),
  duration: numberField({ rules: [] }),
  medication: field(),
};

const fieldDefs: FieldDefinitions<PsychiatricHistory> = {
  appointmentId: field(),
  historyOfComplicatedEtohOrBenzoWithdrawal: stringField({ rules: [] }),
  pastInpatientHospitalization: stringField({ rules: [] }),
  pastOutpatientTreatment: stringField(),
  pastPsychotropicMedicationsResponses: arrayField({
    rules: [
      (value) => {
        const isValid =
          value !== undefined &&
          value.every((_) =>
            validateForm<PsychotropicMedication>(_, psychotropicMedicationDef),
          );
        if (!isValid) return 'This field contains invalid input';
        return undefined;
      },
    ],
  }),
  pastSelfHarmEpisode: stringField({ rules: [] }),
  pastSubstanceUseTreatment: stringField({ rules: [] }),
  pastSuicidalIdeationEpisode: stringField({ rules: [] }),
  pastSuicideAttempt: arrayField({
    rules: [
      (value) => {
        const isValid =
          value !== undefined &&
          value.every((_) =>
            validateForm<SuicideAttempt>(_, suicideAttemptDef),
          );
        if (!isValid) return 'This field contains invalid input';
        return undefined;
      },
    ],
  }),
  pastTreatments: {
    inpatientTreatment: booleanField({ rules: [] }),
    outpatientTreatment: booleanField({ rules: [] }),
  },
  version: field<PsychiatricHistory_Version>({
    default: PsychiatricHistory_Version.v0,
  }),
};

const psychotropicMedicationColumnDef: ColumnDefinition<
  PsychotropicMedication
>[] = [
  {
    columnSize: 25,
    columnType: 'textarea',
    key: 'medication',
    label: 'Medication',
    placeholder: 'Drug name',
  },
  {
    columnSize: 90,
    columnType: 'textarea',
    key: 'comments',
    label: 'Comments (optional)',
    placeholder: 'Dosage, duration, clinical response, adverse reaction...',
  },
];

const suicideAttemptColumnDef: ColumnDefinition<SuicideAttempt>[] = [
  {
    columnSize: 25,
    columnType: 'textarea',
    key: 'timeframe',
    label: 'When & how',
  },
  {
    columnSize: 90,
    columnType: 'textarea',
    key: 'comments',
    label: 'Comments (Optional)',
  },
];

type Props = {
  appointmentId: string;
  onSubmit: (data: PsychiatricHistorySection) => void;
  disabled?: boolean;
  initialValue?: PsychiatricHistory;
};

export function PsychiatricHistoryFormV2(props: Props) {
  const { initialValue, disabled, appointmentId } = props;

  const { fields, getValue } = useForm<PsychiatricHistory>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      version: PsychiatricHistory_Version.v0,
    } as PsychiatricHistory,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const [
    notEndorsingPastTreatment,
    setNotEndorsingPastTreatment,
  ] = React.useState(false);
  const [
    notEndorsingPastSuicidalIdeationEpisode,
    setNotEndorsingPastSuicidalIdeationEpisode,
  ] = React.useState(false);
  const [
    notEndorsingPastSelfHarmEpisode,
    setNotEndorsingPastSelfHarmEpisode,
  ] = React.useState(false);

  const onNotEndorsingPastTreatmentChange = React.useCallback(
    (checked: boolean) => {
      setNotEndorsingPastTreatment(checked);
      if (checked) {
        fields.pastTreatments.inpatientTreatment.setValue(false);
        fields.pastTreatments.outpatientTreatment.setValue(false);
      }
    },
    [setNotEndorsingPastTreatment],
  );

  const onNotEndorsingPastSuicidalIdeationEpisodeChange = React.useCallback(
    (checked: boolean) => {
      setNotEndorsingPastSuicidalIdeationEpisode(checked);
      if (checked) {
        fields.pastSuicidalIdeationEpisode.setValue('');
      }
    },
    [setNotEndorsingPastSuicidalIdeationEpisode],
  );

  const onNotEndorsingPastSelfHarmEpisodeChange = React.useCallback(
    (checked: boolean) => {
      setNotEndorsingPastSelfHarmEpisode(checked);
      if (checked) {
        fields.pastSelfHarmEpisode.setValue('');
      }
    },
    [setNotEndorsingPastSelfHarmEpisode],
  );

  const pastPsychotropicMedicationsResponsesRow = useFormTable<
    PsychotropicMedication
  >(
    Labels.psychHistory.pastPsychotropicMedicationsResponses.label,
    props.initialValue?.pastPsychotropicMedicationsResponses,
    fields.pastPsychotropicMedicationsResponses.setValue,
  );
  const pastSuicideAttemptRow = useFormTable<SuicideAttempt>(
    Labels.psychHistory.pastSuicideAttempt.label,
    props.initialValue?.pastSuicideAttempt,
    fields.pastSuicideAttempt.setValue,
  );

  useEffect(() => {
    if (
      !fields.pastTreatments.outpatientTreatment.value &&
      !fields.pastTreatments.inpatientTreatment.value
    )
      setNotEndorsingPastTreatment(true);
  }, [
    fields.pastTreatments.outpatientTreatment.value,
    fields.pastTreatments.inpatientTreatment.value,
  ]);

  useEffect(() => {
    if (!fields.pastSuicidalIdeationEpisode.value)
      setNotEndorsingPastSuicidalIdeationEpisode(true);
  }, [fields.pastSuicidalIdeationEpisode.value]);

  useEffect(() => {
    if (!fields.pastSelfHarmEpisode.value)
      setNotEndorsingPastSelfHarmEpisode(true);
  }, [fields.pastSelfHarmEpisode.value]);

  const isPastTreatmentInvalid = (checkTouched = true) => {
    let isInvalid =
      notEndorsingPastTreatment == false &&
      !fields.pastTreatments.outpatientTreatment.value &&
      !fields.pastTreatments.inpatientTreatment.value;

    if (checkTouched) {
      isInvalid =
        isInvalid &&
        (fields.pastTreatments.outpatientTreatment.touched ||
          fields.pastTreatments.inpatientTreatment.touched);
    }

    return isInvalid;
  };

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: PsychiatrySectionName.PSYCH_HISTORY,
    });
  };
  return (
    <div>
      <TextAreaField
        disabled={disabled}
        testId={Labels.psychHistory.pastOutpatientTreatment.id}
        field={fields.pastOutpatientTreatment}
        label={Labels.psychHistory.pastOutpatientTreatment.label}
        placeholder={Labels.psychHistory.pastOutpatientTreatment.placeholder}
        rows={1}
        autoExpand={true}
        width={Width.FULL}
      />
      <NoteFormControlLabel label="Has the member had any of the following past treatment?">
        <Checkbox
          disabled={disabled}
          testId={
            Labels.psychHistory.pastTreatments.notEndorsingPastTreatment.id
          }
          label={
            Labels.psychHistory.pastTreatments.notEndorsingPastTreatment.label
          }
          checked={notEndorsingPastTreatment}
          onChange={(e) => onNotEndorsingPastTreatmentChange(e)}
        />
        <EnumCheckboxField
          disabled={disabled || notEndorsingPastTreatment}
          testId={Labels.psychHistory.pastTreatments.outpatientTreatment.id}
          label={Labels.psychHistory.pastTreatments.outpatientTreatment.label}
          field={fields.pastTreatments.outpatientTreatment}
          checkedValue={true}
          uncheckedValue={false}
        />
        <EnumCheckboxField
          disabled={disabled || notEndorsingPastTreatment}
          testId={Labels.psychHistory.pastTreatments.inpatientTreatment.id}
          label={Labels.psychHistory.pastTreatments.inpatientTreatment.label}
          field={fields.pastTreatments.inpatientTreatment}
          checkedValue={true}
          uncheckedValue={false}
        />
        {isPastTreatmentInvalid() && (
          <FormError error="Must select at least one past treatment" />
        )}
      </NoteFormControlLabel>
      <PsychiatricHistoryTableV2
        label={Labels.psychHistory.Medication.label}
        noDataCheckboxLabel={Labels.psychHistory.Medication.checkboxLabel}
        onChange={pastPsychotropicMedicationsResponsesRow.onChange}
        error={
          pastPsychotropicMedicationsResponsesRow.error ||
          fields.pastPsychotropicMedicationsResponses.error
        }
        testId={Labels.psychHistory.Medication.id}
        initialValue={props.initialValue?.pastPsychotropicMedicationsResponses}
        columns={psychotropicMedicationColumnDef}
        fieldDefs={psychotropicMedicationDef}
        getBlankRecord={() => PsychotropicMedication.fromPartial({})}
        disabled={disabled}
        addButtonLabel="Medication"
      />
      <PsychiatricHistoryTableV2
        label={Labels.psychHistory.SuicideAttempt.label}
        noDataCheckboxLabel={Labels.psychHistory.SuicideAttempt.checkboxLabel}
        onChange={pastSuicideAttemptRow.onChange}
        error={pastSuicideAttemptRow.error || fields.pastSuicideAttempt.error}
        initialValue={props.initialValue?.pastSuicideAttempt}
        testId={Labels.psychHistory.SuicideAttempt.id}
        columns={suicideAttemptColumnDef}
        fieldDefs={suicideAttemptDef}
        getBlankRecord={() => SuicideAttempt.fromPartial({})}
        disabled={disabled}
        addButtonLabel="Attempt"
      />
      <NoteFormControlLabel
        label={
          Labels.psychHistory.notEndorsingPastSuicidalIdeationEpisode.label
        }
      >
        <Checkbox
          disabled={disabled}
          testId={
            Labels.psychHistory.notEndorsingPastSuicidalIdeationEpisode.id
          }
          label={
            Labels.psychHistory.notEndorsingPastSuicidalIdeationEpisode
              .checkboxLabel
          }
          checked={notEndorsingPastSuicidalIdeationEpisode}
          onChange={(e) => onNotEndorsingPastSuicidalIdeationEpisodeChange(e)}
        />
        <TextAreaField
          disabled={disabled || notEndorsingPastSuicidalIdeationEpisode}
          testId={Labels.psychHistory.pastSuicidalIdeationEpisode.label}
          field={fields.pastSuicidalIdeationEpisode}
          label={Labels.psychHistory.pastSuicidalIdeationEpisode.label}
          placeholder={
            Labels.psychHistory.pastSuicidalIdeationEpisode.placeholder
          }
          rows={1}
          autoExpand={true}
          width={Width.FULL}
        />
        {!notEndorsingPastSuicidalIdeationEpisode &&
          !fields.pastSuicidalIdeationEpisode.value &&
          fields.pastSuicidalIdeationEpisode.touched && (
            <FormError error="This field is required" />
          )}
      </NoteFormControlLabel>
      <NoteFormControlLabel
        label={Labels.psychHistory.notEndorsingPastSelfHarmEpisode.label}
      >
        <Checkbox
          disabled={disabled}
          testId={Labels.psychHistory.notEndorsingPastSelfHarmEpisode.id}
          label={
            Labels.psychHistory.notEndorsingPastSelfHarmEpisode.checkboxLabel
          }
          checked={notEndorsingPastSelfHarmEpisode}
          onChange={(e) => onNotEndorsingPastSelfHarmEpisodeChange(e)}
        />
        <TextAreaField
          disabled={disabled || notEndorsingPastSelfHarmEpisode}
          testId={Labels.psychHistory.pastSelfHarmEpisode.id}
          field={fields.pastSelfHarmEpisode}
          label={Labels.psychHistory.pastSelfHarmEpisode.label}
          placeholder={Labels.psychHistory.pastSelfHarmEpisode.placeholder}
          rows={1}
          autoExpand={true}
          width={Width.FULL}
        />
        {!notEndorsingPastSelfHarmEpisode &&
          !fields.pastSelfHarmEpisode.value &&
          fields.pastSelfHarmEpisode.touched && (
            <FormError error="This field is required" />
          )}
      </NoteFormControlLabel>
    </div>
  );
}

export function validate(data: PsychiatricHistory | null): boolean {
  return data !== null && validateForm<PsychiatricHistory>(data, fieldDefs);
}
