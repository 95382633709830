import { Button } from '@mui/material';
import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

type Props = {
  disabled?: boolean;
  isLoading?: boolean;
  label?: string;
  onSubmit: () => void;
};

export function SaveButton(props: Props) {
  const { disabled, isLoading, label = 'Save', onSubmit } = props;

  if (disabled) return null;

  const component = isLoading ? (
    <BeatLoader size={12} color="#4379b6" loading={true} />
  ) : (
    label
  );

  return (
    <Button
      disabled={isLoading}
      data-testid="submitButton"
      color="primary"
      type="submit"
      onClick={onSubmit}
    >
      {component}
    </Button>
  );
}
