import {
  GetMemberById,
  GetMemberByIdVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/GetMemberById';
import { getMemberQuery } from '@headspace/carehub-graphql/dist/queries/GetMember';
import { assertResponseHasNoError } from 'app/inbox/assertResponseHasNoError';
import { InboxSource } from 'app/inbox/types';
import {
  clickMemberChartTabAction,
  clickMemberChartTabClosedAction,
  openedNewMemberChartTab,
} from 'app/state/amplitude/actions/inbox';
import { Context } from 'app/state/context';
import { updateSessionState } from 'app/state/features/auth/authSlice';
import { SessionState } from 'app/state/features/auth/types';
import { setCurrentChannel } from 'app/state/inbox/actions';
import { displayableMessagesToString } from 'app/state/inbox/utils';
import { State } from 'app/state/schema';
import { createActionHandlers } from 'redux-reloaded';
import { isGraphQLAuthenticationError } from 'shared-components/error-state/utils';
import { isClinicianOrSupervisor, isCoachOrSupervisor } from 'utils';

import {
  closeTab,
  openNewTab,
  refetchMemberData,
  removeMemberFromTabList,
  selectTab,
  setActiveTab,
} from './actions';

export const handlers = createActionHandlers<Context, State>();

handlers.on(openNewTab, ({ action, redux, context: { services } }) => {
  const {
    multiTab: { openTabs, activeTab },
    user: { role },
  } = redux.getState();
  const { section, memberId, source } = action.payload;

  services.logger.info('MultiTabs.openNewTab: inbox item clicked', {
    currentActiveTab: activeTab,
    memberIdClicked: memberId,
  });

  if (activeTab === memberId) return;
  const isNewTab = !openTabs.includes(memberId);
  const openTabsNumber = isNewTab ? openTabs.length + 1 : openTabs.length;
  const amplitudePayload = {
    memberId,
    openTabCount: openTabsNumber,
    role: role!,
    section,
    source,
  };

  redux.dispatch(setActiveTab(memberId));
  redux.dispatch(
    setCurrentChannel({ currentChannelId: '', currentMemberId: '' }),
  );

  const additionalData: Record<string, unknown> = {
    ...amplitudePayload,
  };

  if (isCoachOrSupervisor(role)) {
    additionalData.unreadMessages = selectUnreadMessagesString(
      redux.getState(),
      memberId,
    );
    additionalData.unreadMessageCount = selectUnreadMessageCount(
      redux.getState(),
      memberId,
    );
  }
  if (isNewTab) {
    services.logger.info(
      'MultiTabs.openNewTab: open new member chart tab',
      additionalData,
    );
    redux.dispatch(openedNewMemberChartTab(amplitudePayload));
  } else {
    services.logger.info(
      'MultiTabs.openNewTab: switched to member chart tab',
      additionalData,
    );
  }
});
handlers.on(closeTab, ({ action, redux, context: { services } }) => {
  const {
    multiTab: { openTabs },
    user: { role },
  } = redux.getState();
  const { activeTab, idToRemove } = action.payload;

  // subtract the one that's closed by the handler
  const openTabCount = openTabs.length - 1;
  const source = isClinicianOrSupervisor(role)
    ? InboxSource.CLINICIAN_INBOX
    : InboxSource.COACH_INBOX;
  const amplitudePayload = {
    memberId: idToRemove,
    openTabCount,
    role: role!,
    source,
  };
  const additionalData: Record<string, unknown> = {
    ...amplitudePayload,
  };
  services.logger.info('MultiTabs.closeTab: closed member chart tab', {
    ...additionalData,
  });

  if (activeTab != null) {
    additionalData.memberId = activeTab;
    if (isCoachOrSupervisor(role)) {
      additionalData.unreadMessages = selectUnreadMessagesString(
        redux.getState(),
        activeTab,
      );
      additionalData.unreadMessageCount = selectUnreadMessageCount(
        redux.getState(),
        activeTab,
      );
    }
    services.logger.info(
      'MultiTabs.closeTab: switched to member chart tab',
      additionalData,
    );
  }

  redux.dispatch(removeMemberFromTabList({ activeTab, idToRemove }));
  redux.dispatch(clickMemberChartTabClosedAction(amplitudePayload));
});
handlers.on(selectTab, ({ action, redux, context: { services } }) => {
  const {
    multiTab: { activeTab },
    user: { role },
  } = redux.getState();
  const { memberId } = action.payload;

  if (activeTab === memberId) return;

  const source = isClinicianOrSupervisor(role)
    ? InboxSource.CLINICIAN_INBOX
    : InboxSource.COACH_INBOX;
  const amplitudePayload = { memberId, role: role!, source };
  const additionalData: Record<string, unknown> = {
    ...amplitudePayload,
  };

  if (isCoachOrSupervisor(role)) {
    additionalData.unreadMessages = selectUnreadMessagesString(
      redux.getState(),
      memberId,
    );
    additionalData.unreadMessageCount = selectUnreadMessageCount(
      redux.getState(),
      memberId,
    );
  }
  services.logger.info(
    'MultiTabs.selectTab: select member chart tab',
    additionalData,
  );

  redux.dispatch(setActiveTab(memberId));
  redux.dispatch(clickMemberChartTabAction(amplitudePayload));
});
handlers.on(
  refetchMemberData,
  async ({ action, context, redux, context: { services } }) => {
    const {
      payload: { memberId },
    } = action;
    const { logger, apollo } = context.services;
    logger.debug('RefetchMemberDataActionHandler: re-fetching member data');
    try {
      const response = await apollo.query<
        GetMemberById,
        GetMemberByIdVariables
      >({
        errorPolicy: 'all',
        fetchPolicy: 'network-only',
        query: getMemberQuery,
        variables: { input: { id: memberId } },
      });
      assertResponseHasNoError(response, services.logger);
    } catch (error) {
      if (isGraphQLAuthenticationError(error)) {
        redux.dispatch(updateSessionState(SessionState.EXPIRED));
      } else {
        logger.error(
          new Error(
            'RefetchMemberDataActionHandler: Unable to re-fetch member data',
          ),
          {
            error,
            memberId,
          },
        );
      }
    }
  },
);

function selectUnreadMessageCount(state: State, memberId: string) {
  const {
    inbox: { inboxItems, unreadMessagesMap },
  } = state;
  const channelId = inboxItems[memberId]?.memberCoachChannelId;
  let unreadMessageCount = 0;
  if (channelId && unreadMessagesMap[channelId]) {
    unreadMessageCount = unreadMessagesMap[channelId].length;
  }
  return unreadMessageCount;
}

function selectUnreadMessagesString(
  state: State,
  memberId: string,
): string | undefined {
  const {
    inbox: { inboxItems, unreadMessagesMap },
  } = state;
  const channelId = inboxItems[memberId]?.memberCoachChannelId;
  if (channelId && unreadMessagesMap[channelId]) {
    return displayableMessagesToString(unreadMessagesMap[channelId]);
  }
  return undefined;
}
