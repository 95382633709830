import { ReactElement, useCallback, useMemo } from 'react';
import { classNameCombiner } from 'utils';

import styles from './InboxSectionItem.module.scss';

const testIdPrefix = 'inbox-item-wrapper';

interface IInboxSectionItemProps {
  topLeft?: ReactElement | string;
  topRight?: ReactElement | string;
  bottomLeft?: ReactElement | string;
  bottomRight?: ReactElement | string;
  onClick: () => void;
}

export const InboxSectionItem = ({
  topLeft,
  topRight,
  bottomLeft,
  bottomRight,
  onClick,
}: IInboxSectionItemProps) => {
  const dataTestId = useMemo(() => `${testIdPrefix}}`, []);

  const handleOnClick = useCallback(() => {
    onClick();
  }, [onClick]);

  return (
    <div
      className={styles.inboxItemWrapper}
      data-testid={testIdPrefix}
      onClick={handleOnClick}
    >
      <div
        data-testId={`${dataTestId}-top-row`}
        className={classNameCombiner([styles.row, styles.topRow])}
      >
        <div data-testId={`${dataTestId}-top-left`}>{topLeft}</div>
        <div data-testId={`${dataTestId}-top-right`}>{topRight}</div>
      </div>
      <div
        data-testId={`${dataTestId}-bottom-row`}
        className={classNameCombiner([styles.row, styles.bottomRow])}
      >
        <div data-testId={`${dataTestId}-bottom-left`}>{bottomLeft}</div>
        <div data-testId={`${dataTestId}-bottom-right`}>{bottomRight}</div>
      </div>
    </div>
  );
};
