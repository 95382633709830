import {
  getVaultListenerUserId,
  getVaultWebUserId,
} from '@ginger.io/vault-core/dist/IdHelpers';
import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import memberChartStorage from 'app/state/member-tabs/memberChartStorage';
import userStorage from 'app/state/user/userStorage';
import { createReducer } from 'redux-reloaded';

import { login, logout, updateShiftStatus, updateTimezone } from './actions';
import { getInitialState, State } from './schema';

export const reducer = createReducer<State>(getInitialState());

reducer.on(login, (state, { payload }) => {
  const {
    appUser: {
      userId,
      role,
      timezone,
      listenerUserId,
      clinicianId,
      pubnubAuthKey,
      listenerId,
      coachinghubUsername,
      pubnubConfig: { rpcToCoachChannelId },
      shiftStatus,
    },
    useVaultUserHeader,
    oktaUser,
  } = payload;

  const useVaultListenerUserId =
    (role === UserRole.COACH || role === UserRole.COACH_SUPERVISOR) &&
    listenerUserId !== null;
  const vaultUserId = useVaultListenerUserId
    ? getVaultListenerUserId(listenerUserId)
    : getVaultWebUserId(userId);

  if (useVaultUserHeader) {
    userStorage.set({ useOktaAuth: 'true' });
  } else {
    userStorage.clear();
  }

  return {
    ...state,
    clinicianId,
    coachinghubUsername,
    firstName: oktaUser.given_name ?? null,
    lastName: oktaUser.family_name ?? null,
    listenerId,
    listenerUserId,
    pubnubAuthKey,
    role,
    rpcToCoachChannelId,
    shiftStatus,
    timezone,
    userId,
    vaultUserId,
  };
});

reducer.on(logout, (state, action) => {
  memberChartStorage.clear();
  userStorage.clear();

  return {
    ...state,
    coachinghubUsername: null,
    listenerId: null,
    pubnubAuthKey: null,
    role: null,
    showAuthorizationModal: false,
    timezone: null,
    userId: null,
    vaultUserId: null,
  };
});

reducer.on(updateShiftStatus, (state, { payload }) => ({
  ...state,
  shiftStatus: payload,
}));

reducer.on(updateTimezone, (state, { payload }) => ({
  ...state,
  timezone: payload.timezone,
}));
