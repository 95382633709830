import { DateMessage as Date } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Date';
import {
  Frequency,
  Frequency_Period,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Frequency';
import {
  AppointmentStatus,
  ClinicalAppointmentNoteStatus,
  ClinicalAppointmentStatus,
  ClinicalNoteStatus,
  ClinicianAppointmentStatus,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { blacklistedKeys, EnumOption, ProtobufEnum } from 'app/notes-ui/types';

import { formatEnumValue } from './index';

export function enumToOptions<T extends Record<string, any>>(
  obj: T,
  keyLabels: Record<string, string> = {},
  keysToExclude: string[] = [],
): EnumOption[] {
  const options: EnumOption[] = [];

  // This is a dirty hack. TS enums with number values get compiled as JS objects that contain both label => value and value => label
  // mappings (i.e. a reverse mapping). This is documunted: https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
  // Thus, for number valued enums, we have to filter out the reverse mapping so we don't render it in the UI
  const isNumberValuedEnum = Object.values(obj).some(
    (_) => typeof _ === 'number',
  );

  for (const key in obj) {
    const value = obj[key];
    const isValidKey =
      !blacklistedKeys.has(key) && !key.toLowerCase().startsWith('undefined_');
    const isValidNumberValue =
      isNumberValuedEnum && isValidKey && typeof value === 'number';
    const isValidStringValue = !isNumberValuedEnum && isValidKey;

    if (
      (isValidNumberValue || isValidStringValue) &&
      !keysToExclude.includes(key)
    ) {
      const name = keyLabels[key] ?? formatEnumKey(key);
      options.push({ name, value });
    }
  }

  return options;
}

/**
 * CPTCode-specific options formatter for the EnumDropDown component
 *
 * @param obj CptCode enum
 * @returns array of {name, value} to be passed into EnumDropDown
 */
export function cptCodeToOptions<T extends Record<string, any>>(
  obj: T,
): EnumOption[] {
  const options: EnumOption[] = [];

  // This is a dirty hack. TS enums with number values get compiled as JS objects that contain both label => value and value => label
  // mappings (i.e. a reverse mapping). This is documunted: https://www.typescriptlang.org/docs/handbook/enums.html#reverse-mappings
  // Thus, for number valued enums, we have to filter out the reverse mapping so we don't render it in the UI
  const isNumberValuedEnum = Object.values(obj).some(
    (_) => typeof _ === 'number',
  );
  const regexp = new RegExp('^cpt_(\\d+)_');

  for (const key in obj) {
    const value = obj[key];

    const isValidKey = !key.toLowerCase().startsWith('undefined_');
    const isValidNumberValue =
      isNumberValuedEnum && isValidKey && typeof value === 'number';
    const isValidStringValue = !isNumberValuedEnum && isValidKey;
    const match = regexp.exec(key);

    if (match && (isValidNumberValue || isValidStringValue)) {
      // cpt code enums are of form cpt_<value>_<description>
      // get second occurence of _ to split value and description
      const code = formatEnumKey(match[0]).toUpperCase();
      const description = formatEnumKey(key.replace(match[0], ''));
      const name = `${code}\t${description}`;
      options.push({ name, value });
    }
  }

  return options;
}

export function protobufDateToString(date: Date): string {
  const { year, month, day } = date;
  return `${year}-${month
    .toString()
    .padStart(2, '0')}-${day.toString().padStart(2, '0')}`;
}

export function stringToProtobufDate(date: string): Date {
  const [year, month, day] = date.split('-');
  return {
    day: parseInt(day),
    month: parseInt(month, 10),
    year: parseInt(year, 10),
  };
}

export function toFrequencyDescription(
  frequency?: Frequency,
): string | undefined {
  if (!frequency) return undefined;

  const { period, timesPerPeriod } = frequency;
  const periodLabel = labelFromEnumValue(Frequency_Period, period);
  return `${timesPerPeriod} time${
    timesPerPeriod && timesPerPeriod > 1 ? 's' : ''
  } per ${periodLabel?.toLowerCase()}`;
}

export function labelFromEnumValue(
  type: ProtobufEnum,
  value?: number,
  enumKeyLabels?: Record<string, string>,
): string | null {
  const options = enumToOptions(type, enumKeyLabels);
  const matchingOptions = options.filter((_) => _.value === value);

  if (matchingOptions.length > 0) {
    const selectedOption = matchingOptions[0];
    return selectedOption.name;
  }

  return null;
}

function formatEnumKey(key: string): string {
  return key
    .split('_')
    .map((word, i) => (i === 0 ? formatEnumValue(word) : word))
    .join(' ');
}

/**
 * Given the status of an appointment and status of its clinican note, return a display value for the note status
 * field (e.g. as shown in the appointment list views).
 *
 * @param appointmentStatus the status of the appointment
 * @param noteStatus the status of the note
 *
 * @returns a display value for the note status (not always the same as the note status)
 */
export function formatClinicalNoteStatus(
  appointmentStatus:
    | ClinicianAppointmentStatus
    | ClinicalAppointmentStatus
    | AppointmentStatus
    | null,
  noteStatus: ClinicalNoteStatus | ClinicalAppointmentNoteStatus | null,
  hideIfNA = false,
): string {
  let noteStatusDisplay = '-';

  // NOTE: ClinicianAppointmentStatus will be obsoleted by ClinicalAppointmentStatus. Until that is complete, support
  // both here
  if (appointmentStatus && noteStatus) {
    switch (appointmentStatus) {
      case ClinicianAppointmentStatus.NoShow:
      case ClinicianAppointmentStatus.ProviderNoShow:
      case ClinicianAppointmentStatus.Cancelled:
      case ClinicianAppointmentStatus.CancelledClinician:
      case ClinicianAppointmentStatus.LateCancelled:
      case ClinicalAppointmentStatus.NoShow:
      case ClinicalAppointmentStatus.ProviderNoShow:
      case ClinicalAppointmentStatus.Cancelled:
      case ClinicalAppointmentStatus.CancelledClinician:
      case ClinicalAppointmentStatus.LateCancelled:
      case ClinicalAppointmentStatus.Rescheduled:
      case AppointmentStatus.NO_SHOW:
      case AppointmentStatus.PROVIDER_NO_SHOW:
      case AppointmentStatus.CANCELLED:
      case AppointmentStatus.CANCELLED_CLINICIAN:
      case AppointmentStatus.LATE_CANCELLED:
      case AppointmentStatus.RESCHEDULED:
        noteStatusDisplay = hideIfNA ? '' : 'N/A';
        break;
      default:
        noteStatusDisplay = formatEnumValue(noteStatus);
    }
  }
  return noteStatusDisplay;
}

export function buildICD10CodeEnumOptionName(
  code: string,
  description: string,
): string {
  return `${code}${
    code.length < 5 ? '\t\t\t' : code.length > 7 ? '  \t' : '\t\t'
  }${description}`;
}

/*
  New CPT Codes were included in the protobuf CPT Code enums
  But the user must see all of them only if he has the cpt code model waffle flag turned on
  This method is to filter the cpt code protobuf enum to have only the old cpt codes
  We are temporarily using this for the cpt code dropdown options in the assessment forms with the flag turned off
*/

export const defaultCptCodes = [
  'cpt_90791_initial_psychotherapy_session_60_min',
  'cpt_90832_psychotherapy_16_to_37_minutes_with_patient',
  'cpt_90834_psychotherapy_38_to_52_minutes_with_patient',
  'cpt_90837_psychotherapy_53_or_more_minutes_with_patient',
  'cpt_90792_diagnostic_evaluation_with_medical_services_45_min',
  'cpt_99212_em_low_complexity_refill_only',
  'cpt_99213_em_mod_complexity',
  'cpt_99214_em_high_complexity',
];

/*
  With multiple CPT code feature ON, the accepted list of codes for launch 02/09/2023 are as follows
*/

export const acceptedPsychiatryCptCodes = [
  'cpt_90792_diagnostic_evaluation_with_medical_services_45_min',
  'cpt_90785_interactive_complexity',
  'cpt_90833_psytx_w_pt_w_e_m_30_min',
  'cpt_90836_psytx_w_pt_w_e_m_45_min',
  'cpt_99212_em_low_complexity_refill_only',
  'cpt_99213_em_mod_complexity',
  'cpt_99214_em_high_complexity',
];

export const acceptedTherapyCptCodes = [
  'cpt_90791_initial_psychotherapy_session_60_min',
  'cpt_90832_psychotherapy_16_to_37_minutes_with_patient',
  'cpt_90834_psychotherapy_38_to_52_minutes_with_patient',
  'cpt_90837_psychotherapy_53_or_more_minutes_with_patient',
  'cpt_90846_family_psytx_w_o_pt_50_min',
  'cpt_90847_family_psytx_w_pt_50_min',
  'cpt_90839_psytx_crisis_initial_60_min',
  'cpt_90785_interactive_complexity',
  'cpt_90840_psytx_crisis_ea_addl_30_min',
];

export function filterCptCodes(
  cptCodeEnum: ProtobufEnum,
  acceptedCptCodes: string[] = defaultCptCodes,
): ProtobufEnum {
  const result = {} as ProtobufEnum;
  for (const key in cptCodeEnum) {
    if (acceptedCptCodes.includes(key.toString())) {
      result[key] = cptCodeEnum[key];
    }
  }
  return result;
}

export enum CareProviderNotesLabel {
  NOTE_VIEWED = 'note_viewed',
  NOTE_CREATED = 'note_created',
  NOTE_UPDATED = 'note_updated',
  NOTE_PUBLISHED = 'note_published',
  NOTE_DELETED = 'note_deleted',
  NOTE_SIGNED_AND_LOCKED = 'note_signed_and_locked',
  NOTE_AMENDED = 'note_amended',
}
