import { Link as MuiLink, TableCell, TableRow } from '@mui/material';
import Paper from '@mui/material/Paper';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { V2Table as Table } from 'shared-components/table/V2Table';
import { V2TableBody as TableBody } from 'shared-components/table/V2TableBody';
import { V2TableHead as TableHead } from 'shared-components/table/V2TableHead';
import { patientProfileRoute } from 'app/top-nav/Routes';
import { useAppState } from 'app/state';
import { viewAppointmentsTableAction } from 'app/state/amplitude/actions/appointments';
import { formatAppointmentStatus, formatEnumValue } from 'utils';
import { formatDate, formatTime } from 'utils/dateTime';
import { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Link } from 'react-router-dom';

import styles from './AppointmentsTable.module.scss';
import { ClinicalNoteActionBar } from './ClinicalNoteActionBar';
import { Appointment, NoteAction } from './types';

type Column = {
  key: string;
  label: string;
};

const defaultColumns: Column[] = [
  { key: 'date', label: 'Date' },
  { key: 'time', label: 'Time' },
  { key: 'name', label: 'Clinician' },
  { key: 'memberName', label: 'Member Name' },
  { key: 'type', label: 'Appt Type' },
  { key: 'status', label: 'Appt Status' },
  { key: 'noteStatus', label: 'Note Status' },
  { key: 'clinicalNote', label: 'Actions' },
];

export interface AppointmentsTableProps {
  appointments: Appointment[];
  localTimezone: string;
  hideClinicianName?: boolean;
  hideMemberName?: boolean;
  navigateTo: (url: string) => void;
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  dataTestId?: string;
}

export function AppointmentsTable(props: AppointmentsTableProps) {
  const dispatch = useDispatch();
  const {
    localTimezone,
    navigateTo,
    hideClinicianName,
    hideMemberName,
    dataTestId = 'apptTable',
  } = props;
  const userId = useAppState((_) => _.user.userId!);
  const {
    vaultEnabled,
    allowedNoteActions,
    canViewPatients,
  } = useFeatureFlags();

  useEffect(() => {
    dispatch(viewAppointmentsTableAction());
  }, [dispatch]);

  const columns = defaultColumns.filter(
    ({ key }) =>
      !(
        (props.hideClinicianName && key === 'name') ||
        (props.hideMemberName && key === 'memberName')
      ),
  );

  return (
    <Paper className={styles.appointments}>
      <Table className={styles.borderCollapseSeparate} dataTestId={dataTestId}>
        <AppointmentTableHead
          columns={columns}
          dataTestId={`${dataTestId}-header`}
        />
        <AppointmentTableBody
          dataTestId={`${dataTestId}-body`}
          userId={userId}
          localTimezone={localTimezone}
          appointments={props.appointments}
          hideClinicianName={hideClinicianName}
          hideMemberName={hideMemberName}
          vaultEnabled={vaultEnabled}
          navigateTo={navigateTo}
          allowedNoteActions={allowedNoteActions}
          signedLockedAction={props.signedLockedAction}
          showPatientLinks={canViewPatients}
        />
      </Table>
    </Paper>
  );
}

function AppointmentTableHead({
  columns,
  dataTestId,
}: {
  columns: Column[];
  dataTestId?: string;
}) {
  const filteredColumns = columns.map(({ label, key }) => (
    <TableCell data-testId={`${dataTestId}-${key}`} key={key}>
      {label}
    </TableCell>
  ));

  return <TableHead>{filteredColumns}</TableHead>;
}

function AppointmentTableBody(props: {
  userId: string;
  localTimezone: string;
  appointments: Appointment[];
  vaultEnabled: boolean;
  hideClinicianName?: boolean;
  hideMemberName?: boolean;
  navigateTo: (url: string) => void;
  allowedNoteActions: NoteAction[];
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  showPatientLinks: boolean;
  dataTestId?: string;
}) {
  const {
    userId,
    appointments,
    localTimezone,
    vaultEnabled,
    hideClinicianName = false,
    hideMemberName = false,
    navigateTo,
    allowedNoteActions,
    signedLockedAction,
    showPatientLinks,
    dataTestId,
  } = props;

  const rows = appointments.map((appt, i) => {
    const keyValue = `appt-${i}`;
    const status = appt.appointmentStatus
      ? formatAppointmentStatus(appt.appointmentStatus)
      : '-';

    const appointmentType = appt.appointmentType
      ? formatEnumValue(appt.appointmentType)
      : '-';

    const date = formatDate(appt.start, localTimezone);
    const startTime = formatTime(appt.start, localTimezone);
    const endTime = formatTime(appt.end, localTimezone);
    const zoomUrl = appt.zoomUrl ? (
      <MuiLink href={appt.zoomUrl} rel="noopener noreferrer" target="_blank">
        {appt.zoomUrl}
      </MuiLink>
    ) : undefined;

    return (
      <TableRow data-testid={appt.id} key={appt.id}>
        <TableCell data-testid={`date-${i}`} key={`date-${keyValue}`}>
          {date}
        </TableCell>

        <TableCell data-testid={`time-${i}`} key={`time-${keyValue}`}>
          <div>{`${startTime} - ${endTime} ${localTimezone}`}</div>
          {zoomUrl && <div data-testid="zoomUrl">{zoomUrl}</div>}
        </TableCell>

        {hideClinicianName === false && (
          <TableCell
            data-testid={`clinicianName-${i}`}
            key={`clinicianName-${keyValue}`}
          >
            {appt.name}
          </TableCell>
        )}

        {!hideMemberName && (
          <TableCell
            data-testid={`memberName-${i}`}
            key={`memberName-${keyValue}`}
          >
            {showPatientLinks ? (
              <Link to={patientProfileRoute(appt.member.id)}>
                {appt.member.name}
              </Link>
            ) : (
              appt.member.name
            )}
          </TableCell>
        )}

        <TableCell
          data-testid={`appointmentType-${i}`}
          key={`appointmentType-${keyValue}`}
        >
          {appointmentType}
        </TableCell>

        <TableCell
          data-testid={`appointmentStatus-${i}`}
          key={`appointmentStatus-${keyValue}`}
        >
          {status}
        </TableCell>

        <TableCell
          data-testid={`noteStatus-${i}`}
          key={`noteStatus-${keyValue}`}
        >
          {appt.noteStatus}
        </TableCell>

        <TableCell data-testid={`actionBar-${i}`} key={`actionBar-${keyValue}`}>
          <ClinicalNoteActionBar
            userId={userId}
            appointment={appt}
            vaultEnabled={vaultEnabled}
            navigateTo={navigateTo}
            className={styles.iconActionBar}
            allowedNoteActions={allowedNoteActions}
            signedLockedAction={signedLockedAction}
          />
        </TableCell>
      </TableRow>
    );
  });

  return <TableBody dataTestId={dataTestId}>{rows}</TableBody>;
}
