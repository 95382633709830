import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import { useLogger } from 'app/state/log/useLogger';
import { useEffect } from 'react';

import {
  subscribeToPubnubChannels,
  unsubscribeFromPubnubChannels,
} from 'app/inboxV2/state/pubnubSubscriptions/thunks';

interface SubscribeToPubnub {
  wildCardPattern?: string;
  rpcToCoachChannelId: string | null;
}

export const useSubscribeCoachToPubnub = ({
  wildCardPattern,
  rpcToCoachChannelId,
}: SubscribeToPubnub) => {
  const dispatch = useAppDispatch();
  const logger = useLogger();

  const unsubscribe = () => {
    dispatch(
      unsubscribeFromPubnubChannels({
        rpcToCoachChannel: rpcToCoachChannelId,
        wildCardPattern,
      }),
    );
    logger.info(
      'useSubscribeCoachToPubnub: dispatched unsubscribeFromPubnubChannels',
      {
        rpcToCoachChannelId,
        wildCardPattern,
      },
    );
  };

  useEffect(() => {
    if (!wildCardPattern || !rpcToCoachChannelId) {
      return undefined;
    }

    dispatch(
      subscribeToPubnubChannels({
        rpcToCoachChannel: rpcToCoachChannelId,
        wildCardPattern,
      }),
    );
    logger.info(
      'useSubscribeCoachToPubnub: dispatched subscribeToPubnubChannels',
      {
        rpcToCoachChannelId,
        wildCardPattern,
      },
    );
    return () => unsubscribe();
  }, [wildCardPattern, rpcToCoachChannelId]);
};
