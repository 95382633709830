import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { GetMemberCoachingTeam_getMember_coachingCareTeam as CoachingCareTeam } from '@headspace/carehub-graphql/dist/queries/generated/GetMemberCoachingTeam';
import { ActionMenu } from 'app/coach/coach-notes/ActionMenu';
import styles from 'app/coach/coach-notes/CoachNotes.module.scss';
import { NotesItemResponse } from 'app/coach/coach-notes/CoachNotesTypes';
import { NoteDetailViewContainer } from 'app/coach/coach-notes/NoteDetailViewContainer';
import { EmptyNoteView } from 'app/coach/coach-notes/NotesTable';
import { useCoachNotes } from 'app/coach/coach-notes/useCoachNotes';
import { parseQueryString } from 'app/coach/coach-notes/utils';
import { renderSliceStateResult } from 'app/state/status/RenderSliceState';
import { Status } from 'app/state/status/types/StateSlice';
import React from 'react';
import { Card } from 'shared-components/Card';
import { classNameCombiner } from 'utils';

export const CoachNotes = ({
  memberId,
  keyGenerator,
  coachingCareTeam,
  'data-testid': testId = 'coachNotes',
}: {
  memberId: string;
  coachingCareTeam?: CoachingCareTeam | null;
  keyGenerator?: KeyGenerator;
  'data-testid'?: string;
}): React.ReactElement => {
  const { noteType } = parseQueryString(location.search);
  const {
    coachNotes,
    onSelectCoachNote,
    setQueryParams,
    publishNote,
    deleteCoachNote,
    updateDraftNoteState,
  } = useCoachNotes({
    coachingCareTeam,
    keyGenerator,
    memberId,
  });

  const onRowClick = async (note: NotesItemResponse) => {
    await onSelectCoachNote(note);
  };

  const showNoteDetailView = !!noteType;

  return (
    <div className={classNameCombiner([styles.container, styles.withMultiTab])}>
      <Card
        data-testid={testId}
        size="fit-container"
        boxTitle="Notes"
        boxContentClass={styles.boxContent}
        isCollapsible={false}
        isScrollable={showNoteDetailView}
        actionMenuClass={styles.actionMenu}
        actionMenu={
          coachNotes.status === Status.COMPLETE ? (
            <ActionMenu
              showNoteDraftActions={showNoteDetailView}
              publishNote={publishNote}
              deleteCoachNote={deleteCoachNote}
              navigateTo={setQueryParams}
            />
          ) : undefined
        }
      >
        {renderSliceStateResult(coachNotes, (data) => {
          const hasNotes = Object.keys(data?.notes ?? []).length > 0;
          return (
            <>
              {hasNotes || showNoteDetailView ? (
                <NoteDetailViewContainer
                  updateNote={updateDraftNoteState}
                  setQueryParams={setQueryParams}
                  onRowClick={onRowClick}
                  data={data!}
                  noteType={noteType}
                />
              ) : (
                <EmptyNoteView />
              )}
            </>
          );
        })}
      </Card>
    </div>
  );
};
