import { TimeTokensAndStamps, TimetokensMap } from 'app/coach/chat/types';
import { ILogger } from 'app/state/log/Logger';

import { getInboxConversationTimetokens_getInboxConversationByIds } from '@headspace/carehub-graphql/dist/state/generated/getInboxConversationTimetokens';
import { updateChatTimetoken_updateChatConversationTimetoken } from '@headspace/carehub-graphql/dist/state/generated/updateChatTimetoken';
import { UpdateTimetokensResult } from './types';

export class TimetokensFormatter {
  /* please be careful when injecting the dependencies; in most cases the logger is the only dependency needed for a utils-like service;
  only a few services (that make bulk requests) may have multiple other services as dependencies;
  the responsibility of those should be limited to those bulk requests; */
  /* service classes in any event should remain stateless;
  the logic from the utils-like services should be easily refactorable by turning them into util functions;
  The reason for introducing utils service classes is to encapsulate business logic in this layer and abstract it from all other layers;
  This will simplify navigation, maintanance and testing. */
  constructor(private logger: ILogger) {}

  buildTimetokensMap(
    rawTimetokensResponse: getInboxConversationTimetokens_getInboxConversationByIds[],
  ): TimetokensMap {
    // intentinal any; we don't know how exactly the time tokens obj can be corrupted / which time tokens can legally be omitted
    const convoStatsWithNoChannelIdToLog: Array<any> = [];
    const updatedTimetokensMap = rawTimetokensResponse
      .filter((item) => {
        const isValid = this._areTimetokensForAConversationValid(item);
        if (!isValid) convoStatsWithNoChannelIdToLog.push(item);
        return isValid;
      })
      .reduce((acc, item) => {
        // assertion is safe here due to the _areTimetokensForAConversationValid being called above
        const channelId = item!.conversationStats!.memberCoachChannelId!;
        const {
          lastListenerReadTimeToken,
          lastMemberReadTimeToken,
          lastMemberWriteTimeToken,
          latestWriteTimestamp,
        } = item!.conversationStats!;
        acc[channelId] = {
          lastListenerReadTimeToken,
          lastMemberReadTimeToken,
          lastMemberWriteTimeToken,
          latestWriteTimestamp,
        };
        return acc;
      }, {} as TimetokensMap);
    if (convoStatsWithNoChannelIdToLog.length) {
      this.logger.error(
        new Error(
          'queryTimetokens query fulfilled, but response data contains unexpected results',
        ),
        {
          convoStatsWithNoChannelIdToLog,
        },
      );
    }
    return updatedTimetokensMap;
  }

  buildTimetokensUpdate(
    updateTimeTokensResponse: updateChatTimetoken_updateChatConversationTimetoken,
  ): UpdateTimetokensResult | undefined {
    if (!this._areTimetokensForAConversationValid(updateTimeTokensResponse)) {
      this.logger.error(
        new Error(
          'updateChatTimetokens mutation fulfilled, but response data contains unexpected results. The state will not be updated on the frontend',
        ),
        {
          updateTimeTokensResponse,
        },
      );
      return;
    }
    const {
      lastListenerReadTimeToken,
      lastMemberReadTimeToken,
      lastMemberWriteTimeToken,
      latestWriteTimestamp,
      memberCoachChannelId,
    } = updateTimeTokensResponse.conversationStats!; // assertion is safe here due to the _areTimetokensForAConversationValid being called above
    const updatedTimetokens: TimeTokensAndStamps = {
      lastListenerReadTimeToken,
      lastMemberReadTimeToken,
      lastMemberWriteTimeToken,
      latestWriteTimestamp,
    };
    return { channelId: memberCoachChannelId, updatedTimetokens };
  }

  private _areTimetokensForAConversationValid(
    rawTimetokensObj:
      | getInboxConversationTimetokens_getInboxConversationByIds
      | updateChatTimetoken_updateChatConversationTimetoken,
  ) {
    return Boolean(rawTimetokensObj?.conversationStats?.memberCoachChannelId);
  }
}
