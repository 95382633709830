import { useQuery } from '@apollo/client';
import {
  GetMemberSessionsForMe,
  GetMemberSessionsForMeVariables,
} from '@headspace/carehub-graphql/dist/patients-tabs-appointments/generated/GetMemberSessionsForMe';
import { getMemberSessionsForMe } from '@headspace/carehub-graphql/dist/patients-tabs-appointments/queries';
import { Box } from '@mui/material';
import Typography from '@mui/material/Typography';
import styles from 'app/appointments/AppointmentsTable.module.scss';
import { ApolloCachingStrategy } from 'app/constants';
import React from 'react';
import { FormError } from 'shared-components/FormError';

type MemberSessionStatisticsProps = {
  patientId: string;
};

export function MemberSessionStatistics(props: MemberSessionStatisticsProps) {
  const { patientId } = props;
  const { error, data } = useQuery<
    GetMemberSessionsForMe,
    GetMemberSessionsForMeVariables
  >(getMemberSessionsForMe, {
    fetchPolicy: ApolloCachingStrategy.CACHE_AND_NETWORK,
    variables: { id: patientId },
  });

  const sessionData: GetMemberSessionsForMe = data ?? {
    getMemberSessionsForMe: null,
  };

  return (
    <div className={styles.memberScheduleStatsWrapper}>
      <div className={styles.memberScheduleStats}>
        <Typography
          variant="body1"
          color="textSecondary"
          className={styles.text}
        >
          You have
          <Box
            className={styles.value}
            component="span"
            data-testid="member-sessions-scheduled"
          >
            {sessionData.getMemberSessionsForMe?.upcomingScheduled || 0}
          </Box>
          upcoming scheduled. You have completed
          <Box
            className={styles.value}
            component="span"
            data-testid="member-completed-sessions"
          >
            {sessionData.getMemberSessionsForMe?.completedSessions || 0}
          </Box>
          of
          <Box
            className={styles.value}
            component="span"
            data-testid="member-sessions-anticipated"
          >
            {sessionData.getMemberSessionsForMe?.anticipatedSessions || 0}
          </Box>
          anticipated sessions.
        </Typography>
      </div>
      <FormError error={error?.message} />
    </div>
  );
}
