import {
  CoachShiftType,
  GuardianRelationshipType,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { isFrenchUser, isSpanishUser } from 'utils';

const scheduleMap: Record<CoachShiftType | string, string> = {
  [CoachShiftType.COACH]: '',
  [CoachShiftType.COACH_A]: 'A',
  [CoachShiftType.COACH_A1]: 'A1',
  [CoachShiftType.COACH_A2]: 'A2',
  [CoachShiftType.COACH_B]: 'B',
  [CoachShiftType.COACH_B1]: 'B1',
  [CoachShiftType.COACH_B2]: 'B2',
  [CoachShiftType.COACH_B3]: 'B3',
  [CoachShiftType.COACH_C]: 'C',
  [CoachShiftType.COACH_C1]: 'C1',
  [CoachShiftType.COACH_C2]: 'C2',
  [CoachShiftType.COACH_D]: 'D',
  [CoachShiftType.COACH_D1]: 'D1',
  [CoachShiftType.COACH_D2]: 'D2',
  [CoachShiftType.COACH_E]: 'E',
  [CoachShiftType.COACH_E1]: 'E1',
  [CoachShiftType.COACH_E2]: 'E2',
  [CoachShiftType.COACH_E3]: 'E3',
  [CoachShiftType.COACH_E4]: 'E4',
  [CoachShiftType.COACH_F]: 'F',
  [CoachShiftType.COACH_F1]: 'F1',
  [CoachShiftType.COACH_F2]: 'F2',
  [CoachShiftType.COACH_F3]: 'F3',
  [CoachShiftType.COACH_F4]: 'F4',
  [CoachShiftType.COACH_F5]: 'F5',
  [CoachShiftType.COACH_F6]: 'F6',
  [CoachShiftType.COACH_G]: 'G',
  [CoachShiftType.LISTENER]: 'Listener',
  [CoachShiftType.COACH_SUPERVISOR]: 'Coach Supervisor',
  [CoachShiftType.CLINICAL_SUPERVISOR]: 'Clinical Supervisor',
  [CoachShiftType.CONCIERGE]: 'Member Support',
  [CoachShiftType.CLINICAL_REVIEWER]: 'Clinical Reviewer',
};

/**
 * Create a displayable value for a coach shift. If an unsupported value, its string form is returned. If
 * null is provided, blank will be returned.
 *
 * @param schedule - the coach shift to format a displayable value for
 * @returns a displayable value for the coach shift (can be blank)
 */
export const formatCoachSchedule = (
  schedule: CoachShiftType | string | null,
): string => {
  if (schedule === null) {
    return '';
  }
  // Optional: just return "" instead of the schedule.toString()
  return scheduleMap[schedule] ?? schedule.toString();
};

export const specialtyTags = {
  [GuardianRelationshipType.GUARDIAN]: {
    full: 'Guardian',
    short: 'Gu',
  },
  [GuardianRelationshipType.DEPENDENT]: {
    full: 'Teen',
    short: 'Te',
  },
  [GuardianRelationshipType.NONE]: null,
  french: { full: 'French', short: 'Fr' },
  spanish: {
    full: 'Spanish',
    short: 'Sp',
  },
};

export const getRoleTag = (
  guardianRelationshipType: keyof typeof GuardianRelationshipType,
) => {
  return guardianRelationshipType && guardianRelationshipType in specialtyTags
    ? specialtyTags[guardianRelationshipType]
    : null;
};

export const getLangTag = (preferredLanguage: string) => {
  if (isSpanishUser(preferredLanguage)) return specialtyTags.spanish;
  if (isFrenchUser(preferredLanguage)) return specialtyTags.french;
  return null;
};

export const fusionTagText = 'Headspace App';
export const legacyGingerAppTagText = 'Headspace Care App';

export const legacyGingetTagTooltip =
  'Member is accessing care though the Headspace Care (formerly Ginger) app. For more details go to Details > Benefits';

export const fusionTagTooltip =
  'Member is accessing care though the Headspace app. For more details go to Details > Benefits';

export const d2cTagText = 'D2C';

export const d2cTagTooltip = 'Member is paying for care services out-of-pocket';

export const headspaceEAP = 'Headspace EAP';

export const videoCoachingText = 'Video Coaching';

export const recordingConsentAcceptedText = 'Recording Consent';
