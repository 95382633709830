import { createAsyncThunk } from '@reduxjs/toolkit';

import { ThunkAPI } from 'app/state/types/state';

export interface SubscribePayload {
  wildCardPattern?: string;
  rpcToCoachChannel?: string | null;
  // {"memberId": "channelId"}
  coachMemberChannels?: Record<string, string>;
  channelIds?: string[];
}

export const subscribeToPubnubChannels = createAsyncThunk<
  void,
  SubscribePayload,
  ThunkAPI
>(
  'pubnubSubscriptions/subscribeToPubnubChannels',
  async (
    { rpcToCoachChannel, coachMemberChannels, wildCardPattern },
    thunkAPI,
  ) => {
    const {
      extra: {
        services: { pubnub },
      },
    } = thunkAPI;

    const channelsToSubscribe: Array<string> = ([] as string[])
      .concat(rpcToCoachChannel ? [rpcToCoachChannel] : [])
      .concat(wildCardPattern ? [wildCardPattern] : []);

    if (coachMemberChannels) {
      Object.entries(coachMemberChannels).forEach(([_, channelId]) => {
        channelsToSubscribe.push(channelId);
      });
    }

    if (channelsToSubscribe.length === 0) {
      return;
    }

    try {
      pubnub.subscribeToChannels({
        channels: channelsToSubscribe,
      });
      console.log('subscribeToPubnubChannels: subscribed to channels', {
        channels: channelsToSubscribe,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: new Error(
          `Failed to subscribe to pubnub channels: ${channelsToSubscribe}`,
          {
            cause: error,
          },
        ),
      });
    }
  },
);

export const unsubscribeFromPubnubChannels = createAsyncThunk<
  void,
  SubscribePayload,
  ThunkAPI
>(
  'pubnubSubscriptions/unsubscribeFromPubnubChannels',
  async (
    { rpcToCoachChannel, coachMemberChannels, wildCardPattern },
    thunkAPI,
  ) => {
    const {
      extra: {
        services: { pubnub },
      },
    } = thunkAPI;

    const channelsToUnsubscribe: Array<string> = ([] as string[])
      .concat(rpcToCoachChannel ? [rpcToCoachChannel] : [])
      .concat(wildCardPattern ? [wildCardPattern] : []);

    if (coachMemberChannels) {
      Object.entries(coachMemberChannels).forEach(([_, channelId]) => {
        channelsToUnsubscribe.push(channelId);
      });
    }

    try {
      pubnub.unsubscribeChannels(channelsToUnsubscribe);
      console.log('unsubscribeFromPubnubChannels: unsubscribed from channels', {
        channels: channelsToUnsubscribe,
      });
    } catch (error) {
      return thunkAPI.rejectWithValue({
        error: new Error(
          `Failed to unsubscribe to pubnub channels: ${channelsToUnsubscribe}`,
          {
            cause: error,
          },
        ),
      });
    }
  },
);
