import { usePubNubAPI } from 'app/coach/pubnub/PubNubContextProvider';
import { PUBNUB_STATUS } from 'app/coach/pubnub/types';
import { InboxErrorState } from 'app/inbox/InboxError/InboxErrorState';
import { InboxV2 } from 'app/inboxV2/components/InboxV2';
import { useAppState } from 'app/state';
import {
  selectRPCToCoachChannelId,
  selectShouldDisplayInboxV2,
  selectWildCardPattern,
} from 'app/state/user/selectors';
import ErrorBoundary from 'shared-components/error-state/ErrorBoundary';
import { Loader } from 'shared-components/loader/Loader';

import { InboxV1 } from './InboxV1';

/*
InboxContainer is responsible for managing: 
- which version of the Inbox is being displayed
- checking whether Inbox can be mounted: ensures we have the state needed to subscribe to Pubnub
*/

export function InboxContainer() {
  const shouldShowInboxV2 = useAppState(selectShouldDisplayInboxV2);

  if (!shouldShowInboxV2) {
    return <InboxV1 />;
  }

  const wildCardPattern = useAppState(selectWildCardPattern);
  const rpcToCoachChannelId = useAppState(selectRPCToCoachChannelId);

  const api = usePubNubAPI();
  const { status } = api;

  if (
    !wildCardPattern ||
    !rpcToCoachChannelId ||
    status === PUBNUB_STATUS.ERROR
  ) {
    const err = new Error(
      'InboxContainer: cannot mount Inbox, no wildCardPattern or rpcToCoachChannelId or Pubnub has not been instantiated',
    );

    return <InboxErrorState error={err} />;
  }

  if (status === PUBNUB_STATUS.NOT_INSTANTIATED) {
    return <Loader />;
  }

  return (
    <ErrorBoundary FallbackComponent={InboxErrorState}>
      <InboxV2 />
    </ErrorBoundary>
  );
}
