import { Stage } from '@ginger.io/core-ui';
import { getHomeworkForMember_getHomeworkForMember_tasks } from '@headspace/carehub-graphql/dist/patients-tabs-content/generated/getHomeworkForMember';

import { HomeworkSource, HomeworkStatus, HomeworkType } from './types';

export const getHomeworkThumbnail = (thumbnail: string | null): string => {
  const stage = process.env.REACT_APP_STAGE == Stage.PROD ? 'io' : 'dev';
  const isFullUrl = thumbnail && thumbnail.includes('content-repository');
  if (isFullUrl) {
    return thumbnail;
  }
  const img = thumbnail
    ? encodeURIComponent(thumbnail)
    : 'assets/mantrameditation.png';
  return `https://content-repository.ginger.${stage}/audio/${img}`;
};

export const convertTaskToHomeworkType = (
  task: getHomeworkForMember_getHomeworkForMember_tasks,
): HomeworkType => {
  let status = HomeworkStatus.NOT_STARTED;
  if (task.startedAt) {
    status = HomeworkStatus.IN_PROGRESS;
  }
  if (task.completedAt) {
    status = HomeworkStatus.COMPLETED;
  }

  return {
    assignmentId: task.id,
    contentType: task.contentType,
    // Ginger, since we don't have any other source
    date: task.createdAt,

    slug: task.contentId ?? '',

    source: HomeworkSource.GINGER,

    status,

    thumbnail: getHomeworkThumbnail(task.thumbnail),
    title: task.text,
  };
};

export const extractAssignmentIdFromUri = (uri: string): string | null => {
  if (!uri) return null;
  const url = new URL(uri);
  const assignmentId = url.searchParams.get('assignment');
  return assignmentId;
};

export const contentDetailsLayoutConfig = {
  24: 1,
  240: 2,
};
