import { NoteAction } from 'app/appointments/types';
import { createContext, useContext } from 'react';

export type BaseFeatureFlags = {
  allowedNoteActions: NoteAction[];
  canViewPatients: boolean;
  canViewMSAppointmentsTable: boolean;
  vaultEnabled: boolean;
};

export type TransientFeatureFlags = Record<TransientFeatureFlag, boolean>;

export type FeatureFlags = BaseFeatureFlags & {
  transientFeatureFlags: TransientFeatureFlags;
};

/**
 * Transient Feature flags are used to toggle experimental and/or preliminary functionality and are used to
 * conditionally enable features to a sample portion of users (can also be used to ramp up launches). The flags are
 * stored and managed server-side (e.g. ginger-web via Waffle) and are typically transient flags that are retired after
 * experiments conclude or once a new functionality is fully launched.
 *
 * These flags should not be used to gate access to released features because they will not support any business
 * logic. See Features as the alternative for gating released features (see getFeatures.ts)
 */
export enum TransientFeatureFlag {
  // Enables the Clinical Hub:Schedule functionality
  SCHEDULE = 'enable_clinhub_schedule',
  CHAT_COLLABORATION = 'enable_clinician_chat_collaboration',
  ENABLE_SUPERVISOR_SIGN_AND_LOCK_NOTES_FOR_USER = 'enable_supervisor_sign_and_lock_notes_for_user',
  ENABLE_DOCUMENT_UPLOAD = 'enable_document_upload',
  ENABLE_CARE_HUB_NOTES_EFFICIENCY = 'enable_care_hub_notes_efficiency',
  ENABLE_FORM_CARE_COORDINATION_REQUEST = 'enable_form_care_coordination_request',
  ENABLE_HIDE_APPOINTMENTS_TAB = 'enable_hide_appointments_tab',
  ENABLE_TASKS_V2 = 'enable_tasks_v2',
  ENABLE_COMPLETING_TASKS = 'enable_completing_tasks',
  ENABLE_CARE_METRICS = 'enable_care_metrics',
  ENABLE_CARE_METRICS_COMPANY_TARGET = 'enable_care_metrics_company_target',
  ENABLE_ADAPTABILITY_SURVEYS_V3 = 'enable_adaptability_surveys_v3',
  ENABLE_SCHEDULER_V2 = 'enable_scheduler_v2',
  ENABLE_MEMBER_BACKGROUND_MEMBER_NOTES_SECTION = 'enable_member_background_member_notes_section',
  ENABLE_ADD_SI_COACH_BUTTON = 'enable_add_si_coach_button',
  ENABLE_ADD_MEMBER_SUPPORT_BUTTON = 'enable_add_member_support_button',
  ENABLE_DROP_IN_NOTES = 'enable_drop_in_notes',
  ENABLE_ESCALATE_TO_CLINICAL_BUTTON = 'enable_escalate_to_clinical_button',
  ENABLE_READ_ONLY_CHAT = 'enable_read_only_chat',
  ENABLE_CAREHUB_PUBNUB_SUBSCRIBE_WILDCARD = 'enable_carehub_pubnub_subscribe_wildcard',
  ENABLE_CAREHUB_DEBUG_LOGGER = 'enable_carehub_debug_logger',
  ENABLE_NOTES_EFFICIENCY_CREATION_DISABLED = 'enable_notes_efficiency_creation_disabled',
  ENABLE_CSC_AUTOMATION = 'enable_csc_automation',
  ENABLE_COACH_SHIFT_STATUS_CHANGES = 'enable_coach_shift_status_changes',
  ENABLE_COACHING_SCHEDULER = 'enable_coaching_scheduler',
  ENABLE_SINGLE_REQUEST_ON_PROGRESS_NOTE_COPIED_SECTION = 'enable_single_request_on_progress_note_copied_section',
  ENABLE_D2C_COACHING_COVERAGE_INFO = 'enable_d2c_coaching_coverage_info',
  CAREHUB_ENABLE_COACH_INBOX_V2 = 'carehub_enable_coach_inbox_v2',
  ENABLE_CSSRS_SCORE_TAB = 'enable_cssrs_score_tab',
  ENABLE_TOOLTIP_LABEL_UNDERLINE = 'enable_tooltip_label_underline',
  ENABLE_PROFILE_CARD_IMPROVEMENTS = 'enable_profile_card_improvements',
  ENABLE_NOTE_PDF_PASSWORD_BYPASS = 'enable_note_pdf_password_bypass',
  ENABLE_TEEN_INTAKE_TAB_V2 = 'enable_teen_intake_tab_v2',
  ENABLE_SESSIONS_OFFERED_METRICS = 'enable_sessions_offered_metrics',
  enable_fix_unread_messages_read_logic = 'enable_fix_unread_messages_read_logic',
  ENABLE_ITMS_TOGGLE = 'enable_itms_toggle',
  ENABLE_BRELLIUM_CLINICAL_NOTES_REVIEW = 'enable_brellium_clinical_notes_review',
  ENABLE_ZOOM_DURATION_BUTTON = 'enable_zoom_duration_button',
  ENABLE_NOTE_INTERACTION_TRACKING = 'enable_note_interaction_tracking',
  ENABLE_PREFILL_PHQGAD_ANSWER_IN_NOTE = 'enable_prefill_phqgad_answer_in_note',
  ENABLE_CARE_HUB_AI_NOTETAKER = 'enable_care_hub_ai_notetaker',
}

export const defaultBaseFeatureFlags: BaseFeatureFlags = {
  allowedNoteActions: [],
  canViewMSAppointmentsTable: false,
  canViewPatients: false,
  vaultEnabled: true,
};

export const defaultTransientFeatureFlags: TransientFeatureFlags = {
  carehub_enable_coach_inbox_v2: false,
  enable_adaptability_surveys_v3: false,
  enable_add_member_support_button: false,
  enable_add_si_coach_button: false,
  enable_brellium_clinical_notes_review: false,
  enable_care_hub_ai_notetaker: false,
  enable_care_hub_notes_efficiency: false,
  enable_care_metrics: false,
  enable_care_metrics_company_target: false,
  enable_carehub_debug_logger: false,
  enable_carehub_pubnub_subscribe_wildcard: false,
  enable_clinhub_schedule: false,
  enable_clinician_chat_collaboration: false,
  enable_coach_shift_status_changes: false,
  enable_coaching_scheduler: false,
  enable_completing_tasks: false,
  enable_csc_automation: false,
  enable_cssrs_score_tab: false,
  enable_d2c_coaching_coverage_info: false,
  enable_document_upload: false,
  enable_drop_in_notes: false,
  enable_escalate_to_clinical_button: false,
  enable_fix_unread_messages_read_logic: false,
  enable_form_care_coordination_request: false,
  enable_hide_appointments_tab: false,
  enable_itms_toggle: false,
  enable_member_background_member_notes_section: false,
  enable_note_interaction_tracking: false,
  enable_note_pdf_password_bypass: false,
  enable_notes_efficiency_creation_disabled: false,
  enable_prefill_phqgad_answer_in_note: false,
  enable_profile_card_improvements: false,
  enable_read_only_chat: false,
  enable_scheduler_v2: false,
  enable_sessions_offered_metrics: false,
  enable_single_request_on_progress_note_copied_section: false,
  enable_supervisor_sign_and_lock_notes_for_user: false,
  enable_tasks_v2: false,
  enable_teen_intake_tab_v2: false,
  enable_tooltip_label_underline: false,
  enable_zoom_duration_button: false,
};

export const defaultFeatureFlags: FeatureFlags = {
  ...defaultBaseFeatureFlags,
  transientFeatureFlags: defaultTransientFeatureFlags,
};

export const FeatureFlagsContext = createContext(defaultFeatureFlags);

export function useFeatureFlags(): FeatureFlags {
  // Deprecated: For all new code, use this selector instead:
  // const featureFlags = useAppState(selectTransientFeatureFlags);
  return useContext(FeatureFlagsContext);
}
