import { SICoachButtonStatusChangedAmplitudeEvent } from 'app/care-team/add-si-coach-button/types';
import { EventType } from 'app/events/EventType';
import { createAction } from 'redux-reloaded';

export const careTeamClicked = createAction(
  EventType.CARE_TEAM_CARD_CLICKED,
  (payload: { memberId: string }) => ({
    ...payload,
  }),
);
export const careTeamAddMemberSupportClicked = createAction(
  EventType.CARE_TEAM_ADD_MEMBER_SUPPORT_CLICKED,
  (payload: { memberId: string }) => ({ ...payload }),
);
export const careTeamEscalateToClinicalClicked = createAction(
  EventType.CARE_TEAM_ESCALATE_TO_CLINICAL_CLICKED,
  (payload: { memberId: string; isTeen: boolean }) => ({ ...payload }),
);
export const careTeamMemberProfileViewed = createAction(
  EventType.CARE_TEAM_MEMBER_PROFILE_VIEWED,
  (payload: { memberId: string }) => ({ ...payload }),
);
export const careTeamTogglePastMembersClicked = createAction(
  EventType.CARE_TEAM_TOGGLE_PAST_MEMBERS_CLICKED,
  (payload: { memberId: string; showPast: boolean }) => ({ ...payload }),
);
export const addSICoachButtonStatusChanged = createAction(
  EventType.ADD_SI_COACH_BUTTON_STATUS_CHANGED,
  (payload: SICoachButtonStatusChangedAmplitudeEvent) => ({ ...payload }),
);
