import {
  ConversationsInboxItem,
  getInitialConversationsState,
  State as ConversationsSliceState,
} from 'app/inboxV2/state/conversations/schema';
import {
  getInitialPubnubSubscriptionsState,
  State as PubnubSubscriptionsState,
} from 'app/inboxV2/state/pubnubSubscriptions/schema';
import { initialState as SideBarInitialState } from 'app/inboxV2/state/sideBar/reducers';
import { SideBarState } from 'app/inboxV2/state/sideBar/sideBarSlice';
import { listenerApi } from 'app/services/server/listenerApi';
import { webApi } from 'app/services/server/webApi';
import { getInitialState as getInitialAuthState } from 'app/state/features/auth/authSlice';
import { IAuthSlice } from 'app/state/features/auth/types';

import {
  getInitialCareTeamState,
  State as CareTeamState,
} from './care-team/schema';
import { getInitialChatState, State as ChatState } from './chat/schema';
import {
  getInitialCoachNotesState,
  State as CoachNotesState,
} from './coach-notes/schema';
import {
  getInitialContentState,
  State as ContentState,
} from './content/schema';
import { initialState as featureFlagsState } from './featureFlags/featureFlagsSlice';
import { IFeatureFlagsSlice } from './featureFlags/types';
import { IConversationTimetokensSlice } from './features/conversationTimetokens/types';
import { PersistedLayout } from './features/persistedLayout/types';
import { getInitialState as getInitialPersistedLayoutState } from './features/persistedLayout/util';
import {
  getInitialSchedulerState,
  State as SchedulerState,
} from './features/scheduler/schema';
import {
  getInitialDropInAlertState,
  State as StructuredCoachingState,
} from './features/structuredCoaching/schema';
import { getInitialInboxState, State as InboxState } from './inbox/schema';
import {
  getInitialMultiTabState,
  State as MultiTabState,
} from './member-tabs/schema';
import { State as NotificationState } from './notifications/schema';
import { State as PatientsState } from './patients/schema';
import { State as RequestState } from './request/schema';
import {
  getInitialTimedSendState,
  State as TimedSendsState,
} from './timed-sends/schema';
import {
  getInitialState as getInitialUserState,
  State as UserState,
} from './user/schema';

/** Global App state */
export type State = Readonly<{
  patients: PatientsState;
  user: UserState;
  notification: NotificationState;
  request: RequestState;
  chat: ChatState;
  persistedLayout: PersistedLayout;
  inbox: InboxState;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [listenerApi.reducerPath]: any;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [webApi.reducerPath]: any;
  sideBar: SideBarState;
  multiTab: MultiTabState;
  content: ContentState;
  careTeam: CareTeamState;
  scheduler: SchedulerState;
  structuredCoaching: StructuredCoachingState;
  timedSends: TimedSendsState;
  coachNotes: CoachNotesState;
  conversationsTimetokens: IConversationTimetokensSlice;
  auth: IAuthSlice;
  featureFlags: IFeatureFlagsSlice;
  // State slices below are exclusively for Inbox v2.
  conversations: ConversationsSliceState<ConversationsInboxItem>;
  pubnubSubscriptions: PubnubSubscriptionsState;
}>;

/** Initial App state (before the user takes any action, queries load etc) */
export function getInitialState(
  user: Partial<UserState> = getInitialUserState(),
): State {
  const userState = getInitialUserState(user);
  const chatState = getInitialChatState();
  const inboxState = getInitialInboxState();
  const multiTabState = getInitialMultiTabState();
  const contentState = getInitialContentState();
  const careTeamState = getInitialCareTeamState();
  const schedulerState = getInitialSchedulerState();
  const structuredCoachingState = getInitialDropInAlertState();
  const timedSendsState = getInitialTimedSendState();
  const coachNotesState = getInitialCoachNotesState();
  const authState = getInitialAuthState();
  const conversationsSliceState = getInitialConversationsState();
  const pubnubSubscriptionsState = getInitialPubnubSubscriptionsState();

  return {
    auth: authState,
    careTeam: careTeamState,
    chat: chatState,
    coachNotes: coachNotesState,
    content: contentState,
    conversations: conversationsSliceState,
    conversationsTimetokens: { timetokensMap: {} },
    featureFlags: featureFlagsState,
    inbox: inboxState,
    multiTab: multiTabState,
    [listenerApi.reducerPath]: listenerApi.reducer,
    [webApi.reducerPath]: webApi.reducer,
    notification: {
      showSnackbar: false,
      snackbarNotification: null,
    },
    patients: {
      selectedPatientId: null,
    },
    persistedLayout: getInitialPersistedLayoutState(),
    pubnubSubscriptions: pubnubSubscriptionsState,
    request: {},
    scheduler: schedulerState,
    sideBar: SideBarInitialState,
    structuredCoaching: structuredCoachingState,
    timedSends: timedSendsState,
    user: userState,
  };
}
