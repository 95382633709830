import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  ClinicianIntakeChecklist,
  ClinicianIntakeChecklist_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import {
  SubstanceUse,
  SubstanceUse_CurrentSubstanceUse,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  PresentingProblem,
  PresentingProblem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/PresentingProblem';
import {
  SocialAndDevelopmental,
  SocialAndDevelopmental_Employment,
  SocialAndDevelopmental_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/SocialAndDevelopmental';
import {
  TreatmentPlan,
  TreatmentPlan_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/TreatmentPlan';
import { TherapyIntakeSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { SubstanceUse_SubstanceType } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  TreatmentGoal,
  TreatmentGoal_GoalType,
} from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';

const AI_DRAFT_PREFIX = 'From AI:';

export function mergeTherapyIntakeWithAiGeneratedNote({
  intakeNote,
  aiNoteDraft,
}: {
  intakeNote: TherapyIntakeNote;
  aiNoteDraft: TherapyIntakeNote;
}): TherapyIntakeNote {
  return {
    ...intakeNote,
    [TherapyIntakeSectionName.CLINICIAN_CHECKLIST]: ClinicianIntakeChecklist.fromPartial(
      {
        ...intakeNote.CLINICIAN_CHECKLIST!,
        introducedCoachingToMember:
          intakeNote.CLINICIAN_CHECKLIST?.introducedCoachingToMember ??
          aiNoteDraft.CLINICIAN_CHECKLIST?.introducedCoachingToMember ??
          false,
        version:
          intakeNote.CLINICIAN_CHECKLIST?.version ??
          ClinicianIntakeChecklist_Version.v0,
      },
    ),
    [TherapyIntakeSectionName.PRESENTING_PROBLEM]: mergePresentingProblemSection(
      intakeNote,
      aiNoteDraft,
    ),
    [TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL]: mergeSocialDevelopmentalSection(
      intakeNote,
      aiNoteDraft,
    ),
    [TherapyIntakeSectionName.SUBSTANCE_ABUSE]: mergeSubstanceAbuseSection(
      intakeNote,
      aiNoteDraft,
    ),
    [TherapyIntakeSectionName.TREATMENT_PLAN]: mergeTreatmentPlanSection(
      intakeNote,
      aiNoteDraft,
    ),
  };
}

function mergePresentingProblemSection(
  intakeNote: TherapyIntakeNote,
  aiNoteDraft: TherapyIntakeNote,
) {
  const impactOfSymptoms = [
    ...(intakeNote.PRESENTING_PROBLEM?.impactOfSymptoms ?? []),
    ...(aiNoteDraft.PRESENTING_PROBLEM?.impactOfSymptoms ?? []),
  ];
  return PresentingProblem.fromPartial({
    ...intakeNote.PRESENTING_PROBLEM!,
    historyOfPresentingProblem: mergeWithAiText(
      intakeNote.PRESENTING_PROBLEM?.historyOfPresentingProblem ?? null,
      aiNoteDraft.PRESENTING_PROBLEM?.historyOfPresentingProblem ?? null,
    ),
    impactOfSymptoms: Array.from(new Set(impactOfSymptoms)),
    presentingProblem: mergeWithAiText(
      intakeNote.PRESENTING_PROBLEM?.presentingProblem ?? null,
      aiNoteDraft.PRESENTING_PROBLEM?.presentingProblem ?? null,
    ),
    receivingTreatmentBeyondClinician:
      intakeNote.PRESENTING_PROBLEM?.receivingTreatmentBeyondClinician ??
      aiNoteDraft.PRESENTING_PROBLEM?.receivingTreatmentBeyondClinician ??
      BooleanOption.undefined_choice,
    version:
      intakeNote.PRESENTING_PROBLEM?.version ?? PresentingProblem_Version.v0,
  });
}

function mergeTreatmentPlanSection(
  intakeNote: TherapyIntakeNote,
  aiNoteDraft: TherapyIntakeNote,
) {
  const data: { [k in TreatmentGoal_GoalType]?: TreatmentGoal } = {};
  (intakeNote.TREATMENT_PLAN?.goal ?? []).forEach((goal) => {
    data[goal.goalType] = goal;
  });

  (aiNoteDraft.TREATMENT_PLAN?.goal ?? []).forEach((goal) => {
    const existing = data[goal.goalType];
    data[goal.goalType] = {
      ...(existing ?? goal),
      actionPlan: mergeWithAiText(
        existing?.actionPlan ?? null,
        goal.actionPlan,
      ),
      goalDetails: mergeWithAiText(
        existing?.goalDetails ?? null,
        goal.goalDetails,
      ),
      otherGoalStatusComment: mergeWithAiText(
        existing?.otherGoalStatusComment ?? null,
        goal.otherGoalStatusComment,
      ),
    };
  });

  return TreatmentPlan.fromPartial({
    ...intakeNote.TREATMENT_PLAN!,
    goal: Object.values(data),
    version: intakeNote.TREATMENT_PLAN?.version ?? TreatmentPlan_Version.v0,
  });
}

function mergeSocialDevelopmentalSection(
  intakeNote: TherapyIntakeNote,
  aiNoteDraft: TherapyIntakeNote,
) {
  const employment: SocialAndDevelopmental_Employment[] = [
    ...(intakeNote.SOCIAL_DEVELOPMENTAL?.employment ?? []),
    ...(aiNoteDraft.SOCIAL_DEVELOPMENTAL?.employment ?? []),
  ];
  const relationshipStatus = [
    ...(intakeNote.SOCIAL_DEVELOPMENTAL?.relationshipStatus ?? []),
    ...(aiNoteDraft.SOCIAL_DEVELOPMENTAL?.relationshipStatus ?? []),
  ];
  return SocialAndDevelopmental.fromPartial({
    ...intakeNote.SOCIAL_DEVELOPMENTAL!,
    culturalConsiderations: mergeWithAiText(
      intakeNote.SOCIAL_DEVELOPMENTAL?.culturalConsiderations ?? null,
      aiNoteDraft.SOCIAL_DEVELOPMENTAL?.culturalConsiderations ?? null,
    ),
    employment: Array.from(new Set(employment)),
    otherComments: mergeWithAiText(
      intakeNote.SOCIAL_DEVELOPMENTAL?.otherComments ?? null,
      aiNoteDraft.SOCIAL_DEVELOPMENTAL?.otherComments ?? null,
    ),
    otherEmploymentComments: mergeWithAiText(
      intakeNote.SOCIAL_DEVELOPMENTAL?.otherEmploymentComments ?? null,
      aiNoteDraft.SOCIAL_DEVELOPMENTAL?.otherEmploymentComments ?? null,
    ),
    otherRelationshipStatusComments: mergeWithAiText(
      intakeNote.SOCIAL_DEVELOPMENTAL?.otherRelationshipStatusComments ?? null,
      aiNoteDraft.SOCIAL_DEVELOPMENTAL?.otherRelationshipStatusComments ?? null,
    ),
    relationshipStatus: Array.from(new Set(relationshipStatus)),
    relevantSocialOrFamilyHistory: mergeWithAiText(
      intakeNote.SOCIAL_DEVELOPMENTAL?.relevantSocialOrFamilyHistory ?? null,
      aiNoteDraft.SOCIAL_DEVELOPMENTAL?.relevantSocialOrFamilyHistory ?? null,
    ),
    version:
      intakeNote.SOCIAL_DEVELOPMENTAL?.version ??
      SocialAndDevelopmental_Version.v0,
  });
}

function mergeSubstanceAbuseSection(
  intakeNote: TherapyIntakeNote,
  aiNoteDraft: TherapyIntakeNote,
) {
  if (intakeNote.SUBSTANCE_ABUSE?.anySubstanceUsed === BooleanOption.no) return intakeNote.SUBSTANCE_ABUSE;
  const data: {
    [k in SubstanceUse_SubstanceType]?: SubstanceUse_CurrentSubstanceUse;
  } = {};

  (intakeNote.SUBSTANCE_ABUSE?.substancesCurrentlyUsed ?? []).forEach(
    (substance) => {
      const substanceType = substance.substance?.substanceType;
      if (substanceType) data[substanceType] = substance;
    },
  );

  (aiNoteDraft.SUBSTANCE_ABUSE?.substancesCurrentlyUsed ?? []).forEach(
    ({ substance }) => {
      const substanceType = substance?.substanceType;
      if (!substanceType) return;
      const existing = data[substanceType];

      data[substanceType] = {
        substance: {
          ...(existing?.substance ?? substance),
          currentlyUsed:
            existing?.substance?.currentlyUsed ?? substance.currentlyUsed,
          description: mergeWithAiText(
            existing?.substance?.description ?? null,
            substance?.description ?? null,
          ),
          otherSubstanceTypeDescription: mergeWithAiText(
            existing?.substance?.otherSubstanceTypeDescription ?? null,
            substance?.otherSubstanceTypeDescription ?? null,
          ),
          pastUsed: existing?.substance?.pastUsed ?? substance.pastUsed,
        },
      };
    },
  );

  const substancesCurrentlyUsed = Object.values(data);
  return SubstanceUse.fromPartial({
    ...intakeNote.SUBSTANCE_ABUSE!,
    anySubstanceUsed: substancesCurrentlyUsed.length
      ? BooleanOption.yes
      : BooleanOption.undefined_choice,
    substancesCurrentlyUsed,
    version: intakeNote.SUBSTANCE_ABUSE?.version ?? SubstanceUse_Version.v0,
  });
}

function mergeWithAiText(existingText: string | null, aiText: string | null): string {
  const prefixedAiText = aiText ? `${AI_DRAFT_PREFIX}\n${aiText}` : '';
  if (!existingText) return prefixedAiText;
  if (existingText.includes(aiText ?? '')) return existingText;
  return `${existingText}\n\n${prefixedAiText}`.trim();
}
