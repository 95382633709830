import {
  TherapyProgressSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import {
  ClinicalNote,
  ClinicalNotesScreenV2,
  Section,
} from 'app/notes-ui/ClinicalNotesScreenV2';
import { validateTherapy as validateSafety } from 'app/notes-ui/shared/safety-intake/schema';
import { validateV2 as validateSubstanceUsed } from 'app/notes-ui/shared/substance-abuse/schema';
import { validate as validateAssessment } from 'app/notes-ui/therapy/progress/assessment/schema';
import {
  BehavioralObservationsFormv2,
  validate as validateBehavioralObservation,
} from 'app/notes-ui/therapy/progress/behavioral-observations/BehavioralObservationsFormv2';
import { validate as validateFocus } from 'app/notes-ui/therapy/progress/focus-area/schema';
import { SubstanceAbuseFormV2 } from 'app/notes-ui/therapy/progress/substance-abuse/SubstanceAbuseFormV2';
import {
  TreatmentPlanFormV2,
  validate as validateTreatmentPlan,
} from 'app/notes-ui/therapy/progress/treatment-plan/TreatmentPlanFormV2';
import { getTherapyProgressV2SectionLabel } from 'app/notes-ui/utils';
import { Status } from 'app/state/status/types/StateSlice';
import { TherapyProgressIds } from 'app/vault/api/TherapyProgressIds';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';
import React, { ElementType } from 'react';

import { NoteHeaderItems } from '../TherapyIntakeNoteContainerv2';
import { AssessmentFormV2 } from './assessment/AssessmentFormV2';
import {
  ClinicianChecklistFormV2,
  validate as validateClinicianChecklist,
} from './clinician-checklist/ClinicianChecklistFormV2';
import { FocusAreaFormv2 } from './focus-area/FocusAreav2';
import { SafetyFormV2 } from './safety/SafetyFormV2';

export type Props = {
  appointmentId: string;
  memberId?: string;
  note: TherapyProgressNote;
  onSubmit: (section: TherapyProgressSection) => Promise<void>;
  status: Status;
  readOnly: boolean;
  noteHeader: NoteHeaderItems;
};

function section<T extends TherapyProgressSection>(
  name: T['name'],
  form: ElementType<{
    appointmentId: string;
    noteLastUpdatedAt?: string;
    onSubmit: (section: T) => void;
    initialValue: T['data'];
  }>,
  validator: (data: T['data']) => boolean,
): Section<T> {
  return [name, getTherapyProgressV2SectionLabel(name), form, validator];
}

const sections = [
  section(
    TherapyProgressSectionName.CLINICIAN_CHECKLIST,
    ClinicianChecklistFormV2,
    validateClinicianChecklist,
  ),
  section(
    TherapyProgressSectionName.FOCUS_AREA,
    FocusAreaFormv2,
    validateFocus,
  ),
  section(
    TherapyProgressSectionName.SUBSTANCE_ABUSE,
    SubstanceAbuseFormV2,
    validateSubstanceUsed,
  ),
  section(TherapyProgressSectionName.SAFETY, SafetyFormV2, validateSafety),
  section(
    TherapyProgressSectionName.BEHAVIORAL_OBSERVATION,
    BehavioralObservationsFormv2,
    validateBehavioralObservation,
  ),
  section(
    TherapyProgressSectionName.ASSESSMENT,
    AssessmentFormV2,
    validateAssessment,
  ),
  section(
    TherapyProgressSectionName.TREATMENT_PLAN,
    TreatmentPlanFormV2,
    validateTreatmentPlan,
  ),
];

export function TherapyProgressNoteScreenV2(props: Props) {
  const {
    appointmentId,
    note,
    status,
    memberId,
    onSubmit,
    noteHeader,
    readOnly,
  } = props;
  return (
    <ClinicalNotesScreenV2
      appointmentId={appointmentId}
      status={status}
      onSubmit={onSubmit}
      memberId={memberId}
      sections={sections}
      ids={TherapyProgressIds}
      note={note as ClinicalNote<TherapyProgressSection>}
      readOnly={readOnly}
      noteHeader={noteHeader}
    />
  );
}
