import {
  Metadata,
  Metadata_NoteStatus,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  PsychiatryIntakeSection,
  PsychiatryProgressSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import {
  TherapyIntakeSection,
  TherapyIntakeSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import {
  TherapyProgressSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { getClinicalCareTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import { useScreen, VaultItemPermissions } from '@ginger.io/vault-ui';
import { AmendmentsFormV2 } from 'app/notes-ui/shared/amendments/AmendmentsFormV2';
import { AmendmentWithAuditLog } from 'app/notes-ui/shared/amendments/types';
import { TabPanel } from 'app/notes-ui/tabs/TabPanel';
import { Tab, TabsV2 } from 'app/notes-ui/tabs/TabsV2';
import { getTherapyIntakeSectionLabel } from 'app/notes-ui/utils';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import { useAppState } from 'app/state';
import { clinicalNoteInteractionCompleted } from 'app/state/amplitude/actions/notes';
import { useLogger } from 'app/state/log/useLogger';
import { Status } from 'app/state/status/types/StateSlice';
import { selectUserIdAndRole } from 'app/state/user/selectors';
import { VaultIds } from 'app/vault/api/VaultIds';
import { useDiagnosisCodes } from 'hooks/useDiagnosisCodes';
import { useInteractionTracking } from 'hooks/useInteractionTracking';
import { ElementType, useCallback, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'redux-reloaded';

import styles from './ClinicalNotesScreenV2.module.scss';
import { TabContext } from './contexts/TabContext';
import { NoteType } from './NoteActions';
import { TabStateV2 } from './tabs/TabV2';
import { NoteHeaderItems } from './therapy/TherapyIntakeNoteContainerv2';

export type ClinicalNoteSection =
  | TherapyIntakeSection
  | TherapyProgressSection
  | PsychiatryIntakeSection
  | PsychiatryProgressSection;

// We only one PsychiatrySectionName because both psych intake and progress notes have identical sections
export type ClinicalNoteSectionName =
  | TherapyIntakeSectionName
  | TherapyProgressSectionName
  | PsychiatrySectionName;

export type Section<T extends ClinicalNoteSection> = [
  T['name'],
  string,
  ElementType,
  (data: any) => boolean,
];

export type ClinicalNote<T extends ClinicalNoteSection> = {
  [key in ClinicalNoteSectionName]: Extract<T, { name: key }>['data'] | null;
} & {
  metadata: Metadata;
  permissions: VaultItemPermissions | null;
  amendments: AmendmentWithAuditLog[];
  createdAt: string | null;
  updatedAt: string | null;
  signingClinicianName: string | null;
};

export type Props<T extends ClinicalNoteSection> = {
  appointmentId: string;
  memberId?: string;
  status: Status;
  note: ClinicalNote<T>;
  onSubmit: (section: T) => Promise<void>;
  sections: Section<T>[];
  ids: typeof VaultIds;
  readOnly?: boolean;
  enabledAI?: boolean;
  aiIconState?: AiMagicIconState;
  noteHeader: NoteHeaderItems;
};

export function sectionNames<T extends ClinicalNoteSection>(
  sections: Section<T>[],
) {
  return sections.map(([key, label]) => {
    return { key, label };
  });
}

export const vaultItemId = (
  appointmentId: string,
  sectionName: ClinicalNoteSectionName,
  ids: typeof VaultIds,
) => {
  return ids.section(appointmentId, sectionName);
};

export function ClinicalNotesScreenV2<T extends ClinicalNoteSection>(
  props: Props<T>,
) {
  const {
    appointmentId,
    note,
    sections,
    readOnly = true,
    noteHeader,
    memberId,
    enabledAI,
    aiIconState,
  } = props;
  const isSignedAndLocked =
    note.metadata.status === Metadata_NoteStatus.signed_and_locked_note;
  const logger = useLogger();
  const [saving, setSaving] = useState(false);
  const [activeTab, setActiveTab] = useState<string | null>(null);

  useDiagnosisCodes();

  const screenNames: Array<ClinicalNoteSectionName> = sections.map(
    ([name]) => name,
  );

  if (isSignedAndLocked) {
    screenNames.push(TherapyIntakeSectionName.AMENDMENTS);
  }

  const { screen, goto } = useScreen(screenNames);

  // set active screen to the active tab
  // this is needed for AuditLogEntryViewV2 component -- which uses the active screen to load audit logs
  useEffect(() => {
    if (activeTab) {
      goto((sectionName) => sectionName === activeTab);
    }
  }, [activeTab]);
  const onSubmit = async (section: any) => {
    try {
      setSaving(true);
      await props.onSubmit(section);
    } catch (error) {
      logger.error(
        new Error('ClinicalNotesScreenV2: Unable to saving note section'),
        {
          appointmentId,
          error,
          memberId,
          section: Object.hasOwn(section, 'name') ? section.name : undefined,
          sections: sectionNames(sections),
        },
      );
    } finally {
      setSaving(false);
    }
  };

  const tabs: Tab[] = sections.map(([key, label, Form, validator]) => {
    let tabState: TabStateV2;
    if (isSignedAndLocked) {
      tabState = TabStateV2.COMPLETED;
    } else {
      tabState =
        note[key] !== null
          ? validator(note[key])
            ? TabStateV2.COMPLETED
            : TabStateV2.PARTIAL
          : TabStateV2.NOT_STARTED;
    }
    return {
      component: (
        <TabPanel
          title={label}
          key={key}
          testId={`${key.toLowerCase()}Panel`}
          hideDivider={true}
        >
          <FormSection
            disabled={isSignedAndLocked || readOnly}
            appointmentId={appointmentId}
            sectionName={key}
            initialValue={note[key]}
            form={Form}
            onSubmit={onSubmit}
            noteLastUpdatedAt={note.updatedAt}
            noteType={noteHeader.noteType}
            memberId={memberId}
            enabledAI={enabledAI}
            aiIconState={aiIconState}
          />
        </TabPanel>
      ),
      key,
      label,
      tabState,
    };
  });

  if (isSignedAndLocked) {
    const key = TherapyIntakeSectionName.AMENDMENTS;
    const label = getTherapyIntakeSectionLabel(key);
    const disabled = note[key] !== null || readOnly;
    tabs.push({
      component: (
        <TabPanel
          title={label}
          key={key}
          testId="amendmentsPanel"
          hideDivider={true}
        >
          <AmendmentsFormV2
            sectionName={TherapyIntakeSectionName.AMENDMENTS}
            savingNote={saving}
            appointmentId={appointmentId}
            onSubmit={onSubmit}
            previousValues={note.amendments}
            disabled={disabled}
          />
        </TabPanel>
      ),
      key,
      label,
      tabState:
        note[key] !== null || note.amendments.length > 0
          ? TabStateV2.COMPLETED
          : TabStateV2.NOT_STARTED,
    });
  }
  let vaultId;
  let vaultGroupId;

  if (screen !== TherapyIntakeSectionName.AMENDMENTS && memberId) {
    vaultGroupId = getClinicalCareTeamGroupId(memberId);
    vaultId = vaultItemId(appointmentId, screen, props.ids);
  }

  return (
    <TabContext.Provider
      value={{
        activeTab,
        sectionName: sectionNames<T>(sections).find((x) => x.key === activeTab)
          ?.label,
        setActiveTab,
      }}
    >
      <TabsV2
        tabs={tabs}
        noteHeader={noteHeader}
        vaultItemId={vaultId}
        vaultGroupId={vaultGroupId}
        className={styles.noteTab}
      />
    </TabContext.Provider>
  );
}

function FormSection<T extends ClinicalNoteSection>(props: {
  appointmentId: string;
  sectionName: T['name'];
  initialValue: T['data'] | null;
  disabled: boolean;
  form: ElementType;
  onSubmit: (section: ClinicalNoteSection) => void;
  noteLastUpdatedAt: string | null;
  noteType: NoteType;
  memberId: string | undefined;
  enabledAI?: boolean;
  aiIconState?: AiMagicIconState;
}) {
  const {
    form: Form,
    initialValue,
    sectionName,
    appointmentId,
    noteType,
    memberId,
    disabled,
    onSubmit,
    noteLastUpdatedAt,
    enabledAI,
    aiIconState,
  } = props;
  const formSectionRef = useRef<HTMLDivElement | null>(null);
  const dispatch = useDispatch();
  const { userId } = useAppState(selectUserIdAndRole);

  const onInteractionCompleted = useCallback(
    (durationInMilliseconds: number) => {
      dispatch(
        clinicalNoteInteractionCompleted({
          appointmentId,
          clinicianId: userId,
          duration: Math.round(durationInMilliseconds / 1000),
          memberId,
          noteType,
          section: sectionName,
        }),
      );
    },
    [appointmentId, dispatch, memberId, noteType, sectionName, userId],
  );

  useInteractionTracking({
    componentRef: formSectionRef,
    disabled,
    onInteractionCompleted,
  });

  return (
    <div ref={formSectionRef}>
      <Form
        appointmentId={appointmentId}
        onSubmit={onSubmit}
        initialValue={initialValue || undefined}
        disabled={disabled}
        noteLastUpdatedAt={noteLastUpdatedAt || undefined}
        enabledAI={enabledAI}
        aiIconState={aiIconState}
      />
    </div>
  );
}
