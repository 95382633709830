import { Field } from '@ginger.io/react-use-form/dist/types';
import { DateMessage as Date } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Date';
import { Frequency_Period } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/Frequency';
import { InputBaseComponentProps } from '@mui/material';
import { TextFieldProps } from '@mui/material/TextField';
import { AutocompleteIcon } from 'app/notes-ui/forms/form-controls/AutoCompleteWithTag';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import { DateTimeRangeInput } from 'app/notes-ui/forms/form-controls/DateTimeRangeInput';
import { EnumCheckboxGroup } from 'app/notes-ui/forms/form-controls/EnumCheckboxGroup';
import { EnumDropdown } from 'app/notes-ui/forms/form-controls/EnumDropdown';
import { FrequencyInput } from 'app/notes-ui/forms/form-controls/FrequencyInput';
import {
  MultipleSelectEnumDropdown,
  MultipleSelectEnumDropdownV2,
} from 'app/notes-ui/forms/form-controls/MultipleSelectEnumDropdown';
import {
  NoteFormControlLabel,
  NoteFormControlLabelStyles,
} from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { NumberInput } from 'app/notes-ui/forms/form-controls/NumberInput';
import {
  Option,
  RadioButtonGroup,
  RadioListOrientations,
} from 'app/notes-ui/forms/form-controls/RadioButtonGroup';
import { TextAreaWithLabel } from 'app/notes-ui/forms/form-controls/TextArea';
import { TextInput } from 'app/notes-ui/forms/form-controls/TextInput';
import { TimeRangeInput } from 'app/notes-ui/forms/form-controls/TimeRangeInput';
import { YesOrNoQuestion } from 'app/notes-ui/forms/form-controls/YesOrNoQuestion';
import { ProtobufEnum } from 'app/notes-ui/types';
import { AiMagicIconState } from 'app/sortable-table/note-header/AiMagicIcon';
import React, { ReactNode, useMemo } from 'react';
import { Dropdown, DropdownProps } from 'shared-components/Dropdown';
import { DatePicker } from 'shared-components/form-inputs/DatePicker';
import { InputProps } from 'shared-components/form-inputs/Input';
import { FormError } from 'shared-components/FormError';
import { Size, Width } from 'types/StyleTypes';
import { enumToOptions } from 'utils/notes';

import { getTimeError, getTimeValue, setTimeValue, TimeField } from './utils';

export type DateFieldValue = {
  day: Field<Date['day']>;
  month: Field<Date['month']>;
  year: Field<Date['year']>;
};

export function YesOrNoField(props: {
  label: string;
  labelSize?: 'XS' | 'M' | 'L';
  field: Field<boolean>;
  testId?: string;
  yesLabel?: string;
  noLabel?: string;
  disabled?: boolean;
  tooltip?: string;
  fullWidth?: boolean;
  gridClass?: string;
  buttonContainerClass?: string;
}) {
  const {
    label,
    field,
    testId,
    yesLabel,
    noLabel,
    fullWidth,
    labelSize,
    gridClass,
    buttonContainerClass,
  } = props;
  return (
    <YesOrNoQuestion
      gridClass={gridClass}
      buttonContainerClass={buttonContainerClass}
      disabled={props.disabled}
      name={testId || ''}
      label={label}
      labelSize={labelSize}
      value={field.value}
      error={field.error}
      onChange={field.setValue}
      tooltip={props.tooltip}
      yesLabel={yesLabel}
      noLabel={noLabel}
      fullWidth={fullWidth}
    />
  );
}

type TextAreaFieldProps = {
  autoExpand?: boolean;
  button?: ReactNode;
  className?: string;
  disabled?: boolean;
  field: Field<string>;
  formControlStyles?: NoteFormControlLabelStyles;
  label?: string;
  labelDescription?: string;
  optional?: boolean;
  placeholder?: string;
  /** number of rows to display (minimal number of rows if auto expandable) */
  rows?: number;
  showAiIcon?: boolean;
  aiIconState?: AiMagicIconState;
  subtext?: string;
  testId?: string;
  tooltip?: string;
  width?: Width;
};

export function TextAreaField(props: TextAreaFieldProps) {
  const {
    label,
    field,
    testId,
    labelDescription,
    tooltip,
    showAiIcon,
    aiIconState,
    button,
    width,
    disabled,
    autoExpand,
    subtext,
    placeholder,
    rows,
    formControlStyles,
    optional,
    className,
  } = props;
  return (
    <TextAreaWithLabel
      tooltip={tooltip}
      disabled={disabled}
      name={testId}
      label={label}
      value={field.value}
      error={field.error}
      onChange={field.setValue}
      placeholder={placeholder}
      className={className}
      rows={rows}
      width={width}
      subtext={subtext}
      formControlStyles={formControlStyles}
      labelDescription={labelDescription}
      autoExpand={autoExpand}
      optional={optional}
      button={button}
      showAiIcon={showAiIcon}
      aiIconState={aiIconState}
    />
  );
}

export function TextAreaFieldList(props: {
  field: Field<string[]>;
  minItems?: number;
  label?: string[];
  labelDescription?: string[];
  subtext?: string[] | ReactNode[];
  testId?: string;
  disabled?: boolean;
  rows?: number;
  autoExpand?: boolean;
  tooltip?: string;
  className?: string;
  placeholder?: string;
  width?: Width;
  formControlStyles?: NoteFormControlLabelStyles;
}) {
  const { field, minItems = 1 } = props;
  const values = [...field.value];

  if (values.length < minItems) {
    // append the missing items
    values.push(...Array(minItems - values.length).fill(''));
  }

  const textAreaList = values.map((fieldValue, i) => (
    <TextAreaWithLabel
      key={`${props.testId}_${i}`}
      name={`${props.testId}_${i}`}
      tooltip={props.tooltip}
      disabled={props.disabled}
      label={props.label ? props.label[i] : undefined}
      labelDescription={
        props.labelDescription ? props.labelDescription[i] : undefined
      }
      subtext={props.subtext ? props.subtext[i] : undefined}
      value={fieldValue}
      onChange={(value) => {
        values[i] = value;
        field.setValue(values);
      }}
      placeholder={props.placeholder}
      className={props.className}
      rows={props.rows}
      autoExpand={props.autoExpand}
      width={props.width}
      formControlStyles={props.formControlStyles}
    />
  ));

  return (
    <>
      {textAreaList}
      <FormError error={field.error} />
    </>
  );
}

export function TextBoxField(props: {
  label: string;
  field: Field<string>;
  placeholder?: string;
  testId?: string;
  variant?: InputProps['variant'];
  disabled?: boolean;
  className?: string;
  size?: TextFieldProps['size'];
}) {
  const { label, field, testId = '', variant = 'outlined' } = props;
  return (
    <TextInput
      name={testId}
      placeholder={props.placeholder ?? label}
      value={field.value}
      error={props.field.error}
      onChange={field.setValue}
      disabled={props.disabled}
      variant={variant}
      className={props.className}
      size={props.size}
    />
  );
}

export function EnumCheckboxesField<T extends number>(props: {
  label: string;
  field: Field<T[]>;
  options: ProtobufEnum;
  testId?: string;
  disabled?: boolean;
  tooltips?: { [K in T]?: string };
  labelSize?: Size;
  keyLabels?: Record<string, string>;
  keysToExclude?: string[];
  aiIconState?: AiMagicIconState;
  showAiIcon?: boolean;
}) {
  const {
    label,
    field,
    options,
    testId,
    keyLabels,
    keysToExclude,
    labelSize,
    disabled,
    tooltips,
    showAiIcon,
    aiIconState,
  } = props;
  return (
    <EnumCheckboxGroup
      tooltips={tooltips}
      disabled={disabled}
      name={testId}
      type={options}
      question={label}
      values={field.value}
      error={field.error}
      labelSize={labelSize}
      onChange={field.setValue}
      keyLabels={keyLabels}
      keysToExclude={keysToExclude}
      showAiIcon={showAiIcon}
      aiIconState={aiIconState}
    />
  );
}

export function EnumCheckboxField<T extends number | boolean>(props: {
  testId?: string;
  disabled?: boolean;
  label: string;
  field: Field<T>;
  checkedValue: T;
  uncheckedValue: T;
  tooltip?: string;
}) {
  const { label, field, testId } = props;
  return (
    <Checkbox
      tooltip={props.tooltip}
      disabled={props.disabled}
      testId={testId}
      checked={field.value === props.checkedValue}
      label={label}
      onChange={(checked) => {
        field.setValue(checked ? props.checkedValue : props.uncheckedValue);
      }}
    />
  );
}

export function EnumRadioField<T>(props: {
  label: string;
  subtext?: string;
  field: Field<T>;
  options: ProtobufEnum;
  testId?: string;
  disabled?: boolean;
  showSelectedOnly?: boolean;
  tooltip?: string;
  keyLabels?: Record<string, string>;
  formControlStyles?: NoteFormControlLabelStyles;
  keysToExclude?: string[];
  radioListOrientation?: RadioListOrientations;
  onChange?: (value: T) => void;
  formatSelectedValue?: (value: T) => string;
}) {
  const {
    label,
    subtext,
    field,
    options,
    testId,
    keyLabels,
    formControlStyles,
    showSelectedOnly,
    keysToExclude = [],
    radioListOrientation,
    formatSelectedValue,
    onChange = field.setValue,
  } = props;

  const enumOptions = useMemo(() => {
    return enumToOptions(options, keyLabels, keysToExclude).map(
      ({ name, value }) => ({
        label: name,
        value,
      }),
    );
  }, [options]);

  return (
    <RadioButtonGroup
      tooltip={props.tooltip}
      readOnly={props.disabled}
      showSelectedOnly={showSelectedOnly}
      label={label}
      subtext={subtext}
      name={testId || label}
      error={field.error}
      value={field.value}
      options={(enumOptions as unknown) as Option<T>[]}
      onChange={onChange}
      formatSelectedValue={formatSelectedValue}
      formControlStyles={formControlStyles}
      radioListOrientation={radioListOrientation}
    />
  );
}

export function EnumDropdownField<T>(props: {
  // testId and label will be used in the future and are kept here for API consistency
  testId: string;
  label?: string;
  options: ProtobufEnum;
  field: Field<T>;
  disabled?: boolean;
  className?: string;
  menuItemClass?: string;
  selectClass?: string;
  placeholder?: string;
  width?: Width;
  size?: Size;
  optionLabels?: Record<string, string>;
}) {
  return (
    <EnumDropdown
      data-testid={props.testId}
      disabled={props.disabled}
      className={props.className}
      menuItemClass={props.menuItemClass}
      selectClass={props.selectClass}
      placeholder={props.placeholder}
      initialValue={props.field.value}
      error={props.field.error}
      enum={props.options}
      onSelect={props.field.setValue}
      width={props.width}
      size={props.size}
      optionLabels={props.optionLabels}
    />
  );
}

export function EnumMultiSelectDropdownField<T>(props: {
  testId: string;
  label: string;
  options: ProtobufEnum;
  field: Field<T[]>;
  disabled?: boolean;
  width?: Width;
  placeholder?: string;
  className?: string;
  icon?: AutocompleteIcon;
}) {
  return (
    <MultipleSelectEnumDropdown
      disabled={props.disabled}
      value={props.field.value}
      name={props.testId}
      type={props.options}
      error={props.field.error}
      onChange={props.field.setValue}
      width={props.width}
      placeholder={props.placeholder}
      className={props.className}
      icon={props.icon}
    />
  );
}

export function EnumMultiSelectDropdownFieldV2<T>(props: {
  testId: string;
  label: string;
  options: ProtobufEnum;
  field: Field<T[]>;
  disabled?: boolean;
  width?: Width;
  placeholder?: string;
  className?: string;
  icon?: AutocompleteIcon;
}) {
  return (
    <MultipleSelectEnumDropdownV2
      disabled={props.disabled}
      value={props.field.value}
      name={props.testId}
      type={props.options}
      error={props.field.error}
      onChange={props.field.setValue}
      width={props.width}
      placeholder={props.placeholder}
      className={props.className}
      icon={props.icon}
    />
  );
}

export function FrequencyField(props: {
  testId?: string;
  periodField: Field<Frequency_Period>;
  timesPerPeriodField: Field<number>;
  disabled?: boolean;
}) {
  const { timesPerPeriodField, periodField } = props;
  return (
    <FrequencyInput
      disabled={props.disabled}
      value={{
        timesPerPeriod: timesPerPeriodField.value,
        period: periodField.value,
      }}
      name={props.testId}
      error={periodField.error || timesPerPeriodField.error}
      onChange={(value) => {
        periodField.setValue(value.period);
        timesPerPeriodField.setValue(value.timesPerPeriod);
      }}
    />
  );
}

export function NumberField(props: {
  testId: string;
  label?: string;
  field: Field<number>;
  disabled?: boolean;
  className?: string;
  inputClass?: string;
  placeholder?: string;
  tooltip?: string;
  inputProps?: InputBaseComponentProps;
}) {
  return (
    <NumberInput
      label={props.label}
      value={props.field.value || 0}
      name={props.testId}
      error={props.field.error}
      onChange={(value) => {
        if (isNaN(value)) {
          props.field.setValue(null as any);
        } else {
          props.field.setValue(value);
        }
      }}
      disabled={props.disabled}
      inputClass={props.inputClass}
      className={props.className}
      placeholder={props.placeholder}
      tooltip={props.tooltip}
      inputProps={props.inputProps}
    />
  );
}

export function DateField(props: {
  testId: string;
  name: string;
  label?: string;
  field: DateFieldValue;
  disabled?: boolean;
  className?: string;
  placeholder?: string;
}) {
  const { field, name } = props;
  return (
    <DatePicker
      data-testid={props.testId}
      className={props.className}
      placeholder={props.placeholder}
      disabled={props.disabled}
      name={name}
      value={getDateValue(field)}
      error={field.day.error || field.month.error || field.year.error}
      onChange={(value) => {
        const [year, month, day] = (value || '').split('-');
        field.day.setValue(parseInt(day));
        field.month.setValue(parseInt(month));
        field.year.setValue(parseInt(year));
      }}
    />
  );
}

export function TimeRangeField(props: {
  field: { startTime: TimeField; endTime: TimeField };
  disabled?: boolean;
  label: string;
  testId: string;
}) {
  const {
    field: { endTime, startTime },
  } = props;
  return (
    <TimeRangeInput
      disabled={props.disabled}
      testId={props.testId}
      label={props.label}
      startTime={getTimeValue({
        hour: startTime.hour.value,
        minute: startTime.minute.value,
        second: startTime.second.value,
      })}
      endTime={getTimeValue({
        hour: endTime.hour.value,
        minute: endTime.minute.value,
        second: endTime.second.value,
      })}
      error={getTimeError(startTime) || getTimeError(endTime)}
      onChange={(data) => {
        setTimeValue(data.startTime, startTime);
        setTimeValue(data.endTime, endTime);
      }}
    />
  );
}

export function CheckboxField(props: {
  disabled?: boolean;
  tooltip?: string;
  testId?: string;
  label: string;
  field: Field<boolean>;
  rootClassName?: string;
  checkBoxClassName?: string;
}) {
  return (
    <Checkbox
      key={props.label}
      tooltip={props.tooltip}
      label={props.label}
      checked={props.field.value}
      error={props.field.error}
      testId={props.testId}
      onChange={props.field.setValue}
      disabled={props.disabled}
      rootClassName={props.rootClassName}
      checkBoxClassName={props.checkBoxClassName}
    />
  );
}

export function CheckboxWithDescriptionField(props: {
  disabled?: boolean;
  showAiIcon?: boolean;
  aiIconState?: AiMagicIconState;
  tooltip?: string;
  testId?: string;
  title?: string;
  description?: string;
  label: string;
  field: Field<boolean>;
  styles?: NoteFormControlLabelStyles;
}) {
  const {
    label,
    description,
    tooltip,
    title,
    disabled,
    field,
    testId,
    styles,
    showAiIcon,
    aiIconState,
  } = props;
  const Label = title
    ? NoteFormControlLabel
    : (p: { children: ReactNode }) => (
        <div>
          {description && <p className={styles?.subtext}>{description}</p>}
          {p.children}
        </div>
      );
  return (
    <>
      <Label
        label={title}
        tooltip={tooltip}
        subtext={description}
        showAiIcon={showAiIcon}
        aiIconState={aiIconState}
      >
        <Checkbox
          key={label}
          label={label}
          checked={field.value}
          error={field.error}
          testId={testId}
          onChange={field.setValue}
          checkBoxClassName={styles?.checkbox}
          disabled={disabled}
        />
      </Label>
    </>
  );
}

function getDateValue(date: DateFieldValue) {
  const hasEmptyComponent = Object.values(date).some(
    ({ value }) => value === undefined || value === 0,
  );

  if (hasEmptyComponent) {
    return null;
  }

  const { day, month, year } = date;
  const padWithZero = (value: number) => `${value}`.padStart(2, '0');
  return `${padWithZero(year.value)}-${padWithZero(month.value)}-${padWithZero(
    day.value,
  )}`;
}

export type DropDownFieldProps<T> = {
  field: Field<T>;
  dropdownProps: Omit<DropdownProps<T>, 'onSelect'>;
  onSelect?: (selected: T) => void;
  onOpen?: () => void;
};

export function DropDownField<T extends string | number>({
  field,
  dropdownProps,
  onSelect,
  onOpen,
}: DropDownFieldProps<T>) {
  const onSelectField = (selected: T) => {
    if (onSelect) onSelect(selected);
    field.setValue(selected);
  };

  return (
    <Dropdown
      onSelect={onSelectField}
      initialValue={field.value}
      error={field.error}
      {...dropdownProps}
      onOpen={onOpen}
    />
  );
}

type DateTimeRangeFieldProps = {
  field: { start: Field<string>; end: Field<string> };
  disabled?: boolean;
  label: string;
  children?: ReactNode;
  autoEndTime?: number;
};

export function DateTimeRangeField(props: DateTimeRangeFieldProps) {
  const {
    field: { end, start },
    disabled,
    label,
    children,
    autoEndTime,
  } = props;
  return (
    <DateTimeRangeInput
      disabled={disabled}
      label={label}
      start={start.value}
      end={end.value}
      error={start.error || end.error}
      onChange={(data) => {
        start.setValue(data.start);
        end.setValue(data.end);
      }}
      autoEndTime={autoEndTime}
    >
      {children}
    </DateTimeRangeInput>
  );
}
