import { Field } from '@ginger.io/react-use-form/src/types';
import { Assessment as PsychiatryIntakeAssessment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/intake/Assessment';
import { Assessment as PsychiatryProgressAssessment } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/progress/Assessment';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  Assessment as TherapyIntakeAssessment,
  Assessment as TherapyProgressAssessment,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/Assessment';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { PsychiatrySectionName } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { TherapyIntakeSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyIntakeSection';
import { TherapyProgressSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { VaultItemPermissions } from '@ginger.io/vault-ui';
import { UserRole } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetAppointmentById_getAppointmentById as Appointment } from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import { formatDuration, intervalToDuration } from 'date-fns';
import { FeatureFlags, TransientFeatureFlag } from 'hooks/useFeatureFlags';
import { isEmpty } from 'lodash';
import moment from 'moment-timezone';
import { isClinicalSupervisor, isClinician } from 'utils';

import { areCodeSetsValid } from './shared/code-set/CodeSetFields';
import { emptyCodeSet } from './shared/code-set/constants';

export const TYPING_THRESHOLD = 600;
export const CHECKBOX_THRESHOLD = 1200;
export const AUTO_SAVE_DRAFT_DELAY =
  process.env.NODE_ENV !== 'test' ? TYPING_THRESHOLD : 0;
export const NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD = 3000;

export const therapyIntakeSectionToLabel: Record<
  TherapyIntakeSectionName,
  string
> = {
  [TherapyIntakeSectionName.CLINICIAN_CHECKLIST]: 'Clinician Checklist',
  [TherapyIntakeSectionName.PRESENTING_PROBLEM]: 'Presenting Problem',
  [TherapyIntakeSectionName.CURRENT_FUNCTIONING]: 'Current Functioning',
  [TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL]: 'Social Developmental',
  [TherapyIntakeSectionName.MEDICAL_HEALTH]: 'Medical Health',
  [TherapyIntakeSectionName.SAFETY]: 'Safety',
  [TherapyIntakeSectionName.SUBSTANCE_ABUSE]: 'Substance Abuse',
  [TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION]: 'Behavioral Observation',
  [TherapyIntakeSectionName.ASSESSMENT]: 'Assessment',
  [TherapyIntakeSectionName.TREATMENT_PLAN]: 'Treatment Plan',
  [TherapyIntakeSectionName.COLLABORATION_PLAN]: 'Collaboration/Goals',
  [TherapyIntakeSectionName.AMENDMENTS]: 'Amendments',
};

export const therapyIntakeV2SectionToLabel: Record<
  TherapyIntakeSectionName,
  string
> = {
  [TherapyIntakeSectionName.CLINICIAN_CHECKLIST]: 'Legal Requirements',
  [TherapyIntakeSectionName.PRESENTING_PROBLEM]: 'Presenting Problem',
  [TherapyIntakeSectionName.CURRENT_FUNCTIONING]: 'Current Functioning',
  [TherapyIntakeSectionName.SOCIAL_DEVELOPMENTAL]: 'Social Developmental',
  [TherapyIntakeSectionName.MEDICAL_HEALTH]: 'Medical Health',
  [TherapyIntakeSectionName.SAFETY]: 'Safety',
  [TherapyIntakeSectionName.SUBSTANCE_ABUSE]: 'Substance Use',
  [TherapyIntakeSectionName.BEHAVIORAL_OBSERVATION]: 'Behavioral Observation',
  [TherapyIntakeSectionName.ASSESSMENT]: 'Diagnostics & Coding',
  [TherapyIntakeSectionName.TREATMENT_PLAN]: 'Treatment Plan',
  [TherapyIntakeSectionName.COLLABORATION_PLAN]: 'Collaboration/Goals',
  [TherapyIntakeSectionName.AMENDMENTS]: 'Amendments',
};

export function getTherapyIntakeSectionLabel(
  sectionName: TherapyIntakeSectionName,
): string {
  return therapyIntakeSectionToLabel[sectionName];
}

export function getTherapyIntakeV2SectionLabel(
  sectionName: TherapyIntakeSectionName,
): string {
  return therapyIntakeV2SectionToLabel[sectionName];
}

export const therapyProgressSectionToLabel: Record<
  TherapyProgressSectionName,
  string
> = {
  [TherapyProgressSectionName.CLINICIAN_CHECKLIST]: 'Clinician Checklist',
  [TherapyProgressSectionName.FOCUS_AREA]: 'Focus Area',
  [TherapyProgressSectionName.SAFETY]: 'Safety',
  [TherapyProgressSectionName.SUBSTANCE_ABUSE]: 'Substance Abuse',
  [TherapyProgressSectionName.BEHAVIORAL_OBSERVATION]: 'Behavioral Observation',
  [TherapyProgressSectionName.ASSESSMENT]: 'Assessment',
  [TherapyProgressSectionName.TREATMENT_PLAN]: 'Treatment Plan',
  [TherapyProgressSectionName.COLLABORATION_PLAN]: 'Collaboration/Goals',
  [TherapyProgressSectionName.AMENDMENTS]: 'Amendments',
};

export const therapyProgressV2SectionToLabel: Record<
  TherapyProgressSectionName,
  string
> = {
  [TherapyProgressSectionName.CLINICIAN_CHECKLIST]: 'Legal Requirements',
  [TherapyProgressSectionName.FOCUS_AREA]: 'Focus Area',
  [TherapyProgressSectionName.SAFETY]: 'Safety',
  [TherapyProgressSectionName.SUBSTANCE_ABUSE]: 'Substance Use',
  [TherapyProgressSectionName.BEHAVIORAL_OBSERVATION]: 'Behavioral Observation',
  [TherapyProgressSectionName.ASSESSMENT]: 'Diagnostics & Coding',
  [TherapyProgressSectionName.TREATMENT_PLAN]: 'Treatment Plan',
  [TherapyProgressSectionName.COLLABORATION_PLAN]: 'Collaboration/Goals',
  [TherapyProgressSectionName.AMENDMENTS]: 'Amendments',
};

export function getTherapyProgressSectionLabel(
  sectionName: TherapyProgressSectionName,
): string {
  return therapyProgressSectionToLabel[sectionName];
}

export function getTherapyProgressV2SectionLabel(
  sectionName: TherapyProgressSectionName,
): string {
  return therapyProgressV2SectionToLabel[sectionName];
}

export const psychiatryIntakeSectionToLabel: Record<
  PsychiatrySectionName,
  string
> = {
  [PsychiatrySectionName.CLINICIAN_CHECKLIST]: 'Clinician Checklist',
  [PsychiatrySectionName.CHIEF_COMPLAINT]: 'Chief Complaint/HPI',
  [PsychiatrySectionName.PSYCH_ROS]: 'Psych ROS',
  [PsychiatrySectionName.MEDICAL_ROS]: 'Medical ROS',
  [PsychiatrySectionName.SAFETY]: 'Safety',
  [PsychiatrySectionName.PSYCH_HISTORY]: 'Psychiatric History',
  [PsychiatrySectionName.ALLERGIES]: 'Allergies',
  [PsychiatrySectionName.MEDICAL_HISTORY]: 'Medical History',
  [PsychiatrySectionName.FAMILY_HISTORY]: 'Family History',
  [PsychiatrySectionName.SOCIAL_HISTORY]: 'Social History',
  [PsychiatrySectionName.SUBSTANCE_ABUSE]: 'Substance Abuse',
  [PsychiatrySectionName.MENTAL_STATUS_EXAM]: 'Mental Status Exam',
  [PsychiatrySectionName.ASSESSMENT]: 'Assessment',
  [PsychiatrySectionName.TREATMENT_PLAN]: 'Treatment Plan',
  [PsychiatrySectionName.COLLABORATION_PLAN]: 'Collaboration/Goals',
  [PsychiatrySectionName.AMENDMENTS]: 'Amendments',
};

export const psychiatryIntakeV2SectionToLabel: Record<
  PsychiatrySectionName,
  string
> = {
  [PsychiatrySectionName.CLINICIAN_CHECKLIST]: 'Legal Requirements',
  [PsychiatrySectionName.CHIEF_COMPLAINT]: 'Chief Complaint / HPI',
  [PsychiatrySectionName.PSYCH_ROS]: 'Psych ROS',
  [PsychiatrySectionName.MEDICAL_ROS]: 'Medical ROS',
  [PsychiatrySectionName.SAFETY]: 'Safety',
  [PsychiatrySectionName.PSYCH_HISTORY]: 'Psych History',
  [PsychiatrySectionName.ALLERGIES]: 'Allergies',
  [PsychiatrySectionName.MEDICAL_HISTORY]: 'Medical History',
  [PsychiatrySectionName.FAMILY_HISTORY]: 'Family History',
  [PsychiatrySectionName.SOCIAL_HISTORY]: 'Social / Family History',
  [PsychiatrySectionName.SUBSTANCE_ABUSE]: 'Substance Use',
  [PsychiatrySectionName.MENTAL_STATUS_EXAM]: 'Mental Status Exam',
  [PsychiatrySectionName.ASSESSMENT]: 'Diagnostics & Coding',
  [PsychiatrySectionName.TREATMENT_PLAN]: 'Treatment Plan',
  [PsychiatrySectionName.COLLABORATION_PLAN]: 'Collaboration / Goals',
  [PsychiatrySectionName.AMENDMENTS]: 'Amendments',
};

export function getPsychiatrySectionLabel(
  sectionName: PsychiatrySectionName,
): string {
  return psychiatryIntakeSectionToLabel[sectionName];
}

export function getPsychiatryV2SectionLabel(
  sectionName: PsychiatrySectionName,
): string {
  return psychiatryIntakeV2SectionToLabel[sectionName];
}

export function canLockNote(
  featureFlags: FeatureFlags,
  appointment: Appointment | undefined,
  user: { role: UserRole | null; userId: string | null },
  permissions: VaultItemPermissions | null,
) {
  const allowedGroupWriteAccess =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_SUPERVISOR_SIGN_AND_LOCK_NOTES_FOR_USER
    ];
  const isOwner = appointment?.clinician.userId === user.userId;
  return (
    (isOwner && isClinician(user.role) && !allowedGroupWriteAccess) ||
    (isOwner && isClinicalSupervisor(user.role)) ||
    (isClinicalSupervisor(user.role) &&
      allowedGroupWriteAccess &&
      permissions === VaultItemPermissions.WritableByAll)
  );
}

export function canCreateOrUpdateNote(
  featureFlags: FeatureFlags,
  appointment: Appointment | undefined,
  user: { role: UserRole | null; userId: string | null },
  noteStatus: Metadata_NoteStatus,
) {
  const allowedGroupWriteAccess =
    featureFlags.transientFeatureFlags[
      TransientFeatureFlag.ENABLE_SUPERVISOR_SIGN_AND_LOCK_NOTES_FOR_USER
    ];
  const isOwner = appointment?.clinician.userId === user.userId;
  return (
    isOwner ||
    (isClinicalSupervisor(user.role) &&
      allowedGroupWriteAccess &&
      noteStatus !== Metadata_NoteStatus.undefined_note_status)
  );
}

export function formatStatus(status: Metadata_NoteStatus): string {
  switch (status) {
    case Metadata_NoteStatus.draft_note: {
      return 'Draft';
    }

    case Metadata_NoteStatus.undefined_note_status: {
      return 'Draft';
    }

    case Metadata_NoteStatus.signed_and_locked_note: {
      return 'Signed & Locked';
    }

    default: {
      throw new Error(`Note status: ${status} isn't valid`);
    }
  }
}

export function showComplianceFields(noteLastUpdatedAt?: string) {
  // We need to display these fields only from the release date of
  // additional notes fields on Treatment plan section
  const releaseDate = moment.utc('2022-06-29', 'YYYY-MM-DD');
  return moment(noteLastUpdatedAt || undefined).isAfter(releaseDate);
}

export function getDefaultEnumValue(field: Field<BooleanOption>) {
  if (field.value) return field.value;
  field.setValue(BooleanOption.not_applicable);
  return BooleanOption.not_applicable;
}

/**
 * Validate that value is not empty or undefined if a state key value is equal to the given value
 * @param value the value to check against
 * @param state the state object
 * @param key the key to check
 * @param equalTo the comparison value to check against state key value
 * @param errorMessage the error message to display
 */
export const requiredFieldIf = <T>(
  value: string,
  state: T,
  key: keyof T,
  equalTo: any,
  errorMessage = 'This field is required',
) => {
  return isEmpty(value) && state[key] === equalTo ? errorMessage : undefined;
};

/**
 * Creates a new codeset array from the given assessment.
 * This is used to create a backwards compatible codeset array.
 *
 * @context
 * In the previous version of the app, we only had one cptCode and a list of codeSets.
 * For the new version, we have an array of objects with a cptCode and icd10Codes.
 *
 * @param assessment
 * @returns
 */
export const codeSetsFromAssessment = (
  assessment:
    | TherapyIntakeAssessment
    | TherapyProgressAssessment
    | PsychiatryIntakeAssessment
    | PsychiatryProgressAssessment
    | undefined,
): Array<{ cptCode: number; icd10Codes: number[] }> => {
  if (assessment !== null && assessment !== undefined) {
    const { codeSets } = assessment;

    // checks if current assessment may be used to create a new codeset array
    const codeSetsValid = areCodeSetsValid(codeSets);

    // If assessment contains a valid codeset
    if (codeSetsValid === true) return codeSets;

    // If assessment contains a codeset with diagnosis codes (copy forward only icd10codes)
    if (codeSets?.length > 0 && codeSets[0]?.icd10Codes?.length > 0)
      return codeSets;
  }

  // If the given code sets are invalid
  // then create a new array using the deprecated cpt + diagnoses codes
  // if they have value, if not default to empty
  return [
    {
      cptCode: assessment?.cptCode || emptyCodeSet.cptCode,
      icd10Codes: assessment?.icd10Codes || emptyCodeSet.icd10Codes,
    },
  ];
};

export const secondsToReadableString = (seconds: number): string => {
  if (seconds < 60) return '0 minutes';
  const duration = intervalToDuration({ end: seconds * 1000, start: 0 });
  return formatDuration(duration, {
    delimiter: ', ',
    format: ['hours', 'minutes'],
  });
};
