import { EventType } from 'app/events/EventType';
import { createAction } from 'redux-reloaded';

export const viewAppointmentScreenAction = createAction(
  EventType.APPOINTMENT_TAB_VIEWED,
);
export const viewAppointmentsTableAction = createAction(
  EventType.PATIENT_APPOINTMENTS_TABLE_VIEWED,
);
export const viewPatientAppointmentTabAction = createAction(
  EventType.PATIENT_APPOINTMENT_TAB_VIEWED,
);
export const viewScheduleCalendarScreenAction = createAction(
  EventType.SCHEDULE_CALENDAR_VIEWED,
);
export const calendarFilterClick = createAction(
  EventType.SCHEDULE_CALENDAR_FILTER_CLICK,
);
export const viewScheduleAppointmentListScreenAction = createAction(
  EventType.SCHEDULE_APPOINTMENT_LIST_VIEWED,
);
export const clickScheduleCalendarNewAppointmentAction = createAction(
  EventType.SCHEDULE_CALENDAR_NEW_APPT_CLICKED,
);
export const clickScheduleCalendarNewEventAction = createAction(
  EventType.SCHEDULE_CALENDAR_NEW_EVENT_CLICKED,
);
export const appointmentRecurringClick = createAction(
  EventType.SCHEDULE_APPOINTMENT_RECURRING_CLICK,
);
export const appointmentRecurringDoesNotRepeatClick = createAction(
  EventType.SCHEDULE_APPOINTMENT_RECURRING_DOESNOTREPEAT_CLICK,
);
export const appointmentRecurringWeeklyClick = createAction(
  EventType.SCHEDULE_APPOINTMENT_RECURRING_WEEKLY_CLICK,
);
export const appointmentRecurringBiweeklyClick = createAction(
  EventType.SCHEDULE_APPOINTMENT_RECURRING_BIWEEKLY_CLICK,
);
export const appointmentRecurringMonthlyClick = createAction(
  EventType.SCHEDULE_APPOINTMENT_RECURRING_MONTHLY_CLICK,
);
export const clickAppointmentListAppointment = createAction(
  EventType.SCHEDULE_APPOINTMENT_LIST_APPT_CLICKED,
  (payload: { appointmentId: string }) => ({ ...payload }),
);
export const appointmentListMSViewed = createAction(
  EventType.SCHEDULE_APPOINTMENT_LIST_MS_VIEWED,
  (payload: { patientId: string }) => ({ ...payload }),
);
export const clickScheduleNewAppointmentSaveAction = createAction(
  EventType.SCHEDULE_NEW_APPOINTMENT_SAVE_CLICKED,
  (payload: { appointmentIds: string[] }) => ({ ...payload }),
);
export const clickScheduleAppointmentListNewAppointmentAction = createAction(
  EventType.SCHEDULE_APPOINTMENT_LIST_NEW_APPT_CLICKED,
);
export const clickScheduleAppointmentListMemberFilterAction = createAction(
  EventType.SCHEDULE_APPOINTMENT_LIST_MEMBER_FILTER_CLICKED,
);
export const createAppointmentAction = createAction(
  EventType.APPOINTMENT_CREATE_VIEWED,
  (payload: { clinicianId?: string; patientId?: string }) => ({ ...payload }),
);
export const viewAppointmentAction = createAction(
  EventType.APPOINTMENT_VIEWED,
  (payload: { appointmentId: string }) => ({ ...payload }),
);
export const updateAppointmentAction = createAction(
  EventType.APPOINTMENT_UPDATED,
  (payload: { appointmentId: string }) => ({ ...payload }),
);
export const doubleClickedEvent = createAction(
  EventType.SCHEDULE_EVENT_DOUBLE_CLICKED,
  (payload: { eventId: string }) => ({
    payload,
  }),
);
export const doubleClickedAppointment = createAction(
  EventType.SCHEDULE_APPOINTMENT_DOUBLE_CLICKED,
  (payload: { appointmentId: string }) => ({
    payload,
  }),
);
export const calendarFilterAppointmentsClick = createAction(
  EventType.SCHEDULE_CALENDAR_FILTER_APPOINTMENTS_CLICK,
  (payload: { exclude: boolean }) => ({
    payload,
  }),
);
export const calendarFilterAvailabilityClick = createAction(
  EventType.SCHEDULE_CALENDAR_FILTER_AVAILABILITY_CLICK,
  (payload: { exclude: boolean }) => ({
    payload,
  }),
);
export const calendarFilterGoogleEventClick = createAction(
  EventType.SCHEDULE_CALENDAR_FILTER_GOOGLE_EVENTS_CLICK,
  (payload: { exclude: boolean }) => ({
    payload,
  }),
);
export const calendarFilterEventsClick = createAction(
  EventType.SCHEDULE_CALENDAR_FILTER_EVENTS_CLICK,
  (payload: { exclude: boolean }) => ({
    payload,
  }),
);
