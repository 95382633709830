import { InboxSectionHeaderColor } from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';

import { InboxSectionItem } from '../InboxSectionItem';
import { InboxSectionIcon, InboxSectionWrapper } from '../InboxSectionWrapper';
import { MainContentText } from '../SectionContentItems';

const dummyResponse = [
  {
    additionalContent: 'Additional Content',
    name: 'Test',
    time: '10mo',
    total: '300',
  },

  {
    additionalContent: '',
    name: 'Test 2',
    time: '1mo',
    total: '9',
  },

  {
    additionalContent: 'Additional Content',
    name: 'Test 3',
    time: '1w',
    total: '0',
  },
];

export const CompletedSection = () => {
  return (
    <InboxSectionWrapper
      headerColor={InboxSectionHeaderColor.LIGHT_MINT}
      sectionTitle="Completed"
      section={InboxSections.COMPLETED}
      sectionIcon={InboxSectionIcon.CHECKMARK}
      sectionItemCount={dummyResponse.length}
    >
      {dummyResponse.map(({ name, time }) => (
        <InboxSectionItem
          key={`${name}-${time}`}
          topLeft={<MainContentText label={name} />}
          onClick={() => console.log('clicked')}
        />
      ))}
    </InboxSectionWrapper>
  );
};
