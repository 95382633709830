import { SerializedError } from '@reduxjs/toolkit';
import {
  State as ConversationsState,
  ConversationsInboxItem,
} from 'app/inboxV2/state/conversations/schema';
import { FetchConversationsAction } from 'app/inboxV2/state/conversations/slice';

export const onFetchConversationsFulfilled = (
  state: ConversationsState<ConversationsInboxItem>,
  action: FetchConversationsAction,
) => {
  state.items = [...state.items, ...action.payload.items];
  state.metadata = action.payload.metadata;
  state.loading = false;
  state.error = null;
};

export const onFetchConversationsPending = (
  state: ConversationsState<ConversationsInboxItem>,
) => {
  state.loading = true;
  state.error = null;
};

export const onFetchConversationsRejected = (
  state: ConversationsState<ConversationsInboxItem>,
  action: { error: SerializedError },
) => {
  state.loading = false;
  state.error = action.error;
};
