import { Typography } from '@mui/material';
import { AppointmentsAndNotesCursor } from 'app/appointments/AppointmentsAndNotesAPIContext';
import {
  CoachNoteSessionType,
  Details,
} from 'app/coach/coach-notes/CoachNotesTypes';
import { TagAndRole } from 'app/sortable-table/note-card/TagAndRole';
import { ReactComponent as CaretLeftArrow } from 'assets/arrows/caretLeft.svg';
import { ReactComponent as CaretLRightArrow } from 'assets/arrows/caretRight.svg';
import { ReactComponent as LeftArrow } from 'assets/arrows/left.svg';
import React from 'react';
import { BulletSeparator } from 'shared-components/BulletSeparator';
import { classNameCombiner } from 'utils';

import styles from './NoteDetailHeader.module.scss';

export interface NavigationType {
  previous: AppointmentsAndNotesCursor | null;
  next: AppointmentsAndNotesCursor | null;
}

type Props = {
  navigation?: NavigationType;
  className?: string;
  dateString?: ISODateString;
  startDate?: ISODateString;
  isCoachNote?: boolean;
  showClinicalView?: boolean;
  careTeamMember?: string | null;
  details?: Details;
  backToListHandler?: () => void;
  navigateToCursor?: (cursor: AppointmentsAndNotesCursor) => void;
  actionButton?: React.ReactNode;
};
export const NoteDetailHeader = (props: Readonly<Props>) => {
  const {
    navigation,
    className = styles.header,
    dateString,
    startDate,
    isCoachNote,
    careTeamMember,
    details,
    showClinicalView,
    backToListHandler,
    navigateToCursor,
    actionButton,
  } = props;
  const { role, session, titleAndSessionNumber, risks } = details || {};
  const isOutreachAttempt = session === CoachNoteSessionType.OUTREACH_ATTEMPT;
  const isBaseRiskAssessment = session === CoachNoteSessionType.RISK;
  const hasRiskAssessmentStyles = isOutreachAttempt || isBaseRiskAssessment;

  return (
    <section className={className}>
      <div className={styles.container}>
        <div
          className={classNameCombiner([
            styles.navigation_back,
            !showClinicalView ? styles.coachNavContainer : '',
          ])}
        >
          {backToListHandler && (
            <button
              data-testid="backToAllButton"
              type="button"
              className={styles.navigation__button}
              onClick={() => backToListHandler?.()}
            >
              <LeftArrow
                width={16}
                height={16}
                className={styles.button_icon}
              />
              Back to all
            </button>
          )}
          <div
            className={classNameCombiner([
              styles.dateContainer,
              !showClinicalView ? styles.coachDateContainer : '',
              navigation?.next ? styles.withMargin : '',
            ])}
          >
            {startDate && (
              <BulletSeparator
                className={classNameCombiner([
                  styles.date,
                  !showClinicalView ? styles.noMargin : '',
                ])}
              >
                {startDate}
              </BulletSeparator>
            )}
            {dateString && (
              <div
                className={classNameCombiner([
                  styles.date,
                  !showClinicalView ? styles.noMargin : '',
                ])}
              >
                {dateString}
              </div>
            )}
            {showClinicalView && !isCoachNote && (
              <div className={styles.details_container}>
                <TagAndRole
                  className={styles.tag}
                  role={role}
                  careTeamMember={careTeamMember}
                />
              </div>
            )}
          </div>
        </div>
        <div className={styles.navigation_container}>
          <div className={styles.nativation_button_container}>
            {navigation?.previous && navigateToCursor && (
              <button
                data-testid="prevButton"
                type="button"
                onClick={() => navigateToCursor(navigation.previous)}
                className={styles.button_circular}
              >
                <CaretLeftArrow width={16} height={16} />
              </button>
            )}
          </div>
          {session && (
            <div className={styles.navigation_framebox}>
              <div
                className={classNameCombiner([
                  styles.description,
                  hasRiskAssessmentStyles ? styles.riskAssessment : '',
                ])}
              >
                {hasRiskAssessmentStyles && (
                  <BulletSeparator>Risk</BulletSeparator>
                )}
                {session !== CoachNoteSessionType.RISK && (
                  <BulletSeparator
                    className={classNameCombiner([
                      isOutreachAttempt ? styles.subTitle : '',
                    ])}
                  >
                    {session}
                  </BulletSeparator>
                )}
                {titleAndSessionNumber && (
                  <BulletSeparator className={styles.subTitle}>
                    {titleAndSessionNumber}
                  </BulletSeparator>
                )}
              </div>
              {risks && isOutreachAttempt && (
                <Typography
                  data-testid="risks"
                  component="div"
                  className={styles.riskAssessment}
                >
                  <BulletSeparator>Risk</BulletSeparator>
                  <BulletSeparator className={styles.subTitle}>
                    {risks}
                  </BulletSeparator>
                </Typography>
              )}
            </div>
          )}
          <div className={styles.nativation_button_container}>
            {navigation?.next && navigateToCursor && (
              <button
                data-testid="nextButton"
                type="button"
                onClick={() => navigateToCursor(navigation.next)}
                className={styles.button_circular}
              >
                <CaretLRightArrow width={16} height={16} />
              </button>
            )}
          </div>
        </div>
        <div
          className={classNameCombiner([
            styles.details_container,
            isCoachNote ? styles.vertical : '',
          ])}
        >
          <TagAndRole
            className={styles.tag}
            role={role}
            careTeamMember={careTeamMember}
          />

          {actionButton}
        </div>
      </div>
    </section>
  );
};
