import {
  CoachShiftStatusEnum,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { EventType } from 'app/events/EventType';
import { createAction } from 'redux-reloaded';

/**
 * This event is dispatched when the user is idle in the browser tab for a certain amount of time.
 * We are logging this event as an experiment to see if a provider has multiple tabs open and if that
 * could be impacting the care hub version they use.
 */
export const logCareHubProviderIdleBrowserTabTimeout = createAction(
  EventType.CARE_HUB_PROVIDER_IDLE_BROWSER_TAB_TIMEOUT,
  (payload: {
    careHubVersion: string;
    lastActiveTime: Date;
    role: UserRole | null;
    shiftStatus: CoachShiftStatusEnum | null;
  }) => ({ ...payload }),
);
