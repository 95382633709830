import { CoachShiftStatusEnum } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { EventType } from 'app/events/EventType';
import { DropInAlertType } from 'app/state/features/structuredCoaching/schema';
import { createAction } from 'redux-reloaded';

export const coachShiftStatusChanged = createAction(
  EventType.COACH_SHIFT_STATUS_CHANGED,
  (payload: {
    listenerId: string | null;
    updatedAt: ISODateString;
    shiftStatus?: CoachShiftStatusEnum;
  }) => ({
    ...payload,
  }),
);

export const enableDropInNotificationDisplayed = createAction(
  EventType.ENABLE_DROP_IN_NOTIFICATION_DISPLAYED,
  (payload: {
    listenerId: string | null;
    displayedAt?: ISODateString;
    motive: DropInAlertType;
    accepted: boolean;
  }) => ({
    ...payload,
  }),
);

export const disableDropInNotificationDisplayed = createAction(
  EventType.DISABLE_DROP_IN_NOTIFICATION_DISPLAYED,
  (payload: {
    listenerId: string | null;
    displayedAt?: ISODateString;
    motive: DropInAlertType;
    accepted: boolean;
  }) => ({
    ...payload,
  }),
);
