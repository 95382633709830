import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  PsychiatryProgressSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { NoteType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetAppointmentById_getAppointmentById as Appointment } from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import { useLogger } from 'app/state/log/useLogger';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { PsychiatryProgressNote } from 'app/vault/api/PsychiatryProgressNotesAPI';
import { buildPsychiatryProgressNote } from 'app/vault/data/buildPsychiatryProgressNote';
import { decodePsychiatryProgressNote } from 'app/vault/data/decodePsychiatryProgressNote';
import {
  useClinicalAppointmentsAPI,
  usePsychiatryIntakeNotesAPI,
  usePsychiatryProgressNotesAPI,
} from 'app/vault/hooks/useClinicalNotesAPI';
import { IdAndNoteType } from 'app/vault/hooks/useTherapyProgressNote';
import { useDeprecatedDiagnosisCodes } from 'hooks/useDiagnosisCodes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useCallback } from 'react';

export function useInitializePsychiatryProgressNote(
  clinicianUserId: string,
  appointmentId: string,
) {
  const logger = useLogger();
  const { transientFeatureFlags } = useFeatureFlags();
  const api = usePsychiatryProgressNotesAPI();
  const getPsychiatryProgressNoteAndPrevious = useGetPsychiatryProgressNoteAndPrevious();
  const clinicalAppointmentsAPI = useClinicalAppointmentsAPI();

  const { getDeprecatedDiagnosisCodes } = useDeprecatedDiagnosisCodes();
  const {
    enable_supervisor_sign_and_lock_notes_for_user: allowGroupWriteAccess,
    enable_single_request_on_progress_note_copied_section: useSingleRequest,
    enable_care_hub_notes_efficiency: enableCareHubNotesEfficiency,
    enable_prefill_phqgad_answer_in_note: canPrefillPHQGADSummary,
  } = transientFeatureFlags;

  const createDraftNote = async (
    noteSection: Partial<PsychiatryProgressNote>,
    currentNote: PsychiatryProgressNote,
    appointment: Appointment,
  ): Promise<Partial<PsychiatryProgressNote>> => {
    const updatedNoteSection = noteSection;
    const sections: PsychiatryProgressSection[] = Object.entries(
      updatedNoteSection,
    )
      .filter(([name]) => {
        const sectionName = name as PsychiatrySectionName;
        return (
          currentNote[sectionName] === null &&
          updatedNoteSection[sectionName] != null &&
          sectionName !== PsychiatrySectionName.AMENDMENTS
        );
      })
      .map(([name, updatedData]) => {
        const sectionName = name as PsychiatrySectionName;
        logger.info(
          `usePsychiatryProgressNote: ${sectionName} section in Vault doesn't exist. Creating draft section.`,
          { appointmentId, clinicianUserId },
        );
        return {
          data: updatedData,
          name,
        } as PsychiatryProgressSection;
      });

    if (sections.length === 0) return updatedNoteSection;

    const { metadata } = currentNote;
    if (useSingleRequest) {
      metadata.status = Metadata_NoteStatus.draft_note;
      await api
        .createDraftNoteSections(
          clinicianUserId,
          appointment,
          sections,
          metadata,
          allowGroupWriteAccess,
        )
        .catch((error) => {
          const sectionNames = sections.map((section) => section.name);
          sectionNames.forEach((section) => {
            updatedNoteSection[section] = null;
          });
          metadata.status = Metadata_NoteStatus.undefined_note_status;
          const message = `usePsychiatryProgressNote: Unable to copy forward from previous note. Clearing section data in local state.`;
          logger.error(error, {
            appointmentId,
            clinicianUserId,
            message,
            sectionNames,
          });
        });
    } else {
      await Promise.all(
        sections.map(async (section) =>
          api
            .createDraftNoteSection(
              clinicianUserId,
              appointment,
              section,
              undefined,
              allowGroupWriteAccess,
            )
            .catch((error) => {
              const sectionName = section.name;
              updatedNoteSection[sectionName] = null;
              const message = `usePsychiatryProgressNote: ${sectionName} section failed on createDraftNoteSection. Clearing section data in local state.`;
              logger.error(error, {
                appointmentId,
                clinicianUserId,
                message,
                sectionName,
              });
            }),
        ),
      );
    }

    return updatedNoteSection;
  };

  return useCallback(
    async (appointment: Appointment): Promise<PsychiatryProgressNote> => {
      const [
        previousAppointment,
        deprecatedDiagnosisCodes,
        phqGadSurvey,
      ] = await Promise.all([
        clinicalAppointmentsAPI.getLatestCompleteClinicalAppointmentAndNoteType(
          appointmentId,
        ),
        getDeprecatedDiagnosisCodes(),
        canPrefillPHQGADSummary
          ? api.getPHQGADSurvey({ memberId: appointment.member.id })
          : Promise.resolve(null),
      ]);
      const results = await getPsychiatryProgressNoteAndPrevious(
        appointment,
        previousAppointment,
      );
      const { currentNote, previousNote } = results;
      const canCopyForward =
        previousAppointment !== null &&
        (previousAppointment.noteType === NoteType.PSYCHIATRY_PROGRESS ||
          previousAppointment.noteType === NoteType.PSYCHIATRY_INTAKE);

      if (!canCopyForward) return currentNote;

      const updatedNoteSection: Partial<PsychiatryProgressNote> = await createDraftNote(
        buildPsychiatryProgressNote({
          appointmentId,
          currentNote,
          deprecatedDiagnosisCodes,
          enableCareHubNotesEfficiency,
          phqGadSurvey,
          previousNote: previousNote ?? null,
        }),
        currentNote,
        appointment,
      );

      return {
        ...currentNote,
        ...updatedNoteSection,
      };
    },
    [
      canPrefillPHQGADSummary,
      allowGroupWriteAccess,
      enableCareHubNotesEfficiency,
      getDeprecatedDiagnosisCodes,
      api,
      appointmentId,
      clinicalAppointmentsAPI,
      createDraftNote,
      getDeprecatedDiagnosisCodes,
      getPsychiatryProgressNoteAndPrevious,
    ],
  );
}

function useGetPsychiatryProgressNoteAndPrevious() {
  const api = usePsychiatryProgressNotesAPI();
  const psychiatryIntakeNotesAPI = usePsychiatryIntakeNotesAPI();
  return useCallback(
    async (
      appointment: Appointment,
      previousAppt: IdAndNoteType | null,
    ): Promise<{
      previousNote?: PsychiatryProgressNote | PsychiatryIntakeNote;
      currentNote: PsychiatryProgressNote;
    }> => {
      // We only want to load previous note if it's a Psychiatry Progress Note or Psychiatry Intake Note
      // Otherwise, we'll just return undefined
      const { id: appointmentId } = appointment;

      let getPreviousNote: (
        id: string,
      ) => Promise<
        PsychiatryProgressNote | PsychiatryIntakeNote | undefined
      > = async () => Promise.resolve(undefined);

      const previousAppointmentId = previousAppt?.id ?? '';
      if (previousAppt?.noteType === NoteType.PSYCHIATRY_PROGRESS) {
        getPreviousNote = api.getNote.bind(api);
      } else if (previousAppt?.noteType === NoteType.PSYCHIATRY_INTAKE) {
        getPreviousNote = psychiatryIntakeNotesAPI.getNote.bind(
          psychiatryIntakeNotesAPI,
        );
      }

      const [currentNote, previousNote] = await Promise.all([
        api.getNote(appointmentId),
        getPreviousNote(previousAppointmentId).catch(() =>
          decodePsychiatryProgressNote(appointmentId, [], []),
        ),
      ]);
      return { currentNote, previousNote };
    },
    [api, psychiatryIntakeNotesAPI],
  );
}
