import {
  arrayField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
} from '@ginger.io/react-use-form';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import {
  SubstanceUse,
  SubstanceUse_CurrentSubstanceUse,
  SubstanceUse_PastSubstanceUse,
  SubstanceUse_SubstanceType,
  SubstanceUse_SubstanceUseLineItem,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import {
  SubstanceAbuseSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { Typography } from '@mui/material';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { OnChangeHandler } from 'app/notes-ui/shared/substance-abuse/SubstanceTableRow';
import { SubstanceUseContainerV2 } from 'app/notes-ui/shared/substance-abuse/SubstanceUseContainerV2';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React, { useRef, useState } from 'react';

type Props = {
  appointmentId: string;
  initialValue?: SubstanceUse;
  readOnly?: boolean;
  onSubmit: (data: SubstanceAbuseSection) => void;
  disabled?: boolean;
};

export const formFieldDefs: FieldDefinitions<SubstanceUse> = {
  appointmentId: field(),
  anySubstanceUsed: field<BooleanOption>({
    default: BooleanOption.yes,
    rules: [],
  }),
  substancesCurrentlyUsed: arrayField<SubstanceUse_CurrentSubstanceUse>({
    default: [],
  }),
  substancesPreviouslyUsed: arrayField<SubstanceUse_PastSubstanceUse>({
    default: [],
  }),
  pastEtohOrBenzoWithdrawal: {
    isPresent: field({ rules: [] }),
    description: stringField({ default: '', rules: [] }),
  },
  pastSubstanceUseTreatment: {
    isPresent: field({ rules: [] }),
    description: stringField({ default: '', rules: [] }),
  },
  version: field<SubstanceUse_Version>({ default: SubstanceUse_Version.v0 }),
};

function getNotEndorsedStatus(value: BooleanOption | undefined) {
  return value === BooleanOption.no;
}

function getBooleanOption(value: boolean) {
  return value ? BooleanOption.no : BooleanOption.yes;
}

export function SubstanceAbuseFormV2(props: Props) {
  const { initialValue, disabled } = props;
  const currentUseRows = useSubstanceItems();

  const [notEndorsedChecked, setNotEndorsedChecked] = useState<boolean>(
    getNotEndorsedStatus(initialValue?.anySubstanceUsed),
  );

  const {
    fields: anySubstanceUseField,
    getValue: anySubstanceUseFieldValue,
  } = useForm<SubstanceUse>(
    formFieldDefs,
    { ...initialValue, version: SubstanceUse_Version.v0 } as SubstanceUse,
    {
      onStateChange: async () => {
        await onSubmit();
      },
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
    },
  );

  const getSectionData = () => {
    const anySubstanceUsedValue = anySubstanceUseFieldValue();
    const substancesUsed = currentUseRows
      .getRows()
      .map((row) => ({ substance: row.item }));
    const section: SubstanceUse = {
      appointmentId: props.appointmentId,
      anySubstanceUsed: anySubstanceUsedValue.anySubstanceUsed,
      substancesCurrentlyUsed: substancesUsed,
      substancesPreviouslyUsed: [],
      pastEtohOrBenzoWithdrawal: {
        isPresent: false,
        description: '',
      },
      pastSubstanceUseTreatment: {
        isPresent: false,
        description: '',
      },
      version: initialValue?.version || SubstanceUse_Version.v0,
    };
    return section;
  };

  const onSubmit = async () => {
    if (disabled) return;

    const data = {
      ...getSectionData(),
      anySubstanceUsed: getBooleanOption(notEndorsedChecked),
    };
    props.onSubmit({
      name: TherapyProgressSectionName.SUBSTANCE_ABUSE,
      data,
    });
  };

  return (
    <>
      <Typography variant={'body1'} className={styles.label}>
        Does the member currently or have they in the past used any substances?
      </Typography>
      <div className={styles.formGroupV2} data-testid="currentlyUsed">
        <Checkbox
          testId={`endorsedState`}
          checked={notEndorsedChecked}
          disabled={disabled}
          onChange={() => {
            anySubstanceUseField.anySubstanceUsed.setValue(
              getBooleanOption(!notEndorsedChecked),
            );
            setNotEndorsedChecked((notEndorsedChecked) => !notEndorsedChecked);
          }}
          label="Not Endorsing any current or past substance use"
        />
        <SubstanceUseContainerV2
          includeStoppedUse={false}
          onChange={currentUseRows.onChange}
          onSubmit={onSubmit}
          initialValue={initialValue?.substancesCurrentlyUsed}
          disabled={disabled}
          notEndorsed={notEndorsedChecked}
        />
      </div>
    </>
  );
}

type SubstanceUseItems = Record<
  SubstanceUse_SubstanceType,
  SubstanceUseItem | undefined
>;

type SubstanceUseItem = {
  item: SubstanceUse_SubstanceUseLineItem;
  isChecked: boolean;
  isValid: boolean;
};

/** Custom hook that keeps the state of SubstanceRows  */
function useSubstanceItems(): {
  isValid: boolean;
  onChange: OnChangeHandler;
  getRows: () => SubstanceUseItem[];
} {
  const items = useRef<SubstanceUseItems>({} as SubstanceUseItems);
  const [isValid, setIsValid] = useState(true);

  const onChange: OnChangeHandler = ({ item, isChecked, isValid }) => {
    if (isChecked) {
      items.current[item.substanceType] = { item, isChecked, isValid };
    } else {
      delete items.current[item.substanceType];
    }

    const currentItem = Object.values(items.current);

    setIsValid(
      currentItem.length === 0 ||
        currentItem.every((_) => _ !== undefined && _.isValid),
    );
  };

  return {
    isValid,
    onChange,
    getRows: () =>
      Object.values(items.current).filter(
        (_) => _ !== undefined,
      ) as SubstanceUseItem[],
  };
}
