import {
  ClinicalAppointmentStatus,
  Reason,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';

export const CANCELLED_APPOINTMENT_STATUSES: ClinicalAppointmentStatus[] = [
  ClinicalAppointmentStatus.Cancelled,
  ClinicalAppointmentStatus.CancelledClinician,
  ClinicalAppointmentStatus.LateCancelled,
  ClinicalAppointmentStatus.Rescheduled,
  ClinicalAppointmentStatus.Deleted,
];

export const SYSTEM_REMINDER_TASK_REASONS = [
  Reason.PHQGADReminder,
  Reason.DeactivationReminder,
];

export enum ClinicalCancellationReason {
  SCHEDULING_CONFLICT = 'SchedulingConflict',
  PERSONAL_EMERGENCY = 'PersonalEmergency',
  NOT_FEELING_WELL = 'NotFeelingWell',
  SOMETHING_ELSE = 'SomethingElse',
  NOT_FEELING_READY_TO_START = 'NotFeelingReadyToStart',
  NOT_SURE_WHAT_IT_WILL_COST = 'NotSureWhatItWillCost',
  CAN_NOT_AFFORD_IT = 'CanNotAffordIt',
  WOULD_LIKE_TO_EXPLORE_ANOTHER_OPTION = 'WouldLikeToExploreAnotherOption',
  FOUND_HELP_OUTSIDE_GINGER = 'FoundHelpOutsideGinger',
  FEELS_BETTER = 'FeelsBetter',
}

export const CANCELLATION_REASONS: ClinicalCancellationReason[] = [
  ClinicalCancellationReason.SCHEDULING_CONFLICT,
  ClinicalCancellationReason.PERSONAL_EMERGENCY,
  ClinicalCancellationReason.NOT_FEELING_WELL,
  ClinicalCancellationReason.SOMETHING_ELSE,
  ClinicalCancellationReason.NOT_FEELING_READY_TO_START,
  ClinicalCancellationReason.NOT_SURE_WHAT_IT_WILL_COST,
  ClinicalCancellationReason.CAN_NOT_AFFORD_IT,
  ClinicalCancellationReason.WOULD_LIKE_TO_EXPLORE_ANOTHER_OPTION,
  ClinicalCancellationReason.FOUND_HELP_OUTSIDE_GINGER,
  ClinicalCancellationReason.FEELS_BETTER,
];

// Using 210s has the polling interval because if our polling interval is too short, clinical notes in our Appointments
// table that "just" changed state (e.g. draft => signed & locked) will flip back to their previous state in the UI and also
// the approximate age of message in our clinical_notes_queue after signing & locking a notes is ~197s.
export const GQL_QUERY_POLLING_INTERVAL = 210_000;
// Refresh the Task Card every minute
export const GQL_TASK_CARD_POLLING_INTERVAL = 60000;

export const COACH_STATUS_POLL_INTERVAL = 120_000; // 2 minutes

// Apollo Caching strategies for queries - https://www.apollographql.com/docs/react/data/queries/#supported-fetch-policies
export enum ApolloCachingStrategy {
  CACHE_FIRST = 'cache-first',
  CACHE_ONLY = 'cache-only',
  CACHE_AND_NETWORK = 'cache-and-network',
  NETWORK_ONLY = 'network-only',
  NO_CACHE = 'no-cache',
  STANDBY = 'standby',
}

export enum ClinicianAutoCompleteType {
  PREVIOUS_CLINICIAN = 'Previously Scheduled Clinicians',
  OTHER_CLINICIAN = 'Other Clinicians',
}

export enum WebURL {
  PROD = 'https://data.ginger.io',
  STAGING = 'https://dev.ginger.io',
}

export const GOOGLE_CALENDAR_URL = 'https://calendar.google.com';

export const CARE_METRICS_COMMENT_MAX_CHARS = 50;

export enum Links {
  BUG_SUBMISSION_FORM_URL = 'https://link.headspace.com/care-issues',
  ACCOLADE_CASE_ROUND_FORM = 'https://docs.google.com/forms/d/1jfnSRnFs-ca3DWHZkz8LRvj_EOoG_t4vfLu8KPTOPQM/viewform?ts=5f7f749a&gxids=7628&edit_requested=true',
  CCA_REFERRAL_FORM = 'https://docs.google.com/forms/d/1Htg0IjUQN-c9kh4HZIzUAq2Dgft4jz0ceQQsM81nz8U/viewform?edit_requested=true',
  CARE_COORDINATION_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSdcqDbpNzgqkTz4uILtuNO0spl-ZJ7xAXDbBPyVclTClTVDVA/viewform',
  CLINICAL_RISK_REVIEW_FORM = 'https://docs.google.com/forms/d/e/1FAIpQLSdWBQFIhIOu1YKDc3qfpOtoAeMdPdsjFdxYlOVpVylbvVcJXw/viewform',
  CRR_FORM = 'https://form.asana.com/?k=30TKezioGBgUWHkQ86orBQ&d=1290778030743',
  RISK_POLICY_PROTOCOLS = 'https://docs.google.com/document/d/1dXnlvdzdVo_axEOSj2ZlfXJh6oYy4nAadYy1YWSAp6s/edit',
  CLINICIAN_SITE = 'https://sites.google.com/care.ginger.io/hhtherapy/home',
  ON_CALL_SCHEDULE = 'https://sites.google.com/care.ginger.io/ginger-coaching/risk/crr-linkon-call',
  COACHING_SITE = 'https://sites.google.com/care.ginger.io/ginger-coaching/home',
}
