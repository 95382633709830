import { ApolloClient, useApolloClient } from '@apollo/client';
import { PaginationInput } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  clinicianInboxQuery,
  clinicianTodaysInbox,
} from '@headspace/carehub-graphql/dist/queries/CareProviderInbox';
import {
  ClinicianInbox,
  ClinicianInboxVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/ClinicianInbox';
import {
  ClinicianTodaysInbox,
  ClinicianTodaysInboxVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/ClinicianTodaysInbox';
import { AnyAction } from '@reduxjs/toolkit';
import { assertResponseHasNoError } from 'app/inbox/assertResponseHasNoError';
import useMultiTabs from 'app/inbox/components/useMultiTabs';
import { loadOpenTabInboxItems } from 'app/inbox/queries';
import { useAppState } from 'app/state';
import {
  inboxMemberSearch,
  loadMemberListAction,
} from 'app/state/amplitude/actions/inbox';
import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import {
  setSearchResult,
  startInboxPolling,
  stopInboxPolling,
  updateInboxSectionItems,
} from 'app/state/inbox/actions';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import { useStateSlice } from 'app/vault/hooks/utils';
import { useOnMount } from 'hooks/useOnMount';
import { useCallback } from 'react';

import {
  InboxItem,
  InboxSections,
  InboxSource,
  InboxTab,
  InboxViewResponse,
  Results,
} from './types';
import {
  getClinicianTodaysInboxVariables,
  gqlAllMemberResponseToInboxItem,
  gqlTasksResponseToInboxItem,
  gqlTodaysAppointmentResponseToInboxItem,
} from './utils';

export function useClinicianInbox(): InboxViewResponse {
  const { initializeTabState } = useMultiTabs();
  const logger = useLogger();

  const apollo = useApolloClient();
  const dispatch = useAppDispatch();
  const { role, timezone } = useAppState(({ user }) => ({
    role: user.role!,
    timezone: user.timezone ?? 'UTC',
  }));

  const {
    setLoading: todayListLoading,
    setError: setTodayListError,
    setData: setTodaysMember,
    state: todaysTab,
  } = useStateSlice<void>();
  const {
    setLoading: allListLoading,
    setError: setAllListError,
    setData: setAllMemberData,
    state: allTab,
  } = useStateSlice<void>();

  const onSearch = async (query: string) => {
    if (query) {
      const allMember = await getClinicalAllMembers(
        apollo,
        timezone,
        logger,
        query,
      );
      dispatch(
        setSearchResult({
          [InboxSections.PAST]: allMember?.pastSection,
          [InboxSections.CLOSED]: allMember?.closedSection,
          [InboxSections.SCHEDULED]: allMember?.scheduledSection,
        }),
      );
      dispatch(
        inboxMemberSearch({ role, source: InboxSource.CLINICIAN_INBOX }),
      );
    } else {
      dispatch(
        setSearchResult({
          [InboxSections.PAST]: undefined,
          [InboxSections.CLOSED]: undefined,
          [InboxSections.SCHEDULED]: undefined,
        }),
      );
    }
  };

  const loadMore = useCallback(
    loadMoreSection(apollo, timezone, dispatch, logger),
    [apollo, timezone, dispatch, logger],
  );

  useOnMount(() => {
    initializeTabState();
    todayListLoading();
    allListLoading();
    const run = async () => {
      const [todaysList, allMember] = await Promise.allSettled([
        getClinicianTodaysMembers(apollo, timezone, logger),
        getClinicalAllMembers(apollo, timezone, logger),
      ]);
      const inboxItems: InboxItem[] = [];
      const sections: { [k in InboxSections]?: Results } = {};
      if (todaysList.status === 'fulfilled') {
        inboxItems.push(
          ...getInboxItems(todaysList.value?.activeTasksSection),
          ...getInboxItems(todaysList.value?.riskAlertSection),
          ...getInboxItems(todaysList.value?.appointmentsSection),
          ...getInboxItems(todaysList.value?.completedSection),
        );
        sections[InboxSections.RISKS] = todaysList.value?.riskAlertSection;
        sections[InboxSections.APPOINTMENTS] =
          todaysList.value?.appointmentsSection;
        sections[InboxSections.TASKS] = todaysList.value?.activeTasksSection;
        sections[InboxSections.COMPLETED] = todaysList.value?.completedSection;

        dispatch(
          loadMemberListAction({
            role,
            source: InboxSource.CLINICIAN_INBOX,
            tab: InboxTab.TODAYS,
          }),
        );
      } else {
        setTodayListError(todaysList.reason);
      }

      if (allMember.status === 'fulfilled') {
        inboxItems.push(
          ...getInboxItems(allMember.value?.closedSection),
          ...getInboxItems(allMember.value?.pastSection),
          ...getInboxItems(allMember.value?.scheduledSection),
        );
        sections[InboxSections.PAST] = allMember.value?.pastSection;
        sections[InboxSections.CLOSED] = allMember.value?.closedSection;
        sections[InboxSections.SCHEDULED] = allMember.value?.scheduledSection;

        dispatch(
          loadMemberListAction({
            role,
            source: InboxSource.CLINICIAN_INBOX,
            tab: InboxTab.ALL,
          }),
        );
      } else {
        setAllListError(allMember.reason);
      }
      const openTabs = await loadOpenTabInboxItems({
        apollo,
        inboxItems,
        logger,
        role,
      });
      dispatch(updateInboxSectionItems({ ...sections, openTabs }));

      if (todaysList.status === 'fulfilled') setTodaysMember();
      if (allMember.status === 'fulfilled') setAllMemberData();
    };
    run()
      .then(() => {
        dispatch(startInboxPolling());
      })
      .catch((error) => logger.error(error));

    return () => {
      dispatch(stopInboxPolling());
    };
  });

  return {
    allTab: allTab.current,
    loadMore,
    onSearch,
    todaysTab: todaysTab.current,
  };
}

function loadMoreSection(
  apollo: ApolloClient<object>,
  timezone: string,
  dispatch: (action: AnyAction) => void,
  logger: ILogger,
) {
  return async (pagination: PaginationInput, section: InboxSections) => {
    if (
      [
        InboxSections.PAST,
        InboxSections.SCHEDULED,
        InboxSections.CLOSED,
      ].includes(section)
    ) {
      const response = await apollo.query<
        ClinicianInbox,
        ClinicianInboxVariables
      >({
        errorPolicy: 'all',
        query: clinicianInboxQuery,
        variables: {
          includeClosed: section === InboxSections.CLOSED,
          includePast: section === InboxSections.PAST,
          includeScheduled: section === InboxSections.SCHEDULED,
          pagination,
        },
      });

      assertResponseHasNoError(response, logger, {
        query: clinicianTodaysInbox,
      });

      if (response.errors) {
        // eslint-disable-next-line no-undef
        R7Insight.error(
          'Received partial response with an error while loading more member for user',
          {
            errors: response.errors,
          },
        );
      }

      const { scheduled, closed, past } = response.data.getClinicalMembersForMe;
      const scheduledSection = scheduled
        ? gqlAllMemberResponseToInboxItem(scheduled.items, scheduled.pagination)
        : undefined;
      const pastSection = past
        ? gqlAllMemberResponseToInboxItem(past.items, past.pagination)
        : undefined;
      const closedSection = closed
        ? gqlAllMemberResponseToInboxItem(closed.items, closed.pagination)
        : undefined;

      dispatch(
        updateInboxSectionItems({
          [InboxSections.PAST]: pastSection,
          [InboxSections.SCHEDULED]: scheduledSection,
          [InboxSections.CLOSED]: closedSection,
        }),
      );
    }
    if (
      [
        InboxSections.RISKS,
        InboxSections.APPOINTMENTS,
        InboxSections.TASKS,
      ].includes(section)
    ) {
      const variables = getClinicianTodaysInboxVariables();
      if (section === InboxSections.RISKS) {
        variables.includeRiskTasks = true;
        variables.riskTasksPagination = pagination;
      }
      if (section === InboxSections.APPOINTMENTS) {
        variables.includeAppointments = true;
        variables.appointmentPagination = pagination;
      }
      if (section === InboxSections.TASKS) {
        variables.includeActiveTasks = true;
        variables.activeTasksPagination = pagination;
      }
      const response = await apollo.query<
        ClinicianTodaysInbox,
        ClinicianTodaysInboxVariables
      >({
        errorPolicy: 'all',
        query: clinicianTodaysInbox,
        variables,
      });

      assertResponseHasNoError(response, logger, {
        query: clinicianTodaysInbox,
      });

      if (response.errors) {
        // eslint-disable-next-line no-undef
        R7Insight.error(
          'Received partial response with an error while loading more member for user',
          {
            errors: response.errors,
          },
        );
      }

      const { riskTasks, appointments, activeTasks } = response.data;

      const appointmentsSection = appointments
        ? gqlTodaysAppointmentResponseToInboxItem(
            appointments?.appointments?.items ?? [],
            appointments?.appointments?.pagination,
            timezone,
          )
        : undefined;

      const riskAlertSection = riskTasks
        ? gqlTasksResponseToInboxItem(
            riskTasks?.items ?? [],
            riskTasks?.pagination,
            timezone,
            true,
          )
        : undefined;
      const activeTasksSection = activeTasks
        ? gqlTasksResponseToInboxItem(
            activeTasks?.items ?? [],
            activeTasks?.pagination,
            timezone,
          )
        : undefined;

      dispatch(
        updateInboxSectionItems({
          [InboxSections.RISKS]: riskAlertSection,
          [InboxSections.APPOINTMENTS]: appointmentsSection,
          [InboxSections.TASKS]: activeTasksSection,
        }),
      );
    }
  };
}

function getInboxItems(state: Results | undefined): InboxItem[] {
  return state?.items ?? [];
}

async function getClinicianTodaysMembers(
  apollo: ApolloClient<object>,
  timezone: string,
  logger: ILogger,
) {
  const response = await apollo.query<
    ClinicianTodaysInbox,
    ClinicianTodaysInboxVariables
  >({
    errorPolicy: 'all',
    query: clinicianTodaysInbox,
    variables: getClinicianTodaysInboxVariables({
      includeActiveTasks: true,
      includeAppointments: true,
      includeCompletedTasks: true,
      includeRiskTasks: true,
    }),
  });

  assertResponseHasNoError(response, logger, { query: clinicianTodaysInbox });
  const { errors, data } = response;
  if (errors) {
    logger.error(
      new Error('ClinicianTodaysInbox returned partial data with an error'),
      { errors },
    );
  }

  const { activeTasks, riskTasks, completedTasks, appointments } = data;
  const riskAlertSection = gqlTasksResponseToInboxItem(
    riskTasks?.items ?? [],
    riskTasks?.pagination,
    timezone,
    true,
  );
  const completedSection = gqlTasksResponseToInboxItem(
    completedTasks ?? [],
    undefined,
    timezone,
  );
  const activeTasksSection = gqlTasksResponseToInboxItem(
    activeTasks?.items ?? [],
    activeTasks?.pagination,
    timezone,
  );
  const appointmentsSection = gqlTodaysAppointmentResponseToInboxItem(
    appointments?.appointments?.items ?? [],
    appointments?.appointments?.pagination,
    timezone,
  );

  return {
    activeTasksSection,
    appointmentsSection,
    completedSection,
    data,
    riskAlertSection,
  };
}

async function getClinicalAllMembers(
  apollo: ApolloClient<object>,
  timezone: string,
  logger: ILogger,
  query?: string,
) {
  let input;
  if (query) input = { query };
  const response = await apollo.query<ClinicianInbox, ClinicianInboxVariables>({
    errorPolicy: 'all',
    query: clinicianInboxQuery,
    variables: { input, isCoach: false },
  });

  assertResponseHasNoError(response, logger, { query: clinicianInboxQuery });
  const { errors, data } = response;

  if (errors) {
    logger.error(
      new Error('getClinicalMembersForMe returned partial data with an error'),
      {
        errors,
        hasInputQuery: !!query,
      },
    );
  }

  const { scheduled, closed, past } = data.getClinicalMembersForMe;
  const scheduledSection = gqlAllMemberResponseToInboxItem(
    scheduled.items,
    scheduled.pagination,
  );
  const pastSection = gqlAllMemberResponseToInboxItem(
    past.items,
    past.pagination,
  );
  const closedSection = gqlAllMemberResponseToInboxItem(
    closed.items,
    closed.pagination,
  );

  return { closedSection, data, pastSection, scheduledSection };
}
