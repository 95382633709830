import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import {
  TherapyProgressSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { NoteType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetAppointmentById_getAppointmentById as Appointment } from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import { ShareableClinicianNoteType } from 'app/coach/coach-notes/CoachNotesTypes';
import { ClinicalNoteUploadData } from 'app/notes-ui/qa-checks/types';
import { useUploadClinicalNoteForQaChecks } from 'app/notes-ui/qa-checks/useUploadClinicalNoteForQaChecks';
import { useAppState } from 'app/state';
import { careProviderNotesEvent } from 'app/state/amplitude/actions/notes';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import { SubsectionType } from 'app/vault/api/ShareableSubsectionTypes';
import {
  TherapyProgressNote,
  TherapyProgressNotesAPI,
} from 'app/vault/api/TherapyProgressNotesAPI';
import { useInitializeTherapyProgressNote } from 'app/vault/hooks/useInitializeTherapyProgressNote';
import { validateTherapyProgressNote } from 'app/vault/validations/therapyProgress';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import Messages from 'i18n/en/vault.json';
import { useCallback, useState } from 'react';
import { useDispatch } from 'react-redux';
import { getTimezone } from 'utils/dateTime';
import { CareProviderNotesLabel } from 'utils/notes';

import { useTherapyProgressNotesAPI } from './useClinicalNotesAPI';
import {
  isItemAlreadyExistsError,
  StateController,
  useStateSlice,
  UseTherapyNoteResult,
} from './utils';

export type IdAndNoteType = { id: string; noteType: NoteType };

export function useTherapyProgressNote(
  userId: string,
  appointmentId: string,
): UseTherapyNoteResult<TherapyProgressNote, TherapyProgressSection> {
  const logger = useLogger();
  const api = useTherapyProgressNotesAPI();
  const dispatch = useDispatch();
  const noteState = useStateSlice<TherapyProgressNote>();
  const noteDraftState = useStateSlice<TherapyProgressNote>();

  const timezone = useAppState((_) => getTimezone(_.user.timezone));

  const { transientFeatureFlags } = useFeatureFlags();
  const { uploadClinicalNoteForQaChecks } = useUploadClinicalNoteForQaChecks();

  const allowGroupWriteAccess =
    transientFeatureFlags.enable_supervisor_sign_and_lock_notes_for_user;

  const isLockable = validateTherapyProgressNote(noteState.state.current.data);

  const [appointment, setAppointment] = useState<Appointment>();
  const initializeNote = useInitializeTherapyProgressNote(
    userId,
    appointmentId,
  );

  useOnMount(() => {
    const analyticsEventData = {
      appointmentId,
      clinicianId: userId,
      label: CareProviderNotesLabel.NOTE_VIEWED,
      noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
    };
    const callback = async () => {
      try {
        noteState.setLoading();

        const appointmentData = await api.getAppointment(appointmentId);
        const data = await initializeNote(appointmentData);

        noteState.setData(data);
        noteDraftState.setData(data);
        setAppointment(appointmentData);
        logger.info('useTherapyProgressNote onMount succeeded', {
          ...analyticsEventData,
        });
      } catch (e) {
        noteState.setError(e);
        logger.error(e, {
          message: 'useTherapyProgressNote onMount failed',
          ...analyticsEventData,
        });
      }
    };
    callback();
    dispatch(careProviderNotesEvent(analyticsEventData));
  });

  const createOrUpdateSection = useCallback(
    createOrUpdateSectionHandler(
      userId,
      appointment,
      api,
      noteState,
      allowGroupWriteAccess,
      dispatch,
      logger,
    ),
    [userId, appointment, api, noteState.state.current, allowGroupWriteAccess],
  );
  const updateDraftNoteState = useCallback(updateDraftState(noteDraftState), [
    noteDraftState.state.current,
  ]);

  const lockNote = useCallback(
    lockNoteHandler(
      userId,
      appointment,
      api,
      noteState,
      timezone,
      allowGroupWriteAccess,
      dispatch,
      logger,
      uploadClinicalNoteForQaChecks,
    ),
    [
      appointment,
      api,
      noteState.state.current,
      timezone,
      userId,
      allowGroupWriteAccess,
    ],
  );

  const deleteDraft = useCallback(
    appointment && noteState.state.current.data
      ? deleteDraftHandler(
          userId,
          appointment,
          api,
          noteState.state.current.data,
          dispatch,
        )
      : () => Promise.resolve({} as DeleteVaultItemsMutation),
    [appointment, api, noteState.state.current],
  );

  return {
    appointment,
    createOrUpdateSection,
    deleteDraft,
    draftNote: noteDraftState.state,
    isLockable,
    lockNote,
    note: noteState.state,
    updateDraftNoteState,
  };
}

async function createAmendment(
  userId: string,
  appointment: Appointment,
  api: TherapyProgressNotesAPI,
  noteState: StateController<TherapyProgressNote>,
  section: TherapyProgressSection,
  dispatch: ReturnType<typeof useDispatch>,
) {
  const note = noteState.state.current.data;
  if (note === null) {
    throw new Error(
      `${Messages.failureToCreateAmendmentSection}: ${noteState.state.current.status}`,
    );
  }

  await api.createAmendment(userId, appointment.id, section);

  const updatedNote: TherapyProgressNote = {
    ...note,
    [section.name]: section.data,
  };
  noteState.setData(updatedNote);
  dispatch(
    careProviderNotesEvent({
      appointmentId: appointment.id,
      clinicianId: appointment.clinician.userId,
      label: CareProviderNotesLabel.NOTE_AMENDED,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
    }),
  );
}

export const createOrUpdateSectionHandler = (
  userId: string,
  appointment: Appointment | undefined,
  api: TherapyProgressNotesAPI,
  noteState: StateController<TherapyProgressNote>,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
  onCreateDraftNoteSectionHandler = createDraftNoteSectionHandler,
  onUpdateDraftNoteSectionHandler = updateDraftNoteSectionHandler,
): ((section: TherapyProgressSection) => Promise<void>) => {
  return async (section: TherapyProgressSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `${Messages.failureToCreateOrUpdateNoteSection}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(Messages.appointmentNotDefined);
    }

    if (api.amendmentSectionName === section.name) {
      return createAmendment(
        userId,
        appointment,
        api,
        noteState,
        section,
        dispatch,
      );
    }

    if (note[section.name] === null) {
      try {
        await onCreateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      } catch (e) {
        if (!isItemAlreadyExistsError(e)) {
          logger.info(
            'useTherapyProgressNote: onCreateDraftNoteSectionHandler skipping retry',
            { error: e },
          );
          throw e;
        }
        logger.info(
          `useTherapyProgressNote: createOrUpdateSectionHandler failed for section ${section.name}, retrying as update...`,
          {
            appointmentId: appointment.id,
            clinicianId: userId,
            error: e,
            label: CareProviderNotesLabel.NOTE_CREATED,
            noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
            section: section.name,
          },
        );
        return onUpdateDraftNoteSectionHandler({
          allowGroupWriteAccess,
          api,
          appointment,
          dispatch,
          logger,
          note,
          noteState,
          section,
          userId,
        });
      }
    } else if (note[section.name] !== null) {
      await onUpdateDraftNoteSectionHandler({
        allowGroupWriteAccess,
        api,
        appointment,
        dispatch,
        logger,
        note,
        noteState,
        section,
        userId,
      });
    }
  };
};

export type createOrUpdateDraftNoteSectionProps = {
  note: TherapyProgressNote;
  section: TherapyProgressSection;
  api: TherapyProgressNotesAPI;
  userId: string;
  appointment: Appointment;
  allowGroupWriteAccess: boolean;
  noteState: StateController<TherapyProgressNote>;
  dispatch: ReturnType<typeof useDispatch>;
  logger: ILogger;
};

export const createDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const careProviderNoteEventPayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_UPDATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
    section: section.name,
  };
  // TODO: is this change in logic ok? Previously: needToCreateMetadata ? updatedNote.metadata : undefined,
  const needToCreateMetadata =
    note.metadata.status === Metadata_NoteStatus.undefined_note_status;

  const updatedNote: TherapyProgressNote = {
    ...note,
    metadata: {
      ...note.metadata,
      status: Metadata_NoteStatus.draft_note,
    },
    [section.name]: section.data,
  };

  await api.createDraftNoteSection(
    userId,
    appointment,
    section,
    needToCreateMetadata ? updatedNote.metadata : undefined,
    allowGroupWriteAccess,
  );
  logger.info(
    `useTherapyProgressNote: createOrUpdateSectionHandler successfully created draft section ${section.name}`,
    { ...careProviderNoteEventPayload },
  );

  noteState.setData(updatedNote);
  careProviderNoteEventPayload.label = CareProviderNotesLabel.NOTE_CREATED;
  dispatch(careProviderNotesEvent(careProviderNoteEventPayload));
};

export const updateDraftNoteSectionHandler = async (
  data: createOrUpdateDraftNoteSectionProps,
) => {
  const {
    note,
    section,
    api,
    userId,
    appointment,
    allowGroupWriteAccess,
    noteState,
    dispatch,
    logger,
  } = data;

  const amplitudePayload = {
    appointmentId: note.metadata.appointmentId,
    clinicianId: userId,
    label: CareProviderNotesLabel.NOTE_UPDATED,
    memberId: appointment.member.id,
    noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
    section: section.name,
  };
  const updatedNote = { ...note, [section.name]: section.data };

  try {
    await api.updateDraftNoteSection(
      userId,
      appointment,
      section,
      undefined,
      allowGroupWriteAccess,
    );
    logger?.info(
      `useTherapyProgressNote: createOrUpdateSectionHandler successfully updated draft section ${section.name}`,
      { ...amplitudePayload },
    );
  } catch (e) {
    // TODO: Why does handling happnen here for update but not for create?
    logger?.error(e, {
      message: 'useTherapyProgressNote: failed to updateDraftNoteSection',
      ...amplitudePayload,
    });
    throw e;
  }
  noteState.setData(updatedNote);
  dispatch(careProviderNotesEvent(amplitudePayload));
};

function updateDraftState(
  noteState: StateController<TherapyProgressNote>,
): (section: TherapyProgressSection) => void {
  return async (section: TherapyProgressSection) => {
    const note = noteState.state.current.data;
    if (note === null) {
      throw new Error(
        `${Messages.failureToUpdateDraftState}: ${noteState.state.current.status}`,
      );
    }

    if (section.name === TherapyProgressSectionName.AMENDMENTS) return;

    const updatedNote = { ...note, [section.name]: section.data };
    noteState.setData(updatedNote);
  };
}

function lockNoteHandler(
  clinicianId: string,
  appointment: Appointment | undefined,
  api: TherapyProgressNotesAPI,
  noteState: StateController<TherapyProgressNote>,
  timezone: string | null,
  allowGroupWriteAccess: boolean,
  dispatch: ReturnType<typeof useDispatch>,
  logger: ILogger,
  uploadClinicalNoteForQaChecks: (
    props: ClinicalNoteUploadData,
  ) => Promise<void>,
): () => Promise<void> {
  return async () => {
    if (noteState.state.current.data === null) {
      throw new Error(
        `${Messages.failureToLockNote}: ${noteState.state.current.status}`,
      );
    }

    if (appointment === undefined) {
      throw new Error(Messages.appointmentNotDefined);
    }

    const appointmentId = appointment.id;
    const analyticsEventData = {
      appointmentId,
      clinicianId: appointment.clinician.userId,
      label: CareProviderNotesLabel.NOTE_SIGNED_AND_LOCKED,
      memberId: appointment.member.id,
      noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
      safetyRiskEndorsed:
        noteState.state.current.data.SAFETY?.anyChangesOrRisks ===
        BooleanOption.yes,
    };
    const updatedNote: TherapyProgressNote = {
      ...noteState.state.current.data,
      metadata: {
        appointmentId,
        status: Metadata_NoteStatus.signed_and_locked_note,
      },
    };

    try {
      await api.updateNoteMetadata(
        updatedNote.metadata,
        appointment.member.id,
        allowGroupWriteAccess,
      );
      logger.info(
        'useTherapyProgressNote: lockNoteHandler successfully updateNoteMetadata',
        { ...analyticsEventData },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useTherapyProgressNote: lockNoteHandler failed to updateNoteMetadata',
        ...analyticsEventData,
      });
      throw e;
    }

    try {
      await api.lockNoteSections(appointment);
      logger.info(
        'useTherapyProgressNote: lockNoteHandler successfully lockNoteSections',
        { ...analyticsEventData },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useTherapyProgressNote: lockNoteHandler failed to lockNoteSections',
        ...analyticsEventData,
      });
      throw e;
    }

    try {
      await api.createShareableSubsections(
        appointment,
        NoteType.THERAPY_PROGRESS,
        {
          [SubsectionType.SAFETY_PLAN]: updatedNote.SAFETY?.safetyPlan,
          [SubsectionType.TREATMENT_PLAN_GOALS]:
            updatedNote.TREATMENT_PLAN?.goal,
          [SubsectionType.MESSAGE_TO_CARE_TEAM]:
            updatedNote.TREATMENT_PLAN?.messageToCareTeam,
        },
      );
      logger.info(
        'useTherapyProgressNote: lockNoteHandler successfully createShareableSubsections',
        {
          ...analyticsEventData,
        },
      );
    } catch (e) {
      logger.error(e, {
        message:
          'useTherapyProgressNote: lockNoteHandler failed to createShareableSubsections. Continuing anyway.',
        ...analyticsEventData,
      });
    }

    noteState.setData(updatedNote);
    dispatch(careProviderNotesEvent(analyticsEventData));

    // useTherapyProgressNote: Submit the signed and locked note for Brellium QA checks
    await uploadClinicalNoteForQaChecks({
      appointmentMetadata: appointment,
      clinicalNote: updatedNote,
      noteType: NoteType.THERAPY_PROGRESS,
    });
  };
}

function deleteDraftHandler(
  userId: string,
  appointment: Appointment,
  api: TherapyProgressNotesAPI,
  note: TherapyProgressNote,
  dispatch: ReturnType<typeof useDispatch>,
): () => Promise<DeleteVaultItemsMutation> {
  return async () => {
    const deletePromise = await api.deleteDraftNote(userId, appointment, note);
    dispatch(
      careProviderNotesEvent({
        appointmentId: note.metadata.appointmentId,
        clinicianId: userId,
        label: CareProviderNotesLabel.NOTE_DELETED,
        memberId: appointment.member.id,
        noteType: ShareableClinicianNoteType.THERAPY_PROGRESS,
      }),
    );
    return deletePromise;
  };
}
