import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { InboxTab } from 'app/inbox/types';
import { initialState } from './reducers';

export interface SideBarState {
  showInbox: boolean;
  selectedTab: InboxTab;
  showInboxDrawer: boolean;
}

const inboxSlice = createSlice({
  initialState,
  name: 'sideBar',
  reducers: {
    setSelectedTab(state, action: PayloadAction<InboxTab>) {
      state.selectedTab = action.payload;
    },
    toggleDrawer(state) {
      state.showInboxDrawer = !state.showInboxDrawer;
    },
    toggleInbox(state) {
      state.showInbox = !state.showInbox;
    },
  },
});

export const { toggleInbox, toggleDrawer, setSelectedTab } = inboxSlice.actions;
export default inboxSlice.reducer;
