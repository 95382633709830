import React from 'react';
import MoonLoader from 'react-spinners/MoonLoader';

import styles from './ModalLoader.module.scss';

export interface ModalLoaderProps {
  description: string;
}

export const ModalLoader = ({ description }: ModalLoaderProps) => {
  return (
    <div className={styles.modalLoader}>
      <div className={styles.loader}>
        <MoonLoader size={36} />
      </div>

      <p className={styles.description}>{description}</p>
    </div>
  );
};
