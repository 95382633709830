import {
  ChatConversationState,
  ClinicalAppointmentType,
  GuardianRelationshipType,
  Reason,
  ScheduledSessionType,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { CareProviderTaskFragment } from '@headspace/carehub-graphql/dist/queries/generated/CareProviderTaskFragment';
import {
  ClinicianTodaysInbox_appointments_appointments_items as AppointmentItems,
  ClinicianTodaysInboxVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/ClinicianTodaysInbox';
import {
  CoachTodaysInbox_closedConversations_items,
  CoachTodaysInbox_completedTasks,
  CoachTodaysInboxVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/CoachTodaysInbox';
import { ConversationStatsFragment } from '@headspace/carehub-graphql/dist/queries/generated/ConversationStatsFragment';
import {
  InboxItemFragment,
  InboxItemFragment_member,
} from '@headspace/carehub-graphql/dist/queries/generated/InboxItemFragment';
import { PaginationFragment } from '@headspace/carehub-graphql/dist/queries/generated/PaginationFragment';
import {
  TaskTodayFragment,
  TaskTodayFragment_tasks,
} from '@headspace/carehub-graphql/dist/queries/generated/TaskTodayFragment';
import { TodaysCoachingConversationFragment } from '@headspace/carehub-graphql/dist/queries/generated/TodaysCoachingConversationFragment';
import {
  ChannelIdMemberIdMap,
  ConversationStateMap,
  ExtractedStateFromInboxItem,
} from 'app/coach/chat/types';
import { SYSTEM_REMINDER_TASK_REASONS } from 'app/constants';
import { displayTaskLabel } from 'app/member-chart-cards/tasks/labels';
import { todaysCompletedInboxItemsComparedFn } from 'app/state/inbox/sort-function';
import { isFrenchUser, isSpanishUser, isTeen } from 'utils';
import { formatDatetimeStr } from 'utils/dateTime';

import { InboxItem, Results } from './types';

export const RISK_ALERT_REASONS = [
  Reason.AssessmentRiskAlert,
  Reason.HighSurveyScore,
  Reason.AssessmentRiskAlert,
  Reason.IntakeRiskAlert,
  Reason.RiskyChatDetected,
  Reason.SICoachAdded,
  Reason.SIReviewTriageNotes,
];

export const COACH_INBOX_MAX_ITEMS_PER_PAGE = 60;
export const CLINICIAN_INBOX_MAX_ITEMS_PER_PAGE = 20;
export const INBOX_LOAD_MORE_ITEMS_PER_PAGE = 20;

function formatMemberName(
  member: Pick<
    InboxItemFragment_member,
    'id' | 'preferredLastName' | 'preferredFirstName' | 'firstName' | 'lastName'
  >,
) {
  const {
    id,
    preferredLastName,
    preferredFirstName,
    firstName,
    lastName,
  } = member;
  return (
    `${preferredFirstName || firstName || ''} ${
      preferredLastName || lastName || ''
    }`.trim() || `Member ${id}`
  );
}

export function gqlAllMemberResponseToInboxItem(
  items: InboxItemFragment[],
  pagination?: PaginationFragment,
): Results {
  return {
    cursor: pagination?.cursor ?? null,
    hasMore: pagination?.hasNextPage ?? false,
    items: items.map((item) => {
      const {
        nextAppointmentDatetime,
        prevAppointmentDatetime,
        terminated,
        transferred,
      } = item;
      const {
        id,
        isD2c,
        preferredLanguage,
        guardianRelationship,
      } = item.member;

      return {
        conversationId: item.conversationStats?.id,
        conversationStats: item.conversationStats ?? undefined,
        id,
        isD2c: Boolean(isD2c),
        isFrench: isFrenchUser(preferredLanguage),
        isSpanish: isSpanishUser(preferredLanguage),
        isTeen:
          guardianRelationship?.guardianRelationshipType ===
          GuardianRelationshipType.DEPENDENT,
        isTyping: false,
        memberCoachChannelId: item.conversationStats?.memberCoachChannelId,
        name: formatMemberName(item.member),
        nextAppointment: nextAppointmentDatetime ?? undefined,
        prevAppointment: prevAppointmentDatetime ?? undefined,
        terminated: terminated ?? false,
        transferred: transferred ?? false,
      };
    }),
  };
}

export function gqlTodaysCoachingConvoResponseToInboxItem(
  items: TodaysCoachingConversationFragment[],
  pagination?: PaginationFragment,
  timezone = 'UTC',
): Results {
  return {
    cursor: pagination?.cursor ?? null,
    hasMore: pagination?.hasNextPage ?? false,
    items: items.map((item) => {
      const { id, isD2c, preferredLanguage, dateOfBirth } = item.member;
      const tasks = item.member.activeCareProviderTasks ?? [];

      let summary: MaybeUndefined<string>;

      if (
        item.nextTodayScheduledSession &&
        item.nextTodayScheduledSession.startTime
      ) {
        const {
          startTime,
          scheduledSessionType,
        } = item.nextTodayScheduledSession;

        let sessionLabel;
        switch (scheduledSessionType) {
          case ScheduledSessionType.FOLLOW_UP:
            sessionLabel = 'Follow-up';
            break;
          case ScheduledSessionType.INITIAL_CONSULT:
            sessionLabel = 'Discovery';
            break;
          case ScheduledSessionType.DROP_IN:
            sessionLabel = 'Drop-in';
            break;
          default:
            sessionLabel = '';
        }

        summary = `${sessionLabel} @ ${formatDatetimeStr(
          startTime,
          'LT z',
          timezone,
        )}`;
      }

      return {
        conversationId: item.conversationStats?.id,
        conversationStats: item.conversationStats ?? undefined,
        id,
        isD2c: Boolean(isD2c),
        isFrench: isFrenchUser(preferredLanguage),
        isSpanish: isSpanishUser(preferredLanguage),
        isTeen: isTeen(dateOfBirth),
        memberCoachChannelId: item.conversationStats?.memberCoachChannelId,
        name: formatMemberName(item.member),
        scheduledSessionFormat:
          item?.nextTodayScheduledSession?.scheduledSessionFormat,
        scheduledSessionType:
          item?.nextTodayScheduledSession?.scheduledSessionType,
        summary,
        taskDetails: getTaskDetails(tasks),
        todayScheduledSessionEndTime: item.nextTodayScheduledSession?.endTime,
        todayScheduledSessionStartTime:
          item.nextTodayScheduledSession?.startTime,
      };
    }),
  };
}

export function gqlTodaysAppointmentResponseToInboxItem(
  items: AppointmentItems[],
  pagination?: PaginationFragment,
  timezone = 'UTC',
): Results {
  return {
    cursor: pagination?.cursor ?? null,
    hasMore: pagination?.hasNextPage ?? false,
    items: items.map((item) => {
      const { member, start, type } = item.appointment;

      if (!member) throw new Error('Member detail was not loaded.');

      let summary;
      if (type) {
        const isFollowUp = [
          ClinicalAppointmentType.PSYCHIATRY_PROGRESS,
          ClinicalAppointmentType.THERAPY_PROGRESS,
        ].includes(type);
        summary = `${
          isFollowUp ? 'Follow-up' : 'Discovery Session'
        } @ ${formatDatetimeStr(start, 'LT z', timezone)}`;
      }
      return {
        id: member.id,
        isD2c: Boolean(member.isD2c),
        isFrench: isFrenchUser(member.preferredLanguage),
        isSpanish: isSpanishUser(member.preferredLanguage),
        isTeen:
          member.guardianRelationship?.guardianRelationshipType ===
          GuardianRelationshipType.DEPENDENT,
        name: formatMemberName(member),
        summary,
      };
    }),
  };
}

export function gqlTasksResponseToInboxItem(
  items: (TaskTodayFragment & {
    conversationStats?: ConversationStatsFragment | null;
  })[],
  pagination?: PaginationFragment,
  timezone = 'UTC',
  isRiskItem = false,
): Results {
  return {
    cursor: pagination?.cursor ?? null,
    hasMore: pagination?.hasNextPage ?? false,
    items: items.map((item) => {
      const {
        member,
        tasks,
        latestTaskDatetime,
        latestCompletedTaskDatetime,
      } = item;
      if (!member) throw new Error('Member detail was not loaded.');
      const mappedItem: InboxItem = {
        conversationId: item.conversationStats?.id,
        conversationStats: item.conversationStats ?? undefined,
        id: member.id,
        isD2c: Boolean(member.isD2c),
        isFrench: isFrenchUser(member.preferredLanguage),
        isSpanish: isSpanishUser(member.preferredLanguage),
        isTeen:
          member.guardianRelationship?.guardianRelationshipType ===
          GuardianRelationshipType.DEPENDENT,
        memberCoachChannelId: item.conversationStats?.memberCoachChannelId,
        name: formatMemberName(member),
      };
      const riskTasks = getRiskTasks(tasks);
      if (isRiskItem && riskTasks.length > 0) {
        mappedItem.showNewRiskTaskIndicator = shouldShowNewRiskTaskIndicator(
          riskTasks,
        );
        mappedItem.riskTaskDetails = getTaskDetails(
          riskTasks,
          latestTaskDatetime,
          latestCompletedTaskDatetime,
        );
      } else {
        mappedItem.taskDetails = getTaskDetails(tasks);
      }
      return mappedItem;
    }),
  };
}

export function isRiskTask(task: CareProviderTaskFragment): boolean {
  const { reasonForCreation, priority } = task;
  return (
    (!!reasonForCreation && RISK_ALERT_REASONS.includes(reasonForCreation)) ||
    (!!priority && isTaskHighPriority(priority))
  );
}
export function getRiskTasks(
  tasks: Array<CareProviderTaskFragment>,
): Array<CareProviderTaskFragment> {
  return tasks.filter(isRiskTask);
}

export function shouldShowNewRiskTaskIndicator(
  tasks: Array<CareProviderTaskFragment>,
): boolean {
  return !getRiskTasks(tasks).every(({ hasViewed }) => hasViewed);
}

export const getCoachTodaysInboxVariables = (
  timezone: string,
  values: Partial<CoachTodaysInboxVariables> = {},
): CoachTodaysInboxVariables => {
  const pagination = {
    cursor: null,
    maxItemsPerPage: COACH_INBOX_MAX_ITEMS_PER_PAGE,
  };
  const defaultVault: CoachTodaysInboxVariables = {
    activeTasksPagination: pagination,
    includeActiveTasks: false,
    includeCompletedTasks: false,
    includeConvo: false,
    includeRiskTasks: false,
    includeScheduledCheckin: false,
    openConvoPagination: pagination,
    pagination,
    riskTasksPagination: pagination,
    scheduledCheckinPagination: pagination,
    timezone,
  };
  return { ...defaultVault, ...values };
};

export const getClinicianTodaysInboxVariables = (
  values: Partial<ClinicianTodaysInboxVariables> = {},
): ClinicianTodaysInboxVariables => {
  const pagination = {
    cursor: null,
    maxItemsPerPage: CLINICIAN_INBOX_MAX_ITEMS_PER_PAGE,
  };
  const defaultVault: ClinicianTodaysInboxVariables = {
    activeTasksPagination: pagination,
    appointmentPagination: pagination,
    includeActiveTasks: false,
    includeAppointments: false,
    includeCompletedTasks: false,
    includeRiskTasks: false,
    pagination,
    riskTasksPagination: pagination,
  };
  return { ...defaultVault, ...values };
};

export function removeMembersWithActiveTasks(
  items: TodaysCoachingConversationFragment[],
) {
  return items.filter(({ member: { activeCareProviderTasks } }) => {
    const todaysTasks = (activeCareProviderTasks ?? []).filter(
      ({ reasonForCreation }) =>
        reasonForCreation &&
        !SYSTEM_REMINDER_TASK_REASONS.includes(reasonForCreation),
    );
    return todaysTasks.length === 0;
  });
}
export function removeMembersWithOpenConversation(
  items: CoachTodaysInbox_completedTasks[],
) {
  return items.filter(
    ({ conversationStats }) =>
      conversationStats?.state === ChatConversationState.DONE,
  );
}

export function mergeClosedConversationAndCompletedTaskAndSort(
  closedConvo: CoachTodaysInbox_closedConversations_items[],
  completedTasks: CoachTodaysInbox_completedTasks[],
  timezone: string,
): InboxItem[] {
  const conversationStateMap = [
    ...closedConvo.map((_) => _.conversationStats),
    ...completedTasks
      .filter((_) => _.conversationStats)
      .map((_) => _.conversationStats!),
  ].reduce((prev, convoStat) => {
    return {
      ...prev,
      [convoStat.memberCoachChannelId]: {
        state: convoStat.state,
        stateLastUpdatedAt: convoStat.stateLastUpdatedAt,
      },
    };
  }, {} as ConversationStateMap);
  const { items: closedConvoItems } = gqlTodaysCoachingConvoResponseToInboxItem(
    removeMembersWithActiveTasks(closedConvo ?? []),
    undefined,
    timezone,
  );
  const { items: completedTaskItems } = gqlTasksResponseToInboxItem(
    removeMembersWithOpenConversation(completedTasks ?? []),
    undefined,
    timezone,
  );
  const inboxItemsMap: Record<string, InboxItem> = {};

  [...closedConvoItems, ...completedTaskItems].forEach((item) => {
    if (!inboxItemsMap[item.id]) inboxItemsMap[item.id] = item;
    inboxItemsMap[item.id] = { ...inboxItemsMap[item.id], ...item };
  });

  return Object.values(inboxItemsMap).sort(
    todaysCompletedInboxItemsComparedFn(conversationStateMap),
  );
}

function getTaskPreview(tasks: CareProviderTaskFragment[]): string | undefined {
  if (tasks.length === 0) return;
  const [taskTodayFragmentTask, ...remainingTasks] = tasks;
  const label = displayTaskLabel(
    taskTodayFragmentTask.reasonForCreation ?? 'unknown ',
    taskTodayFragmentTask.relatedCareProviderName ?? '',
  );
  return `${label} ${
    remainingTasks.length > 0 ? `+ ${remainingTasks.length} task(s)` : ''
  }`.trim();
}

function getTaskDetails(
  param: TaskTodayFragment_tasks[],
  latestTaskDatetime?: string | null,
  latestCompletedTaskDatetime?: string | null,
): InboxItem['taskDetails'] {
  const compareFn = (
    a: TaskTodayFragment_tasks,
    b: TaskTodayFragment_tasks,
  ) => {
    if ((b.priority ?? 0) - (a.priority ?? 0) !== 0) {
      return (b.priority ?? 0) - (a.priority ?? 0);
    }
    const timestampA = new Date(a?.createdAt ?? 0).getTime();
    const timestampB = new Date(b?.createdAt ?? 0).getTime();
    return timestampB - timestampA;
  };
  if (param.length === 0) return;
  const highestPriority = Math.max(...param.map((_) => _.priority ?? 0));
  const latestDatetime = Math.max(
    ...param.map((_) => new Date(_.createdAt ?? 0).getTime()),
  );
  const tasks = [...param].sort(compareFn);
  return {
    highestPriority,
    latestCompletedTaskDatetime: latestCompletedTaskDatetime ?? undefined,
    latestCreatedTaskDatetime:
      latestTaskDatetime ?? new Date(latestDatetime).toISOString(),
    previewText: getTaskPreview(tasks),
    tasks,
  };
}

export const isTaskHighPriority = (priority: number): boolean => {
  return priority >= 20000;
};

export const getConversationStateMapFromInboxItem = (
  inboxItem: InboxItem,
): ConversationStateMap => {
  const { memberCoachChannelId, conversationStats } = inboxItem;
  return memberCoachChannelId && conversationStats
    ? {
        [memberCoachChannelId]: {
          state: conversationStats.state,
          stateLastUpdatedAt: conversationStats.stateLastUpdatedAt,
        },
      }
    : {};
};

export const getChannelMemberMapFromInboxItem = (
  inboxItem: InboxItem,
): ChannelIdMemberIdMap => {
  const { memberCoachChannelId, id } = inboxItem;
  return memberCoachChannelId
    ? {
        [memberCoachChannelId]: id,
      }
    : {};
};

export const extractStateFromInboxItems = (
  inboxItems: InboxItem[],
): ExtractedStateFromInboxItem => {
  const initialAccumValue = {
    channelMemberMap: {},
    conversationStateMap: {},
  };
  return inboxItems.reduce<ExtractedStateFromInboxItem>(
    (accum, currentInboxItem) => {
      const { memberCoachChannelId } = currentInboxItem;
      if (!memberCoachChannelId) return accum;
      accum.channelMemberMap = {
        ...accum.channelMemberMap,
        ...getChannelMemberMapFromInboxItem(currentInboxItem),
      };
      accum.conversationStateMap = {
        ...accum.conversationStateMap,
        ...getConversationStateMapFromInboxItem(currentInboxItem),
      };
      return accum;
    },
    initialAccumValue,
  );
};
