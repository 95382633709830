/**
 * Our Amplitude client used to automatically add the "clinical_hub_" prefix to all event names before
 * we renamed the system to "Care Hub." Now that we call it "Care Hub," we're using "care_hub_" as our
 * default prefix. To avoid changing the event names and breaking existing reports, we've added "clinical_hub_"
 * to all events that existed prior to the renaming.
 *
 * We should use the prefix "care_hub_" for all new events.
 */
export enum EventType {
  TASK_TAB_VIEWED = 'clinical_hub_task_tab_viewed',
  TASK_LIST_VIEWED = 'clinical_hub_task_list_viewed',
  APPOINTMENT_TAB_VIEWED = 'clinical_hub_appointment_tab_viewed',
  APPOINTMENT_VIEWED = 'clinical_hub_appointment_viewed',
  APPOINTMENT_UPDATED = 'clinical_hub_appointment_updated',
  APPOINTMENT_CREATE_VIEWED = 'clinical_hub_appointment_create_viewed',

  MEMBER_TAB_VIEWED = 'clinical_hub_member_tab_viewed',
  MEMBER_LIST_VIEWED = 'clinical_hub_member_list_viewed',
  PATIENT_SUMMARY_TAB_CLICKED = 'clinical_hub_patient_summary_clicked',
  PATIENT_SUMMARY_VIEWED = 'clinical_hub_patient_summary_viewed',
  PATIENT_INTAKE_TAB_CLICKED = 'clinical_hub_patient_intake_clicked',
  PATIENT_INTAKE_TAB_VIEWED = 'clinical_hub_patient_intake_viewed',
  PATIENT_SURVEY_TAB_CLICKED = 'clinical_hub_patient_survey_clicked',
  PATIENT_SURVEY_TAB_VIEWED = 'clinical_hub_patient_survey_viewed',
  PATIENT_APPOINTMENT_TAB_VIEWED = 'clinical_hub_patient_appointment_tab_viewed',
  PATIENT_APPOINTMENTS_TABLE_VIEWED = 'clinical_hub_patient_appointments_table_viewed',
  PHQ9_CHART_DROPDOWN_SELECTED = 'clinical_hub_phq9_chart_dropdown_selected',
  GAD7_CHART_DROPDOWN_SELECTED = 'clinical_hub_gad7_chart_dropdown_selected',
  GAD7_CHART_HOVERED = 'clinical_hub_patient_gad7_chart_hovered',
  PHQ9_CHART_HOVERED = 'clinical_hub_patient_phq9_chart_hovered',
  PATIENT_NAME_CLICKED = 'clinical_hub_patient_name_click',
  MARK_TASK_COMPLETE = 'clinical_hub_task_list_complete',
  MEMBER_SEARCH_SCREEN_VIEWED = 'clinical_hub_member_search_viewed',
  MEMBER_PROFILE_UPDATED = 'clinical_hub_member_profile_updated',
  MEMBER_PROFILE_VIEWED = 'clinical_hub_member_profile_viewed',
  DEPENDENT_REPORT_VIEWED = 'clinical_hub_member_tab_viewed',
  MEMBER_SCREEN_VIEWED = 'clinical_hub_member_screen_viewed',
  NAV_LINK_CLICKED = 'clinical_hub_navigation_tab_clicked',
  TOOLTIP_VIEWED = 'clinical_hub_tooltip_viewed',
  BANNER_LINK_CLICKED = 'clinical_hub_banner_link_clicked',

  CARE_HUB_PROVIDER_IDLE_BROWSER_TAB_TIMEOUT = 'care_hub_provider_idle_browser_tab_timeout',

  // Schedule Amplitude signals
  SCHEDULE_CALENDAR_VIEWED = 'clinical_hub_schedule_calendar_viewed',
  SCHEDULE_APPOINTMENT_LIST_VIEWED = 'clinical_hub_schedule_apptlist_viewed',
  SCHEDULE_APPOINTMENT_LIST_APPT_CLICKED = 'clinical_hub_schedule_apptlist_appt_clicked',
  SCHEDULE_APPOINTMENT_LIST_MS_VIEWED = 'clinical_hub_schedule_apptlist_ms_viewed',
  SCHEDULE_CALENDAR_NEW_APPT_CLICKED = 'clinical_hub_schedule_calendar_new_appt_clicked',
  SCHEDULE_CALENDAR_NEW_EVENT_CLICKED = 'clinical_hub_schedule_calendar_new_event_clicked',
  SCHEDULE_NEW_APPOINTMENT_SAVE_CLICKED = 'clinical_hub_schedule_new_appointment_save_clicked',
  SCHEDULE_NEW_EVENT_CREATE_CLICKED = 'clinical_hub_schedule_new_event_save_clicked',
  SCHEDULE_EVENT_UPDATE_CLICKED = 'clinical_hub_schedule_event_update_clicked',
  SCHEDULE_APPOINTMENT_LIST_NEW_APPT_CLICKED = 'clinical_hub_schedule_applist_new_appt_clicked',
  SCHEDULE_APPOINTMENT_LIST_MEMBER_FILTER_CLICKED = 'clinical_hub_schedule_applist_member_filter_clicked',
  SCHEDULE_EVENT_DOUBLE_CLICKED = 'clinical_hub_schedule_event_double_clicked',
  SCHEDULE_APPOINTMENT_DOUBLE_CLICKED = 'clinical_hub_schedule_appointment_double_clicked',
  SCHEDULE_NEW_EVENT_VIEWED = 'clinical_hub_schedule_new_event_viewed',
  SCHEDULE_EVENT_VIEWED = 'clinical_hub_schedule_event_viewed',
  SCHEDULE_CALENDAR_FILTER_CLICK = 'clinical_hub_schedule_filter_click',
  SCHEDULE_CALENDAR_FILTER_APPOINTMENTS_CLICK = 'clinical_hub_schedule_filter_appointments_click',
  SCHEDULE_CALENDAR_FILTER_AVAILABILITY_CLICK = 'clinical_hub_schedule_filter_availability_click',
  SCHEDULE_CALENDAR_FILTER_EVENTS_CLICK = 'clinical_hub_schedule_filter_events_click',
  SCHEDULE_CALENDAR_FILTER_GOOGLE_EVENTS_CLICK = 'clinical_hub_schedule_filter_google_events_click',
  SCHEDULE_APPOINTMENT_RECURRING_CLICK = 'clinical_hub_schedule_appointment_recurring_click',
  SCHEDULE_APPOINTMENT_RECURRING_DOESNOTREPEAT_CLICK = 'clinical_hub_schedule_appointment_recurring_doesnotrepeat_click',
  SCHEDULE_APPOINTMENT_RECURRING_WEEKLY_CLICK = 'clinical_hub_schedule_appointment_recurring_weekly_click',
  SCHEDULE_APPOINTMENT_RECURRING_BIWEEKLY_CLICK = 'clinical_hub_schedule_appointment_recurring_biweekly_click',
  SCHEDULE_APPOINTMENT_RECURRING_MONTHLY_CLICK = 'clinical_hub_schedule_appointment_recurring_monthly_click',
  SCHEDULE_LIST_THERAPY_INTAKE_CLICK = 'clinical_hub_schedule_apptlist_therapy_intake_clicked',
  SCHEDULE_LIST_THERAPY_PROGRESS_CLICK = 'clinical_hub_schedule_apptlist_therapy_progress_clicked',
  SCHEDULE_LIST_PSYCHIATRY_INTAKE_CLICK = 'clinical_hub_schedule_apptlist_psychiatry_intake_clicked',
  SCHEDULE_LIST_PSYCHIATRY_PROGRESS_CLICK = 'clinical_hub_schedule_apptlist_psychiatry_progress_clicked',
  SCHEDULE_MEMBER_LIST_NEW_APPOINTMENT_CLICK = 'clinical_hub_schedule_member_apptlist_new_appointment_clicked',

  // OOS/termination note Amplitude signals
  NOTE_TAB_VIEWED = 'clinical_hub_note_tab_viewed',
  CLICK_NEW_BUTTON = 'clinical_hub_non_appointment_note_button_clicked',
  SELECT_NON_APPOINTMENT_NOTE_TEMPLATE_FROM_DROPDOWN = 'clinical_hub_select_non_appointment_note_template_from_dropdown',
  VIEW_OUT_OF_SESSION_NOTE = 'clinical_hub_viewed_out_of_session_note',
  REASON_FOR_CONTACT_SELECTED = 'clinical_hub_out_of_session_note_reason_for_contact_selected',
  REASON_FOR_TERMINATION_SELECTED = 'clinical_hub_termination_note_reason_for_termination_clicked',
  CLICKED_SIGN_AND_LOCK_OUT_OF_SESSION_NOTE = 'clinical_hub_clicked_sign_and_lock_out_of_session_note',
  CLICKED_SAVE_DRAFT_OUT_OF_SESSION_NOTE = 'clinical_hub_clicked_save_draft_out_of_session_note',
  CLICKED_SIGN_AND_LOCK_TERMINATION_NOTE = 'clinical_hub_clicked_sign_and_lock_termination_note',
  CLICKED_SAVE_DRAFT_TERMINATION_NOTE = 'clinical_hub_clicked_save_draft_termination_note',
  CLICKED_NOTE_LIST_ACTION_BUTTON_NOTE = 'clinical_hub_clicked_note_list_action_button_note',

  // document upload
  DOCUMENTS_TAB_CLICK = 'clinical_hub_documents_tab_click',
  DOCUMENTS_TAB_VIEW = 'clinical_hub_documents_tab_view',
  DOCUMENTS_FILE_UPLOAD_CLICK = 'clinical_hub_documents_file_upload_click',
  DOCUMENTS_UPLOAD_CLICK = 'clinical_hub_documents_upload_click',
  DOCUMENTS_OPEN_DOCUMENT_CLICK = 'clinical_hub_documents_open_document_click',
  DOCUMENTS_DOWNLOAD_CLICK = 'clinical_hub_documents_download_click',
  DOCUMENTS_EDIT_CLICK = 'clinical_hub_documents_edit_click',
  DOCUMENTS_DELETE_CLICK = 'clinical_hub_documents_delete_click',
  DOCUMENTS_PRINT_CLICK = 'clinical_hub_documents_print_click',
  DOCUMENTS_PREVIEW_CLICK = 'clinical_hub_documents_name_click',

  // For mutations that use global state
  ITEM_MODIFIED = 'clinical_hub_item_modified',

  // Collaboration
  COLLABORATION_MESSAGE_MORE_CLICK = 'clinical_hub_collaboration_message_more_clicked',
  COLLABORATION_TAB_VIEW = 'clinical_hub_collaboration_tab_viewed',
  COLLABORATION_NEW_MESSAGE_CLICK = 'clinical_hub_collaboration_new_message_clicked',
  COLLABORATION_NEW_MESSAGE_SEND_CLICK = 'clinical_hub_collaboration_new_message_send_clicked',
  COLLABORATION_NEW_MESSAGE_CANCEL_CLICK = 'clinical_hub_collaboration_new_message_cancel_clicked',
  COLLABORATION_EMPTY_VIEW = 'clinical_hub_collaboration_empty_viewed',
  COLLABORATION_TAB_CLICK = 'clinical_hub_collaboration_tab_clicked',

  // Care Metrics
  CLINICIAN_ANALYTICS_VIEWED = 'clinical_hub_clinician_analytics_viewed',
  WEEKLY_BREAKDOWN_VIEW_CLICKED = 'clinical_hub_weekly_breakdown_view_clicked',
  CARE_METRICS_MONTHLY_PICKER_CLICKED = 'clinical_hub_care_metrics_monthly_picker_clicked',
  GOOGLE_CALENDAR_LINK_CLICKED = 'clinical_hub_google_calendar_link_clicked',
  FEEDBACK_CAROUSAL_CLICKED = 'clinical_hub_feedback_carousal_clicked',

  // Coach and Clinician Notes
  CARE_PROVIDER_NOTES_EVENT = 'clinical_hub_care_provider_notes_event',
  CARE_PROVIDER_NOTES_INTERACTION_COMPLETED = 'care_hub_care_provider_notes_interaction_completed',

  // Inbox
  INBOX_TAB_CLICKED = 'care_hub_inbox_tab_clicked',
  MEMBER_ITEM_CLICKED = 'care_hub_member_name_clicked',
  OPEN_NEW_MEMBER_CHART_TAB = 'care_hub_opened_new_member_chart_tab',
  MEMBER_CHART_TAB_CLICKED = 'care_hub_member_chart_tab_clicked',
  MEMBER_CHART_TAB_CLOSED = 'care_hub_member_chart_tab_closed',
  INBOX_MENU_BUTTON_CLICKED = 'care_hub_inbox_menu_button_clicked',
  INBOX_MEMBER_LIST_LOADED = 'care_hub_inbox_member_list_loaded',
  INBOX_MEMBER_LIST_PAGINATION = 'care_hub_inbox_member_list_load_more_items',
  INBOX_MEMBER_SEARCHED = 'care_hub_inbox_member_searched',

  // Chat Amplitude signals (coming from the listener)
  LISTENER_MSG_FOCUS = 'listener_msg_focus',
  LISTENER_MSG_TYPING = 'listener_msg_typing',
  LISTENER_CHAT_ERROR = 'listener_chat_error',
  LISTENER_CHAT_READ = 'listener_chat_read',
  LISTENER_SENT_LINK = 'listener_sent_link',
  LISTENER_CHAT_WRITE = 'listener_chat_write',

  // Care Team Signals
  ADD_SI_COACH_BUTTON_STATUS_CHANGED = 'care_hub_add_si_coach_button_status_changed',
  CARE_TEAM_CARD_CLICKED = 'care_hub_care_team_card_clicked',
  CARE_TEAM_ADD_MEMBER_SUPPORT_CLICKED = 'care_hub_care_team_add_member_support_clicked',
  CARE_TEAM_ESCALATE_TO_CLINICAL_CLICKED = 'care_hub_care_team_escalate_to_clinical_clicked',
  CARE_TEAM_MEMBER_PROFILE_VIEWED = 'care_hub_care_team_member_profile_viewed',
  CARE_TEAM_TOGGLE_PAST_MEMBERS_CLICKED = 'care_hub_care_team_toggle_past_members_clicked',

  // Structured Coaching Signals
  ENABLE_DROP_IN_NOTIFICATION_DISPLAYED = 'enable_drop_in_notification_displayed',
  DISABLE_DROP_IN_NOTIFICATION_DISPLAYED = 'disable_drop_in_notification_displayed',
  COACH_SHIFT_STATUS_CHANGED = 'coach_shift_status_changed',

  // Content Related signals
  VIEW_LIBRARY_BUTTON_CLICKED = 'care_hub_view_library_button_clicked',
  ADD_CONTENT_CIRCLE_BUTTON_CLICKED = 'care_hub_add_content_circle_button_clicked',
  CONTENT_URL_PASTED_INTO_MODAL = 'care_hub_content_url_pasted_into_modal',
  ADD_HOMEWORK_BUTTON_CLICKED = 'care_hub_add_homework_button_clicked',
  INVALID_CONTENT_URL_ADDED = 'care_hub_invalid_content_url_added',
  UNASSIGN_BUTTON_CLICKED = 'care_hub_unassign_button_clicked',

  VAULT_LOGIN_COMPLETED = 'care_hub_vault_login_completed',
  PAGE_LOAD = 'care_hub_page_load',
  USER_IDLE_TIMEOUT = 'care_hub_user_idle_timeout',
}
