import { useAppState } from 'app/state';
import { logCareHubProviderIdleBrowserTabTimeout } from 'app/state/amplitude/actions/page';
import { useEffect, useState } from 'react';
import { useIdleTimer } from 'react-idle-timer';
import { useDispatch } from 'react-redux';

const IDLE_PAGE_TIMEOUT = 1000 * 60 * 60 * 24; // 24 hours

export default function IdlePageTimerLogger() {
  const dispatch = useDispatch();
  const [isIdle, setIsIdle] = useState<boolean>(false);

  const userInfo = useAppState((_) => {
    return {
      role: _.user.role,
      shiftStatus: _.user.shiftStatus,
    };
  });

  const onIdle = () => {
    setIsIdle(true);
  };

  const onActive = () => {
    setIsIdle(false);
  };

  const { getLastActiveTime } = useIdleTimer({
    onActive,
    onIdle,
    startOnMount: true,
    throttle: 500,
    timeout: IDLE_PAGE_TIMEOUT,
  });

  useEffect(() => {
    if (isIdle) {
      const activeTime = getLastActiveTime();

      dispatch(
        logCareHubProviderIdleBrowserTabTimeout({
          careHubVersion: window.careHubVersion,
          lastActiveTime: new Date(activeTime),
          ...userInfo,
        }),
      );
    }
  }, [isIdle, userInfo, dispatch, getLastActiveTime]);

  return <></>;
}
