import { ReactComponent as MagicDisabledIcon } from 'assets/magic-disabled-icon.svg';
import { ReactComponent as MagicEnabledIcon } from 'assets/magic-enabled-icon.svg';

import styles from './AiMagicIcon.module.scss';

export enum AiMagicIconState {
  ACTIVE = 'active',
  INACTIVE = 'inactive',
}

export function AiMagicIcon({
  state,
  standalone = false,
}: {
  state?: AiMagicIconState;
  standalone?: boolean;
}) {
  return state === AiMagicIconState.ACTIVE ? (
    <MagicEnabledIcon data-testid="enabled-magic-icon" />
  ) : (
    <MagicDisabledIcon
      data-testid="disabled-magic-icon"
      className={standalone ? styles.standaloneIconDisabled : undefined}
    />
  );
}
