import { GAD7Severity } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetMemberSurvey_getMember_surveyResponses_gad7s_surveys as GAD7Surveys } from '@headspace/carehub-graphql/dist/queries/generated/GetMemberSurvey';
import { useAppState } from 'app/state';
import warningIcon from 'assets/warning-icon.svg';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { isNull } from 'lodash';
import React, { Fragment } from 'react';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';
import { classNameCombiner } from 'utils';
import { fromUTCToLocal } from 'utils/dateTime';

import ChangeIcon from './ChangeIcon';
import styles from './GAD7Info.module.scss';
import headerStyles from './header.module.scss';
import { GAD7SeverityMap } from './utils';

interface GAD7InfoProps {
  gad7Latest: GAD7Surveys | null | undefined;
  gad7Previous: GAD7Surveys | null | undefined;
}

/** GAD7 Order
 * Anxiety
 * Control
 * Worry
 * Relaxation
 * Restlessness
 * Irritation
 * Fear
 */
const GAD7Info = ({ gad7Latest, gad7Previous }: GAD7InfoProps) => {
  const timezone = useAppState((_) => _.user.timezone) || 'UTC';
  const {
    enable_tooltip_label_underline,
  } = useFeatureFlags().transientFeatureFlags;
  if (gad7Latest) {
    const { severity } = gad7Latest;
    const severityString = severity ? GAD7SeverityMap[severity] : undefined;
    const showWarning =
      severity === GAD7Severity.MODERATE || severity === GAD7Severity.SEVERE;
    const totalComponent =
      !isNull(gad7Latest.total) && severityString ? (
        <p
          className={classNameCombiner([
            styles.main,
            styles.total,
            enable_tooltip_label_underline ? headerStyles.underline : '',
          ])}
          data-testid="gad-severity"
        >
          {showWarning ? (
            <img
              className={styles.icon}
              alt="Warning: GAD7 Moderate or above"
              src={warningIcon}
            />
          ) : null}

          <span>{`${severityString} (${gad7Latest.total}/21)`}</span>
        </p>
      ) : undefined;
    const timestampComponent = gad7Latest.timestamp ? (
      <p className={classNameCombiner([styles.main, styles.timestamp])}>
        <ChangeIcon
          latestScore={gad7Latest.total}
          previousScore={gad7Previous?.total}
        />
        <span className={styles.content}>
          {fromUTCToLocal(gad7Latest.timestamp, timezone).format(
            'MMM DD, YYYY h:mma z',
          )}
        </span>
      </p>
    ) : undefined;
    return (
      <div className={styles.GAD7Info} data-testid="gad7info">
        <p className={[styles.main, styles.title].join(' ')}>GAD</p>
        <TooltipWithRedux
          labelForAnalytics="Member Header: GAD7Info"
          title={
            <div>
              {!isNull(gad7Latest.anxiety) ? (
                <>
                  <p className={styles.tooltipLine}>
                    <span>Anxiety:</span>
                    <b>{gad7Latest.anxiety}</b>
                    <ChangeIcon
                      latestScore={gad7Latest.anxiety}
                      previousScore={gad7Previous?.anxiety}
                    />
                  </p>
                </>
              ) : undefined}
              {!isNull(gad7Latest.control) ? (
                <>
                  <p className={styles.tooltipLine}>
                    <span>Control:</span>
                    <b>{gad7Latest.control}</b>
                    <ChangeIcon
                      latestScore={gad7Latest.control}
                      previousScore={gad7Previous?.control}
                    />
                  </p>
                </>
              ) : undefined}
              {!isNull(gad7Latest.worry) ? (
                <>
                  <p className={styles.tooltipLine}>
                    <span>Worry:</span>
                    <b>{gad7Latest.worry}</b>
                    <ChangeIcon
                      latestScore={gad7Latest.worry}
                      previousScore={gad7Previous?.worry}
                    />
                  </p>
                </>
              ) : undefined}
              {!isNull(gad7Latest.relaxation) ? (
                <>
                  <p className={styles.tooltipLine}>
                    <span>Relaxation:</span>
                    <b>{gad7Latest.relaxation}</b>
                    <ChangeIcon
                      latestScore={gad7Latest.relaxation}
                      previousScore={gad7Previous?.relaxation}
                    />
                  </p>
                </>
              ) : undefined}
              {!isNull(gad7Latest.restlessness) ? (
                <>
                  <p className={styles.tooltipLine}>
                    <span>Restlessness:</span>
                    <b>{gad7Latest.restlessness}</b>
                    <ChangeIcon
                      latestScore={gad7Latest.restlessness}
                      previousScore={gad7Previous?.restlessness}
                    />
                  </p>
                </>
              ) : undefined}
              {!isNull(gad7Latest.irritation) ? (
                <>
                  <p className={styles.tooltipLine}>
                    <span>Irritation:</span>
                    <b>{gad7Latest.irritation}</b>
                    <ChangeIcon
                      latestScore={gad7Latest.irritation}
                      previousScore={gad7Previous?.irritation}
                    />
                  </p>
                </>
              ) : undefined}
              {!isNull(gad7Latest.fear) ? (
                <>
                  <p className={styles.tooltipLine}>
                    <span>Fear:</span>
                    <b>{gad7Latest.fear}</b>
                    <ChangeIcon
                      latestScore={gad7Latest.fear}
                      previousScore={gad7Previous?.fear}
                    />
                  </p>
                </>
              ) : undefined}
            </div>
          }
        >
          <div>{totalComponent}</div>
        </TooltipWithRedux>
        {timestampComponent}
      </div>
    );
  }
  // if no info, return empty JSX fragment
  return <Fragment />;
};

export default GAD7Info;
