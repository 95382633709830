import { TimeTokensAndStamps } from 'app/coach/chat/types';
import { getDataOnChagesInTimetokens } from 'app/coach/chat/utils';
import {
  RefetchHistoryActionPayload,
  ServerRPC,
  UpdateConvosAfterRefetchPayload,
} from 'app/coach/pubnub/types';
import { updateTimetokens } from 'app/state/features/conversationTimetokens/conversationTimetokensSlice';
import { refetchHistory } from 'app/state/inbox/actions';

import { ActionHandler } from './types';
import { GetTimetokensForConversations } from '@headspace/carehub-graphql/dist/state/ConversationTimetokensQueries';
import {
  getInboxConversationTimetokens,
  getInboxConversationTimetokensVariables,
} from '@headspace/carehub-graphql/dist/state/generated/getInboxConversationTimetokens';

/* This handler is triggered whenever the server signals about SOME changes in the inbox items.
There's three groups of possible changes:
1. some of the time tokens / time stamps have changed
2. conversation state has been changed
3. messages history is outdated
The purpose of this handler is to:
1. compare the time tokens and stamps against the existing ones and update if necessary
2. build the refetchHistory payload (since the change of the last listener read and last member read is a signal to update the statuses of messages and unread messages accordingly)
3. call the updateConversationState reducer that conditionally updates the state (if it indeed has changed) */
export const onUpdateConversationsBasedOnRefetch = async ({
  action,
  redux,
  context,
}: ActionHandler<UpdateConvosAfterRefetchPayload>) => {
  const { RPC, memberIds, timetokensMap } = action.payload;
  const { logger, apollo } = context.services;
  const { messagesMap } = redux.getState().inbox;
  // a temp solution to keep action handlers working w/ RTK during migration;
  try {
    const { data, error } = await apollo.query<
      getInboxConversationTimetokens,
      getInboxConversationTimetokensVariables
    >({
      fetchPolicy: 'network-only',
      query: GetTimetokensForConversations,
      variables: { memberIds },
    });
    if (!data.getInboxConversationByIds || error) {
      logger.error(
        new Error(
          'inboxActionHandlers.updateConversationsBasedOnRefetch: Unable to fetch conversation timetokens: unexpected getInboxConversationByIds value; action handler',
          { cause: error },
        ),
        {
          error,
          memberIds,
        },
      );
      return;
    }
    // an array of objects that contain channelId and time tokens and stamps that has changed
    const updatedTimetokensAndTimestamps: Array<{
      timetokens: Partial<TimeTokensAndStamps>;
      channelId: string;
    }> = [];
    const convosWithDataOnUpdates = data.getInboxConversationByIds.map(
      (newConversationData) => {
        const { conversationStats } = newConversationData;
        if (!conversationStats) {
          context.services.logger.error(
            new Error(
              `updateConversationsBasedOnRefetch got invalid timetokens`,
            ),
            {
              conversationStats,
            },
          );
          return;
        }
        const { memberCoachChannelId } = conversationStats;
        // modify it by checking the time stamp as well
        const {
          hasLastMemberReadChanged,
          updatedConvoStatsData,
          hasLastListenerReadChanged,
          hasLastMemberWriteChanged,
        } = getDataOnChagesInTimetokens({
          existingTimetokens: timetokensMap[memberCoachChannelId],
          newTimetokens: conversationStats,
        });
        if (updatedConvoStatsData)
          updatedTimetokensAndTimestamps.push({
            channelId: memberCoachChannelId,
            timetokens: { ...updatedConvoStatsData },
          });
        return {
          channelId: memberCoachChannelId,
          hasLastListenerReadChanged,
          hasLastMemberReadChanged,
          hasLastMemberWriteChanged,
          lastListenerReadTimeToken: hasLastListenerReadChanged
            ? conversationStats.lastListenerReadTimeToken
            : timetokensMap[memberCoachChannelId].lastListenerReadTimeToken,
          lastMemberReadTimeToken: hasLastMemberReadChanged
            ? conversationStats.lastMemberReadTimeToken
            : timetokensMap[memberCoachChannelId].lastMemberReadTimeToken,
        };
      },
    );

    // update timetokens and/ot time stamps that have been changed
    if (updatedTimetokensAndTimestamps.length > 0)
      redux.dispatch(updateTimetokens(updatedTimetokensAndTimestamps));
    // filter out convos in which last listener read and last member write time tokens have not changed and build objects for re-fetching history
    const refetchHistoryPayload: RefetchHistoryActionPayload = convosWithDataOnUpdates
      .filter((convoData) => {
        if (!convoData) return false;
        const {
          hasLastListenerReadChanged,
          hasLastMemberWriteChanged,
        } = convoData;
        return checkIfHistoryUpdateNeeded({
          RPC,
          hasLastListenerReadChanged,
          hasLastMemberWriteChanged,
        });
      })
      .reduce(
        (accumulator: RefetchHistoryActionPayload, currentConvo) => {
          if (!currentConvo) return accumulator;
          const {
            channelId,
            hasLastMemberReadChanged,
            lastMemberReadTimeToken,
            lastListenerReadTimeToken,
          } = currentConvo;
          if (channelId in messagesMap) {
            accumulator.convosMapForRefetching[channelId] = {
              hasLastMemberReadChanged,
              lastMemberReadTimeToken,
            };
          }
          // in any event add the channel to the updating unread count group
          accumulator.channelsToUpdateUnreadMessagesCount.push({
            lastListenerReadTimetoken: lastListenerReadTimeToken ?? '0',
            memberCoachChannelId: channelId,
          });

          return accumulator;
        },
        { channelsToUpdateUnreadMessagesCount: [], convosMapForRefetching: {} },
      );

    // refetch history for channels that are in the messagesMap and refresh unread count for those that aren't
    if (
      Object.keys(refetchHistoryPayload.convosMapForRefetching).length > 0 ||
      refetchHistoryPayload.channelsToUpdateUnreadMessagesCount.length > 0
    ) {
      redux.dispatch(refetchHistory(refetchHistoryPayload));
    }
  } catch (e) {
    logger.error(
      new Error(
        'inboxActionHandlers.updateConversationsBasedOnRefetch: Unable to fetch conversation timetokens: caught an error; action handler',
        { cause: e },
      ),
      {
        error: e,
        memberIds,
      },
    );
  }
};

export const checkIfHistoryUpdateNeeded = ({
  RPC,
  hasLastListenerReadChanged,
  hasLastMemberWriteChanged,
}: {
  RPC: ServerRPC;
  hasLastListenerReadChanged: boolean;
  hasLastMemberWriteChanged: boolean;
}) => {
  let isHistoryRefechNeeded: boolean = false;
  switch (RPC) {
    case ServerRPC.DIRTY_CONVERSATION: {
      isHistoryRefechNeeded = true;
      break;
    }
    case ServerRPC.NEW_MESSAGE: {
      isHistoryRefechNeeded =
        hasLastListenerReadChanged || hasLastMemberWriteChanged;
      break;
    }
  }
  return isHistoryRefechNeeded;
};
