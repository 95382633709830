import { GetMemberSurvey_getMember_surveyResponses } from '@headspace/carehub-graphql/dist/queries/generated/GetMemberSurvey';
import * as Sentry from '@sentry/react';
import { surveysV3Route as surveysV3CoachingRoute } from 'app/coach/member-chart/Routes';
import { MemberChartErrorState } from 'app/member-chart-cards/error-state/MemberChartErrorState';
import { surveysV3Route as surveysV3ClinicianRoute } from 'app/patients/Routes';
import SurveysTabV3All from 'app/patients/tabs/surveys/SurveysV3/SurveysTabV3All';
import SurveysTabV3CSSRS from 'app/patients/tabs/surveys/SurveysV3/SurveysV3CSSRS';
import SurveysTabV3GAD from 'app/patients/tabs/surveys/SurveysV3/SurveysV3GAD';
import SurveysTabV3PHQ from 'app/patients/tabs/surveys/SurveysV3/SurveysV3PHQ';
import SurveysTabV3PSS from 'app/patients/tabs/surveys/SurveysV3/SurveysV3PSS';
import SurveysTabV3RISC from 'app/patients/tabs/surveys/SurveysV3/SurveysV3RISC';
import { SurveyTab } from 'app/patients/tabs/surveys/SurveysV3/utils';
import { useAppState } from 'app/state';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useMemberSurveys } from 'hooks/useMemberSurveys';
import React from 'react';
import { Redirect, Route, useLocation } from 'react-router-dom';
import { Loader } from 'shared-components/loader/Loader';
import { isCoachOrSupervisor } from 'utils';

export const SurveyResponseRoutesV3 = (props: {
  memberId: string;
  pathPrefix: string;
}) => {
  const { memberId, pathPrefix } = props;
  const { search } = useLocation();
  const SentryRoute = Sentry.withSentryRouting(Route);
  const isCoach = useAppState((_) => isCoachOrSupervisor(_.user.role));
  const surveysV3Route = isCoach
    ? surveysV3CoachingRoute
    : surveysV3ClinicianRoute;

  const {
    enable_cssrs_score_tab,
    enable_adaptability_surveys_v3,
  } = useFeatureFlags().transientFeatureFlags;

  const { data, error, loading } = useMemberSurveys({
    loadCSSRS: enable_cssrs_score_tab,
    loadGAD7s: true,
    loadPHQ9s: true,
    loadPSS: true,
    loadQuestions: true,
    loadRisc10s: enable_adaptability_surveys_v3,
    memberId,
  });

  if (loading) return <Loader />;

  if (error) return <MemberChartErrorState error={error} />;

  const surveyResponses = data?.getMember!.surveyResponses!;

  return (
    <>
      <Route exact={true} path={surveysV3Route(pathPrefix, memberId)}>
        <Redirect
          to={surveysV3Route(pathPrefix, memberId, SurveyTab.ALL, search)}
        />
      </Route>
      <SentryRoute
        exact={true}
        path={surveysV3Route(pathPrefix, memberId, SurveyTab.ALL)}
      >
        <SurveysTabV3All
          surveyResponses={surveyResponses}
          pathPrefix={pathPrefix}
          id={memberId}
          coachingPaths={isCoach}
        />
      </SentryRoute>
      <SentryRoute
        exact={true}
        path={surveysV3Route(pathPrefix, memberId, SurveyTab.PHQ9)}
      >
        <SurveysTabV3PHQ
          surveyResponses={surveyResponses}
          pathPrefix={pathPrefix}
          id={memberId}
          coachingPaths={isCoach}
        />
      </SentryRoute>
      <SentryRoute
        exact={true}
        path={surveysV3Route(pathPrefix, memberId, SurveyTab.GAD7)}
      >
        <SurveysTabV3GAD
          surveyResponses={surveyResponses}
          pathPrefix={pathPrefix}
          id={memberId}
          coachingPaths={isCoach}
        />
      </SentryRoute>
      <SentryRoute
        exact={true}
        path={surveysV3Route(pathPrefix, memberId, SurveyTab.PSS)}
      >
        <SurveysTabV3PSS
          surveyResponses={surveyResponses}
          pathPrefix={pathPrefix}
          id={memberId}
          coachingPaths={isCoach}
        />
      </SentryRoute>
      <SentryRoute
        exact={true}
        path={surveysV3Route(pathPrefix, memberId, SurveyTab.CSSRS)}
      >
        {enable_cssrs_score_tab ? (
          <SurveysTabV3CSSRS
            surveyResponses={surveyResponses}
            pathPrefix={pathPrefix}
            id={memberId}
            coachingPaths={isCoach}
          />
        ) : (
          <Redirect to={surveysV3Route(pathPrefix, memberId, SurveyTab.ALL)} />
        )}
      </SentryRoute>
      <SentryRoute
        exact={true}
        path={surveysV3Route(pathPrefix, memberId, SurveyTab.RISC10)}
      >
        <SurveysRISC
          memberId={memberId}
          pathPrefix={pathPrefix}
          surveyResponses={surveyResponses}
        />
      </SentryRoute>
    </>
  );
};

function SurveysRISC(
  param: Readonly<{
    memberId: string;
    surveyResponses: GetMemberSurvey_getMember_surveyResponses;
    pathPrefix: string;
  }>,
) {
  const { memberId, pathPrefix, surveyResponses } = param;
  const isCoach = useAppState((_) => isCoachOrSupervisor(_.user.role));
  const surveysV3Route = isCoach
    ? surveysV3CoachingRoute
    : surveysV3ClinicianRoute;

  const {
    enable_adaptability_surveys_v3,
  } = useFeatureFlags().transientFeatureFlags;

  if (enable_adaptability_surveys_v3) {
    return (
      <SurveysTabV3RISC
        surveyResponses={surveyResponses}
        pathPrefix={pathPrefix}
        id={memberId}
        coachingPaths={isCoach}
      />
    );
  }
  return <Redirect to={surveysV3Route(pathPrefix, memberId, SurveyTab.ALL)} />;
}
