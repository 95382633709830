import { ClinicalDocument_ClinicalDocumentType as ClinicalDocumentType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/ClinicalDocument';
import { DeleteVaultItemResult } from '@ginger.io/vault-ui';
import { VaultItemSortOrder } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { StateSlice } from 'app/state/status/types/StateSlice';

export enum DocumentStatus {
  PENDING,
  UPLOADING,
  UPLOADED,
  FAILED,
}

export interface Document {
  fileId: string;
  name: string;
  fileSize: string;
  author: string;
  documentType: ClinicalDocumentType;
  itemId: string;
  createdAt: string;
  ext: string;
  status?: DocumentStatus;
}

export interface UploadDocumentInput {
  file: File;
  name: string;
  documentType: ClinicalDocumentType;
  memberId: string;
  onUploadProgressCallback?: (progress: number) => void;
}

export interface UploadResponse {
  success: boolean;
  data: Document | null;
  errorMessage?: string;
}

export interface UpdateDocumentInput {
  name: string;
  documentType: ClinicalDocumentType;
  itemId: string;
}

export interface ClinicalDocumentHookState {
  documents: StateSlice<Document[]>;
  uploadDocuments: (input: UploadDocumentInput[]) => Promise<UploadResponse[]>;
  updateDocument: (input: UpdateDocumentInput) => Promise<UploadResponse>;
  refreshData: (
    sortOrder?: VaultItemSortOrder,
    showLoader?: boolean,
  ) => Promise<void>;
  deleteDocument: (itemId: string) => Promise<DeleteVaultItemResult>;
  generateDownloadUrl: (document: Document) => Promise<string>;
}

export type UploadFileTypeLabels =
  | 'Consent Form'
  | 'Release of Information'
  | 'Homework / Resource'
  | 'Assessments'
  | 'Lab Results'
  | 'Referral'
  | 'Billing'
  | 'Treatment Summary'
  | 'Other'
  | '';

export const UploadFileTypeMap: Map<ClinicalDocumentType, string> = new Map();
UploadFileTypeMap.set(ClinicalDocumentType.undefined_type, '');
UploadFileTypeMap.set(ClinicalDocumentType.consent_form, 'Consent Form');
UploadFileTypeMap.set(
  ClinicalDocumentType.treatment_summary,
  'Treatment Summary',
);
UploadFileTypeMap.set(ClinicalDocumentType.lab_results, 'Lab Results');
UploadFileTypeMap.set(
  ClinicalDocumentType.member_homework,
  'Homework / Resource',
);
UploadFileTypeMap.set(ClinicalDocumentType.billing, 'Billing');
UploadFileTypeMap.set(
  ClinicalDocumentType.release_of_information,
  'Release of Information',
);
UploadFileTypeMap.set(ClinicalDocumentType.assessments, 'Assessments');
UploadFileTypeMap.set(ClinicalDocumentType.other, 'Other');

export type InputFile = {
  name: string;
  file: File | null;
  fileType: ClinicalDocumentType;
  size: number;
};
