import { Typography } from '@mui/material';
import {
  AiMagicIcon,
  AiMagicIconState,
} from 'app/sortable-table/note-header/AiMagicIcon';
import React, { ReactNode } from 'react';
import { Size } from 'types/StyleTypes';
import { classNameCombiner } from 'utils';

import defaultStyles from './NoteFormControlLabel.module.scss';
import { NoteTooltip } from './NoteTooltip';

export type NoteFormControlLabelStyles = {
  label?: string;
  subtext?: string;
  root?: string;
  checkbox?: string;
};

type Props = {
  label?: string;
  size?: Size;
  tooltip?: string;
  children: ReactNode;
  subtext?: string | ReactNode;
  styles?: NoteFormControlLabelStyles;
  labelDescription?: string;
  optional?: boolean;
  showAiIcon?: boolean;
  aiIconState?: AiMagicIconState;
  button?: ReactNode;
};

export function NoteFormControlLabel({
  children,
  subtext,
  label,
  labelDescription,
  tooltip,
  styles,
  size = Size.SM,
  optional = false,
  button = null,
  showAiIcon = false,
  aiIconState,
}: Props) {
  const styleClasses = {
    ...defaultStyles,
    ...styles,
  };

  return (
    <div className={styleClasses.root}>
      <div
        className={classNameCombiner([
          styleClasses.label || defaultStyles.label,
          defaultStyles[size],
        ])}
        data-testid="label"
      >
        <div>
          <div>
            {label}
            {optional && (
              <>
                &nbsp;
                <span className={defaultStyles.optional}> (optional)</span>
              </>
            )}
            {tooltip && <NoteTooltip tooltip={tooltip} />}
            {showAiIcon && (
              <>
                &nbsp;
                <AiMagicIcon state={aiIconState} standalone={true} />
              </>
            )}
          </div>
          {subtext && <div className={styleClasses.subtext}>{subtext}</div>}
        </div>
      </div>
      {button}
      {labelDescription && (
        <Typography variant="body2" color="textSecondary">
          {labelDescription}
        </Typography>
      )}
      {children}
    </div>
  );
}
