import { GetTeenDependantSurveyResponse_dependant } from '@headspace/carehub-graphql/dist/patients-tabs-intake/generated/GetTeenDependantSurveyResponse';
import { MemberChartTab } from 'app/coach/member-chart/MemberChartTab';
import { IntakeCard } from 'app/patients/tabs/intake/IntakeTabV2Card';
import Labels from 'i18n/en/dependent-intake.json';
import React from 'react';
import { formatEthnicity, formatGender, formatPronouns } from 'utils';

import { formatDate, formatYesNoValue } from './common';
import styles from './DependentReport.module.scss';

interface Prop {
  teenDemographics: GetTeenDependantSurveyResponse_dependant;
}
export function TeenResponseTab(props: Readonly<Prop>) {
  const { teenDemographics } = props;

  return (
    <div className={styles.dependentReport}>
      <div className={styles.completedBy}>
        Completed by dependent&nbsp;
        {formatDate(null)}
      </div>
      <MemberChartTab>
        <IntakeCard
          testId="demographics-card"
          title={Labels.demographicsAndReasonForCare}
          terms={{
            [Labels.preferredName]: `${
              teenDemographics.preferredFirstName ?? ''
            } ${teenDemographics.preferredLastName ?? ''}`.trim(),
            [Labels.preferredEmail]: formatYesNoValue(teenDemographics.email),
            [Labels.gender]: formatGender(
              teenDemographics.genderIdentification,
              true,
            ),
            [Labels.pronouns]: formatPronouns(teenDemographics.pronouns, true),
            [Labels.raceAndEthnicity]: formatEthnicity(
              teenDemographics.ethnicities,
              true,
            ),
            [Labels.howCanWeHelp]: formatYesNoValue(
              teenDemographics.onboardAnswers?.howCanWeHelp.answers?.join(', '),
            ),
            [Labels.impactArea]: formatYesNoValue(
              teenDemographics.onboardAnswers?.impact.answers?.join(', '),
            ),
          }}
        />
      </MemberChartTab>
    </div>
  );
}
