export type State = {
  // coachMemberChannels is a map of non-wildcard channels:
  // {"memberId": "channelId"}
  coachMemberChannels: Record<string, string>;
  rpcToCoachChannel: string;
};

export const initialState: State = {
  coachMemberChannels: {},
  rpcToCoachChannel: '',
};

export const getInitialPubnubSubscriptionsState = (): State => {
  return initialState;
};
