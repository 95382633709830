import { ApolloClient, useApolloClient } from '@apollo/client';
import { PaginationInput } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  coachInboxQuery,
  coachTodaysInbox,
} from '@headspace/carehub-graphql/dist/queries/CareProviderInbox';
import {
  CoachInbox,
  CoachInboxVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/CoachInbox';
import {
  CoachTodaysInbox,
  CoachTodaysInboxVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/CoachTodaysInbox';
import { usePubnubListener } from 'app/coach/pubnub/usePubnubListener';
import { assertResponseHasNoError } from 'app/inbox/assertResponseHasNoError';
import useMultiTabs from 'app/inbox/components/useMultiTabs';
import {
  GetCoachAllMember,
  getCoachAllMembers,
  GetCoachTodaysMember,
  getCoachTodaysMembers,
  loadOpenTabInboxItems,
} from 'app/inbox/queries';
import { useAppState } from 'app/state';
import {
  inboxMemberSearch,
  loadMemberListAction,
} from 'app/state/amplitude/actions/inbox';
import { TimetokensService } from 'app/state/features/conversationTimetokens/TimetokensService';
import { useAppDispatch } from 'app/state/hooks/baseTypedHooks';
import {
  bulkSubscribeToTodaysMemberChannels,
  setSearchResult,
  updateInboxSectionItems,
} from 'app/state/inbox/actions';
import { ILogger } from 'app/state/log/Logger';
import { useLogger } from 'app/state/log/useLogger';
import { useStateSlice } from 'app/vault/hooks/utils';
import { useOnMount } from 'hooks/useOnMount';
import { useCallback } from 'react';
import { Dispatch } from 'redux';

import {
  InboxItem,
  InboxSections,
  InboxSource,
  InboxTab,
  InboxViewResponse,
  Results,
} from './types';
import {
  getCoachTodaysInboxVariables,
  gqlAllMemberResponseToInboxItem,
  gqlTasksResponseToInboxItem,
  gqlTodaysCoachingConvoResponseToInboxItem,
} from './utils';

export function useCoachInbox(): InboxViewResponse {
  const logger = useLogger();
  const { initializeTabState } = useMultiTabs();
  const conversations = useAppState(
    ({ inbox }) => inbox.tabSections.CONVERSATIONS,
  );
  const dispatch = useAppDispatch();
  const apollo = useApolloClient();
  const { role, timezone } = useAppState(({ user }) => ({
    role: user.role!,
    timezone: user.timezone ?? 'UTC',
  }));
  const {
    setLoading: todayListLoading,
    setError: setTodayListError,
    setData: setTodaysMember,
    state: todaysTab,
  } = useStateSlice<void>();
  const {
    setLoading: allListLoading,
    setError: setAllListError,
    setData: setAllMemberData,
    state: allTab,
  } = useStateSlice<void>();

  // add a listener for the "message" and "presence" events on mount and remove on unmount
  usePubnubListener();

  useOnMount(() => {
    initializeTabState();
    todayListLoading();
    allListLoading();
    const run = async () => {
      const [todaysList, allMember] = await Promise.allSettled([
        getCoachTodaysMembers({
          apollo,
          logger,
          timezone,
          variables: getCoachTodaysInboxVariables(timezone ?? 'UTC', {
            includeActiveTasks: false,
            includeCompletedTasks: true,
            includeConvo: true,
            includeRiskTasks: true,
            includeScheduledCheckin: true,
          }),
        }),
        getCoachAllMembers(apollo, timezone, logger),
      ]);
      const { inboxItems, sections } = processCoachInboxQueryResponse(
        todaysList,
        allMember,
      );
      const openTabs = await loadOpenTabInboxItems({
        apollo,
        inboxItems,
        logger,
        role,
      });
      await dispatch(
        TimetokensService.queryTimetokens({
          memberIds: inboxItems.map((i) => i.id),
        }),
      );
      if (
        todaysList.status === 'fulfilled' &&
        todaysList.value.response.errors
      ) {
        logger.error(
          new Error('CoachTodaysInbox returned partial data with an error'),
          {
            errors: todaysList.value.response.errors,
          },
        );
      }

      dispatch(
        updateInboxSectionItems({
          ...sections,
          openTabs,
          shouldSubscribeToPubnubChannels: false,
        }),
      );
      if (todaysList.status === 'fulfilled') {
        dispatch(
          loadMemberListAction({
            role,
            source: InboxSource.COACH_INBOX,
            tab: InboxTab.TODAYS,
          }),
        );
        setTodaysMember();
      } else {
        setTodayListError(todaysList.reason);
      }
      if (allMember.status === 'fulfilled') {
        dispatch(
          loadMemberListAction({
            role,
            source: InboxSource.COACH_INBOX,
            tab: InboxTab.ALL,
          }),
        );
        setAllMemberData();
      } else {
        setAllListError(allMember.reason);
      }
      const channelIdsToSub = [...inboxItems, ...openTabs]
        .map((_) => _.memberCoachChannelId!)
        .filter(Boolean);
      dispatch(bulkSubscribeToTodaysMemberChannels(channelIdsToSub));
    };
    run().catch((error) => logger.error(error));
  });

  const onSearch = async (query: string) => {
    if (query) {
      const allMember = await getCoachAllMembers(
        apollo,
        timezone,
        logger,
        query,
      );
      dispatch(
        setSearchResult({
          [InboxSections.PAST]: allMember?.pastSection,
          [InboxSections.CLOSED]: allMember?.closedSection,
          [InboxSections.SCHEDULED]: allMember?.scheduledSection,
        }),
      );
      dispatch(inboxMemberSearch({ role, source: InboxSource.COACH_INBOX }));
    } else {
      dispatch(
        setSearchResult({
          [InboxSections.PAST]: undefined,
          [InboxSections.CLOSED]: undefined,
          [InboxSections.SCHEDULED]: undefined,
        }),
      );
    }
  };
  const loadMore = useCallback(
    loadMoreSection(apollo, timezone, dispatch, conversations, logger),
    [apollo, timezone, dispatch, conversations, logger],
  );

  return {
    allTab: allTab.current,
    loadMore,
    onSearch,
    todaysTab: todaysTab.current,
  };
}
function processCoachInboxQueryResponse(
  todaysList: PromiseSettledResult<GetCoachTodaysMember>,
  allMember: PromiseSettledResult<GetCoachAllMember>,
) {
  const inboxItems: InboxItem[] = [];
  const sections: { [k in InboxSections]?: Results } = {};
  if (todaysList.status === 'fulfilled') {
    const convosSection = todaysList.value?.convosSection;
    const activeTasksSection = todaysList.value?.activeTasksSection;
    inboxItems.push(
      ...getInboxItems(todaysList.value?.scheduledSessionSection),
      ...getInboxItems(todaysList.value?.riskAlertSection),
      ...getInboxItems(convosSection),
      ...getInboxItems(activeTasksSection),
      ...getInboxItems(todaysList.value?.completedSection),
    );
    sections[InboxSections.SCHEDULED_CHECKINS] =
      todaysList.value?.scheduledSessionSection;
    sections[InboxSections.RISKS] = todaysList.value?.riskAlertSection;
    sections[InboxSections.CONVERSATIONS] = convosSection;
    sections[InboxSections.TASKS] = activeTasksSection;
    sections[InboxSections.COMPLETED] = todaysList.value?.completedSection;
    sections[InboxSections.CONVERSATIONS_AND_TASKS] = {
      cursor:
        (convosSection.hasMore
          ? convosSection.cursor
          : activeTasksSection?.cursor) ?? null,
      hasMore: convosSection.hasMore || (activeTasksSection?.hasMore ?? false),
      items: [...convosSection.items, ...(activeTasksSection?.items ?? [])],
    };
  }

  if (allMember.status === 'fulfilled') {
    inboxItems.push(
      ...getInboxItems(allMember.value?.closedSection),
      ...getInboxItems(allMember.value?.pastSection),
      ...getInboxItems(allMember.value?.scheduledSection),
    );
    sections[InboxSections.PAST] = allMember.value?.pastSection;
    sections[InboxSections.CLOSED] = allMember.value?.closedSection;
    sections[InboxSections.SCHEDULED] = allMember.value?.scheduledSection;
  }

  return { inboxItems, sections };
}

function loadMoreSection(
  apollo: ApolloClient<object>,
  timezone: string,
  dispatch: Dispatch,
  conversations: { ids: Set<string>; hasMore?: boolean; cursor: string | null },
  logger: ILogger,
) {
  return async (pagination: PaginationInput, section: InboxSections) => {
    const partialError = new Error(
      'Received partial response with an error while loading more member for user',
    );
    if (
      [
        InboxSections.PAST,
        InboxSections.SCHEDULED,
        InboxSections.CLOSED,
      ].includes(section)
    ) {
      const response = await apollo.query<CoachInbox, CoachInboxVariables>({
        errorPolicy: 'all',
        query: coachInboxQuery,
        variables: {
          includeClosed: section === InboxSections.CLOSED,
          includePast: section === InboxSections.PAST,
          includeScheduled: section === InboxSections.SCHEDULED,
          isCoach: true,
          pagination,
        },
      });

      assertResponseHasNoError(response, logger, { query: coachInboxQuery });
      const { data, errors } = response;
      if (errors) {
        logger.error(partialError, { errors, pagination, section });
      }

      const { scheduled, closed, past } = data.getCoachingMembersForMe;
      const scheduledSection = scheduled
        ? gqlAllMemberResponseToInboxItem(scheduled.items, scheduled.pagination)
        : undefined;
      const pastSection = past
        ? gqlAllMemberResponseToInboxItem(past.items, past.pagination)
        : undefined;
      const closedSection = closed
        ? gqlAllMemberResponseToInboxItem(closed.items, closed.pagination)
        : undefined;

      dispatch(
        updateInboxSectionItems({
          [InboxSections.PAST]: pastSection,
          [InboxSections.SCHEDULED]: scheduledSection,
          [InboxSections.CLOSED]: closedSection,
          shouldSubscribeToPubnubChannels: true,
        }),
      );
    }
    if (
      [InboxSections.SCHEDULED_CHECKINS, InboxSections.RISKS].includes(section)
    ) {
      const variables = getCoachTodaysInboxVariables(timezone ?? 'UTC');
      if (section === InboxSections.RISKS) {
        variables.includeRiskTasks = true;
        variables.riskTasksPagination = pagination;
      }
      if (section === InboxSections.SCHEDULED_CHECKINS) {
        variables.includeScheduledCheckin = true;
        variables.scheduledCheckinPagination = pagination;
      }
      const response = await apollo.query<
        CoachTodaysInbox,
        CoachTodaysInboxVariables
      >({
        errorPolicy: 'all',
        query: coachTodaysInbox,
        variables,
      });

      assertResponseHasNoError(response, logger, { query: coachInboxQuery });
      const { data, errors } = response;

      if (errors) {
        logger.error(partialError, { errors, pagination, section });
      }

      const riskAlerts = data.riskTasks;
      const { scheduledSessions } = data;

      const scheduledSessionSection = scheduledSessions
        ? gqlTodaysCoachingConvoResponseToInboxItem(
            scheduledSessions?.items ?? [],
            scheduledSessions?.pagination,
            timezone,
          )
        : undefined;

      const riskAlertSection = riskAlerts
        ? gqlTasksResponseToInboxItem(
            riskAlerts?.items ?? [],
            riskAlerts?.pagination,
            timezone,
            true,
          )
        : undefined;

      dispatch(
        updateInboxSectionItems({
          [InboxSections.RISKS]: riskAlertSection,
          [InboxSections.SCHEDULED_CHECKINS]: scheduledSessionSection,
          shouldSubscribeToPubnubChannels: true,
        }),
      );
    }
    if ([InboxSections.CONVERSATIONS_AND_TASKS].includes(section)) {
      const variables = getCoachTodaysInboxVariables(timezone);
      const shouldLoadTaskNext = !conversations.hasMore;
      if (shouldLoadTaskNext) {
        variables.includeActiveTasks = true;
        variables.excludeMemberIds = Array.from(conversations.ids);
        variables.activeTasksPagination = pagination;
      } else {
        variables.includeConvo = true;
        variables.openConvoPagination = pagination;
      }

      const response = await apollo.query<
        CoachTodaysInbox,
        CoachTodaysInboxVariables
      >({
        errorPolicy: 'all',
        query: coachTodaysInbox,
        variables,
      });

      assertResponseHasNoError(response, logger, { query: coachInboxQuery });
      const { data, errors } = response;
      if (errors) {
        logger.error(partialError, { errors, pagination, section });
      }

      if (data.openConversations || data.activeTasks) {
        const coachingConversations = data.openConversations;
        const conversationsSection = coachingConversations
          ? gqlTodaysCoachingConvoResponseToInboxItem(
              coachingConversations.items,
              coachingConversations.pagination,
              timezone,
            )
          : undefined;

        const { activeTasks } = data;
        let activeTasksSection = data.activeTasks
          ? gqlTasksResponseToInboxItem(
              activeTasks?.items ?? [],
              activeTasks?.pagination,
              timezone,
            )
          : undefined;

        const openConversationsPagination = coachingConversations?.pagination;

        if (
          conversationsSection &&
          !conversationsSection.hasMore &&
          openConversationsPagination.totalCount <
            openConversationsPagination.maxItemsPerPage
        ) {
          const excludeMemberIds = [
            ...Array.from(conversations.ids),
            ...conversationsSection.items.map((_) => _.id),
          ];
          const { data: tasksData, errors: taskErrors } = await apollo.query<
            CoachTodaysInbox,
            CoachTodaysInboxVariables
          >({
            query: coachTodaysInbox,
            variables: {
              ...variables,
              activeTasksPagination: {
                cursor: null,
                maxItemsPerPage:
                  openConversationsPagination.maxItemsPerPage -
                  openConversationsPagination.totalCount,
              },
              excludeMemberIds,
              includeActiveTasks: true,
              includeConvo: false,
            },
          });

          if (taskErrors) {
            logger.error(
              new Error('loadMoreSection::Unable to fetch Task items'),
              { errors: taskErrors, pagination, section },
            );
          }
          if (tasksData) {
            activeTasksSection = gqlTasksResponseToInboxItem(
              tasksData.activeTasks.items,
              tasksData.activeTasks.pagination,
              timezone,
              false,
            );
          }
        }

        const cursor = conversationsSection?.hasMore
          ? conversationsSection?.cursor
          : activeTasksSection?.hasMore
          ? activeTasksSection.cursor
          : null;

        dispatch(
          updateInboxSectionItems({
            [InboxSections.CONVERSATIONS]: conversationsSection,
            [InboxSections.TASKS]: activeTasksSection,
            [InboxSections.CONVERSATIONS_AND_TASKS]: {
              cursor,
              hasMore:
                conversationsSection?.hasMore ||
                (activeTasksSection?.hasMore ?? false),
              items: [
                ...(conversationsSection?.items ?? []),
                ...(activeTasksSection?.items ?? []),
              ],
            },
            shouldSubscribeToPubnubChannels: true,
          }),
        );
      }
    }
  };
}

function getInboxItems(state: Results | undefined): InboxItem[] {
  return state?.items ?? [];
}
