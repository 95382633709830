import { ClinicalAppointmentNoteType } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { NoteDetailView } from 'app/coach/coach-notes/note-detail-view/NoteDetailView';
import { PsychiatryIntakeNoteContainerv2 } from 'app/notes-ui/psychiatry/PsychiatryIntakeNoteContainerv2';
import { PsychiatryProgressNoteContainerv2 } from 'app/notes-ui/psychiatry/PsychiatryProgressNoteContainerv2';
import { TherapyIntakeNoteContainerv2 } from 'app/notes-ui/therapy/TherapyIntakeNoteContainerv2';
import { TherapyProgressNoteContainerv2 } from 'app/notes-ui/therapy/TherapyProgressNoteContainerv2';
import React, { ReactElement, useEffect } from 'react';
import { useHistory, useLocation } from 'react-router-dom';

import { AppointmentNoteDetailHeader } from './AppointmentNoteDetailHeader';
import {
  AppointmentsAndNotesCursor,
  useAppointmentsAndNotesAPI,
} from './AppointmentsAndNotesAPIContext';
import { getNoteTypeFromAppointmetType } from './utils';

const renderAppointmentNote = (
  appointmentOrNote: AppointmentsAndNotesCursor,
  setCursor: (cursor: AppointmentsAndNotesCursor) => void,
) => {
  let component: MaybeUndefined<ReactElement | null>;
  let showHeader = true;

  if (!appointmentOrNote) {
    return {
      component: null,
      showHeader,
    };
  }

  if ('type' in appointmentOrNote) {
    // clinical appointments have a "type" field
    const noteType = getNoteTypeFromAppointmetType(appointmentOrNote.type);
    if (noteType === ClinicalAppointmentNoteType.THERAPY_INTAKE) {
      showHeader = false;
      component = (
        <TherapyIntakeNoteContainerv2 appointmentId={appointmentOrNote.id} />
      );
    } else if (noteType === ClinicalAppointmentNoteType.THERAPY_PROGRESS) {
      component = (
        <TherapyProgressNoteContainerv2 appointmentId={appointmentOrNote.id} />
      );
    } else if (noteType === ClinicalAppointmentNoteType.PSYCHIATRY_INTAKE) {
      component = (
        <PsychiatryIntakeNoteContainerv2 appointmentId={appointmentOrNote.id} />
      );
    } else if (noteType === ClinicalAppointmentNoteType.PSYCHIATRY_PROGRESS) {
      component = (
        <PsychiatryProgressNoteContainerv2
          appointmentId={appointmentOrNote.id}
        />
      );
    } else {
      component = <div>Not implemented yet</div>;
    }
  } else {
    // ...and shared coach notes have a "noteType" field
    showHeader = false;
    component = (
      <NoteDetailView
        data-testid="noteDetailView"
        noteType={appointmentOrNote.noteType!}
        selectedNote={appointmentOrNote}
        setCursor={setCursor}
        showClinicalView={true}
        readOnly={true}
      />
    );
  }

  return {
    component,
    showHeader,
  };
};

export const AppointmentNoteDetail = ({ memberId }: { memberId: string }) => {
  const history = useHistory();
  const location = useLocation();
  const {
    noteDetails: { cursor: appointment, setCursor },
  } = useAppointmentsAndNotesAPI();

  useEffect(() => {
    if (!appointment) return;

    const searchParams = new URLSearchParams(location.search);
    const appointmentNote = searchParams.get('appointmentNote');

    // check if the appointmentNote is already in the url, so we can
    // prevent pushing the appointmentNote again -- this is to prevent re-rendering
    if (appointmentNote !== appointment.id) {
      searchParams.set('appointmentNote', appointment?.id as string);
      history.push({
        pathname: location.pathname,
        search: searchParams.toString(),
      });
    }
    return () => setCursor(null);
  }, [memberId]);

  const { showHeader, component } = renderAppointmentNote(
    appointment,
    setCursor,
  );

  return (
    <div>
      {showHeader && <AppointmentNoteDetailHeader memberId={memberId} />}
      {component}
    </div>
  );
};
