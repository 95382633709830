import { InboxSectionHeaderColor } from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';

import { InboxSectionWrapper } from '../InboxSectionWrapper';
import { InboxSectionItem } from '../InboxSectionItem';
import {
  MainContentText,
  SubContentText,
  SubContentTextColorEnum,
  CountBubble,
} from '../SectionContentItems';

const dummyResponse = [
  {
    name: 'Test',
    time: '10mo',
    additionalContent: 'Additional Content',
    total: '300',
  },

  {
    name: 'Test 2',
    time: '1mo',
    additionalContent: '',
    total: '9',
  },

  {
    name: 'Test 3',
    time: '1w',
    additionalContent: 'Additional Content',
    total: '0',
  },
];

export const ConversationSection = () => {
  return (
    <InboxSectionWrapper
      headerColor={InboxSectionHeaderColor.LIGHT_CLOUD}
      sectionTitle="Conversations"
      section={InboxSections.CONVERSATIONS}
      sectionItemCount={dummyResponse.length}
    >
      {dummyResponse.map(({ name, time, additionalContent, total }) => (
        <InboxSectionItem
          key={`${name}-${time}`}
          topLeft={<MainContentText label={name} />}
          topRight={<SubContentText label={time} />}
          bottomLeft={
            <SubContentText
              label={additionalContent}
              textColor={SubContentTextColorEnum.primary}
            />
          }
          bottomRight={<CountBubble total={total} />}
          onClick={() => console.log('clicked')}
        />
      ))}
    </InboxSectionWrapper>
  );
};
