import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import {
  defaultFeatureFlags,
  defaultTransientFeatureFlags,
} from 'hooks/useFeatureFlags';

import { IFeatureFlagsSlice } from './types';

export const initialState: IFeatureFlagsSlice = {
  ...defaultFeatureFlags,
  transientFeatureFlags: {
    ...defaultTransientFeatureFlags,
  },
};

export const featureFlagsSliceName = 'featureFlags';

const featureFlagsSlice = createSlice({
  initialState,
  name: featureFlagsSliceName,
  reducers: {
    updateFeatureFlags(state, { payload }: PayloadAction<IFeatureFlagsSlice>) {
      Object.assign(state, payload);
    },
  },
});

export default featureFlagsSlice.reducer;

export const { updateFeatureFlags } = featureFlagsSlice.actions;
