import {
  field,
  FieldDefinitions,
  nonEmptyArrayField,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  MedicalReviewOfSystem,
  MedicalReviewOfSystem_Constitutional,
  MedicalReviewOfSystem_Endocrine,
  MedicalReviewOfSystem_Musculoskeletal,
  MedicalReviewOfSystem_Neurological,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalROS';
import {
  MedicalReviewOfSystemSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { EnumCheckboxesField, TextAreaField } from 'app/notes-ui/forms/fields';
import { Checkbox } from 'app/notes-ui/forms/form-controls/Checkbox';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { Width } from 'types/StyleTypes';

import { Labels } from './constants';

export function requiredIfOtherConstitutional(
  value: string,
  state: MedicalReviewOfSystem,
): string | undefined {
  if (
    state.constitutional.includes(MedicalReviewOfSystem_Constitutional.other) &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
  return undefined;
}

export function requiredIfOtherEndocrine(
  value: string,
  state: MedicalReviewOfSystem,
): string | undefined {
  if (
    state.endocrine.includes(MedicalReviewOfSystem_Endocrine.other_endocrine) &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
  return undefined;
}

export function requiredIfOtherNeurological(
  value: string,
  state: MedicalReviewOfSystem,
): string | undefined {
  if (
    state.neurological.includes(
      MedicalReviewOfSystem_Neurological.other_neurological,
    ) &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
  return undefined;
}

export function requiredIfOtherMusculoskeletal(
  value: string,
  state: MedicalReviewOfSystem,
): string | undefined {
  if (
    state.musculoskeletal.includes(
      MedicalReviewOfSystem_Musculoskeletal.other_musculoskeletal,
    ) &&
    isEmpty(value)
  ) {
    return 'This field is required';
  }
  return undefined;
}

type Props = {
  appointmentId: string;
  onSubmit: (data: MedicalReviewOfSystemSection) => void;
  disabled?: boolean;
  initialValue?: MedicalReviewOfSystem;
};

export const fieldDefs: FieldDefinitions<MedicalReviewOfSystem> = {
  appointmentId: field(),
  comments: stringField({ rules: [] }),
  constitutional: nonEmptyArrayField(),
  constitutionalComment: stringField({
    rules: [requiredIfOtherConstitutional],
  }),
  endocrine: nonEmptyArrayField(),
  endocrineComment: stringField({ rules: [requiredIfOtherEndocrine] }),
  musculoskeletal: nonEmptyArrayField(),
  musculoskeletalComment: stringField({
    rules: [requiredIfOtherMusculoskeletal],
  }),
  neurological: nonEmptyArrayField(),
  neurologicalComment: stringField({ rules: [requiredIfOtherNeurological] }),
};

export function MedicalReviewOfSystemFormV2(props: Props) {
  const { appointmentId, disabled, initialValue } = props;

  const { fields, getValue } = useForm<MedicalReviewOfSystem>(
    fieldDefs,
    MedicalReviewOfSystem.fromPartial({ ...initialValue, appointmentId }),
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: PsychiatrySectionName.MEDICAL_ROS,
    });
  };

  const toggleMedicalROSNegated = () => {
    const isMedicalROSNegated =
      fields.constitutional.value.includes(
        MedicalReviewOfSystem_Constitutional.denies_constitutional_symptoms,
      ) &&
      fields.endocrine.value.includes(
        MedicalReviewOfSystem_Endocrine.denies_endocrine_symptoms,
      ) &&
      fields.musculoskeletal.value.includes(
        MedicalReviewOfSystem_Musculoskeletal.denies_musculoskeletal_symptoms,
      ) &&
      fields.neurological.value.includes(
        MedicalReviewOfSystem_Neurological.denies_neurological_symptoms,
      );

    if (isMedicalROSNegated) {
      fields.constitutional.setValue([]);
      fields.endocrine.setValue([]);
      fields.musculoskeletal.setValue([]);
      fields.neurological.setValue([]);
    } else {
      fields.constitutional.setValue([
        MedicalReviewOfSystem_Constitutional.denies_constitutional_symptoms,
      ]);
      fields.endocrine.setValue([
        MedicalReviewOfSystem_Endocrine.denies_endocrine_symptoms,
      ]);
      fields.musculoskeletal.setValue([
        MedicalReviewOfSystem_Musculoskeletal.denies_musculoskeletal_symptoms,
      ]);
      fields.neurological.setValue([
        MedicalReviewOfSystem_Neurological.denies_neurological_symptoms,
      ]);
    }
  };

  return (
    <div>
      <div className={styles.marginNotEndorsing}>
        <Checkbox
          label="Negative medical ROS"
          checked={
            fields.constitutional.value.includes(
              MedicalReviewOfSystem_Constitutional.denies_constitutional_symptoms,
            ) &&
            fields.endocrine.value.includes(
              MedicalReviewOfSystem_Endocrine.denies_endocrine_symptoms,
            ) &&
            fields.musculoskeletal.value.includes(
              MedicalReviewOfSystem_Musculoskeletal.denies_musculoskeletal_symptoms,
            ) &&
            fields.neurological.value.includes(
              MedicalReviewOfSystem_Neurological.denies_neurological_symptoms,
            )
          }
          onChange={toggleMedicalROSNegated}
        />
      </div>
      <Grid>
        <Columns
          className={styles.gridColumn}
          widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}
        >
          <>
            <NoteFormControlLabel label={Labels.constitutional.label}>
              <EnumCheckboxesField
                disabled={disabled}
                testId={Labels.constitutional.id}
                label=""
                options={MedicalReviewOfSystem_Constitutional}
                field={fields.constitutional}
                keyLabels={{
                  denies_constitutional_symptoms:
                    Labels.constitutional.deniesLabel,
                  other: 'Other constitutional symptom',
                }}
                keysToExclude={['fatigue']}
              />
              {fields.constitutional.value.includes(
                MedicalReviewOfSystem_Constitutional.other,
              ) && (
                <TextAreaField
                  disabled={disabled}
                  testId={Labels.constitutional.id}
                  label=""
                  placeholder={Labels.comments.placeholder}
                  field={fields.constitutionalComment}
                  rows={1}
                  className={styles.otherTextField}
                  autoExpand={true}
                  width={Width.FULL}
                />
              )}
            </NoteFormControlLabel>
            <NoteFormControlLabel label={Labels.musculoskeletal.label}>
              <EnumCheckboxesField
                disabled={disabled}
                testId={Labels.musculoskeletal.id}
                label=""
                options={MedicalReviewOfSystem_Musculoskeletal}
                field={fields.musculoskeletal}
                keyLabels={{
                  bone_pain: 'Pain',
                  denies_musculoskeletal_symptoms:
                    Labels.musculoskeletal.deniesLabel,
                  other_musculoskeletal: 'Other musculoskeletal symptom',
                }}
                keysToExclude={['limited_range_of_motion']}
              />
              {fields.musculoskeletal.value.includes(
                MedicalReviewOfSystem_Musculoskeletal.other_musculoskeletal,
              ) && (
                <TextAreaField
                  disabled={disabled}
                  testId={Labels.musculoskeletal.id}
                  label=""
                  placeholder={Labels.comments.placeholder}
                  field={fields.musculoskeletalComment}
                  rows={1}
                  className={styles.otherTextField}
                  autoExpand={true}
                  width={Width.FULL}
                />
              )}
            </NoteFormControlLabel>
          </>
          <>
            <NoteFormControlLabel label={Labels.endocrine.label}>
              <EnumCheckboxesField
                disabled={disabled}
                testId={Labels.endocrine.id}
                label=""
                options={MedicalReviewOfSystem_Endocrine}
                field={fields.endocrine}
                keyLabels={{
                  denies_endocrine_symptoms: Labels.endocrine.deniesLabel,
                  other_endocrine: 'Other endocrine symptom',
                }}
                keysToExclude={['constipation']}
              />
              {fields.endocrine.value.includes(
                MedicalReviewOfSystem_Endocrine.other_endocrine,
              ) && (
                <TextAreaField
                  disabled={disabled}
                  testId={Labels.endocrine.id}
                  label=""
                  placeholder={Labels.comments.placeholder}
                  field={fields.endocrineComment}
                  rows={1}
                  width={Width.FULL}
                  className={styles.otherTextField}
                  autoExpand={true}
                />
              )}
            </NoteFormControlLabel>

            <NoteFormControlLabel label={Labels.neurological.label}>
              <EnumCheckboxesField
                disabled={disabled}
                testId={Labels.neurological.id}
                label=""
                options={MedicalReviewOfSystem_Neurological}
                field={fields.neurological}
                keyLabels={{
                  denies_neurological_symptoms: Labels.neurological.deniesLabel,
                  other_neurological: 'Other neurologic symptom',
                }}
                keysToExclude={['paralysis']}
              />
              {fields.neurological.value.includes(
                MedicalReviewOfSystem_Neurological.other_neurological,
              ) && (
                <TextAreaField
                  disabled={disabled}
                  testId={Labels.neurological.id}
                  label=""
                  placeholder={Labels.comments.placeholder}
                  field={fields.neurologicalComment}
                  rows={1}
                  className={styles.otherTextField}
                  autoExpand={true}
                  width={Width.FULL}
                />
              )}
            </NoteFormControlLabel>
          </>
        </Columns>
      </Grid>
      <TextAreaField
        testId={Labels.comments.id}
        disabled={disabled}
        label={Labels.comments.label}
        placeholder={Labels.comments.placeholder}
        field={fields.comments}
        width={Width.FULL}
        autoExpand={true}
        optional={true}
        rows={1}
      />
    </div>
  );
}

export const validate = (data: MedicalReviewOfSystem | null) =>
  data !== null && validateForm(data, fieldDefs);
