import { PHQ9SuicidalIdeation } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetMemberSurvey_getMember_surveyResponses,
  GetMemberSurvey_getMember_surveyResponses_phq9s_surveys,
} from '@headspace/carehub-graphql/dist/queries/generated/GetMemberSurvey';
import { Phq9LineChart } from 'app/charts/Phq9LineChart';
import { ThreeDots } from 'app/charts/ThreeDots';
import InfoBox from 'app/patients/tabs/surveys/SurveysV3/InfoBox';
import { ScoreDetails } from 'app/patients/tabs/surveys/SurveysV3/ScoreDetails';
import { viewSurveyTabAction } from 'app/state/amplitude/actions/surveys';
import { formatEnumValue, sortTimestampAsc } from 'utils';
import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Box } from 'shared-components/Box';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { NavigationTabs } from 'shared-components/tabs/NavigationTabs';

import { PHQ_GAD_SCORE_LABEL, PHQ9MAX, titleImageMapping } from './constants';
import styles from './SurveysTabV3PHQ.module.scss';
import { InfoBoxContent, Insight, PHQ9SurveyResponse, Summary } from './types';
import { useTabLinksForSurveyV3 } from './utils';

interface Props {
  id: string;
  pathPrefix: string;
  surveyResponses: GetMemberSurvey_getMember_surveyResponses;
  coachingPaths?: boolean;
}

export default function SurveysTabV3PHQ(props: Props) {
  const { id, pathPrefix, surveyResponses, coachingPaths = false } = props;
  const tabs = useTabLinksForSurveyV3(pathPrefix, id, coachingPaths);

  const phq9s = formatPHQ9(surveyResponses);

  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(viewSurveyTabAction());
  }, [dispatch]);

  const [selectedInfo, setSelectedInfo] = useState<InfoBoxContent | null>(null);
  const [selectedResponse, setSelectedResponse] = useState<
    GetMemberSurvey_getMember_surveyResponses_phq9s_surveys | undefined
  >(undefined);

  const handleClick = (index: number, data: PHQ9SurveyResponse) => {
    setSelectedInfo({
      insights: data.insights,
      scoreType: data.total > 9 ? 'negative' : 'positive',
      summaries: data.summaries,
    });
    setSelectedResponse(surveyResponses?.phq9s?.surveys[index]);
  };

  return (
    <div className={styles.body}>
      <div className={styles.content}>
        <Grid>
          <Columns widths={[COLUMN_WIDTH.FULL]}>
            <Box>
              <div className={styles.surveyScore}>
                <NavigationTabs links={tabs} preserveQueries={true} />
                <div className={styles.graph}>
                  {phq9s.length >= 1 ? (
                    <Phq9LineChart
                      disableTooltip={true}
                      responses={phq9s}
                      onClick={handleClick}
                    />
                  ) : (
                    <p>Member has not completed this survey yet. </p>
                  )}
                </div>
                <InfoBox info={selectedInfo} />
                <ScoreDetails
                  title="Over the last 2 weeks, how often have you been bothered by the following problems:"
                  Component={ThreeDots}
                  scoreLabels={PHQ_GAD_SCORE_LABEL}
                  questions={surveyResponses?.phq9s?.questions}
                  answers={selectedResponse}
                />
              </div>
            </Box>
          </Columns>
        </Grid>
      </div>
    </div>
  );
}

export function formatPHQ9(
  surveyResponses: GetMemberSurvey_getMember_surveyResponses,
): PHQ9SurveyResponse[] {
  return (surveyResponses?.phq9s?.surveys ?? [])
    .sort(sortTimestampAsc)
    .map(
      ({ total, timestamp, severity, insights, suicidalIdeation, ...rest }) => {
        const summaries: Summary[] = [];

        if (suicidalIdeation !== null) {
          const subtextMapping: Record<PHQ9SuicidalIdeation, string> = {
            [PHQ9SuicidalIdeation.DENIED]: 'Denied suicidal thoughts',
            [PHQ9SuicidalIdeation.ENDORSED]: 'Endorsed suicidal thoughts',
            [PHQ9SuicidalIdeation.UNKNOWN]:
              'Answer to suicidal thoughts unknown',
          };
          summaries.push({
            colorText: 'danger',
            icon: titleImageMapping['Suicide-Risk'],
            subText: `(${rest.selfHarm ?? 0}) ${
              subtextMapping[suicidalIdeation]
            }`,
            title: 'Suicidal Ideation',
          });
        }
        if (severity !== null && total !== null) {
          summaries.push({
            colorText: 'normal',
            icon: severity ? titleImageMapping[severity] : '',
            subText: `${total ?? 0} / ${PHQ9MAX}`,
            title: `${formatEnumValue(severity)} Depression`,
          });
        }

        return {
          concentration: rest.concentration ?? 0,
          eating: rest.eating ?? 0,
          energy: rest.energy ?? 0,
          enjoyment: rest.enjoyment ?? 0,
          failure: rest.failure ?? 0,
          insights: formatInsights(insights),
          mood: rest.mood ?? 0,
          restlessness: rest.restlessness ?? 0,
          selfHarm: rest.selfHarm ?? 0,
          severity: severity ? formatEnumValue(severity) : '',
          sleep: rest.sleep ?? 0,
          suicidalIdeation: suicidalIdeation !== null,
          summaries,
          timestamp: timestamp!,
          total: total ?? 0,
        };
      },
    );
}

export function formatInsights(
  insights: GetMemberSurvey_getMember_surveyResponses_phq9s_surveys['insights'],
): Insight[] {
  return (insights ?? []).map(({ frequency, details, title }) => ({
    details: details ?? '',
    frequency: frequency ? formatEnumValue(frequency) : '',
    title: title ?? '',
  }));
}
