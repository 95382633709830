import { ChatConversationState } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { SerializedError } from '@reduxjs/toolkit';
import { InboxItem, QueryMetadata } from 'app/inboxV2/types';

export interface ConversationsInboxItem extends InboxItem {
  isReadOnly: boolean;
  status: ChatConversationState;
  unreadMessagesCount: number;
  /* eslint-disable no-undef */
  memberLastReadAt: ISODateString | null;
  /* eslint-disable no-undef */
  memberLastWroteAt: ISODateString | null;
  /* eslint-disable no-undef */
  coachLastReadAt: ISODateString | null;
  /* eslint-disable no-undef */
  coachLastWroteAt: ISODateString | null;
  channelId: string;
  isDropIn: boolean;
}

export interface State<ItemsType> {
  items: ItemsType[];
  metadata: QueryMetadata;
  loading: boolean;
  error: SerializedError | null;
}

export const initialMeta = {
  cursor: null,
  hasNext: false,
  limit: 0,
  total: 0,
};

export const initialState: State<ConversationsInboxItem> = {
  error: null,
  items: [],
  loading: false,
  metadata: initialMeta,
};

export const getInitialConversationsState = (): State<ConversationsInboxItem> =>
  initialState;
