import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { MemberDetailsById_getMember as MemberDetails } from '@headspace/carehub-graphql/dist/vault/generated/MemberDetailsById';
import CreateSharpIcon from '@mui/icons-material/CreateSharp';
import VisibilityOutlinedIcon from '@mui/icons-material/VisibilityOutlined';
import {
  DownloadClinicalNoteButton,
  startPDFDownload,
} from 'app/appointments/DownloadClinicalNoteButton';
import { IconActionBarItem } from 'app/appointments/IconActionBarItem';
import { NoteAction } from 'app/appointments/types';
import {
  NonAppointmentNote,
  NonAppointmentNotePDF,
  OutOfSessionNotePDF,
  TerminationNotePDF,
} from 'app/notes-ui/pdf/NonAppointmentNotePDF';
import { NoteHeader } from 'app/notes-ui/pdf/types';
import { clickedNoteListActionButtonNote } from 'app/state/amplitude/actions/notes';
import { OutOfSessionOrTerminationNote } from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import { ClinicalNotesAPIClients } from 'app/vault/hooks/useClinicalNotesAPI';
import blobStream from 'blob-stream';
import moment from 'moment-timezone';
import React from 'react';
import { useDispatch } from 'react-redux';
import { DateTime, formatDate } from 'utils/dateTime';

type Props = {
  note: OutOfSessionOrTerminationNote;
  allowedNoteActions: NoteAction[];
  onClick: () => void;
  signedLockedAction: NoteAction.VIEW | NoteAction.DOWNLOAD;
  memberId: string;
  clinicianId: string;
};

export function NotesTableActionButton(props: Props) {
  const {
    onClick,
    signedLockedAction,
    allowedNoteActions,
    note,
    memberId,
    clinicianId,
  } = props;
  const canEdit =
    note.contents.note.clinicianId === clinicianId ||
    allowedNoteActions.includes(NoteAction.REVIEW_DRAFT);
  const dispatch = useDispatch();

  if (
    allowedNoteActions.includes(NoteAction.EDIT) &&
    note.status === Metadata_NoteStatus.draft_note &&
    canEdit
  ) {
    return (
      <IconActionBarItem
        testId="editNote"
        Icon={CreateSharpIcon}
        onClick={() => {
          dispatch(
            clickedNoteListActionButtonNote({
              action: 'edit-note',
              clinicianId,
              memberId,
            }),
          );
          onClick();
        }}
        title="Edit Note"
      />
    );
  }
  if (
    allowedNoteActions.includes(NoteAction.DOWNLOAD) &&
    note.status === Metadata_NoteStatus.signed_and_locked_note &&
    signedLockedAction === NoteAction.DOWNLOAD
  ) {
    return (
      <DownloadClinicalNoteButton
        noteDownloadFn={async (_, clinicalNotesApi, password) => {
          dispatch(
            clickedNoteListActionButtonNote({
              action: 'download-note',
              clinicianId,
              memberId,
            }),
          );
          return downloadClinicalNote(
            note,
            memberId,
            clinicalNotesApi,
            password,
          );
        }}
      />
    );
  }
  if (
    allowedNoteActions.includes(NoteAction.VIEW) &&
    note.status === Metadata_NoteStatus.signed_and_locked_note &&
    signedLockedAction === NoteAction.VIEW
  ) {
    return (
      <IconActionBarItem
        testId="viewNote"
        onClick={() => {
          dispatch(
            clickedNoteListActionButtonNote({
              action: 'view-note',
              clinicianId,
              memberId,
            }),
          );
          onClick();
        }}
        title="View Note"
        Icon={VisibilityOutlinedIcon}
      />
    );
  }
  return <div data-testid="emptyNoteAction" />;
}

async function downloadClinicalNote(
  data: OutOfSessionOrTerminationNote,
  memberId: string,
  clinicalNotesApi: ClinicalNotesAPIClients,
  password?: string,
): Promise<void> {
  let pdfBuilder: NonAppointmentNotePDF<NonAppointmentNote>;

  const member = await clinicalNotesApi.nonAppointmentNotesAPI.getMemberDetails(
    memberId,
  );
  const { contents, authorName, createdAt } = data;

  if (
    contents.schema === VaultItem_SchemaType.vault_clinical_notes_termination
  ) {
    const { note } = contents;
    pdfBuilder = new TerminationNotePDF(
      note,
      toNoteHeader(member, authorName, createdAt, true),
      password,
    );
  } else if (
    contents.schema === VaultItem_SchemaType.vault_clinical_notes_out_of_session
  ) {
    const { note } = contents;
    const date: DateTime = {
      ...note.dateOfContact!,
      ...note.durationOfContact?.startTime!,
    };
    pdfBuilder = new OutOfSessionNotePDF(
      note,
      toNoteHeader(member, authorName, date, false),
      password,
    );
  } else {
    throw new Error('Unsupported note type');
  }

  const pdfDoc = pdfBuilder.getPDF();
  const stream = pdfDoc.pipe(blobStream());

  return new Promise((resolve, reject) => {
    stream.on('error', (e) => {
      reject(e);
    });

    stream.on('finish', () => {
      try {
        const href = stream.toBlobURL('application/pdf');
        const noteType =
          contents.schema ===
          VaultItem_SchemaType.vault_clinical_notes_out_of_session
            ? 'out-of-session'
            : 'termination';
        const filename = `${noteType}-${memberId}.pdf`;
        startPDFDownload(href, filename);
        resolve();
      } catch (e) {
        reject(e);
      }
    });
  });
}

export function toNoteHeader(
  member: MemberDetails,
  authorName: string,
  date: string | DateTime,
  isTerminationNote: boolean,
  timezone = 'America/Los_Angeles',
): NoteHeader {
  const { firstName, lastName, gender, dateOfBirth } = member;
  let dateTime;
  if (typeof date === 'string') {
    dateTime = moment(new Date(date));
  } else {
    dateTime = moment({ ...date, month: date.month - 1 });
  }
  return {
    clinician: { name: authorName },
    dateTime: dateTime.tz(timezone).format('M/D/YYYY h:mma z'),
    isTerminationNote,
    patient: {
      dateOfBirth: dateOfBirth ? formatDate(dateOfBirth) : 'Not specified',
      firstName: firstName ?? '',
      gender: gender ?? 'Not specified',
      lastName: lastName ?? '',
    },
    updatedAt: null,
  };
}
