import { isApolloError } from '@apollo/client';
import { CollaborationPlan_GoalType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import {
  TreatmentGoal,
  TreatmentGoal_GoalStatus,
  TreatmentGoal_GoalType,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/TreatmentGoal';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import {
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  PaginationInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetAppointmentById_getAppointmentById as Appointment } from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import { areCodeSetsValid } from 'app/notes-ui/shared/code-set/CodeSetFields';
import { ProtobufCodeSet } from 'app/notes-ui/types';
import { getMemberAppointments } from 'app/patients/utils';
import {
  dataState,
  errorState,
  loadingState,
  StateSlice,
} from 'app/state/status/types/StateSlice';
import {
  AppointmentIdWithNoteType,
  clinicalAppointNoteTypeToNoteType,
} from 'app/typeUtils';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import {
  PsychiatryProgressNote,
  PsychiatryProgressNotesAPI,
} from 'app/vault/api/PsychiatryProgressNotesAPI';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import {
  TherapyProgressNote,
  TherapyProgressNotesAPI,
} from 'app/vault/api/TherapyProgressNotesAPI';
import { useRef, useState } from 'react';

export type ReadOnlyRef<T> = { readonly current: T };

export type UseTherapyNoteResult<T, U> = {
  note: ReadOnlyRef<StateSlice<T>>;
  draftNote: ReadOnlyRef<StateSlice<T>>;
  isLockable: boolean;
  createOrUpdateSection: (section: U) => Promise<void>;
  updateDraftNoteState: (section: U) => void;
  lockNote: () => Promise<void>;
  appointment?: Appointment;
  deleteDraft: () => Promise<DeleteVaultItemsMutation>;
};

export type StateController<T = null> = {
  state: ReadOnlyRef<StateSlice<T>>;
  setLoading: () => void;
  setError: (e: Error) => void;
  setData: (item: T) => void;
};

export function useStateSlice<T = null>(
  initialState: StateSlice<T> = loadingState(),
): StateController<T> {
  const state = useRef<StateSlice<T>>(initialState);
  const [, forceRender] = useState(false);

  const setState = (updated: StateSlice<T>) => {
    state.current = updated;
    forceRender((bool) => !bool); // flipping the flag forces a re-render
  };

  return {
    setData: (item: T) => setState(dataState(item)),
    setError: (e: Error) => setState(errorState(e)),
    setLoading: () => setState(loadingState()),
    state,
  };
}

export function buildCodeSets(
  codeSets: ProtobufCodeSet[],
  previousCodeSets: ProtobufCodeSet[],
  deprecatedDiagnosisCodes: number[] = [],
) {
  if (areCodeSetsValid(codeSets)) {
    return codeSets;
  }

  const newCodeSets = [];

  if (areCodeSetsValid(previousCodeSets)) {
    for (let i = 0; i < previousCodeSets.length; i++) {
      const codeSet = previousCodeSets[i];
      const icd10Codes = codeSet.icd10Codes.filter(
        (c) => !deprecatedDiagnosisCodes.includes(c),
      );
      // If all icd10 codes are marked as deprecated don't create the code set
      if (icd10Codes.length > 0) {
        newCodeSets.push({
          cptCode: 0, // The CPT Code must not be carried over from previous appointments.
          icd10Codes,
        } as ProtobufCodeSet);
      }
    }
  }

  return newCodeSets;
}

export const collaborationPlanGoalsToTreatmentGoals = (
  currentNote: PsychiatryProgressNote | TherapyProgressNote,
  previousNote:
    | PsychiatryProgressNote
    | PsychiatryIntakeNote
    | TherapyProgressNote
    | TherapyIntakeNote,
): TreatmentGoal[] => {
  if (
    currentNote.TREATMENT_PLAN?.goal &&
    currentNote.TREATMENT_PLAN?.goal.length > 0
  ) {
    return currentNote.TREATMENT_PLAN?.goal;
  }

  if (
    previousNote.TREATMENT_PLAN?.goal &&
    previousNote.TREATMENT_PLAN?.goal.length > 0
  ) {
    const previousGoals = previousNote.TREATMENT_PLAN?.goal.map((goal) => ({
      actionPlan: goal.actionPlan,
      goalDetails: goal.goalDetails,
      goalStatus: goal.goalStatus,
      goalStatusUpdateComment: goal.goalStatusUpdateComment,
      goalType: goal.goalType,
      otherGoalStatusComment: '',
    }));
    // We only pull forward active goals
    return previousGoals.filter(
      (goal) => goal.goalStatus === TreatmentGoal_GoalStatus.active,
    );
  }

  if (
    previousNote.COLLABORATION_PLAN?.goal &&
    previousNote.COLLABORATION_PLAN?.goal.length > 0
  ) {
    return previousNote.COLLABORATION_PLAN?.goal.map((goal, index) => {
      // Third goal recommendedSteps was meant to be message to care team
      const actionPlanText = index == 2 ? '' : goal.recommendedSteps;
      return {
        actionPlan: actionPlanText,
        goalDetails: '',
        // default to active as collaboration plan goals do not have a goal status
        goalStatus: TreatmentGoal_GoalStatus.active,

        goalStatusUpdateComment: '',
        goalType: mapCollaborationPlanGoalsToTreatmentGoals(goal.goalType),
        otherGoalStatusComment: goal.otherGoalTypeDescription,
      };
    });
  }

  return [];
};

export const mapCollaborationPlanGoalsToTreatmentGoals = (
  goalType: CollaborationPlan_GoalType,
): TreatmentGoal_GoalType => {
  /**
   * This is a mapping of the Collaboration Plan Goals to the Treatment Goals.
   * We are mapping it due to the fact that Notes Efficiency project is using
   * the Treatment Goals instead of the Collaboration Plan Goals.
   * spreadsheet reference: https://docs.google.com/spreadsheets/d/1oly6Jntu4YKkivhU6dUQcYy5r0pliZrK5CMIXl4ZsPs/edit#gid=1954133704
   */

  const goalTypeMap: {
    [key in CollaborationPlan_GoalType]: TreatmentGoal_GoalType;
  } = {
    [CollaborationPlan_GoalType.decrease_symptoms_of_anxiety]:
      TreatmentGoal_GoalType.manage_symptoms_of_anxiety,
    [CollaborationPlan_GoalType.decrease_symptoms_of_depression_or_negative_affect]:
      TreatmentGoal_GoalType.manage_symptoms_of_depression_or_negative_affect,
    [CollaborationPlan_GoalType.increase_self_awareness_or_insight]:
      TreatmentGoal_GoalType.improve_self_awareness_or_insight,
    [CollaborationPlan_GoalType.increase_coping_stress_management_skills]:
      TreatmentGoal_GoalType.increase_coping_stress_management_skills,
    [CollaborationPlan_GoalType.increase_willingness_to_follow_through_on_treatment_recommendations]:
      TreatmentGoal_GoalType.improve_willingness_to_follow_through_on_treatment_recommendations,
    [CollaborationPlan_GoalType.improve_self_care_]:
      TreatmentGoal_GoalType.improve_self_care,
    [CollaborationPlan_GoalType.improve_communication_interpersonal_effectiveness]:
      TreatmentGoal_GoalType.improve_communication_interpersonal_effectiveness,
    [CollaborationPlan_GoalType.decrease_engagement_in_unhealthy_relationships_or_interpersonal_patterns]:
      TreatmentGoal_GoalType.decrease_engagement_in_unhealthy_relationships_interpersonal_patterns,
    [CollaborationPlan_GoalType.increase_emotional_regulation]:
      TreatmentGoal_GoalType.improve_emotional_regulation,
    [CollaborationPlan_GoalType.improve_self_esteem_self_concept]:
      TreatmentGoal_GoalType.improve_self_esteem_self_concept,
    [CollaborationPlan_GoalType.decrease_the_effects_of_better_tolerate_symptoms_of_trauma_or_loss]:
      TreatmentGoal_GoalType.decrease_the_effects_of_better_tolerate_symptoms_of_trauma_or_loss,
    [CollaborationPlan_GoalType.increase_healthy_adaptive_cognitions]:
      TreatmentGoal_GoalType.increase_healthy_adaptive_cognitions,
    [CollaborationPlan_GoalType.improve_relaxation_mindfulness_skills]:
      TreatmentGoal_GoalType.improve_relaxation_mindfulness_skills,
    [CollaborationPlan_GoalType.increase_distress_tolerance]:
      TreatmentGoal_GoalType.increase_distress_tolerance,
    [CollaborationPlan_GoalType.decrease_substance_use]:
      TreatmentGoal_GoalType.decrease_substance_use,
    [CollaborationPlan_GoalType.decrease_or_eliminate_si]:
      TreatmentGoal_GoalType.decrease_or_eliminate_si,
    [CollaborationPlan_GoalType.decrease_unhealthy_eating_patterns]:
      TreatmentGoal_GoalType.decrease_unhealthy_eating_patterns,
    [CollaborationPlan_GoalType.improve_body_image]:
      TreatmentGoal_GoalType.improve_body_image,
    [CollaborationPlan_GoalType.decrease_or_eliminate_self_harm]:
      TreatmentGoal_GoalType.decrease_or_eliminate_self_harm,
    [CollaborationPlan_GoalType.other]: TreatmentGoal_GoalType.other,
    [CollaborationPlan_GoalType.UNRECOGNIZED]:
      TreatmentGoal_GoalType.UNRECOGNIZED,
    [CollaborationPlan_GoalType.undefined_goal_type]:
      TreatmentGoal_GoalType.undefined_goal_type,
  };

  return goalTypeMap[goalType];
};

export const getPreviousCompleteAppointmentAndNoteType = async (
  api: PsychiatryProgressNotesAPI | TherapyProgressNotesAPI,
  appointment: Appointment,
  appointmentTypes: ClinicalAppointmentType[],
): Promise<AppointmentIdWithNoteType> => {
  const previousAppointmentPagination: PaginationInput = {
    maxItemsPerPage: 1,
  };
  const previousAppointment = await getMemberAppointments(
    api.apollo,
    appointment.member.id,
    [ClinicalAppointmentStatus.Complete],
    previousAppointmentPagination,
    appointmentTypes,
  );
  const previousApptIdAndNoteType =
    previousAppointment?.length > 0 && previousAppointment[0].clinicalNote
      ? {
          id: previousAppointment[0].id,
          noteType: clinicalAppointNoteTypeToNoteType(
            previousAppointment[0].clinicalNote?.noteType,
          ),
        }
      : null;
  return previousApptIdAndNoteType;
};

export const isItemAlreadyExistsError = (e: Error) => {
  if (isApolloError(e)) {
    return e.graphQLErrors.some(
      (error) => error.extensions?.code === 'VAULT_ITEM_ALREADY_EXIST',
    );
  }
  return false;
};
