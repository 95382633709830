import { KeyGenerator } from '@ginger.io/vault-core/dist/crypto';
import { clinicalDocument_ClinicalDocumentTypeToJSON } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/ClinicalDocument';
import { ItemState } from '@ginger.io/vault-member-chart/dist/generated/protobuf-schemas/vault-member-chart/member-tasks/ItemState';
import {
  Reason,
  VaultItemSortOrder,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import useTaskMutations from 'app/member-chart-cards/tasks/useTaskMutations';
import { useCareProviderTasks } from 'app/member-chart-cards/tasks/useTasks';
import { useAppState } from 'app/state';
import {
  documentDeleteClicked,
  documentDownloadClicked,
  documentEditClicked,
  documentFileUploadClicked,
  documentPreviewClicked,
  documentPrintClicked,
  documentTabViewed,
  documentUploadClicked,
} from 'app/state/amplitude/actions/documents';
import { useLogger } from 'app/state/log/useLogger';
import { Status } from 'app/state/status/types/StateSlice';
import axios from 'axios';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useOnMount } from 'hooks/useOnMount';
import { useSnackNotification } from 'hooks/useSnackNotification';
import mime from 'mime-types';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from 'shared-components/button/Button';
import { isClinicianOrSupervisor } from 'utils';

import { ActionModal } from './ActionModal';
import DocumentGrid, { convertSliceStateToElement } from './DocumentGrid';
import { Action } from './DocumentGrid/DocumentGridActionButton';
import { EmptyTableView } from './DocumentGrid/EmptyTableView';
import styles from './DocumentUpload.module.scss';
import { Document, UpdateDocumentInput, UploadDocumentInput } from './types';
import DocumentUpdateModal from './UploadModal/DocumentUpdateModal';
import DocumentUploadModal from './UploadModal/DocumentUploadModal';
import { useClinicalDocument } from './useClinicalDocument';
import { ALLOWED_FILE_TYPES, sanitizeFile } from './utils/sanitizeFile';

interface Props {
  memberId: string;
  onPut?: (...args: Array<any>) => Promise<any>;
  generateId?: () => string;
  keyGenerator?: KeyGenerator;
}

function printDocument(document: Document) {
  return {
    ...document,
    documentType: clinicalDocument_ClinicalDocumentTypeToJSON(
      document.documentType,
    ),
    name: '<redacted>',
  };
}

export default function DocumentUpload(props: Props) {
  const { memberId, keyGenerator, generateId, onPut } = props;
  const dispatch = useDispatch();
  const uploadInputRef = useRef<HTMLInputElement>(null);
  const role = useAppState(({ user }) => user.role);

  const [open, setOpen] = useState(false);
  const [files, setFiles] = useState<FileList | null>(null);
  const [filesToUpload, setFilesToUpload] = useState<UploadDocumentInput[]>();
  const onUploadClick = async (event: React.FormEvent) => {
    const { files: inputFiles } = event.target as HTMLInputElement;
    if (inputFiles && inputFiles.length > 0) {
      const sanitizedFiles = await Promise.all(
        Array.from(inputFiles)
          .filter((file) => ALLOWED_FILE_TYPES.includes(file.type))
          .map(sanitizeFile),
      );

      if (sanitizedFiles.length > 0) {
        const dataTransfer = new DataTransfer();
        sanitizedFiles.forEach((file) => {
          if (file) {
            dataTransfer.items.add(file);
          }
        });

        setFiles(dataTransfer.files);
        setOpen(true);
      } else {
        showErrorNotification('Upload failed: Unsupported file format');
      }
    }
    dispatch(documentFileUploadClicked({ patientId: memberId }));
  };
  const {
    enable_care_hub_notes_efficiency,
    enable_tasks_v2,
  } = useFeatureFlags().transientFeatureFlags;
  const { dismissTasksByReason } = useTaskMutations({ memberId });
  const styleClassName = enable_care_hub_notes_efficiency
    ? styles.documentUploadV2
    : styles.documentUpload;

  /**
   *  This function allows the input to take the same file twice, by clearing its cache onClick
   */
  const clearFiles = (event: React.FormEvent) =>
    ((event.target as HTMLInputElement).value = '');

  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const logger = useLogger();
  const [sortOrder, setSortOrder] = useState<VaultItemSortOrder>(
    VaultItemSortOrder.DESC,
  );
  const [docToDelete, setDocToDelete] = useState<Document>();
  const [docToEdit, setDocToEdit] = useState<Document>();
  const [printURL, setPrintURL] = useState<string>();
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const {
    documents,
    deleteDocument,
    uploadDocuments,
    refreshData,
    updateDocument,
    generateDownloadUrl,
  } = useClinicalDocument(memberId, { generateId, keyGenerator, onPut });

  const tasks = useCareProviderTasks(memberId);
  useOnMount(() => {
    dispatch(documentTabViewed({ patientId: memberId }));
  });

  useEffect(() => {
    if (
      !enable_tasks_v2 ||
      !isClinicianOrSupervisor(role) ||
      tasks.status !== Status.COMPLETE
    )
      return;
    const reason = Reason.NewClinicalDocumentUpload;
    const unchecked = [
      ItemState.unchecked,
      ItemState.undefined_state,
      undefined,
    ];
    const unCompletedCollabTasks = tasks.data.todaysItems.filter(
      (_) => _.label === reason && unchecked.includes(_.state),
    );
    if (unCompletedCollabTasks.length > 0) dismissTasksByReason([reason]);
  }, [tasks.status, enable_tasks_v2, role]);

  const createBlobURL = async (doc: Document) => {
    const url = await generateDownloadUrl(doc);
    const response = await axios({ method: 'GET', responseType: 'blob', url });
    const blob = new Blob([response.data], {
      type: mime.contentType(doc.ext) || undefined,
    });
    return URL.createObjectURL(blob);
  };

  const onPrint = async (document: Document) => {
    try {
      setPrintURL(await createBlobURL(document));
      dispatch(documentPrintClicked({ patientId: memberId }));
    } catch (error) {
      logger.error(new Error('DocumentUpload: Unable to print document'), {
        document: printDocument(document),
        error,
        memberId,
      });
      showErrorNotification(`Unable to print ${document.name}.${document.ext}`);
    }
  };

  const onDownload = async (document: Document) => {
    try {
      const downloadURL = await generateDownloadUrl(document);
      window.open(downloadURL, '_self');
      dispatch(documentDownloadClicked({ patientId: memberId }));
    } catch (error) {
      logger.error(new Error('DocumentUpload: Unable to download document'), {
        document: printDocument(document),
        error,
        memberId,
      });
      showErrorNotification(
        `Unable to download ${document.name}.${document.ext}`,
      );
    }
  };

  const onPreview = async (document: Document) => {
    try {
      const url = await createBlobURL(document);
      dispatch(documentPreviewClicked({ patientId: memberId }));
      window
        .open(url, '_blank', 'width=600,height=600')
        ?.addEventListener('beforeunload', () => {
          URL.revokeObjectURL(url);
        });
    } catch (error) {
      logger.error(new Error('DocumentUpload: Unable to preview document'), {
        document: printDocument(document),
        error,
        memberId,
      });
      showErrorNotification(
        `Unable to preview document: ${document.name}.${document.ext}`,
      );
    }
  };

  const onActionItemClick = async (action: Action, document: Document) => {
    switch (action) {
      case Action.DELETE:
        setDocToDelete(document);
        dispatch(documentDeleteClicked({ patientId: memberId }));
        break;
      case Action.DOWNLOAD:
        await onDownload(document);
        break;
      case Action.EDIT:
        setDocToEdit(document);
        setIsEditModalOpen(true);
        dispatch(documentEditClicked({ patientId: memberId }));
        break;
      case Action.PRINT:
        await onPrint(document);
        break;
      default:
        logger.warning('DocumentUpload: Unknown action', {
          action,
          document: printDocument(document),
        });
    }
  };

  const handleEditConfirm = async (input: UpdateDocumentInput) => {
    try {
      await updateDocument(input);
    } catch (e) {
      showErrorNotification('Unable to update clinical document');
    } finally {
      setIsEditModalOpen(false);
    }
  };

  const onSortClick = async (order: VaultItemSortOrder) => {
    await refreshData(order);
    setSortOrder(order);
  };

  const onError = (message: string) => showErrorNotification(message);

  const onModalClose = () => {
    setFiles(null);
    setOpen(false);
  };

  const shouldShowTable =
    (!!documents.data && documents.data.length > 0) ||
    (filesToUpload ?? [])?.length > 0;
  const { loader, errorElement } = convertSliceStateToElement(
    documents,
    onActionItemClick,
    onPreview,
  );
  if (errorElement) return errorElement;

  let mainView: ReactElement | null = loader;
  if (!shouldShowTable && documents.status === Status.COMPLETE) {
    mainView = (
      <EmptyTableView
        onClick={() => {
          if (uploadInputRef!.current) {
            uploadInputRef.current.click();
          }
        }}
      />
    );
  } else if (shouldShowTable) {
    mainView = (
      <DocumentGrid
        filesToUpload={filesToUpload}
        uploadDocuments={uploadDocuments}
        refreshData={refreshData}
        documents={documents}
        onSortClick={onSortClick}
        onActionItemClick={onActionItemClick}
        onPreview={onPreview}
        onComplete={() => setFilesToUpload(undefined)}
        onError={onError}
      />
    );
  }

  return (
    <div className={styleClassName}>
      <div className={styles.header}>
        <div>
          <input
            ref={uploadInputRef}
            data-testid="document-upload-input"
            style={{ display: 'none' }}
            id="document-upload-input"
            multiple={true}
            type="file"
            onClick={clearFiles}
            onInput={onUploadClick}
            accept={ALLOWED_FILE_TYPES.join(',')}
          />
          <label htmlFor="document-upload-input">
            <Button className={styles.uploadBtn} size="medium" component="span">
              File Upload
            </Button>
          </label>
        </div>
      </div>
      <div className={styles.body}>{mainView}</div>
      <DocumentUploadModal
        open={open}
        onUploadClick={(files) => {
          setFilesToUpload(files);
          dispatch(documentUploadClicked({ patientId: memberId }));
        }}
        onClose={onModalClose}
        files={files}
        memberId={memberId}
      />
      {printURL && (
        <PrintDocIFrame
          url={printURL}
          onClose={() => URL.revokeObjectURL(printURL)}
        />
      )}
      <ActionModal
        isOpen={docToDelete !== undefined}
        isConfirmDisabled={false}
        title="Delete Document"
        actionLabel="Delete"
        onClose={() => setDocToDelete(undefined)}
        onConfirm={async () => {
          if (docToDelete) {
            const result = await deleteDocument(docToDelete.itemId);
            if (result.success) {
              showSuccessNotification(
                `Deleted ${docToDelete.name} successfully`,
              );
              await refreshData(sortOrder);
              setDocToDelete(undefined);
            } else {
              onError(
                result.errorMessage ?? `Unable to delete ${docToDelete.name}`,
              );
            }
          }
        }}
      >
        <div>
          <strong>Are you sure you want to delete?</strong>
          <p>Documents cannot be recovered once deleted.</p>
        </div>
      </ActionModal>
      <DocumentUpdateModal
        open={isEditModalOpen}
        document={docToEdit}
        onClose={() => {
          setIsEditModalOpen(false);
          setDocToEdit(undefined);
        }}
        onUploadClick={handleEditConfirm}
      />
    </div>
  );
}

function PrintDocIFrame(props: { url: string; onClose?: () => void }) {
  const onload = function (e: any) {
    const { contentWindow } = e.target!;
    if (contentWindow) {
      contentWindow.onbeforeunload = props.onClose;
      contentWindow.onafterprint = props.onClose;
      contentWindow.focus();
      contentWindow.print();
    }
  };

  const IFrame = React.createElement(
    'iframe',
    { onLoad: onload, src: props.url, style: { display: 'none' } },
    null,
  );
  return <>{IFrame}</>;
}
