import { GetMemberQueryWithCareTeam_getMember as Member } from '@headspace/carehub-graphql/dist/queries/generated/GetMemberQueryWithCareTeam';
import { MemberChartDataState } from 'app/inbox/multi-tabs/MemberChartDataState';
import { MultiTabMemberScreen } from 'app/inbox/multi-tabs/MultiTabMemberScreen';
import { Routes, TopLevelRoutes } from 'app/top-nav/Routes';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import { useUpdateRiskAlertIndicator } from 'hooks/useUpdateRiskAlertIndicator';
import React from 'react';
import { useHistory } from 'react-router-dom';
import { classNameCombiner } from 'utils';

import { MemberHeaderBar } from './header/MemberHeaderBar';
import { PatientProfile as PatientProfileV2 } from './PatientProfileV2';
import styles from './PatientsScreen.module.scss';
import { summaryRoute } from './Routes';

export const MemoizedMemberChartDataState = React.memo(
  ({ selectedMemberId }: { selectedMemberId: string }) => {
    return (
      <MemberChartDataState
        Component={MemberChartTabContent}
        selectedMemberId={selectedMemberId}
      />
    );
  },
);

function PatientsScreenV2() {
  const history = useHistory();
  const navigateTo = (key: string | null) => {
    if (key !== null) {
      history.replace(summaryRoute(Routes.PATIENTS, key));
    } else {
      history.replace(TopLevelRoutes.PATIENTS);
    }
  };
  return (
    <MultiTabMemberScreen
      paramKey="patientId"
      Component={MemoizedMemberChartDataState}
      navigateTo={navigateTo}
    />
  );
}

const MemoizedPatientProfileV2 = React.memo(PatientProfileV2);
const MemoizedMemberHeaderBar = React.memo(MemberHeaderBar);

function MemberChartTabContent({ member }: { member: Member }) {
  const {
    enable_care_hub_notes_efficiency: enableV2,
  } = useFeatureFlags().transientFeatureFlags;
  const className = enableV2 ? styles.profileV2Wrapper : styles.profileWrapper;
  const { id: memberId } = member;
  useUpdateRiskAlertIndicator(memberId);

  return (
    <>
      <MemoizedMemberHeaderBar memberId={memberId} />
      <div className={classNameCombiner([className, styles.withMultiTab])}>
        <MemoizedPatientProfileV2
          member={member}
          selectedPatientId={memberId}
          pathPrefix={Routes.PATIENTS}
        />
      </div>
    </>
  );
}

export default PatientsScreenV2;
