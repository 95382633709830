import { CoachingSessionModificationScope } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import React from 'react';
import { Button } from 'shared-components/button/Button';

import {
  CancelSessionModal,
  DialogDetails,
} from '../modals/CancelSessionModal';
import { SessionModal } from '../modals/SessionModal';
import styles from '../SchedulerCard.module.scss';
import { ExistingCoachingSession } from '../types';

export const CancelAndEditButtons = ({
  existingCoachingSession,
  onClickCancel,
  onDismissConfirmEdit,
  onConfirmEdit,
  showConfirmEdit,
  editDialogDetails,
  onDismissConfirmCancel,
  onConfirmCancel,
  showConfirmCancel,
  cancelDialogDetails,
}: {
  existingCoachingSession: ExistingCoachingSession;
  onClickCancel: () => void;
  onDismissConfirmEdit: () => void;
  onConfirmEdit: (modificationScope: CoachingSessionModificationScope) => void;
  showConfirmEdit: boolean;
  editDialogDetails: DialogDetails;
  onDismissConfirmCancel: () => void;
  onConfirmCancel: (
    modificationScope: CoachingSessionModificationScope,
  ) => void;
  showConfirmCancel: boolean;
  cancelDialogDetails: DialogDetails;
}) => (
  <>
    <Button
      aria-controls="cancel-session"
      aria-haspopup="true"
      onClick={onClickCancel}
      className={styles.cancelSession}
      variant="outlined"
      size="small"
      disabled={false}
      testId="cancelSessionBtn"
    >
      Cancel Session
    </Button>

    <SessionModal
      onClose={onDismissConfirmEdit}
      sessionId={existingCoachingSession?.coachingSession.id}
      onConfirm={onConfirmEdit}
      open={showConfirmEdit}
      isSingleSession={existingCoachingSession.recurrence === null}
      dialogDetails={editDialogDetails}
    />

    <CancelSessionModal
      onClose={onDismissConfirmCancel}
      sessionId={existingCoachingSession?.coachingSession.id}
      onConfirm={onConfirmCancel}
      open={showConfirmCancel}
      isSingleSession={existingCoachingSession.recurrence === null}
      dialogDetails={cancelDialogDetails}
    />
  </>
);
