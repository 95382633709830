import {
  Assessment,
  Assessment_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/progress/Assessment';
import { Allergies } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/Allergies';
import {
  ChiefComplaint,
  ChiefComplaint_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/ChiefComplaint';
import { FamilyHistory } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/FamilyHistory';
import {
  MedicalHistory,
  MedicalHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MedicalHistory';
import {
  MentalStatusExam,
  MentalStatusExam_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import {
  PsychiatricHistory,
  PsychiatricHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychiatricHistory';
import {
  PsychReviewOfSystem,
  PsychReviewOfSystem_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/PsychROS';
import {
  SocialHistory,
  SocialHistory_ChildHoodAbuse,
  SocialHistory_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/SocialHistory';
import { TreatmentPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/TreatmentPlan';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { BooleanWithComments } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanWithComments';
import { CollaborationPlan } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/CollaborationPlan';
import { NoteType as ClinicalNoteType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/NoteType';
import {
  SafetyPlanStatus,
  Version as SafetyPlanVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/safety/SafetyPlan';
import {
  Safety,
  Version as SafetyVersion,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SafetyProgress';
import {
  SubstanceUse,
  SubstanceUse_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/SubstanceUse';
import { TreatmentPlan_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/intake/TreatmentPlan';
import { PsychiatrySectionName } from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { MentalStatusExam_MentalStatusItem } from '@ginger.io/vault-clinical-notes/src/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import { remapSubstanceUse } from 'app/notes-ui/shared/substance-abuse/utils';
import { PHQGADSurvey } from 'app/vault/api/ClinicalNotesAPI';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { PsychiatryProgressNote } from 'app/vault/api/PsychiatryProgressNotesAPI';
import { formatSurveyDetailFromClinicalNote } from 'app/vault/data/utils';
import {
  mapMedicalHistory,
  mapMedicationResponses,
  mapSuicideAttempt,
} from 'app/vault/hooks/templateMappingUtil';
import {
  buildCodeSets,
  collaborationPlanGoalsToTreatmentGoals,
} from 'app/vault/hooks/utils';

export const mapOldChildAbuseField = (
  socialHistory: SocialHistory | null,
): SocialHistory_ChildHoodAbuse[] => {
  const newChildAbuseField: SocialHistory_ChildHoodAbuse[] = [];
  if (socialHistory?.childhood?.childAbuse) {
    const { physical, sexual, emotional } = socialHistory.childhood.childAbuse;
    if (physical) {
      newChildAbuseField.push(SocialHistory_ChildHoodAbuse.physical);
    }
    if (sexual) {
      newChildAbuseField.push(SocialHistory_ChildHoodAbuse.sexual);
    }
    if (emotional) {
      newChildAbuseField.push(SocialHistory_ChildHoodAbuse.emotional);
    }
  }
  if (!newChildAbuseField.length && socialHistory?.childAbuseExperiences) {
    return socialHistory.childAbuseExperiences;
  }
  return newChildAbuseField;
};

export function buildPsychiatryProgressNote(params: {
  appointmentId: string;
  currentNote: PsychiatryProgressNote;
  deprecatedDiagnosisCodes: number[];
  enableCareHubNotesEfficiency: boolean;
  phqGadSurvey: PHQGADSurvey | null;
  previousNote: PsychiatryProgressNote | PsychiatryIntakeNote | null;
}): Partial<PsychiatryProgressNote> {
  const {
    appointmentId,
    currentNote,
    previousNote,
    enableCareHubNotesEfficiency,
    deprecatedDiagnosisCodes,
    phqGadSurvey,
  } = params;
  // copy forward from previous note: Safety.safetyPlan, SubstanceUsed current &
  // past used substances, Assessment icd-10 codes, CollaborationPlan goals.
  // History of Present Illness (HPI)
  // Note: Vault client may return null of each section of a previous note.
  // This can happen when previous note has not been shared with the clinician.

  // if notes efficiency feature flag is enabled, we will copy forward additional fields from previous note

  let psychROS: PsychReviewOfSystem | null = null;
  let mentalStatusExam: MentalStatusExam | null = null;
  let safety: Safety | null = null;
  let collaborationPlan: CollaborationPlan | null = null;
  let substanceAbuse: SubstanceUse | null = null;
  let assessment: Assessment | null = null;
  let chiefComplaint: ChiefComplaint | null = null;
  let psychiatricHistory: PsychiatricHistory | null = null;
  let allergies: Allergies | null = null;
  let socialHistory: SocialHistory | null = null;
  let medicalHistory: MedicalHistory | null = null;
  let familyHistory: FamilyHistory | null = null;
  let treatmentPlan: TreatmentPlan | null = null;
  const surveyScoreSummary = formatSurveyDetailFromClinicalNote(phqGadSurvey);

  if (previousNote?.CHIEF_COMPLAINT) {
    const chiefComplaintText =
      currentNote.CHIEF_COMPLAINT?.chiefComplaint ?? '';
    const hpiText =
      currentNote.CHIEF_COMPLAINT?.historyOfPresentIllness ??
      `${surveyScoreSummary}\n\n${
        previousNote.CHIEF_COMPLAINT.historyOfPresentIllness ?? ''
      }`.trim();
    if (enableCareHubNotesEfficiency) {
      chiefComplaint = {
        appointmentId,
        chiefComplaint: chiefComplaintText,
        historyOfPresentIllness: hpiText,
        relevantPhysicalHealthUpdates:
          currentNote.CHIEF_COMPLAINT?.relevantPhysicalHealthUpdates ??
          previousNote.CHIEF_COMPLAINT.relevantPhysicalHealthUpdates ??
          '',
        version:
          currentNote.CHIEF_COMPLAINT?.version || ChiefComplaint_Version.v0,
      };
    } else {
      chiefComplaint = {
        appointmentId,
        chiefComplaint: chiefComplaintText,
        historyOfPresentIllness: hpiText,
        relevantPhysicalHealthUpdates:
          currentNote.CHIEF_COMPLAINT?.relevantPhysicalHealthUpdates || '',
        version:
          currentNote.CHIEF_COMPLAINT?.version ||
          ChiefComplaint_Version.undefined_version,
      };
    }
  }

  if (previousNote?.PSYCH_REVIEW_OF_SYSTEM) {
    if (enableCareHubNotesEfficiency) {
      psychROS = {
        appointmentId,
        comments:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.comments ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.comments,
        depression:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.depression ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.depression,
        eatingDisorder:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.eatingDisorder ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.eatingDisorder,
        hypomania:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.hypomania ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.hypomania,
        ocd:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.ocd ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.ocd,
        panic:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.panic ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.panic,
        psychosis:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.psychosis ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.psychosis,
        ptsd:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.ptsd ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.ptsd,
        version:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.version ||
          PsychReviewOfSystem_Version.v0,
      };
    } else {
      psychROS = {
        appointmentId,
        comments: currentNote.PSYCH_REVIEW_OF_SYSTEM?.comments || '',
        depression:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.depression ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.depression,
        eatingDisorder:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.eatingDisorder || [],
        hypomania:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.hypomania ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.hypomania,
        ocd:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.ocd ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.ocd,
        panic:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.panic ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.panic,
        psychosis:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.psychosis ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.psychosis,
        ptsd:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.ptsd ||
          previousNote.PSYCH_REVIEW_OF_SYSTEM.ptsd,
        version:
          currentNote.PSYCH_REVIEW_OF_SYSTEM?.version ||
          PsychReviewOfSystem_Version.undefined_version,
      };
    }
  }

  if (previousNote?.SAFETY) {
    let fallbackSafetyVersion = SafetyVersion.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackSafetyVersion = SafetyVersion.v1;
    }

    safety = {
      anyChangesOrRisks:
        currentNote.SAFETY?.anyChangesOrRisks ||
        previousNote.SAFETY.anyChangesOrRisks,
      appointmentId,
      currentHomicidalIdeation:
        currentNote.SAFETY?.currentHomicidalIdeation ||
        previousNote.SAFETY.currentHomicidalIdeation,
      currentSelfInjury:
        currentNote.SAFETY?.currentSelfInjury ||
        previousNote.SAFETY.currentSelfInjury,
      currentSuicidalIdeation:
        currentNote.SAFETY?.currentSuicidalIdeation ||
        previousNote.SAFETY.currentSuicidalIdeation,
      domesticViolenceRisk:
        currentNote.SAFETY?.domesticViolenceRisk ||
        previousNote.SAFETY.domesticViolenceRisk,
      eatingDisorderRisk:
        currentNote.SAFETY?.eatingDisorderRisk ||
        previousNote.SAFETY.eatingDisorderRisk,
      highSubstanceUse:
        currentNote.SAFETY?.highSubstanceUse ||
        previousNote.SAFETY.highSubstanceUse,
      otherConcerns:
        currentNote.SAFETY?.otherConcerns || previousNote.SAFETY.otherConcerns,
      safetyPlan: {
        actionsTaken:
          currentNote.SAFETY?.safetyPlan?.actionsTaken ||
          previousNote.SAFETY.safetyPlan?.actionsTaken,
        appointmentEnd: '',
        appointmentId: '',
        appointmentStart: '',
        description:
          currentNote.SAFETY?.safetyPlan?.description ??
          previousNote.SAFETY.safetyPlan?.description ??
          '',
        noteType: ClinicalNoteType.undefined_note_type,
        psychiatryQuestions:
          currentNote.SAFETY?.safetyPlan?.psychiatryQuestions ||
          previousNote.SAFETY.safetyPlan?.psychiatryQuestions,
        status:
          currentNote.SAFETY?.safetyPlan?.status ||
          previousNote.SAFETY.safetyPlan?.status ||
          SafetyPlanStatus.undefined_status,
        therapyQuestions:
          currentNote.SAFETY?.safetyPlan?.therapyQuestions ||
          previousNote.SAFETY.safetyPlan?.therapyQuestions,
        version:
          currentNote.SAFETY?.safetyPlan?.version ||
          SafetyPlanVersion.undefined_version,
      },
      selectedConcerns:
        currentNote.SAFETY?.selectedConcerns ||
        previousNote.SAFETY.selectedConcerns ||
        [],
      version: currentNote.SAFETY?.version || fallbackSafetyVersion,
      vulnerablePopulationsAbuse:
        currentNote.SAFETY?.vulnerablePopulationsAbuse ||
        previousNote.SAFETY.vulnerablePopulationsAbuse,
    };
  }

  if (previousNote?.COLLABORATION_PLAN) {
    const goals = previousNote.COLLABORATION_PLAN!.goal;
    collaborationPlan = {
      appointmentId,
      goal: currentNote.COLLABORATION_PLAN?.goal || goals,
    };
  }

  if (previousNote?.ASSESSMENT) {
    let fallbackAssessmentVersion = Assessment_Version.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackAssessmentVersion = Assessment_Version.v0;
    }
    const previousAssessment = previousNote.ASSESSMENT as PsychiatryProgressNote['ASSESSMENT'];
    const previousIntakeAssessment = previousNote.ASSESSMENT as PsychiatryIntakeNote['ASSESSMENT'];
    assessment = {
      appointmentDurationInMinutes:
        currentNote.ASSESSMENT?.appointmentDurationInMinutes || 0,
      appointmentId,
      codeSets: buildCodeSets(
        currentNote.ASSESSMENT?.codeSets ?? [],
        previousAssessment?.codeSets ?? [],
        deprecatedDiagnosisCodes,
      ),
      cptCode: currentNote.ASSESSMENT?.cptCode || 0,
      icd10Codes:
        currentNote.ASSESSMENT?.icd10Codes ||
        previousAssessment?.icd10Codes?.filter(
          (c) => !deprecatedDiagnosisCodes.includes(c),
        ) ||
        [],
      todaysUpdate:
        currentNote.ASSESSMENT?.todaysUpdate ||
        previousAssessment?.todaysUpdate ||
        previousIntakeAssessment?.formulationAndMedicalDecisionMaking ||
        '',
      version: currentNote.ASSESSMENT?.version || fallbackAssessmentVersion,
    };
  }

  if (previousNote?.SUBSTANCE_ABUSE) {
    const substanceUsedVersion = enableCareHubNotesEfficiency
      ? SubstanceUse_Version.v0
      : SubstanceUse_Version.undefined_version;
    const substanceUsed = {
      anySubstanceUsed:
        previousNote.SUBSTANCE_ABUSE.anySubstanceUsed ?? BooleanOption.yes,
      appointmentId,
      pastEtohOrBenzoWithdrawal:
        previousNote.SUBSTANCE_ABUSE.pastEtohOrBenzoWithdrawal ??
        BooleanWithComments.fromPartial({}),
      pastSubstanceUseTreatment:
        previousNote.SUBSTANCE_ABUSE.pastSubstanceUseTreatment ??
        BooleanWithComments.fromPartial({}),
      substancesCurrentlyUsed:
        previousNote.SUBSTANCE_ABUSE.substancesCurrentlyUsed ?? [],
      substancesPreviouslyUsed:
        previousNote.SUBSTANCE_ABUSE.substancesPreviouslyUsed ?? [],
      version: substanceUsedVersion,
    };

    substanceAbuse = {
      anySubstanceUsed:
        currentNote.SUBSTANCE_ABUSE?.anySubstanceUsed ??
        substanceUsed.anySubstanceUsed,
      appointmentId,
      pastEtohOrBenzoWithdrawal: {
        description:
          currentNote.SUBSTANCE_ABUSE?.pastEtohOrBenzoWithdrawal?.description ??
          substanceUsed.pastEtohOrBenzoWithdrawal.description,
        isPresent:
          currentNote.SUBSTANCE_ABUSE?.pastEtohOrBenzoWithdrawal?.isPresent ??
          substanceUsed.pastEtohOrBenzoWithdrawal.isPresent,
      },
      pastSubstanceUseTreatment: {
        description:
          currentNote.SUBSTANCE_ABUSE?.pastSubstanceUseTreatment?.description ??
          substanceUsed.pastSubstanceUseTreatment.description,
        isPresent:
          currentNote.SUBSTANCE_ABUSE?.pastSubstanceUseTreatment?.isPresent ??
          substanceUsed.pastSubstanceUseTreatment.isPresent,
      },
      substancesCurrentlyUsed: remapSubstanceUse(
        currentNote.SUBSTANCE_ABUSE?.substancesCurrentlyUsed ??
          substanceUsed.substancesCurrentlyUsed,
      ),
      substancesPreviouslyUsed: remapSubstanceUse(
        currentNote.SUBSTANCE_ABUSE?.substancesPreviouslyUsed ??
          substanceUsed.substancesPreviouslyUsed,
      ),
      version: currentNote.SUBSTANCE_ABUSE?.version ?? substanceUsed.version,
    };
  }

  if (previousNote?.PSYCH_HISTORY) {
    if (enableCareHubNotesEfficiency) {
      psychiatricHistory = currentNote.PSYCH_HISTORY || {
        appointmentId,
        historyOfComplicatedEtohOrBenzoWithdrawal:
          previousNote.PSYCH_HISTORY.historyOfComplicatedEtohOrBenzoWithdrawal,
        pastInpatientHospitalization:
          previousNote.PSYCH_HISTORY.pastInpatientHospitalization,
        pastOutpatientTreatment:
          previousNote.PSYCH_HISTORY.pastOutpatientTreatment,
        pastPsychotropicMedicationsResponses: mapMedicationResponses(
          previousNote.PSYCH_HISTORY.pastPsychotropicMedicationsResponses,
        ),
        pastSelfHarmEpisode: previousNote.PSYCH_HISTORY.pastSelfHarmEpisode,
        pastSubstanceUseTreatment:
          previousNote.PSYCH_HISTORY.pastSubstanceUseTreatment,
        pastSuicidalIdeationEpisode:
          previousNote.PSYCH_HISTORY.pastSuicidalIdeationEpisode,
        pastSuicideAttempt: mapSuicideAttempt(
          previousNote.PSYCH_HISTORY.pastSuicideAttempt,
        ),
        pastTreatments: previousNote.PSYCH_HISTORY.pastTreatments,
        version: PsychiatricHistory_Version.v0,
      };
    } else {
      psychiatricHistory = currentNote.PSYCH_HISTORY || {
        ...previousNote.PSYCH_HISTORY,
        appointmentId,
        version: PsychiatricHistory_Version.undefined_version,
      };
    }
  }

  if (previousNote?.ALLERGIES) {
    allergies = currentNote.ALLERGIES || {
      ...previousNote.ALLERGIES,
      appointmentId,
    };
  }

  if (previousNote?.MEDICAL_HISTORY) {
    if (enableCareHubNotesEfficiency) {
      medicalHistory =
        currentNote.MEDICAL_HISTORY ||
        mapMedicalHistory(
          previousNote.MEDICAL_HISTORY,
          previousNote.ALLERGIES?.allergies,
          appointmentId,
        );
    } else {
      medicalHistory = currentNote.MEDICAL_HISTORY || {
        ...previousNote.MEDICAL_HISTORY,
        appointmentId,
        version: MedicalHistory_Version.undefined_version,
      };
    }
  }

  if (previousNote?.SOCIAL_HISTORY) {
    if (enableCareHubNotesEfficiency) {
      socialHistory = currentNote.SOCIAL_HISTORY || {
        ...previousNote.SOCIAL_HISTORY,
        appointmentId,
        childAbuseExperiences: mapOldChildAbuseField(
          previousNote.SOCIAL_HISTORY,
        ),
        family: {
          additionalSocialFamilyHistoryComments:
            previousNote.SOCIAL_HISTORY.family
              ?.additionalSocialFamilyHistoryComments || '',
          history:
            previousNote.SOCIAL_HISTORY?.family?.history ||
            previousNote.FAMILY_HISTORY?.comments ||
            '',
        },
        version: SocialHistory_Version.v0,
      };
    } else {
      socialHistory = currentNote.SOCIAL_HISTORY || {
        ...previousNote.SOCIAL_HISTORY,
        appointmentId,
        version: SocialHistory_Version.undefined_version,
      };
    }
  }

  if (previousNote?.FAMILY_HISTORY) {
    familyHistory = currentNote.FAMILY_HISTORY || {
      ...previousNote.FAMILY_HISTORY,
      appointmentId,
    };
  }
  const defaultValue: MentalStatusExam_MentalStatusItem = {
    effectWithinNormalLimits: false,
    other: {
      description: '',
      isPresent: false,
    },
  };

  if (previousNote?.MENTAL_STATUS_EXAM) {
    if (enableCareHubNotesEfficiency) {
      mentalStatusExam = currentNote.MENTAL_STATUS_EXAM || {
        ...previousNote.MENTAL_STATUS_EXAM,
        appointmentId,
        moodAndAffect: {
          affect: previousNote.MENTAL_STATUS_EXAM.moodAndAffect?.affect,
          affectDescription: [],
          moodInPatientWord: '',
        },
        version: MentalStatusExam_Version.v0,
      };
    } else {
      mentalStatusExam = {
        affect: defaultValue,
        appearance: defaultValue,
        appointmentId,
        associations:
          currentNote.MENTAL_STATUS_EXAM?.associations ||
          previousNote.MENTAL_STATUS_EXAM.associations,
        attentionAndConcentration:
          currentNote.MENTAL_STATUS_EXAM?.attentionAndConcentration ||
          previousNote.MENTAL_STATUS_EXAM.attentionAndConcentration,
        cognitive: defaultValue,
        fundOfKnowledge:
          currentNote.MENTAL_STATUS_EXAM?.fundOfKnowledge ||
          previousNote.MENTAL_STATUS_EXAM.fundOfKnowledge,
        insight:
          currentNote.MENTAL_STATUS_EXAM?.insight ||
          previousNote.MENTAL_STATUS_EXAM.insight,
        insightStatus: defaultValue,
        judgement: defaultValue,
        judgment:
          currentNote.MENTAL_STATUS_EXAM?.judgment ||
          previousNote.MENTAL_STATUS_EXAM.judgment,
        language:
          currentNote.MENTAL_STATUS_EXAM?.language ||
          previousNote.MENTAL_STATUS_EXAM.language,
        moodAndAffect: currentNote.MENTAL_STATUS_EXAM?.moodAndAffect || {
          affect: previousNote.MENTAL_STATUS_EXAM.moodAndAffect?.affect,
          affectDescription: [],
          moodInPatientWord: '',
        },
        orientation:
          currentNote.MENTAL_STATUS_EXAM?.orientation ||
          previousNote.MENTAL_STATUS_EXAM.orientation,
        orientationStatus: defaultValue,
        recentAndRemoteMemory:
          currentNote.MENTAL_STATUS_EXAM?.recentAndRemoteMemory ||
          previousNote.MENTAL_STATUS_EXAM.recentAndRemoteMemory,
        speech:
          currentNote.MENTAL_STATUS_EXAM?.speech ||
          previousNote.MENTAL_STATUS_EXAM.speech,
        speechStatus: defaultValue,
        thoughtContent:
          currentNote.MENTAL_STATUS_EXAM?.thoughtContent ||
          previousNote.MENTAL_STATUS_EXAM.thoughtContent,
        thoughtContentStatus: defaultValue,
        thoughtProcess:
          currentNote.MENTAL_STATUS_EXAM?.thoughtProcess ||
          previousNote.MENTAL_STATUS_EXAM.thoughtProcess,
        thoughtProcessStatus: defaultValue,
        version:
          currentNote.MENTAL_STATUS_EXAM?.version ||
          MentalStatusExam_Version.undefined_version,
      };
    }
  }

  if (previousNote?.TREATMENT_PLAN) {
    let fallbackTreatmentPlanVersion = TreatmentPlan_Version.undefined_version;
    if (enableCareHubNotesEfficiency) {
      fallbackTreatmentPlanVersion = TreatmentPlan_Version.v0;
    }
    if (enableCareHubNotesEfficiency) {
      treatmentPlan = {
        appointmentId,
        appointmentOffered:
          currentNote.TREATMENT_PLAN?.appointmentOffered ||
          previousNote.TREATMENT_PLAN.appointmentOffered ||
          BooleanOption.not_applicable,
        approachesUpdateComments: '',
        followUp: currentNote.TREATMENT_PLAN?.followUp || '',
        goal: collaborationPlanGoalsToTreatmentGoals(currentNote, previousNote),
        medicationManagement:
          currentNote.TREATMENT_PLAN?.medicationManagement ||
          previousNote.TREATMENT_PLAN.medicationManagement,
        memberOutOfScope:
          currentNote.TREATMENT_PLAN?.memberOutOfScope ||
          previousNote.TREATMENT_PLAN.memberOutOfScope ||
          false,
        memberOutOfScopeReason:
          currentNote.TREATMENT_PLAN?.memberOutOfScopeReason ||
          previousNote.TREATMENT_PLAN.memberOutOfScopeReason ||
          '',

        messageToCareTeam: currentNote.TREATMENT_PLAN?.messageToCareTeam || '',
        numberOfSessionsAnticipated:
          currentNote.TREATMENT_PLAN?.numberOfSessionsAnticipated ||
          previousNote.TREATMENT_PLAN.numberOfSessionsAnticipated,
        offeredAppointmentAccepted:
          currentNote.TREATMENT_PLAN?.offeredAppointmentAccepted ||
          previousNote.TREATMENT_PLAN.offeredAppointmentAccepted ||
          BooleanOption.not_applicable,
        referralsToCareOutsideGingerNecessary:
          currentNote.TREATMENT_PLAN?.referralsToCareOutsideGingerNecessary ||
          previousNote.TREATMENT_PLAN.referralsToCareOutsideGingerNecessary,
        // this is not used in the psychiatry note, just in the therapy note.
        version:
          currentNote.TREATMENT_PLAN?.version || fallbackTreatmentPlanVersion,
        waitTimeDetrimentalEffect:
          currentNote.TREATMENT_PLAN?.waitTimeDetrimentalEffect ||
          previousNote.TREATMENT_PLAN.waitTimeDetrimentalEffect,
      };
    } else {
      treatmentPlan = {
        appointmentId,
        appointmentOffered:
          currentNote.TREATMENT_PLAN?.appointmentOffered ||
          previousNote.TREATMENT_PLAN.appointmentOffered ||
          BooleanOption.not_applicable,
        approachesUpdateComments: '',
        followUp: currentNote.TREATMENT_PLAN?.followUp || '',
        goal:
          currentNote.TREATMENT_PLAN?.goal ||
          previousNote.TREATMENT_PLAN.goal ||
          [],
        medicationManagement:
          currentNote.TREATMENT_PLAN?.medicationManagement ||
          previousNote.TREATMENT_PLAN.medicationManagement,
        memberOutOfScope:
          currentNote.TREATMENT_PLAN?.memberOutOfScope ||
          previousNote.TREATMENT_PLAN.memberOutOfScope ||
          false,
        memberOutOfScopeReason:
          currentNote.TREATMENT_PLAN?.memberOutOfScopeReason ||
          previousNote.TREATMENT_PLAN.memberOutOfScopeReason ||
          '',

        messageToCareTeam: currentNote.TREATMENT_PLAN?.messageToCareTeam || '',
        numberOfSessionsAnticipated:
          currentNote.TREATMENT_PLAN?.numberOfSessionsAnticipated ||
          previousNote.TREATMENT_PLAN.numberOfSessionsAnticipated,
        offeredAppointmentAccepted:
          currentNote.TREATMENT_PLAN?.offeredAppointmentAccepted ||
          previousNote.TREATMENT_PLAN.offeredAppointmentAccepted ||
          BooleanOption.not_applicable,
        referralsToCareOutsideGingerNecessary:
          currentNote.TREATMENT_PLAN?.referralsToCareOutsideGingerNecessary ||
          previousNote.TREATMENT_PLAN.referralsToCareOutsideGingerNecessary,
        // this is not used in the psychiatry note, just in the therapy note.
        version:
          currentNote.TREATMENT_PLAN?.version || fallbackTreatmentPlanVersion,
        waitTimeDetrimentalEffect:
          currentNote.TREATMENT_PLAN?.waitTimeDetrimentalEffect ||
          previousNote.TREATMENT_PLAN.waitTimeDetrimentalEffect,
      };
    }
  }
  return {
    [PsychiatrySectionName.CHIEF_COMPLAINT]: chiefComplaint,

    [PsychiatrySectionName.ASSESSMENT]: assessment,

    [PsychiatrySectionName.COLLABORATION_PLAN]: collaborationPlan,

    [PsychiatrySectionName.SUBSTANCE_ABUSE]: substanceAbuse,

    [PsychiatrySectionName.SAFETY]: safety,

    [PsychiatrySectionName.PSYCH_HISTORY]: psychiatricHistory,

    [PsychiatrySectionName.ALLERGIES]: allergies,

    [PsychiatrySectionName.MEDICAL_HISTORY]: medicalHistory,

    [PsychiatrySectionName.SOCIAL_HISTORY]: socialHistory,

    [PsychiatrySectionName.FAMILY_HISTORY]: familyHistory,

    [PsychiatrySectionName.TREATMENT_PLAN]: treatmentPlan,

    [PsychiatrySectionName.PSYCH_ROS]: psychROS,

    [PsychiatrySectionName.MENTAL_STATUS_EXAM]: mentalStatusExam,
  };
}
