import { CSSRSScore } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { CSSRSScoreMap } from 'app/patients/header/utils';
import styles from 'app/patients/tabs/surveys/SurveysV3/InfoBox.module.scss';
import { classNameCombiner } from 'utils';

import { CSSRSSummary } from './types';

interface Props {
  info: CSSRSSummary | null;
}
export default function CssrsInfoBox({ info }: Props) {
  if (!info) {
    return (
      <div className={classNameCombiner([styles.infobox, styles.default])}>
        <div className={styles.defaultText}>
          Click on a specific point above to view a summary
        </div>
      </div>
    );
  }
  const { riskLevel } = info;
  const enumRisk: CSSRSScore = CSSRSScore[riskLevel as keyof typeof CSSRSScore];

  return (
    <div
      className={classNameCombiner([styles.infobox, ''])}
      data-testid="cssrs-Info-box"
    >
      <div className={classNameCombiner([styles.tr, styles.header])}>
        <div className={classNameCombiner([styles.td, styles.title])}>
          Risk Level
        </div>
      </div>
      <div className={styles.tr}>
        {riskLevel ? (
          <div
            className={classNameCombiner([
              styles.td,
              styles.section,
              styles.summary,
            ])}
            data-testId="cssrs-risk-level"
          >
            {CSSRSScoreMap[enumRisk]}
          </div>
        ) : undefined}
      </div>
    </div>
  );
}
