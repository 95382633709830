import { useForm, validate as validateForm } from '@ginger.io/react-use-form';
import {
  MentalStatusExam,
  MentalStatusExam_MentalStatusItem,
  MentalStatusExam_Version,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/psychiatry/shared/MentalStatusExam';
import {
  MentalStatusExamSection,
  PsychiatrySectionName,
} from '@ginger.io/vault-clinical-notes/dist/PsychiatryIntakeSection';
import { CheckboxField, TextAreaField } from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabel } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { isEmpty } from 'app/notes-ui/shared/ValidationRules';
import { NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD } from 'app/notes-ui/utils';
import React from 'react';
import { COLUMN_WIDTH, Columns, Grid } from 'shared-components/grid';
import { Width } from 'types/StyleTypes';

import { Labels } from './constants';
import { fieldDefs } from './schema';

type Props = {
  appointmentId: string;
  onSubmit: (data: MentalStatusExamSection) => void;
  disabled?: boolean;
  initialValue?: MentalStatusExam;
};

export function MentalStatusExamFormV2(props: Props) {
  const { disabled, appointmentId, initialValue } = props;

  const { fields, getValue } = useForm<MentalStatusExam>(
    fieldDefs,
    MentalStatusExam.fromPartial({
      ...initialValue,
      appointmentId,
      version: MentalStatusExam_Version.v0,
    }),
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: PsychiatrySectionName.MENTAL_STATUS_EXAM,
    });
  };

  return (
    <>
      <Grid>
        <NoteFormControlLabel label="Mood (in the patient’s own words)">
          <TextAreaField
            disabled={disabled}
            testId="moodInPatientWord"
            label=""
            placeholder="Type here..."
            field={fields.moodAndAffect.moodInPatientWord}
            rows={1}
            width={Width.FULL}
            autoExpand={true}
          />
        </NoteFormControlLabel>
        <Columns
          className={styles.gridColumn}
          widths={[COLUMN_WIDTH.HALF, COLUMN_WIDTH.HALF]}
        >
          <>
            <NoteFormControlLabel label={Labels.appearance.label}>
              <CheckboxField
                disabled={disabled}
                testId="appearanceLimit"
                label={Labels.appearance.withinLimitLabel}
                field={fields.appearance.effectWithinNormalLimits}
              />
              <CheckboxField
                disabled={disabled}
                testId="appearanceOther"
                label={Labels.appearance.other}
                field={fields.appearance.other.isPresent}
              />
              {fields.appearance.other.isPresent.value && (
                <TextAreaField
                  disabled={disabled}
                  testId="otherAppearanceDescription"
                  label=""
                  placeholder="Comment..."
                  field={fields.appearance.other.description}
                  rows={1}
                  width={Width.FULL}
                  autoExpand={true}
                />
              )}
            </NoteFormControlLabel>
            <NoteFormControlLabel label={Labels.speech.label}>
              <CheckboxField
                disabled={disabled}
                testId="speechLimit"
                label={Labels.speech.withinLimitLabel}
                field={fields.speechStatus.effectWithinNormalLimits}
              />
              <CheckboxField
                disabled={disabled}
                testId="speechOther"
                label={Labels.speech.other}
                field={fields.speechStatus.other.isPresent}
              />
              {fields.speechStatus.other.isPresent.value && (
                <TextAreaField
                  disabled={disabled}
                  testId="otherSpeechDescription"
                  label=""
                  placeholder="Comment..."
                  field={fields.speechStatus.other.description}
                  rows={1}
                  width={Width.FULL}
                  autoExpand={true}
                />
              )}
            </NoteFormControlLabel>

            <NoteFormControlLabel label={Labels.thoughtProcess.label}>
              <CheckboxField
                disabled={disabled}
                testId="thoughtProcessLimit"
                label={Labels.thoughtProcess.withinLimitLabel}
                field={fields.thoughtProcessStatus.effectWithinNormalLimits}
              />
              <CheckboxField
                disabled={disabled}
                testId="thoughtProcessStatusOther"
                label={Labels.thoughtProcess.other}
                field={fields.thoughtProcessStatus.other.isPresent}
              />
              {fields.thoughtProcessStatus.other.isPresent.value && (
                <TextAreaField
                  disabled={disabled}
                  testId="otherThoughtProcessDescription"
                  label=""
                  placeholder="Comment..."
                  field={fields.thoughtProcessStatus.other.description}
                  rows={1}
                  width={Width.FULL}
                  autoExpand={true}
                />
              )}
            </NoteFormControlLabel>

            <NoteFormControlLabel label={Labels.thoughtContent.label}>
              <CheckboxField
                disabled={disabled}
                testId="thoughtContentLimit"
                label={Labels.thoughtContent.withinLimitLabel}
                field={fields.thoughtContentStatus.effectWithinNormalLimits}
              />
              <CheckboxField
                disabled={disabled}
                testId="thoughtContentOther"
                label={Labels.thoughtContent.other}
                field={fields.thoughtContentStatus.other.isPresent}
              />
              {fields.thoughtContentStatus.other.isPresent.value && (
                <TextAreaField
                  disabled={disabled}
                  testId="otherThoughtContentDescription"
                  label=""
                  placeholder="Comment..."
                  field={fields.thoughtContentStatus.other.description}
                  rows={1}
                  width={Width.FULL}
                  autoExpand={true}
                />
              )}
            </NoteFormControlLabel>

            <NoteFormControlLabel label={Labels.affect.label}>
              <CheckboxField
                disabled={disabled}
                testId="affectLimit"
                label={Labels.affect.withinLimitLabel}
                field={fields.affect.effectWithinNormalLimits}
              />
              <CheckboxField
                disabled={disabled}
                testId="affectOther"
                label={Labels.affect.other}
                field={fields.affect.other.isPresent}
              />
              {fields.affect.other.isPresent.value && (
                <TextAreaField
                  disabled={disabled}
                  testId="otherAffectDescription"
                  label=""
                  placeholder="Comment..."
                  field={fields.affect.other.description}
                  rows={1}
                  width={Width.FULL}
                  autoExpand={true}
                />
              )}
            </NoteFormControlLabel>
          </>
          <>
            <NoteFormControlLabel label={Labels.orientation.label}>
              <CheckboxField
                disabled={disabled}
                testId="orientationLimit"
                label={Labels.orientation.withinLimitLabel}
                field={fields.orientationStatus.effectWithinNormalLimits}
              />
              <CheckboxField
                disabled={disabled}
                testId="orientationStatusOther"
                label={Labels.orientation.other}
                field={fields.orientationStatus.other.isPresent}
              />
              {fields.orientationStatus.other.isPresent.value && (
                <TextAreaField
                  disabled={disabled}
                  testId="otherOrientationDescription"
                  label=""
                  placeholder="Comment..."
                  field={fields.orientationStatus.other.description}
                  rows={1}
                  width={Width.FULL}
                  autoExpand={true}
                />
              )}
            </NoteFormControlLabel>
            <NoteFormControlLabel label={Labels.insight.label}>
              <CheckboxField
                disabled={disabled}
                testId="insightLimit"
                label={Labels.insight.withinLimitLabel}
                field={fields.insightStatus.effectWithinNormalLimits}
              />
              <CheckboxField
                disabled={disabled}
                testId="insightOther"
                label={Labels.insight.other}
                field={fields.insightStatus.other.isPresent}
              />
              {fields.insightStatus.other.isPresent.value && (
                <TextAreaField
                  disabled={disabled}
                  testId="otherInsightDescription"
                  label=""
                  placeholder="Comment..."
                  field={fields.insightStatus.other.description}
                  rows={1}
                  autoExpand={true}
                  width={Width.FULL}
                />
              )}
            </NoteFormControlLabel>
            <NoteFormControlLabel label={Labels.judgement.label}>
              <CheckboxField
                disabled={disabled}
                testId="judgementLimit"
                label={Labels.judgement.withinLimitLabel}
                field={fields.judgement.effectWithinNormalLimits}
              />
              <CheckboxField
                disabled={disabled}
                testId="judgementOther"
                label={Labels.judgement.other}
                field={fields.judgement.other.isPresent}
              />
              {fields.judgement.other.isPresent.value && (
                <TextAreaField
                  disabled={disabled}
                  testId="otherJudgementDescription"
                  label=""
                  placeholder="Comment..."
                  field={fields.judgement.other.description}
                  rows={1}
                  width={Width.FULL}
                  autoExpand={true}
                />
              )}
            </NoteFormControlLabel>
            <NoteFormControlLabel label={Labels.cognitive.label}>
              <CheckboxField
                disabled={disabled}
                testId="cognitiveLimit"
                label={Labels.cognitive.withinLimitLabel}
                field={fields.cognitive.effectWithinNormalLimits}
              />
              <CheckboxField
                disabled={disabled}
                testId="cognitiveOther"
                label={Labels.cognitive.other}
                field={fields.cognitive.other.isPresent}
              />
              {fields.cognitive.other.isPresent.value && (
                <TextAreaField
                  disabled={disabled}
                  testId="otherCognitiveDescription"
                  label=""
                  placeholder="Comment..."
                  field={fields.cognitive.other.description}
                  rows={1}
                  width={Width.FULL}
                  autoExpand={true}
                />
              )}
            </NoteFormControlLabel>
          </>
        </Columns>
      </Grid>
    </>
  );
}

export function requiredIfYes(
  value: string,
  state: MentalStatusExam,
  key: keyof MentalStatusExam,
): string | undefined {
  const field = state[key] as MentalStatusExam_MentalStatusItem;
  const isPresent = field?.other?.isPresent;
  if (isPresent !== undefined && isPresent && isEmpty(value)) {
    return 'This field is required';
  }
  return undefined;
}

export const validate = (data: MentalStatusExam | null) =>
  data !== null && validateForm(data, fieldDefs);
