import { useQuery } from '@apollo/client';
import {
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  PaginationInput,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetClinicalAppointmentsForMember,
  GetClinicalAppointmentsForMemberVariables,
} from '@headspace/carehub-graphql/dist/patients/generated/GetClinicalAppointmentsForMember';
import { queryApptsForMemberSchedule } from '@headspace/carehub-graphql/dist/patients/queries';
import { useAppointmentsAndNotesAPI } from 'app/appointments/AppointmentsAndNotesAPIContext';
import { ApolloCachingStrategy } from 'app/constants';
import { useAppState } from 'app/state';
import { Status } from 'app/state/status/types/StateSlice';
import { useOutOfSessionAndTerminationNotes } from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import { useEffect } from 'react';
import { unifyAppointmentAndNotesItems } from 'utils';
import { getTimezone } from 'utils/dateTime';

export function useLoadAppointmentAndNotes(props: {
  patientId: string;
  appointmentStatus?: ClinicalAppointmentStatus[];
  appointmentType?: ClinicalAppointmentType[];
  pagination?: PaginationInput;
  pollInterval?: number;
}) {
  const {
    table: { setAppointmentsAndNotes },
  } = useAppointmentsAndNotesAPI();

  const { role, userId, timezone, vaultUserId } = useAppState(({ user }) => ({
    role: user.role!,
    timezone: getTimezone(user.timezone),
    userId: user.userId!,
    vaultUserId: user.vaultUserId!,
  }));

  const {
    patientId,
    appointmentStatus = null,
    appointmentType = null,
    pagination,
    pollInterval,
  } = props;
  const variables = {
    input: {
      filters: {
        appointmentStatus_In: appointmentStatus,
        appointmentType_In: appointmentType,
      },
      memberId: patientId,
      pagination,
    },
  };
  const {
    data: appointments,
    loading: appointmentsLoading,
    error: appointmentsError,
    refetch: refetchAppointments,
  } = useQuery<
    GetClinicalAppointmentsForMember,
    GetClinicalAppointmentsForMemberVariables
  >(queryApptsForMemberSchedule, {
    fetchPolicy: ApolloCachingStrategy.NETWORK_ONLY,
    pollInterval,
    variables,
  });

  const {
    data: notes,
    status: notesStatus,
    error: notesError,
  } = useOutOfSessionAndTerminationNotes({
    memberId: patientId,
    role,
    timezone,
    userId,
    vaultUserId,
  });
  const loading = appointmentsLoading || notesStatus === Status.LOADING;

  useEffect(() => {
    if (loading) return;
    const appointmentsAndNotes = unifyAppointmentAndNotesItems(
      timezone,
      appointments?.getClinicalAppointmentsForMember?.appointments,
      notes,
    );

    setAppointmentsAndNotes(appointmentsAndNotes);
  }, [timezone, appointments, notes, loading]);

  return {
    error: appointmentsError ?? notesError ?? undefined,
    loading,
    notesUserMetadata: notes?.notesUserMetadata,
    refetchAppointments,
  };
}
