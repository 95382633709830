import { ChatConversationState } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  ConversationStateMap,
  TimetokensMap,
  UnreadMessagesMap,
} from 'app/coach/chat/types';
import { InboxItemState } from 'app/inbox/types';
import { pubnubTimeTokenToMoment } from 'app/state/inbox/utils';
import moment from 'moment-timezone';

const epochStartDate = new Date(0);

export function todaysCompletedInboxItemsComparedFn(
  conversationStateMap: ConversationStateMap,
) {
  return (inboxItemA: InboxItemState, inboxItemB: InboxItemState): number => {
    const channelIdA = inboxItemA.memberCoachChannelId;
    const channelIdB = inboxItemB.memberCoachChannelId;
    if (!channelIdA || !channelIdB) return 0;

    const getTimestamp = (inboxItem: InboxItemState): moment.Moment =>
      moment.max([
        moment(
          conversationStateMap[inboxItem.memberCoachChannelId!]
            ?.stateLastUpdatedAt ?? epochStartDate,
        ),
        moment(
          inboxItem.taskDetails?.latestCompletedTaskDatetime ?? epochStartDate,
        ),
      ]);
    const timestampA = getTimestamp(inboxItemA);
    const timestampB = getTimestamp(inboxItemB);

    if (timestampA.isAfter(timestampB)) {
      return -1;
    }
    if (timestampA.isBefore(timestampB)) {
      return 1;
    }
    return 0;
  };
}

export function openConvoAndTaskCompareFn(
  unreadMessagesMap: UnreadMessagesMap,
  timetokensMap: TimetokensMap,
  conversationStateMap: ConversationStateMap,
) {
  return (inboxItemA: InboxItemState, inboxItemB: InboxItemState): number => {
    /**
     * Sort order:
     * 1. Open convo, unread messages: Member with an open conversation, with an unread message with the most recent
     * timestamp... to the member with an open conversation with the least recent timestamp
     *
     * 2. Open convo, read messages: Member with an open conversation, with a read message with the most recent
     * timestamp... to the member with a read message with the least recent timestamp.
     * 3. Uncompleted tasks (Closed ): Member with the highest priority task with the most recent timestamp to the member with the
     * lowest priority task with the least time stamp
     *
     */
    const channelIdA = inboxItemA.memberCoachChannelId;
    const channelIdB = inboxItemB.memberCoachChannelId;
    if (!channelIdA || !channelIdB) return 0;
    const conversationStateA = conversationStateMap[channelIdA];
    const conversationStateB = conversationStateMap[channelIdB];

    if (
      conversationStateA?.state === ChatConversationState.OPEN &&
      conversationStateB?.state === ChatConversationState.DONE
    ) {
      return -1;
    }
    if (
      conversationStateB?.state === ChatConversationState.OPEN &&
      conversationStateA?.state === ChatConversationState.DONE
    ) {
      return 1;
    }

    const timeTokensA = timetokensMap[channelIdA];
    const timeTokensB = timetokensMap[channelIdB];

    const lastMemberWriteTimeTokenA = pubnubTimeTokenToMoment(
      timeTokensA?.lastMemberWriteTimeToken,
    );
    const lastListenerReadTimeTokenA = pubnubTimeTokenToMoment(
      timeTokensA?.lastListenerReadTimeToken,
    );

    const lastMemberWriteTimeTokenB = pubnubTimeTokenToMoment(
      timeTokensB?.lastMemberWriteTimeToken,
    );
    const lastListenerReadTimeTokenB = pubnubTimeTokenToMoment(
      timeTokensB?.lastListenerReadTimeToken,
    );

    const unreadMessageAValue =
      unreadMessagesMap[channelIdA]?.length > 0 ||
      lastMemberWriteTimeTokenA.isAfter(lastListenerReadTimeTokenA)
        ? 1
        : 0;
    const unreadMessageBValue =
      unreadMessagesMap[channelIdB]?.length > 0 ||
      lastMemberWriteTimeTokenB.isAfter(lastListenerReadTimeTokenB)
        ? 1
        : 0;
    if (unreadMessageAValue > unreadMessageBValue) {
      return -1;
    }
    if (unreadMessageAValue < unreadMessageBValue) {
      return 1;
    }
    if (
      conversationStateA?.state === ChatConversationState.OPEN &&
      conversationStateB?.state === ChatConversationState.OPEN
    ) {
      const latestWriteTimestampA = moment(
        timeTokensA?.latestWriteTimestamp ?? epochStartDate,
      );
      const latestWriteTimestampB = moment(
        timeTokensB?.latestWriteTimestamp ?? epochStartDate,
      );

      if (latestWriteTimestampA.isAfter(latestWriteTimestampB)) {
        return -1;
      }
      if (latestWriteTimestampB.isAfter(latestWriteTimestampA)) {
        return 1;
      }
    } else if (
      conversationStateA?.state === ChatConversationState.DONE &&
      conversationStateB?.state === ChatConversationState.DONE
    ) {
      const taskDetailsA = inboxItemA.taskDetails;
      const taskDetailsB = inboxItemB.taskDetails;
      const priorityA = taskDetailsA?.highestPriority ?? 0;
      const priorityB = taskDetailsB?.highestPriority ?? 0;

      const timestampA = new Date(
        taskDetailsA?.latestCreatedTaskDatetime ?? 0,
      ).getTime();
      const timestampB = new Date(
        taskDetailsB?.latestCreatedTaskDatetime ?? 0,
      ).getTime();
      if (priorityB - priorityA !== 0) return priorityB - priorityA;
      return timestampB - timestampA;
    }

    return 0;
  };
}

export function riskTaskCompareFn(
  inboxItemA: InboxItemState,
  inboxItemB: InboxItemState,
): number {
  // Sort order:
  // Member with an uncompleted risk task with the most recent time stamp...
  // to the member with an uncompleted risk task with the least recent time stamp
  const taskDetailsA = inboxItemA.riskTaskDetails;
  const taskDetailsB = inboxItemB.riskTaskDetails;

  const timestampA = moment(
    taskDetailsA?.latestCreatedTaskDatetime ?? epochStartDate,
  );
  const timestampB = moment(
    taskDetailsB?.latestCreatedTaskDatetime ?? epochStartDate,
  );
  if (timestampA.isAfter(timestampB)) {
    return -1;
  }
  if (timestampA.isBefore(timestampB)) {
    return 1;
  }
  return 0;
}

export function scheduledCheckComparedFn(
  a: InboxItemState,
  b: InboxItemState,
): number {
  // Sort order:
  // Scheduled sessions
  // Closed convo, scheduled session: Member with a closed conversation with a scheduled session with the earliest
  // timestamp... to the member with a closed conversation with a scheduled session with the latest timestamp.

  // Ideally `nextAppointmentStart` should be defined for members in the scheduled checkin section. In a rare case that
  // `nextAppointmentStart` is undefined/null we can place the member at the bottom of the list
  if (!a.todayScheduledSessionStartTime && b.todayScheduledSessionStartTime) {
    return 1;
  }
  if (a.todayScheduledSessionStartTime && !b.todayScheduledSessionStartTime) {
    return -1;
  }
  const timestampA = moment(a.todayScheduledSessionStartTime ?? epochStartDate);
  const timestampB = moment(b.todayScheduledSessionStartTime ?? epochStartDate);
  if (timestampA.isAfter(timestampB)) {
    return 1;
  }
  if (timestampA.isBefore(timestampB)) {
    return -1;
  }
  return 0;
}
