import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RPCEnvelope } from 'app/coach/pubnub/types';
import { listenForActions } from 'app/state/hooks/baseTypedHooks';
import { ActionHandler } from 'app/state/inbox/actionHandlers/types';
import { onNewMessageRPC } from 'app/state/inbox/actions';
import { QueryMetadata } from 'app/inboxV2/types';
import { conversationsApi } from 'app/inboxV2/endpoints/conversations';
import {
  onFetchConversationsFulfilled,
  onFetchConversationsPending,
  onFetchConversationsRejected,
} from 'app/inboxV2/state/conversations/reducers';
import {
  ConversationsInboxItem,
  initialState,
} from 'app/inboxV2/state/conversations/schema';
import { selectShouldDisplayInboxV2 } from 'app/state/user/selectors';

export interface FetchConversationsAction {
  payload: {
    items: ConversationsInboxItem[];
    metadata: QueryMetadata;
  };
}

const conversationsSlice = createSlice({
  extraReducers: (builder) => {
    builder
      .addMatcher(
        conversationsApi.endpoints.getConversations.matchFulfilled,
        onFetchConversationsFulfilled,
      )
      .addMatcher(
        conversationsApi.endpoints.getConversations.matchPending,
        onFetchConversationsPending,
      )
      .addMatcher(
        conversationsApi.endpoints.getConversations.matchRejected,
        onFetchConversationsRejected,
      );
  },
  initialState,
  name: 'conversationsSlice',
  reducers: {},
});

export default conversationsSlice.reducer;

listenForActions({
  effect: (_action, _eventListenerApi) => {
    if (!selectShouldDisplayInboxV2(_eventListenerApi.getState())) {
      return;
    }

    conversationsApi.endpoints.getConversations.initiate({ limit: 20 });
  },
  matcher: isAnyOf(
    (
      action,
    ): action is PayloadAction<ActionHandler<{ envelope: RPCEnvelope }>> =>
      action.type === onNewMessageRPC.type,
  ),
});
