import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import {
  AppointmentStatus,
  ClinicalAppointmentNoteStatus,
  ClinicalAppointmentStatus,
  ClinicianAppointmentStatus,
  Ethnicity,
  GenderIdentification,
  Pronouns,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments as ClinicalAppointment } from '@headspace/carehub-graphql/dist/patients/generated/GetClinicalAppointmentsForMember';
import {
  AppointmentsAndNotes,
  AppointmentsAndNotesCursor,
} from 'app/appointments/AppointmentsAndNotesAPIContext';
import {
  CLINICAL_APPOINTMENT,
  OUT_OF_SESSION_OR_TERMINATION_NOTE,
} from 'app/appointments/utils';
import { NotesItemResponse } from 'app/coach/coach-notes/CoachNotesTypes';
import { ClinicalCancellationReason } from 'app/constants';
import {
  NonAppointmentNotes,
  OutOfSessionOrTerminationNote,
} from 'app/vault/hooks/NonAppointments/useOutOfSessionAndTerminationNotes';
import { DecodedJWTToken } from 'app/vault/VaultTokenStorage';
import cancellationReasonDisplayValues from 'i18n/apps/appointments/CancellationReasonStatus/en_US.json';
import appointmentStatusDisplayValues from 'i18n/apps/appointments/ClinicianAppointmentStatus/en_US.json';
import { decode } from 'jsonwebtoken';
import moment from 'moment';

export function formatEnumValue(value: string | null): string {
  if (!value) return 'Unknown';
  return value
    .toLowerCase()
    .split('_')
    .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
    .join(' ')
    .replace(/\band\b/gi, '&');
}

export function removeNullEnums(value: string | null): string {
  if (!value) return 'Unknown';
  return value;
}

export function doesAppointmentRequireUpdate(
  start: string,
  status: ClinicianAppointmentStatus | AppointmentStatus | null,
): boolean {
  const startDate = moment(new Date(start));
  const now = moment();
  return (
    startDate.isBefore(now) &&
    (status === ClinicianAppointmentStatus.Confirmed ||
      status === AppointmentStatus.CONFIRMED)
  );
}

export function getOrEmptyString(value: string | number | null): string {
  if (value === null) return '';
  return `${value}`;
}
export function isConfirmedAppointment(
  status: ClinicalAppointmentStatus | AppointmentStatus | null,
): boolean {
  if (status === null) {
    return false;
  }
  return [
    ClinicalAppointmentStatus.Confirmed,
    ClinicalAppointmentStatus.Complete,
    ClinicalAppointmentStatus.Tentative,
    AppointmentStatus.CONFIRMED,
    AppointmentStatus.COMPLETE,
    AppointmentStatus.TENTATIVE,
  ].includes(status);
}

export function formatAppointmentStatus(
  status: ClinicalAppointmentStatus | AppointmentStatus,
): string {
  return appointmentStatusDisplayValues[status] ?? formatEnumValue(status);
}

export function formatCancellationReasons(
  cancellationReason: ClinicalCancellationReason,
): string {
  return cancellationReasonDisplayValues[cancellationReason] ?? 'Unknown';
}

export function formatZoomMeetingUrl(
  zoomMeetingId: string | null | undefined,
): string | undefined {
  // TODO: Have server side give us the actual URL
  return zoomMeetingId
    ? `https://gingerio.zoom.us/s/${zoomMeetingId}`
    : undefined;
}

export function shouldShowZoomButton(
  startTime: moment.Moment,
  timezone: string = 'UTC',
): boolean {
  // Show zoom meeting link if the appointment is on the same day
  // or if the meeting is less than 4 hours away.
  const currentTime = moment.tz(timezone);
  const isSameDay = startTime.isSame(currentTime, 'day');
  const isWithinFourHours =
    Math.abs(currentTime.diff(startTime, 'hours', true)) < 4;

  return isSameDay || isWithinFourHours;
}

export function formatFileSize(size: number): string {
  const i = Math.floor(Math.log(size) / Math.log(1024));
  const unit = ['B', 'kB', 'MB', 'GB', 'TB'][i];
  return `${(size / Math.pow(1024, i)).toFixed(2)} ${unit}`;
}

/**
 * from Https://github.com/HeadspaceMeditation/web/blob/adbee073b72ca10842ced338a826476d7aafc7f8/shared/webmerge/models.py#L1599-L1606
 */
const pronounStringMap: Record<Pronouns, string> = {
  [Pronouns.HE]: 'He / Him',
  [Pronouns.PREFER_NOT_TO_SAY]: 'Prefer not to say',
  [Pronouns.SHE]: 'She / Her',
  [Pronouns.SOMETHING_ELSE]: 'Something Else',
  [Pronouns.THEY]: 'They / Them',
  [Pronouns.UNDISCLOSED]: 'I Choose Not to Disclose',
  [Pronouns.ZE]: 'Ze / Hir-Zir',
};

const genderIdentificationMap: Record<GenderIdentification, string> = {
  [GenderIdentification.FEMALE]: 'Female',
  [GenderIdentification.MALE]: 'Male',
  [GenderIdentification.NON_BINARY]: 'Non-Binary',
  [GenderIdentification.PREFER_NOT_TO_SAY]: 'Prefer not to say',
  [GenderIdentification.QUEER]: 'Queer',
  [GenderIdentification.SOMETHING_ELSE]: 'Something Else',
  [GenderIdentification.TRANSGENDER]: 'Transgender',
  [GenderIdentification.TRANSGENDER_FEMALE]: 'Trans Female',
  [GenderIdentification.TRANSGENDER_MALE]: 'Trans Male',
  [GenderIdentification.UNDISCLOSED]: 'I Choose Not to Disclose',
};

/**
 * https://github.com/HeadspaceMeditation/web/blob/adbee073b72ca10842ced338a826476d7aafc7f8/shared/webmerge/models.py#L1580-L1589
 */
const ethnicityMap: Record<Ethnicity, string> = {
  [Ethnicity.AFRICAN_AMERICAN]: 'Black / African American',
  [Ethnicity.ASIAN_AMERICAN]: 'Asian American / Pacific Islander',
  [Ethnicity.ASIAN_PACIFIC_ISLANDER]: 'Asian / Pacific Islander',
  [Ethnicity.CAUCASIAN]: 'White / Caucasian',
  [Ethnicity.HISPANIC]: 'Hispanic',
  [Ethnicity.MIDDLE_EASTERN_AMERICAN]: 'Middle Eastern American',
  [Ethnicity.MIDDLE_EASTERN_NORTH_AFRICAN]: 'Middle Eastern / North African',
  [Ethnicity.NATIVE_AMERICAN]: 'Native American / Alaskan Native',
  [Ethnicity.PREFER_NOT_TO_SAY]: 'Prefer not to say',
  [Ethnicity.SOMETHING_ELSE]: 'Something Else',
  [Ethnicity.UNDISCLOSED]: 'I Choose Not to Disclose',
};

const convertToCommaSeparatedList = (
  informationList: (string | null)[],
  stringMap: object,
  category: typeof Pronouns | typeof GenderIdentification | typeof Ethnicity,
  showUndisclosed?: boolean,
): string | null => {
  return informationList
    .filter((info) => {
      const hasUndisclosedInfo =
        info === category.SOMETHING_ELSE || info === category.UNDISCLOSED;
      const hideInfo = !showUndisclosed && hasUndisclosedInfo;
      return info !== null && !hideInfo;
    })
    .map((info) => `${(stringMap as any)[info!]}`)
    .join(', ');
};

export const formatListToString = (
  informationList: string[],
  separator: string = ', ',
): string => {
  return informationList.join(separator);
};

export const formatPronouns = (
  pronouns?: (Pronouns | null)[] | null,
  showUndisclosed?: boolean,
): string | null => {
  return pronouns
    ? convertToCommaSeparatedList(
        pronouns,
        pronounStringMap,
        Pronouns,
        showUndisclosed,
      )
    : null;
};

export const formatGender = (
  gender?: (GenderIdentification | null)[] | null,
  showUndisclosed?: boolean,
): string | null => {
  return gender
    ? convertToCommaSeparatedList(
        gender,
        genderIdentificationMap,
        GenderIdentification,
        showUndisclosed,
      )
    : null;
};

export const formatEthnicity = (
  ethnicity?: (Ethnicity | null)[] | null,
  showUndisclosed?: boolean,
): string | null => {
  return ethnicity
    ? convertToCommaSeparatedList(
        ethnicity,
        ethnicityMap,
        Ethnicity,
        showUndisclosed,
      )
    : null;
};

export const formatName = (
  firstName?: string | null,
  lastName?: string | null,
): string | null => {
  const name = [firstName, lastName].filter(Boolean).join(' ');

  if (!name.length) {
    return null;
  }

  return name;
};

export const formatNameOrMemberID = (
  memberId: string,
  firstName?: string | null,
  lastName?: string | null,
): string => {
  const memberName = formatName(firstName, lastName);
  return memberName ?? `Member ID ${memberId}`;
};

export const formatDate = (date: string, format: string) => {
  return moment(new Date(date)).format(format);
};

export const formatDateOfBirth = (
  dateOfBirth?: ISODateString | null,
): string | null => {
  return dateOfBirth ? moment(dateOfBirth).calendar() : null;
};

export const formatAge = (
  dateOfBirth?: ISODateString | null,
): number | null => {
  return dateOfBirth ? moment().diff(dateOfBirth, 'years', false) : null;
};

/*
 * https://stackoverflow.com/questions/8358084/regular-expression-to-reformat-a-us-phone-number-in-javascript
 */
export const formatPhoneNumber = (
  phoneNumberString?: string | null,
): string | null | undefined => {
  const cleaned = `${phoneNumberString}`.replace(/\D/g, '');
  const match = cleaned.match(/^(1|)?(\d{3})(\d{3})(\d{4})$/);
  if (match) {
    const intlCode = match[1] ? '1' : '';
    return [intlCode, '(', match[2], ') ', match[3], '-', match[4]].join('');
  }
  return phoneNumberString;
};

export const formatDeviceDescription = (
  model: string,
  version?: string | null,
): string | null => {
  let modelString = model.charAt(0).toUpperCase() + model.slice(1);
  const match = modelString.toLowerCase().match(/iphone/) || [];

  if (match[0] === 'iphone') {
    modelString = 'iPhone';
  }

  const description = [modelString, version].filter(Boolean).join(' ');

  if (!description.length) {
    return null;
  }

  return description;
};

export const classNameCombiner = (classes: string[]): string => {
  return classes.filter((_) => !!_).join(' ');
};

export const unifyAppointmentAndNotesItems = (
  localTimezone: string,
  appointments?: ClinicalAppointment[],
  nonAppointmentNotes?: NonAppointmentNotes | null,
): (
  | ClinicalAppointment
  | OutOfSessionOrTerminationNote
  | NotesItemResponse
)[] => {
  const { outOfSessionOrTerminationNotes, sharedCoachNotes } =
    nonAppointmentNotes ?? {};
  const coachNotes = Object.values(sharedCoachNotes ?? {});

  return [
    ...(appointments ?? []),
    ...(outOfSessionOrTerminationNotes ?? []),
    ...(coachNotes ?? []),
  ].sort((a, b) => {
    const aDate =
      '__typename' in a
        ? a.__typename === CLINICAL_APPOINTMENT
          ? moment.tz(a.start, localTimezone)
          : moment(a.date)
        : moment(a.createdAt);
    const bDate =
      '__typename' in b
        ? b.__typename === CLINICAL_APPOINTMENT
          ? moment.tz(b.start, localTimezone)
          : moment(b.date)
        : moment(b.createdAt);
    return aDate > bDate ? -1 : 1;
  });
};

export const SUPERVISOR_USER_ROLE = [
  UserRole.CLINICAL_SUPERVISOR,
  UserRole.THERAPIST_SUPERVISOR,
  UserRole.PSYCHIATRIST_SUPERVISOR,
];

export const CLINICIAN_USER_ROLE = [
  UserRole.CLINICIAN,
  UserRole.THERAPIST,
  UserRole.PSYCHIATRIST,
];

export const COACH_OR_SUPERVISOR_USER_ROLE = [
  UserRole.COACH,
  UserRole.COACH_SUPERVISOR,
];

export const PSYCHIATRIST_OR_SUPERVISOR_USER_ROLE = [
  UserRole.PSYCHIATRIST,
  UserRole.PSYCHIATRIST_SUPERVISOR,
];

export const CLINICIAN_OR_SUPERVISOR_USER_ROLE = [
  ...CLINICIAN_USER_ROLE,
  ...SUPERVISOR_USER_ROLE,
];

export function isSpanishUser(lang: string | null) {
  return lang === 'es';
}

export function isFrenchUser(lang: string | null) {
  return lang === 'fr';
}

export const isMS = (role: UserRole | null): boolean =>
  !!role && role === UserRole.MEMBER_SUPPORT;

export const isClinician = (role: UserRole | null): boolean =>
  CLINICIAN_USER_ROLE.includes(role!);

export const isCoach = (role: UserRole | null): boolean =>
  !!role && role === UserRole.COACH;

export const isClinicalSupervisor = (role: UserRole | null): boolean =>
  role != null && SUPERVISOR_USER_ROLE.includes(role);

export const isClinicianOrSupervisor = (role: UserRole | null): boolean =>
  role != null && CLINICIAN_OR_SUPERVISOR_USER_ROLE.includes(role);

export const isCoachOrSupervisor = (role: UserRole | null): boolean =>
  isCoach(role) || role === UserRole.COACH_SUPERVISOR;

export const isPsychiatristOrSupervisor = (role: UserRole | null): boolean =>
  role != null && PSYCHIATRIST_OR_SUPERVISOR_USER_ROLE.includes(role);

export const CARE_TEAM_USER_ROLE = [
  ...CLINICIAN_OR_SUPERVISOR_USER_ROLE,
  UserRole.COACH,
  UserRole.COACH_SUPERVISOR,
  UserRole.MEMBER_SUPPORT,
];

export const isVaultItemAuthor = async (
  vaultUserId: string,
  creatorId?: string,
): Promise<boolean> => {
  const hashedVaultId = await Base64.hash(vaultUserId ?? '');
  return creatorId === hashedVaultId;
};

export const toKebabCase = (str: string) => {
  const matchedStr = str.match(
    /[A-Z]{2,}(?=[A-Z][a-z]+[0-9]*|\b)|[A-Z]?[a-z]+[0-9]*|[A-Z]|[0-9]+/g,
  );

  if (matchedStr) {
    return matchedStr.map((x) => x.toLowerCase()).join('-');
  }

  return str;
};

export const camelCaseToTitleCase = (str?: string): string => {
  if (!str) {
    return '';
  }

  return str
    .replace(/([A-Z])/g, ' $1')
    .replace(/^./, (str) => str.toUpperCase());
};
export const toSentenceCase = (word: string) => {
  const lowerCase = word.toLowerCase().trim();
  return lowerCase.charAt(0).toUpperCase() + lowerCase.slice(1);
};

export const isAppointmentSignedAndLocked = (
  item: ClinicalAppointment,
): boolean => {
  return (
    item.clinicalNote?.status ===
    ClinicalAppointmentNoteStatus.SIGNED_AND_LOCKED
  );
};

export const isOutOfSessionOrTerminationNoteSignedAndLocked = (
  item: OutOfSessionOrTerminationNote,
): boolean => {
  return item.status === Metadata_NoteStatus.signed_and_locked_note;
};

export const getAppointmentOrNoteIdAndStatus = (
  item: AppointmentsAndNotes | AppointmentsAndNotesCursor,
) => {
  let id: MaybeUndefined<string>;
  let readOnly: MaybeUndefined<boolean> = false;

  if (item) {
    const isClinicalAppointment =
      (item as ClinicalAppointment).__typename === CLINICAL_APPOINTMENT;
    const isOutOfSessionOrTerminationNote =
      (item as OutOfSessionOrTerminationNote).__typename ===
      OUT_OF_SESSION_OR_TERMINATION_NOTE;

    if (isClinicalAppointment) {
      id = (item as ClinicalAppointment).id;
      readOnly = isAppointmentSignedAndLocked(item as ClinicalAppointment);
    } else if (isOutOfSessionOrTerminationNote) {
      id = (item as OutOfSessionOrTerminationNote).vaultItemId;
      readOnly = isOutOfSessionOrTerminationNoteSignedAndLocked(
        item as OutOfSessionOrTerminationNote,
      );
    } else {
      // shared coach note
      id = (item as NotesItemResponse).id;
      readOnly = (item as NotesItemResponse).readOnly;
    }
  }

  return { id, readOnly };
};

export const capitalize = (str: string) => {
  if (str && str.length > 0) {
    return str.charAt(0).toUpperCase() + str.slice(1).toLowerCase();
  }
  return str;
};

/**
 * Checks if a value is a valid enum value.
 * @param enumObject - The enum object.
 * @param value - The value to check.
 * @returns `true` if the value is a valid enum value, `false` otherwise.
 */
export function isValidEnumValue<T extends object>(
  enumObject: T,
  value: string | number | undefined,
): boolean {
  if (!value) return false;
  return Object.values(enumObject).includes(value as any);
}

/**
 * Whether the age is below 18 and at least 13
 */
export const isTeen = (dateOfBirth?: string | null): boolean => {
  const age = formatAge(dateOfBirth);
  return age !== null && age < 18 && age >= 13;
};

/**
 * Whether the age is below 13
 */
export const isChild = (dateOfBirth?: string | null): boolean => {
  const age = formatAge(dateOfBirth);
  return age !== null && age <= 12;
};

/**
 * Whether the age is at least 18
 */
export const isAdult = (dateOfBirth?: string | null): boolean => {
  const age = formatAge(dateOfBirth);
  return age !== null && age >= 18;
};

export function isObjKey<T>(key: PropertyKey, obj: T): key is keyof T {
  return key in obj;
}

export function oktaTokenExpirationDate(data: MaybeUndefined<string>) {
  try {
    if (!data) return;
    const { payload } = decode(data, { complete: true }) as DecodedJWTToken;
    return new Date(payload.exp * 1000);
  } catch (e) {
    console.warn('Error parsing or decoding Okta JWT Token', e);
  }
}

export const sortTimestampAsc = (
  a: { timestamp: string | null },
  b: { timestamp: string | null },
) =>
  new Date(a.timestamp ?? 0).getTime() - new Date(b.timestamp ?? 0).getTime();
export const sortTimestampDesc = (
  a: { timestamp: string | null },
  b: { timestamp: string | null },
) =>
  new Date(b.timestamp ?? 0).getTime() - new Date(a.timestamp ?? 0).getTime();
