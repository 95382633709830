import { AnticipatedSession_Frequency } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/AnticipatedSession';
import { Interventions } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/behavioral-observations/Interventions';
import { BooleanOption } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/BooleanOption';
import { Location } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/shared/ClinicianIntakeChecklist';
import { ClinicianChecklist_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/ClinicianChecklist';
import { TreatmentPlan_Version } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/TreatmentPlan';
import { ApproachType } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/ApproachType';
import { CPTCode } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/CPTCode';
import { TherapyProgressSectionName } from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import Labels from 'app/notes-ui/strings/en.json';
import { therapyProgressV2SectionToLabel } from 'app/notes-ui/utils';
import { ILogger } from 'app/state/log/Logger';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';

import { EnumOption } from '../types';
import { NotePDFBuilder, NotePDFBuilderImpl } from './NotePDFBuilder';
import { TherapyNotePDF } from './TherapyNotePDF';
import { NoteHeader } from './types';
import { buildAssessmentCodeSets } from './utils';

export class TherapyProgressNotePDF extends TherapyNotePDF<
  TherapyProgressNote
> {
  constructor(
    protected note: TherapyProgressNote,
    protected noteHeader: NoteHeader,
    protected diagnosisCodesOptions: EnumOption[],
    protected logger: ILogger,
    protected password?: string,
    protected timezone = 'America/Los_Angeles',
    protected pdfBuilder: NotePDFBuilder = new NotePDFBuilderImpl(
      password,
      therapyProgressV2SectionToLabel,
    ),
  ) {
    super(
      note,
      noteHeader,
      timezone,
      diagnosisCodesOptions,
      logger,
      pdfBuilder,
    );
  }

  getPDF(): typeof PDFDocument {
    this.header();
    this.clinicianChecklist();
    this.focusArea();
    this.substanceAbuse();
    this.safetyProgress();
    this.behavioralObservation();
    this.assessment();
    this.treatmentPlan();
    this.collaborationPlan();
    this.amendments(TherapyProgressSectionName.AMENDMENTS);

    return this.pdfBuilder.build();
  }

  private clinicianChecklist() {
    this.pdfBuilder.section(
      TherapyProgressSectionName.CLINICIAN_CHECKLIST,
      () => {
        const checklist = this.note.CLINICIAN_CHECKLIST;
        if (!checklist) return false;

        if (checklist.version === ClinicianChecklist_Version.v0) {
          this.pdfBuilder
            .radioItemAnswer(
              Location,
              Labels.PHYSICAL_LOCATION,
              checklist.physicallyLocatedAtApptAddress,
            )
            .shortTextAnswer(Labels.CURRENT_LOCATION, checklist.currentLocation)
            .padding()
            .yesOrNoAnswer(
              Labels.REVIEWED_INFORMED_CONSENT,
              checklist.memberGaveConsentForTelehealthAppointment,
            )
            .padding()
            .longTextAnswer(
              Labels.REMARKABLE_ENTRIES,
              checklist.remarkableEntries,
            );
        } else {
          this.pdfBuilder
            .yesOrNoAnswer(
              Labels.REVIEWED_LAST_SESSION,
              checklist.reviewedLastSessionEntries,
            )
            .padding()
            .longTextAnswer(
              Labels.REMARKABLE_ENTRIES,
              checklist.remarkableEntries,
            )
            .padding()
            .yesOrNoAnswer(
              Labels.INTRO_COACHING_TO_MEMBER_PROGRESS,
              checklist.introducedCoachingToMember,
            )
            .padding()
            .yesOrNoAnswer(
              Labels.RELEASE_OF_INFORMATION_REQUESTED,
              checklist.releaseOfInformationRequested,
            );
        }
        return true;
      },
      'No clinician checklist',
    );
  }

  private focusArea() {
    this.pdfBuilder.section(
      TherapyProgressSectionName.FOCUS_AREA,
      () => {
        const focus = this.note.FOCUS_AREA;

        if (focus) {
          this.pdfBuilder.longTextAnswer(
            Labels.SESSION_FOCUS_IN_CLIENT_WORDS,
            focus.sessionFocusInClientWords,
          );
          return true;
        }
        return false;
      },
      'No focus area specified',
    );
  }

  private assessment() {
    this.pdfBuilder.section(
      TherapyProgressSectionName.ASSESSMENT,
      () => {
        const assessment = this.note.ASSESSMENT;

        if (assessment) {
          buildAssessmentCodeSets(
            this.pdfBuilder,
            CPTCode,
            this.diagnosisCodesOptions,
            assessment.codeSets,
            assessment.cptCode,
            assessment.icd10Codes,
            this.logger,
          )
            .padding()
            .longTextAnswer(
              Labels.CHANGE_IN_SYMPTOMS,
              assessment.changeInSymptoms,
            );
          return true;
        }
        return false;
      },
      'No assessment data',
    );
  }

  private treatmentPlan() {
    this.pdfBuilder.section(
      TherapyProgressSectionName.TREATMENT_PLAN,
      () => {
        const plan = this.note.TREATMENT_PLAN;

        if (!plan) return false;

        if (plan.version === TreatmentPlan_Version.v0 || plan.goal.length > 0) {
          plan.goal.forEach((goal, index) => {
            this.treatmentGoal(goal, index + 1);
          });
          this.pdfBuilder
            .longTextAnswer(Labels.MESSAGE_TO_CARE_TEAM, plan.messageToCareTeam)
            .padding()
            .enumSelectionsGroup(
              Interventions,
              plan.interventionsUsed,
              Labels.INTERVENTIONS_USED,
              'No interventions selected.',
            )
            .padding()
            .enumSelectionsGroup(
              ApproachType,
              plan.approaches,
              Labels.APPROACH,
              'No approaches selected.',
            )
            .padding()
            .longTextAnswer(
              Labels.NUMBER_OF_SESSIONS_ANTICIPATED,
              plan.anticipatedSession?.numberOfSessionsAnticipated.toString(),
            )

            .padding()
            .enumSelectionsGroup(
              AnticipatedSession_Frequency,
              [plan.anticipatedSession?.frequency],
              Labels.FREQUENCY,
              'No frequency selected.',
            )
            .padding()

            .padding()
            .yesOrNoAnswer(
              Labels.WAIT_TIME_EFFECT,
              plan.waitTimeDetrimentalEffect,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.OFFER_AN_APPOINTMENT,
              plan.appointmentOffered,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.APPOINTMENT_OFFER_ACCEPTED,
              plan.offeredAppointmentAccepted,
            )
            .padding()
            .yesOrNoAnswer(Labels.MEMBER_OUT_OF_SCOPE, plan.memberOutOfScope)
            .padding()
            .longTextAnswer(
              Labels.REFERRALS_TO_CARE_OUTSIDE,
              plan.referralsToCareOutsideGingerNecessary,
            )
            .padding();
        } else {
          this.pdfBuilder
            .longTextAnswer(
              Labels.NUMBER_OF_SESSIONS_ANTICIPATED,
              plan.anticipatedSession?.numberOfSessionsAnticipated.toString(),
            )
            .padding()
            .yesOrNoAnswer(
              Labels.WAIT_TIME_EFFECT,
              plan.waitTimeDetrimentalEffect,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.OFFER_AN_APPOINTMENT,
              plan.appointmentOffered,
            )
            .padding()
            .radioItemAnswer(
              BooleanOption,
              Labels.APPOINTMENT_OFFER_ACCEPTED,
              plan.offeredAppointmentAccepted,
            )
            .padding()
            .longTextAnswer(
              Labels.REFERRALS_TO_CARE_OUTSIDE,
              plan.referralsToCareOutsideGingerNecessary,
            )
            .padding()
            .longTextAnswer(
              Labels.CHANGES_TO_PLAN,
              plan.changesToTreatmentPlan,
            );
        }
        return true;
      },
      'No treatment plan',
    );
  }
}
