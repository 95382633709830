import { createSlice, isAnyOf, PayloadAction } from '@reduxjs/toolkit';
import { RPCEnvelope } from 'app/coach/pubnub/types';
import { listenForActions } from 'app/state/hooks/baseTypedHooks';
import { ActionHandler } from 'app/state/inbox/actionHandlers/types';
import { onNewMessageRPC, setCurrentChannel } from 'app/state/inbox/actions';

import {
  selectShouldDisplayInboxV2,
  selectWildCardPattern,
} from 'app/state/user/selectors';
import { selectIsSubscribed } from 'app/inboxV2/state/pubnubSubscriptions/selectors';
import { onSubscribedToChannels } from 'app/inboxV2/state/pubnubSubscriptions/reducers';
import {
  subscribeToPubnubChannels,
  unsubscribeFromPubnubChannels,
} from 'app/inboxV2/state/pubnubSubscriptions/thunks';
import { initialState } from 'app/inboxV2/state/pubnubSubscriptions/schema';
/*

When do we need to know the channel ID given a member ID?

Data flows:

1) Load chat for a member
Need channelId to subscribe to pubnub channel for that member if not already subscribed

Read/Writing a chat message
- Delayed send feature (capture the current channelId at the time of scheduling 
the message)

*/

const pubnubSubscriptionsSlice = createSlice({
  extraReducers: (builder) => {
    builder.addCase(
      subscribeToPubnubChannels.fulfilled,
      onSubscribedToChannels,
    );
    builder.addCase(subscribeToPubnubChannels.rejected, (state, action) => {
      console.error('Failed to subscribe to pubnub channels', action.payload);
    });
    builder.addCase(unsubscribeFromPubnubChannels.rejected, (state, action) => {
      console.error(
        'Failed to unsubscribe from pubnub channels',
        action.payload,
      );
    });
  },
  initialState,
  name: 'pubnubSubscriptions',
  reducers: {},
});

export default pubnubSubscriptionsSlice.reducer;

// subscribe to a member coach channel on getting a new_message RPC and setting the current channel (tab change, open member screen, open member screen by link)
listenForActions({
  effect: (action, _eventListenerApi) => {
    if (!selectShouldDisplayInboxV2(_eventListenerApi.getState())) {
      return;
    }

    const memberIdToChannelMap: Record<string, string> = {};
    const memberIdToChannelMapToSubscribe: Record<string, string> = {};

    const wildCardPattern = selectWildCardPattern(_eventListenerApi.getState());

    // TODO @kelsey: We need to update the "new message" RPC to include the memberIdToChannelMap.
    if (action.type === onNewMessageRPC.type) {
      const { message } = action.payload.envelope;

      if (message?.memberIdToChannelMap) {
        Object.entries(message.memberIdToChannelMap).forEach(
          ([memberId, channelId]) => {
            memberIdToChannelMap[memberId] = channelId as string;
          },
        );
      }
    }

    if (action.type === setCurrentChannel.type) {
      const { currentChannelId, currentMemberId } = action.payload;

      if (currentMemberId && currentChannelId) {
        memberIdToChannelMap[currentMemberId] = currentChannelId;
      }
    }

    Object.entries(memberIdToChannelMap)
      .filter(([memberId, channelId]) => memberId && channelId)
      .forEach(([memberId, channelId]) => {
        const isWildCardChannel =
          wildCardPattern && channelId.startsWith(wildCardPattern);
        if (
          isWildCardChannel ||
          selectIsSubscribed(_eventListenerApi.getState(), channelId)
        ) {
          return;
        }
        memberIdToChannelMapToSubscribe[memberId] = channelId;
      });

    _eventListenerApi.dispatch(
      subscribeToPubnubChannels({
        coachMemberChannels: memberIdToChannelMapToSubscribe,
      }),
    );
  },
  matcher: isAnyOf(
    (
      action,
    ): action is PayloadAction<ActionHandler<{ envelope: RPCEnvelope }>> =>
      action.type === onNewMessageRPC.type,
    (action): action is PayloadAction<{ channelId: string }> =>
      action.type === setCurrentChannel.type,
  ),
});
