/* eslint-disable no-param-reassign */
import { PayloadAction } from '@reduxjs/toolkit';

import { State as PubnubSubscriptionsState } from 'app/inboxV2/state/pubnubSubscriptions/schema';
import { SubscribePayload } from './thunks';

interface SubscribeActionMeta {
  arg: SubscribePayload;
  requestId: string;
  requestStatus: 'fulfilled';
}

export const onSubscribedToChannels = (
  state: PubnubSubscriptionsState,
  action: PayloadAction<void, string, SubscribeActionMeta, never>,
) => {
  const { rpcToCoachChannel, coachMemberChannels } = action.meta.arg;

  if (rpcToCoachChannel) {
    state.rpcToCoachChannel = rpcToCoachChannel;
  }
  if (coachMemberChannels) {
    state.coachMemberChannels = {
      ...state.coachMemberChannels,
      ...coachMemberChannels,
    };
  }
};
