import { ApolloCache } from '@apollo/client';
import {
  CreateMemberChartVaultItems,
  CreateMemberChartVaultItems_createVaultItems as VaultItemWithKey,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/CreateMemberChartVaultItems';
import { DeleteMemberChartVaultItems } from '@headspace/carehub-graphql/dist/coach-member-chart/generated/DeleteMemberChartVaultItems';
import { ShareMemberChartVaultItems } from '@headspace/carehub-graphql/dist/coach-member-chart/generated/ShareMemberChartVaultItems';
import {
  GetCoachNotesAndUserMetadata,
  GetCoachNotesAndUserMetadata_CoachNotes_items as CoachNotesItems,
  GetCoachNotesAndUserMetadata_NotesUserMetadata_items as NotesUserMetadataItems,
  GetCoachNotesAndUserMetadataVariables,
} from '@headspace/carehub-graphql/dist/coach-notes/generated/GetCoachNotesAndUserMetadata';
import { getCoachNotesAndUserMetadata } from '@headspace/carehub-graphql/dist/coach-notes/queries';
import { VaultItemPermissions } from '@headspace/carehub-graphql/dist/generated/globalTypes';

type UpdateCacheParams = {
  cache: ApolloCache<any>;
  data: CreateMemberChartVaultItems | DeleteMemberChartVaultItems;
  variables: GetCoachNotesAndUserMetadataVariables;
  /**
   * @description Because this query is aliased to return multiple different fields (CoachNotes,
   * NotesUserMetadata, and LegacyCoachSummaryNotes), we can only update the field that the returned
   * createVaultItem object belongs to.
   *
   * For example, if createMetadataVaultItems is successfully called, and we get back a new Metadata
   * VaultItem, the NotesUserMetadata cache must be updated to include the newly created item. This
   * is flagged by setting isMetadataCache to true.
   *
   * We do not need to worry about updating the cache for LegacyCoachSummaryNotes because
   * they were created on Listener and no new ones will be added nor will any existing ones be
   * removed on Care Hub.
   * */
  isMetadataCache?: boolean;
};

type UpdateShareVaultItemsCacheParams = {
  cache: ApolloCache<any>;
  data: ShareMemberChartVaultItems;
  variables: GetCoachNotesAndUserMetadataVariables;
  noteId?: string;
};

/**
 * Updates the query cache after a create mutation is made to include the newly created VaultItem
 * in the query response body
 *
 * @param params
 * @returns
 */
export const updateCache = (params: UpdateCacheParams) => {
  const { cache, data, variables, isMetadataCache } = params;
  const items = cache.readQuery<
    GetCoachNotesAndUserMetadata,
    GetCoachNotesAndUserMetadataVariables
  >({
    query: getCoachNotesAndUserMetadata,
    variables,
  });
  const item: VaultItemWithKey[] = (data as CreateMemberChartVaultItems)
    .createVaultItems;

  const cachedCoachNotesItems = items?.CoachNotes.items ?? [];
  const cachedMetadataNotesItems = items?.NotesUserMetadata.items ?? [];

  cache.writeQuery<
    GetCoachNotesAndUserMetadata,
    GetCoachNotesAndUserMetadataVariables
  >({
    data: {
      CoachNotes: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.CoachNotes,
        items: isMetadataCache
          ? [...cachedCoachNotesItems]
          : [...item, ...cachedCoachNotesItems],
      },
      // no new legacy notes will be created on Care Hub, so we don't need to update cache here
      LegacyCoachSummaryNotes: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        ...items?.LegacyCoachSummaryNotes!,
      },

      NotesUserMetadata: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.NotesUserMetadata,
        items: isMetadataCache
          ? [...item, ...cachedMetadataNotesItems]
          : [...cachedMetadataNotesItems],
      },
    },
    query: getCoachNotesAndUserMetadata,
    variables,
  });
};

/**
 * Updates the query cache after a share mutation is called to reflect the updated permissions
 * of the modified VaultItem in the query response body
 *
 * @param params
 * @returns
 */
export const updateShareVaultItemCache = (
  params: UpdateShareVaultItemsCacheParams,
) => {
  const { cache, data, variables, noteId } = params;
  const items = cache.readQuery<
    GetCoachNotesAndUserMetadata,
    GetCoachNotesAndUserMetadataVariables
  >({
    query: getCoachNotesAndUserMetadata,
    variables,
  });
  const cachedCoachNotesItems = items?.CoachNotes.items ?? [];
  const cachedMetadataNotesItems = items?.NotesUserMetadata.items ?? [];

  const updatedCoachNotesItems: CoachNotesItems[] = cachedCoachNotesItems.map(
    (item) => {
      if (item.encryptedItem.id === noteId && data.shareVaultItems) {
        return {
          __typename: 'VaultItemWithKey',
          encryptedItem: {
            ...item.encryptedItem,
            permissions: VaultItemPermissions.READ_ONLY,
          },
        };
      }

      return item;
    },
  );

  cache.writeQuery<
    GetCoachNotesAndUserMetadata,
    GetCoachNotesAndUserMetadataVariables
  >({
    data: {
      CoachNotes: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.CoachNotes,
        items: updatedCoachNotesItems,
      },
      // no new legacy notes will be shared on Care Hub, so we don't need to update cache here
      LegacyCoachSummaryNotes: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        ...items?.LegacyCoachSummaryNotes!,
      },

      NotesUserMetadata: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.NotesUserMetadata,
        items: [...cachedMetadataNotesItems],
      },
    },
    query: getCoachNotesAndUserMetadata,
    variables,
  });
};

type CachedItems = CoachNotesItems | NotesUserMetadataItems;

const deleteCachedItems = (
  data: DeleteMemberChartVaultItems,
  cachedItems: CachedItems[],
) =>
  data.deleteVaultItems.reduce((arr: CachedItems[], _) => {
    const updatedList = [...arr];
    const index = arr.findIndex((item) => item.encryptedItem.id === _.id);
    if (_.success && index > -1) {
      updatedList.splice(index, 1);
    }
    return updatedList;
  }, cachedItems);

/**
 * Removes the VaultItem from the query cache after a delete mutation is succesfully made
 *
 * @param params
 * @returns
 */
export const removeItemsFromCache = (params: UpdateCacheParams) => {
  const { cache, data, variables, isMetadataCache } = params;
  const items = cache.readQuery<
    GetCoachNotesAndUserMetadata,
    GetCoachNotesAndUserMetadataVariables
  >({
    query: getCoachNotesAndUserMetadata,
    variables,
  });

  const cachedCoachNotesItems: CoachNotesItems[] =
    items?.CoachNotes.items ?? [];
  const cachedMetadataNotesItems: NotesUserMetadataItems[] =
    items?.NotesUserMetadata.items ?? [];

  const updatedCoachNotesItems = deleteCachedItems(
    data as DeleteMemberChartVaultItems,
    cachedCoachNotesItems,
  ) as CoachNotesItems[];
  const updatedNotesUserMetadataItems = deleteCachedItems(
    data as DeleteMemberChartVaultItems,
    cachedMetadataNotesItems,
  ) as NotesUserMetadataItems[];

  cache.writeQuery<
    GetCoachNotesAndUserMetadata,
    GetCoachNotesAndUserMetadataVariables
  >({
    data: {
      CoachNotes: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.CoachNotes,
        items: isMetadataCache
          ? [...(items?.CoachNotes.items ?? [])]
          : updatedCoachNotesItems,
      },
      // no existing legacy notes will be removed through Care Hub, so we don't need to update the cache here
      LegacyCoachSummaryNotes: {
        // eslint-disable-next-line @typescript-eslint/no-non-null-asserted-optional-chain
        ...items?.LegacyCoachSummaryNotes!,
      },

      NotesUserMetadata: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.NotesUserMetadata,
        items: isMetadataCache
          ? updatedNotesUserMetadataItems
          : [...(items?.NotesUserMetadata.items ?? [])],
      },
    },
    query: getCoachNotesAndUserMetadata,
    variables,
  });
};
