import { PSSScore } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { PSSScoreMap } from 'app/patients/header/utils';
import styles from 'app/patients/tabs/surveys/SurveysV3/InfoBox.module.scss';
import React from 'react';
import { classNameCombiner } from 'utils';

import { PSSSummary } from './types';

interface Props {
  info: PSSSummary | null;
}
export default function PssInfoBox({ info }: Props) {
  if (!info) {
    return (
      <div className={classNameCombiner([styles.infobox, styles.default])}>
        <div className={styles.defaultText}>
          Click on a specific point above to view a summary
        </div>
      </div>
    );
  }
  const { stressLevel } = info;
  const enumStress: PSSScore = PSSScore[stressLevel as keyof typeof PSSScore];

  return (
    <div className={classNameCombiner([styles.infobox, ''])}>
      <div className={classNameCombiner([styles.tr, styles.header])}>
        <div className={classNameCombiner([styles.td, styles.title])}>
          Stress Level
        </div>
      </div>
      <div className={styles.tr}>
        {stressLevel ? (
          <div
            className={classNameCombiner([
              styles.td,
              styles.section,
              styles.summary,
            ])}
          >
            {PSSScoreMap[enumStress]}
          </div>
        ) : undefined}
      </div>
    </div>
  );
}
