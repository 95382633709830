import { EventType } from 'app/events/EventType';
import { createAction } from 'redux-reloaded';

export const viewSurveyTabAction = createAction(
  EventType.PATIENT_SURVEY_TAB_VIEWED,
);
export const selectGAD7ChartDropdown = createAction(
  EventType.GAD7_CHART_DROPDOWN_SELECTED,
  ({ selectedItem }: { selectedItem: string }) => ({ selectedItem }),
);
export const selectPHQ9ChartDropdown = createAction(
  EventType.PHQ9_CHART_DROPDOWN_SELECTED,
  ({ selectedItem }: { selectedItem: string }) => ({ selectedItem }),
);
export const selectGAD7ChartHover = createAction(
  EventType.GAD7_CHART_HOVERED,
  ({ chartName }: { chartName: string }) => ({ chartName }),
);
export const selectPHQ9ChartHover = createAction(
  EventType.PHQ9_CHART_HOVERED,
  ({ chartName }: { chartName: string }) => ({ chartName }),
);
