// The dropdown showing the able/diosable status of the outreach
import { useQuery } from '@apollo/client';
import { createCSCAutomatedPauseReauestMutation } from '@headspace/carehub-graphql/dist/patients-CSCOutreach/CSCAutoOutreachMutatons';
import { getOutreachHistoryGQL } from '@headspace/carehub-graphql/dist/queries/CSCOutreach';
import {
  getOutreachHistory,
  getOutreachHistoryVariables,
} from '@headspace/carehub-graphql/dist/queries/generated/getOutreachHistory';
import { SwitchUnstyled } from '@mui/base';
import CloseIcon from '@mui/icons-material/Close';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import {
  Dialog,
  DialogContent,
  DialogTitle,
  Grid,
  Link,
  Typography,
} from '@mui/material';
import IconButton from '@mui/material/IconButton';
import Stack from '@mui/material/Stack';
import { useLogger } from 'app/state/log/useLogger';
import { useMutationWithGlobalState as useMutation } from 'hooks/useMutationWithGlobalState';
import React from 'react';
import switchStyles from 'shared-components/buttons/ToggleButton.module.scss';
import { Loader } from 'shared-components/loader/Loader';

import { CSCAutoOutreacDisableComponent } from './CSCAutoOutreacDisableComponent';
import { CSCAutoOutreacEnableComponent } from './CSCAutoOutreacEnableComponent';
import styles from './CSCAutoOutreachEnablePopup.module.scss';
import { CSCAutoOutreacHistoryPopup } from './CSCAutoOutreacHistoryPopup';
import { CreateCSCOutreachPauseRequestInput, CSCOutreachStatus } from './types';

export type Props = {
  memberId: string;
  clinicianId: string;
};

export function CSCAutoOutreachEnablePopup({ memberId, clinicianId }: Props) {
  const [checked, setChecked] = React.useState(false);
  const [
    cscOutreachHistroypopoverOpen,
    setCscOutreachHistroypopoverOpen,
  ] = React.useState(false);
  const [
    enableCscOutreachModalOpen,
    setEnableCscOutreachModalOpen,
  ] = React.useState(false);
  const [
    disableCscOutreachModalOpen,
    setDisableCscOutreachModalOpen,
  ] = React.useState(false);
  const [pauseRequestHistory, setPauseRequestHistory] = React.useState<
    (CSCOutreachStatus | null)[]
  >([]);
  type PauseRequestInput = { input: CreateCSCOutreachPauseRequestInput };
  const [createPauseRequest] = useMutation<
    CSCOutreachStatus,
    PauseRequestInput
  >(createCSCAutomatedPauseReauestMutation);

  const [lastDisableReason, setLastDisableReason] = React.useState<
    string | null
  >();
  const [lastDisableNote, setLastDisableNote] = React.useState<string | null>();
  const { loading, data, error, refetch } = useQuery<
    getOutreachHistory,
    getOutreachHistoryVariables
  >(getOutreachHistoryGQL, {
    variables: {
      input: {
        userId: memberId,
      },
    },
  });

  const logger = useLogger();

  const handleHistoryClick = async () => {
    setCscOutreachHistroypopoverOpen(true);
  };

  const handleEnableSwitchChange = (
    event: React.ChangeEvent<HTMLInputElement>,
  ) => {
    setChecked(event.target.checked);
    if (event.target.checked === true) {
      setEnableCscOutreachModalOpen(true);
    } else {
      setDisableCscOutreachModalOpen(true);
    }
  };

  const onHistoryModalClose = () => {
    setCscOutreachHistroypopoverOpen(false);
  };

  const onEnableModalClose = () => {
    setEnableCscOutreachModalOpen(false);
  };

  const onDisableModalClose = () => {
    setDisableCscOutreachModalOpen(false);
  };

  const unPause = async (notes: string) => {
    const input: CreateCSCOutreachPauseRequestInput = {
      note: notes,
      pause: false,
      reason: null,
      unpauseAt: null,
      updatedBy: clinicianId,
      userId: memberId,
    };

    try {
      await createPauseRequest({ input });
      setChecked(true);
      await refetch();
    } catch (e) {
      logger.error(
        new Error(
          `CSCAutoOutreachEnablePopup: unable to create outreach unpause request`,
        ),
        {
          error: e,
          input,
          memberId,
        },
      );
    } finally {
      unPausePopupCloser();
    }
  };

  const pause = async (
    reason: string,
    unpauseDate: string | null,
    additionalNotes: string | null,
  ) => {
    const input: CreateCSCOutreachPauseRequestInput = {
      note: additionalNotes,
      pause: true,
      reason,
      unpauseAt: unpauseDate,
      updatedBy: clinicianId,
      userId: memberId,
    };
    try {
      await createPauseRequest({ input });
      setChecked(false);
      await refetch();
    } catch (e) {
      logger.error(
        new Error(
          `CSCAutoOutreachEnablePopup: unable to create outreach pause request`,
        ),
        {
          error: e,
          input,
          memberId,
        },
      );
    } finally {
      pausePopupCloser();
    }
  };

  const unPausePopupCloser = () => {
    setEnableCscOutreachModalOpen(false);
  };

  const pausePopupCloser = () => {
    setDisableCscOutreachModalOpen(false);
  };

  React.useEffect(() => {
    if (error) {
      logger.error(
        new Error(
          `CSCAutoOutreachEnablePopup: unable to fetch outreach pause request history`,
        ),
        {
          error,
          memberId,
        },
      );
    } else if (data && data.getOutreachHistory) {
      const histories = data?.getOutreachHistory?.history;
      if (histories) {
        setPauseRequestHistory(histories);
        const history =
          histories != null ? histories[histories.length - 1] : null;
        if (history) {
          setLastDisableNote(history?.additionalNote);
          setLastDisableReason(history?.reason);
          setChecked(!history?.pause);
        }
      }
    }
  }, [data, error]);
  return (
    <div className={styles.pauseUnpauseOutrechModalContainer}>
      {loading && <Loader />}
      {!loading && (
        <Stack
          direction="column"
          justifyContent="flex-start"
          alignItems="flex-start"
          spacing={2}
          padding={2}
        >
          <Grid container={true} justifyContent="space-between">
            <Grid item={true}>
              <Typography>Allow auto-outreach</Typography>
            </Grid>
            <Grid item={true}>
              <SwitchUnstyled
                slotProps={{
                  input: { className: switchStyles.input },
                  root: {
                    className: checked
                      ? `${switchStyles.switch} ${switchStyles.switchChecked}`
                      : switchStyles.switch,
                  },
                  thumb: {
                    className: checked
                      ? `${switchStyles.thumb} ${switchStyles.thumbChecked}`
                      : switchStyles.thumb,
                  },
                  track: { className: switchStyles.track },
                }}
                checked={checked}
                onChange={handleEnableSwitchChange}
              />
            </Grid>
          </Grid>

          {/* eslint-disable-next-line jsx-a11y/anchor-is-valid */}
          <Link component="button" onClick={handleHistoryClick} hidden={true}>
            View History
          </Link>

          <div className={styles.avartarContainer}>
            <HelpOutlineIcon fontSize="medium" className={styles.helpIcon} />

            {checked && (
              <Typography>
                This member will receive auto-outreaches to schedule a follow-up
                if no future appointments are scheduled.
              </Typography>
            )}

            {!checked && (
              <Typography>
                This member will not receive auto-outreaches to schedule a
                follow-up if no future appointments are scheduled.
              </Typography>
            )}
          </div>

          <Dialog
            open={cscOutreachHistroypopoverOpen}
            className={styles.pauseUnpauseOutrechModal}
            classes={{ paper: styles.modalContainer }}
          >
            <DialogTitle className={styles.modalTitle}>
              <span>Clinical auto-outreach history</span>
              <IconButton
                className={styles.close}
                onClick={onHistoryModalClose}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={styles.modalBody}>
              <CSCAutoOutreacHistoryPopup history={pauseRequestHistory} />
            </DialogContent>
          </Dialog>
          <Dialog
            open={enableCscOutreachModalOpen}
            className={styles.pauseUnpauseOutrechModal}
            classes={{ paper: styles.modalContainer }}
          >
            <DialogTitle className={styles.modalTitle}>
              <span>Enable clinical auto-outreach?</span>
              <IconButton className={styles.close} onClick={onEnableModalClose}>
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={styles.modalBody}>
              <CSCAutoOutreacEnableComponent
                lastDisableReason={lastDisableReason}
                lastDisableNote={lastDisableNote}
                closer={unPausePopupCloser}
                unPause={unPause}
              />
            </DialogContent>
          </Dialog>
          <Dialog
            open={disableCscOutreachModalOpen}
            className={styles.pauseUnpauseOutrechModal}
            classes={{ paper: styles.modalContainer }}
          >
            <DialogTitle className={styles.modalTitle}>
              <span>Disable clinical auto-outreach?</span>
              <IconButton
                className={styles.close}
                onClick={onDisableModalClose}
              >
                <CloseIcon />
              </IconButton>
            </DialogTitle>
            <DialogContent className={styles.modalBody}>
              <CSCAutoOutreacDisableComponent
                closer={pausePopupCloser}
                pause={pause}
              />
            </DialogContent>
          </Dialog>
        </Stack>
      )}
    </div>
  );
}
