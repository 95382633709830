import { DocumentFormat, LabelValueTextStyle, Styles } from './types';

export enum Font {
  COURIER = 'Courier',
  COURIER_BOLD = 'Courier-Bold',
  COURIER_OBLIQUE = 'Courier-Oblique',
  COURIER_BOLD_OBLIQUE = 'Courier-BoldOblique',
  HELVETICA = 'Helvetica',
  HELVETICA_BOLD = 'Helvetica-Bold',
  HELVETICA_OBLIQUE = 'Helvetica-Oblique',
  HELVETICA_BOLD_OBLIQUE = 'Helvetica-BoldOblique',
  SYMBOL = 'Symbol',
  TIMES_ROMAN = 'Times-Roman',
  TIMES_BOLD = 'Times-Bold',
  TIMES_ITALIC = 'Times-Italic',
  TIMES_BOLD_ITALIC = 'Times-BoldItalic',
  ZAPF_DINGBATS = 'ZapfDingbats',
  NOTO = 'NotoSerif-Regular',
  NOTO_BOLD = 'NotoSerif-Bold',
}

// PDFKit is in 72 DPI. This const makes it easier for us to maintain 1-inch margins.
export const oneInchInPixels = 72;

export enum ElementType {
  H1 = 'h1',
  H2 = 'h2',
  H3 = 'h3',
  HEADER_TEXT_IN_COLUMNS = 'headerTextInColumns',
  HORIZONTAL_LINE = 'horizontalLine',
  INLINE_TEXT = 'inlineText',
  LINE_BREAK = 'lineBreak',
  TEXT = 'text',
  UL = 'ul',
}

export const labelValueTextStyle: LabelValueTextStyle = {
  labelStyle: {
    font: Font.NOTO_BOLD,
    fontSize: 10,
  },
  valueStyle: {
    font: Font.NOTO,
    fontSize: 10,
  },
};

export const defaultStyles: Styles = {
  h1Style: {
    font: Font.NOTO_BOLD,
    fontSize: 16,
  },
  h2Style: {
    font: Font.NOTO_BOLD,
    fontSize: 12,
    underline: true,
  },
  h3Style: {
    font: Font.NOTO_BOLD,
    fontSize: 10,
  },
  listTextStyle: {
    font: Font.NOTO,
    fontSize: 10,
  },
  regularTextStyle: {
    font: Font.NOTO,
    fontSize: 10,
  },
};

export const defaultDocumentFormat: DocumentFormat = {
  leftAndRightMargins: oneInchInPixels,
  rowGap: 18,
  topAndBottomMargins: oneInchInPixels,
};
