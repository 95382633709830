import { useMutation } from '@apollo/client';
import { assignHomeworkToMember as assignHomeworkToMemberMutation } from '@headspace/carehub-graphql/dist/patients-tabs-content/ContentQueries';
import {
  assignHomeworkToMember,
  assignHomeworkToMemberVariables,
} from '@headspace/carehub-graphql/dist/patients-tabs-content/generated/assignHomeworkToMember';
import { usePubNubAPI } from 'app/coach/pubnub/PubNubContextProvider';
import { ContentRPC, MessageRPC } from 'app/coach/pubnub/types';
import {
  assignHomeworkResultStatus,
  assignHomeworkStatus,
  HomeworkSource,
  HomeworkStatus,
  HomeworkType,
} from 'app/patients/tabs/content/types';
import { extractAssignmentIdFromUri } from 'app/patients/tabs/content/utils';
import { useAppState } from 'app/state';
import { addHomework } from 'app/state/content/actions';
import { selectCurrentPubnubChannelId } from 'app/state/inbox/selectors';
import { useLogger } from 'app/state/log/useLogger';
import { useDispatch } from 'react-redux';
import { isCoachOrSupervisor } from 'utils';
import { v4 as uuidv4 } from 'uuid';

enum HOMEWORK_ERR_MSG {
  IS_DUPLICATED = "This assignment already exists in the member's stream.",
  IS_COMPLETED = 'This task was already completed by the member.',
  NOT_ALLOWED_FOR_FUSION = "This activity can't be assigned for Fusion members.",
  NOT_ALLOWED_FOR_NON_FUSION = 'This activity is only allowed for Fusion members.',
}

export function useAssignHomeworkMutation(): (
  contentId: string,
  contentType: string,
  memberId: string,
) => Promise<assignHomeworkStatus> {
  const { isCoach } = useAppState(({ user: { role } }) => ({
    isCoach: isCoachOrSupervisor(role),
  }));
  const [assignHomeworkMutation] = useMutation<
    assignHomeworkToMember,
    assignHomeworkToMemberVariables
  >(assignHomeworkToMemberMutation);
  const dispatch = useDispatch();
  const logger = useLogger();

  const api = usePubNubAPI();
  const {
    user: { listenerId, coachinghubUsername },
  } = useAppState((_) => _);
  const currentChannelId = selectCurrentPubnubChannelId();
  const { publishMessage } = api;

  const sendAssignHomeworkRPC = async (homework: HomeworkType, uri: string) => {
    if (listenerId) {
      const assignHomeworkRPC: MessageRPC = {
        extra_params: {
          content_id: homework.slug,
          content_type: 'content',
          cta: 'Activity',
          label: homework.title,
          uri,
        },
        id: uuidv4(),
        oncall_listener_id: null,
        rpc: ContentRPC.GIO_DEEPLINK_BUTTON,
        senderId: listenerId,
        senderType: 'listener',
        username: coachinghubUsername ?? '',
      };
      try {
        void (await publishMessage({
          channel: currentChannelId,
          message: assignHomeworkRPC,
        }));
      } catch (e) {
        logger.error(
          new Error(`Could not publish ${ContentRPC.GIO_DEEPLINK_BUTTON} rpc.`),
          {
            error: e,
            listenerId,
            memberCoachChannelId: currentChannelId,
            status: e.status,
          },
        );
      }
    }
  };

  return async (
    contentId: string,
    contentType: string,
    memberId: string,
  ): Promise<assignHomeworkStatus> => {
    try {
      const res = await assignHomeworkMutation({
        variables: {
          input: {
            contentId,
            contentType,
            memberId: Number(memberId),
          },
        },
      });
      if (res.data?.assignHomeworkToMember?.success) {
        if (!res.data?.assignHomeworkToMember.links)
          return { status: assignHomeworkResultStatus.NO_LINK, title: '' };

        const uri = res.data.assignHomeworkToMember.links[0] ?? '';
        const assignmentId = extractAssignmentIdFromUri(uri);

        if (!assignmentId) {
          logger.error(
            new Error(
              `Received a invalid link from assignHomeworkToMember mutation`,
            ),
            {
              contentId,
              contentType,
              invalidLink: res.data,
              memberId,
            },
          );
          return { status: assignHomeworkResultStatus.INVALID_LINK, title: '' };
        }

        const title = res.data.assignHomeworkToMember.title ?? '';
        const newHomework: HomeworkType = {
          assignmentId: `${contentType}-${assignmentId}`,
          contentType,
          date: new Date().toISOString(),
          slug: contentId,

          source: HomeworkSource.GINGER,

          status: HomeworkStatus.NOT_STARTED,

          thumbnail:
            'https://content-repository.ginger.dev/audio/thumbnails/COVID.png',
          // TODO: Get this from the JSON file!!
          title,
        };

        if (isCoach) await sendAssignHomeworkRPC(newHomework, uri);
        dispatch(
          addHomework({
            homework: newHomework,
            userId: `${memberId}`,
          }),
        );
        return { status: assignHomeworkResultStatus.OK, title };
      }
      const title = res.data?.assignHomeworkToMember?.title ?? '';
      const exception = res.data?.assignHomeworkToMember?.exception;
      switch (exception) {
        case HOMEWORK_ERR_MSG.IS_DUPLICATED:
          return { status: assignHomeworkResultStatus.IS_DUPLICATED, title };
        case HOMEWORK_ERR_MSG.IS_COMPLETED:
          return { status: assignHomeworkResultStatus.IS_COMPLETED, title };
        case HOMEWORK_ERR_MSG.NOT_ALLOWED_FOR_FUSION:
        case HOMEWORK_ERR_MSG.NOT_ALLOWED_FOR_NON_FUSION:
          return { status: assignHomeworkResultStatus.NOT_ALLOWED, title };
        default:
          return { status: assignHomeworkResultStatus.UNKNOWN, title };
      }
    } catch (e) {
      logger.error(
        new Error(
          `Mutation assignHomeworkToMember failed, homework has not been assigned.`,
        ),
        {
          contentId,
          contentType,
          memberId,
        },
      );
    }
    return { status: assignHomeworkResultStatus.UNKNOWN, title: '' };
  };
}
