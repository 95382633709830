import { Metadata_NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { DeleteVaultItemsMutation } from '@ginger.io/vault-ui/src/generated/graphql';
import { GetAppointmentById_getAppointmentById as Appointment } from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { useAppointmentsAndNotesAPI } from 'app/appointments/AppointmentsAndNotesAPIContext';
import { APPOINTMENT_NOTE_QUERYSTRING_ID } from 'app/appointments/constants';
import { ActionModal } from 'app/patients/tabs/document-upload/ActionModal';
import { useAppState } from 'app/state';
import { useLogger } from 'app/state/log/useLogger';
import { useSnackNotification } from 'hooks/useSnackNotification';
import qs from 'query-string';
import React, { SyntheticEvent, useState } from 'react';
import { useHistory } from 'react-router-dom';
import ClipLoader from 'react-spinners/ClipLoader';
import { Button } from 'shared-components/button/Button';
import { Grid } from 'shared-components/grid';
import { formatDateWithDayOfWeek, getTimezone } from 'utils/dateTime';

import { AuditLogEntryViewV2 } from './AuditLogEntryViewV2';
import { LockNoteDialog } from './LockNoteDialog';
import styles from './NoteActions.module.scss';

export enum NoteType {
  THERAPY_INTAKE = 'Therapy Intake',
  THERAPY_PROGRESS = 'Therapy Progress',
  PSYCHIATRY_INTAKE = 'Psychiatry Intake',
  PSYCHIATRY_PROGRESS = 'Psychiatry Progress',
}

type NoteHeaderProps = {
  status: Metadata_NoteStatus;
  noteType: NoteType;
  disabled?: boolean;
  onNoteLocked: () => Promise<void>;
  deleteDraftNote: () => Promise<DeleteVaultItemsMutation>;
  appointment?: Appointment;
  vaultItemId?: string;
  vaultGroupId?: string;
};

export function NoteActions(props: NoteHeaderProps) {
  const {
    status,
    appointment,
    deleteDraftNote,
    disabled = true,
    vaultGroupId,
    vaultItemId,
  } = props;
  const [openNoteDialog, setLockNoteDialogOpen] = useState(false);
  const [isLocking, setLocking] = useState(false);
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const { timezone } = useAppState((_) => _.user);
  const localTimezone = getTimezone(timezone);
  const { apptDate, memberId, memberName } = getAppointmentInfo(
    appointment,
    localTimezone,
  );
  const [isAuditLogOpen, setIsAuditLogOpen] = useState(false);

  const onViewAuditLogClick = () => {
    setAnchorEl(undefined);
    setIsAuditLogOpen(true);
  };

  const showConfirmationDialog = () => setLockNoteDialogOpen(true);

  const isAuditLogPresent = () =>
    vaultGroupId !== undefined && vaultItemId !== undefined;

  const onClose = () => setLockNoteDialogOpen(false);
  const [anchorEl, setAnchorEl] = useState<Element | undefined>(undefined);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  const onMoreActionsClick = (event?: SyntheticEvent) => {
    setAnchorEl(event?.currentTarget);
  };
  const onActionMenuClose = () => {
    setAnchorEl(undefined);
  };
  const onDeleteNoteClick = () => {
    setAnchorEl(undefined);
    setIsDeleteModalOpen(true);
  };

  const onNoteLocked = async () => {
    try {
      onClose();
      setLocking(true);
      await props.onNoteLocked();
      showSuccessNotification('Note has been signed & locked');
    } catch (e) {
      showErrorNotification('Unable to lock note');
    } finally {
      setLocking(false);
    }
  };

  const actionItems = [];

  if (status === Metadata_NoteStatus.draft_note) {
    actionItems.push(
      <MenuItem
        key="deleteNote"
        data-testid="deleteNote"
        onClick={onDeleteNoteClick}
      >
        Delete Note
      </MenuItem>,
    );
  }

  if (vaultGroupId !== undefined && vaultItemId !== undefined) {
    actionItems.push(
      <MenuItem
        key="viewAuditLog"
        data-testid="viewAuditLog"
        onClick={onViewAuditLogClick}
      >
        View Audit Log
      </MenuItem>,
    );
  }

  return (
    <>
      <LockNoteDialog
        data-testid="lockNoteModal"
        memberId={memberId}
        memberName={memberName}
        appointmentDate={apptDate}
        open={openNoteDialog && !disabled}
        onNoteLocked={onNoteLocked}
        onClose={onClose}
      />
      <DeleteNoteDialog
        isOpen={isDeleteModalOpen}
        setIsOpen={setIsDeleteModalOpen}
        deleteDraft={deleteDraftNote}
      />

      {isAuditLogPresent() && (
        <AuditLogEntryViewV2
          vaultItemId={vaultItemId!}
          vaultGroupId={vaultGroupId!}
          isOpen={isAuditLogOpen}
          setIsOpen={setIsAuditLogOpen}
        />
      )}

      <div className={styles.root}>
        <div className={styles.action}>
          <Grid className={styles.buttonSection}>
            <Button
              testId="signBtn"
              disabled={disabled || isLocking}
              className={styles.signBtn}
              size="small"
              onClick={showConfirmationDialog}
            >
              {isLocking ? (
                <ClipLoader color="#fff" size={12} />
              ) : (
                'Sign & Lock'
              )}
            </Button>
          </Grid>
          <Grid className={styles.buttonSection}>
            <Button
              aria-controls="simple-menu"
              aria-haspopup="true"
              onClick={onMoreActionsClick}
              className={styles.actionsBtn}
              variant="outlined"
              size="small"
              endIcon={<ArrowDropDownIcon />}
              disabled={actionItems.length === 0 || isLocking}
              testId="moreActionsBtn"
            >
              Actions
            </Button>
            <Menu
              anchorEl={anchorEl}
              keepMounted={true}
              open={Boolean(anchorEl)}
              onClose={onActionMenuClose}
              PaperProps={{
                style: { marginTop: '12px', width: anchorEl?.clientWidth },
              }}
              anchorOrigin={{ horizontal: 'center', vertical: 'bottom' }}
              transformOrigin={{ horizontal: 'center', vertical: 'top' }}
            >
              {actionItems}
            </Menu>
          </Grid>
        </div>
      </div>
    </>
  );
}

function getAppointmentInfo(
  appointment: Appointment | undefined,
  timezone: string,
): {
  apptDate: string;
  memberName: string;
  memberId: string;
  clinicianName: string;
} {
  if (appointment) {
    const { start, member, clinician } = appointment;
    return {
      apptDate: formatDateWithDayOfWeek(start, timezone),
      clinicianName: clinician.name,
      memberId: member.id,
      memberName: `${member.firstName} ${member.lastName}`,
    };
  }
  return {
    apptDate: '',
    clinicianName: '',
    memberId: '',
    memberName: '',
  };
}

type DeleteNoteDialogProps = {
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
  deleteDraft: () => Promise<DeleteVaultItemsMutation>;
};

function DeleteNoteDialog({
  deleteDraft,
  isOpen,
  setIsOpen,
}: DeleteNoteDialogProps) {
  const logger = useLogger();
  const [deleting, setDeleting] = useState(false);
  const {
    showErrorNotification,
    showSuccessNotification,
  } = useSnackNotification();
  const {
    noteDetails: { setCursor: setCurrentAppointmentAndNotes },
  } = useAppointmentsAndNotesAPI();
  const history = useHistory();

  const onDeleteModalConfirm = async () => {
    try {
      setDeleting(true);
      await deleteDraft();
      showSuccessNotification('The draft note has been deleted');
      setCurrentAppointmentAndNotes(null); // Go back to appointments
      history.push({
        search: qs.stringify({ [APPOINTMENT_NOTE_QUERYSTRING_ID]: undefined }),
      });
    } catch (error) {
      setDeleting(false);
      logger.error(new Error('DeleteNoteDialog: Unable to delete draft note'), {
        error,
      });
      showErrorNotification('Unable to delete draft note');
    }
  };

  return (
    <>
      <ActionModal
        isOpen={isOpen}
        isConfirmDisabled={false}
        title="Are you sure you want to delete your draft note?"
        onClose={() => {
          if (!deleting) setIsOpen(false);
        }}
        onConfirm={onDeleteModalConfirm}
        actionLabel="Yes, Delete"
        variation="modernWarning"
      >
        <p className={styles.modalText}>
          All note entries <b>will be lost and cannot be recovered</b> once
          deleted.
        </p>
      </ActionModal>
    </>
  );
}
