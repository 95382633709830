import React from 'react';
import BeatLoader from 'react-spinners/BeatLoader';

import styles from './styles/CareProviderInboxLoader.module.scss';

export function CareProviderInboxLoader() {
  return (
    <div data-testid="loader" className={styles.center}>
      <BeatLoader size={10} color="#dea531" loading={true} />
    </div>
  );
}
