import { GetMemberSurvey_getMember_surveyResponses_phq9s_surveys as Phq9Surveys } from '@headspace/carehub-graphql/dist/queries/generated/GetMemberSurvey';
import { Chip } from '@mui/material';
import { RiskTag } from 'app/inbox/components/Tags';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React, { useEffect, useState } from 'react';
import { TooltipWithRedux } from 'shared-components/tooltip/TooltipWithRedux';
import { classNameCombiner } from 'utils';

import headerStyles from './header.module.scss';
import styles from './SuicidalFlag.module.scss';
import { determineFlagText } from './utils';

export const SuicidalFlag = ({ phqLatest }: { phqLatest: Phq9Surveys }) => {
  const { suicidalIdeation, selfHarm } = phqLatest;
  const [flagText, setFlagText] = useState('');
  const [tooltipText, setTooltipText] = useState('');

  const {
    enable_tooltip_label_underline,
  } = useFeatureFlags().transientFeatureFlags;

  useEffect(() => {
    const { flag, tooltip } = determineFlagText(suicidalIdeation, selfHarm);
    setFlagText(flag);
    setTooltipText(tooltip);
  }, [suicidalIdeation, selfHarm]);

  return (
    <>
      {flagText ? (
        <TooltipWithRedux
          labelForAnalytics="memberHeaderBarSuicidalFlag"
          title={tooltipText}
        >
          <Chip
            label={
              <>
                <RiskTag />
                <div
                  className={classNameCombiner([
                    styles.flagText,
                    enable_tooltip_label_underline
                      ? headerStyles.underline
                      : '',
                  ])}
                  data-testid="siFlag"
                >
                  {flagText}
                </div>
              </>
            }
            className={styles.chip}
          />
        </TooltipWithRedux>
      ) : null}
    </>
  );
};
