import Tab from '@mui/material/Tab';
import Tabs from '@mui/material/Tabs';
import { usePubnubListener } from 'app/coach/pubnub/usePubnubListener';
import { InboxTab } from 'app/inbox/types';
import { useSubscribeCoachToPubnub } from 'app/inboxV2/hooks/useSubscribeCoachToPubnub';
import {
  getSelectedTab,
  getShowInbox,
} from 'app/inboxV2/state/sideBar/selectors';
import { setSelectedTab } from 'app/inboxV2/state/sideBar/sideBarSlice';
import styles from 'app/inboxV2/styles/InboxV2.module.scss';
import { useAppState } from 'app/state';
import {
  selectRPCToCoachChannelId,
  selectWildCardPattern,
} from 'app/state/user/selectors';
import React, { SyntheticEvent, useCallback } from 'react';
import { useDispatch } from 'react-redux';

import { CompletedSection } from './CompletedSection';
import { ConversationSection } from './ConversationSection';
import { RiskSection } from './RiskSection';
import { ScheduledSection } from './ScheduledSection';
import { TaskSection } from './TaskSection';

export const InboxV2 = () => {
  // TODO: uncomment this code once the conversations endpoint in listener-server returns data in the right format.
  // https://headspace.atlassian.net/browse/CARE-5921
  // const logger = useLogger();
  //
  // const { data, isLoading, isError, error } = useGetConversationsQuery({
  //   limit: 20,
  //   status: 'open',
  // });
  //
  // logger.info('useGetConversationsQuery result', {
  //   data,
  //   error,
  //   isError,
  //   isLoading,
  // });

  // @TODO: https://headspace.atlassian.net/browse/CARE-5845
  // const tasks = useGetRiskTasksQuery({});
  // logger.info('useGetRiskTasksQuery result', tasks);

  const dispatch = useDispatch();

  const wildCardPattern = useAppState(selectWildCardPattern);
  const rpcToCoachChannelId = useAppState(selectRPCToCoachChannelId);

  const showInbox = useAppState(getShowInbox);
  const selectedTab = useAppState(getSelectedTab);

  useSubscribeCoachToPubnub({
    rpcToCoachChannelId,
    wildCardPattern,
  });

  usePubnubListener();

  const handleChange = useCallback(
    (event: SyntheticEvent, value: InboxTab) => {
      dispatch(setSelectedTab(value));
    },
    [dispatch],
  );

  return (
    <>
      {showInbox ? (
        <div data-testid="inboxV2" className={styles.inboxContainer}>
          <Tabs
            classes={{
              root: styles.inboxHeaderTabs,
            }}
            value={selectedTab}
            variant="fullWidth"
            onChange={handleChange}
          >
            <Tab
              className={styles.inboxChildTab}
              label="Today"
              value={InboxTab.TODAYS}
            />
            <Tab
              className={styles.inboxChildTab}
              label="All"
              value={InboxTab.ALL}
            />
          </Tabs>

          <RiskSection />
          <ConversationSection />
          <TaskSection />
          <ScheduledSection />
          <CompletedSection />
        </div>
      ) : null}
    </>
  );
};
