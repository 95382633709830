import {
  AppointmentType,
  ClinicalAppointmentNoteType,
  ClinicalAppointmentType,
  NoteType,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import MUIPopover from '@mui/material/Popover';
import React, { MouseEvent, useState } from 'react';

import { IconActionBarItem } from './IconActionBarItem';
import { NoteTemplateDropdown, Option } from './NoteTemplateDropdown';

type Props = {
  onClick: (noteType: ClinicalAppointmentNoteType | NoteType) => void;
  appointmentType: ClinicalAppointmentType | AppointmentType;
};

// TODO: switch to use ClinicalAppointmentNoteType
const options: Option[] = [
  { label: 'Intake', template: NoteType.THERAPY_INTAKE },
  { label: 'Progress', template: NoteType.THERAPY_PROGRESS },
  { label: 'Dr. Chrono (legacy)', template: NoteType.UNSPECIFIED },
];

// TODO: switch to use ClinicalAppointmentNoteType
const psychOptions: Option[] = [
  { label: 'Intake', template: NoteType.PSYCHIATRY_INTAKE },
  { label: 'Progress', template: NoteType.PSYCHIATRY_PROGRESS },
  { label: 'Dr. Chrono (legacy)', template: NoteType.UNSPECIFIED },
];

export function CreateClinicalNoteButton(props: Props) {
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [openPopover, setOpenPopover] = useState(false);

  const onClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };

  return (
    <>
      <div role="presentation" onClick={onClick}>
        <IconActionBarItem
          testId="createNote"
          onClick={() => setOpenPopover(true)}
          title="Create Note"
          Icon={AddCircleOutlineIcon}
        />
      </div>
      <MUIPopover
        id="popover"
        open={openPopover}
        anchorEl={anchorEl}
        onClose={() => setOpenPopover(false)}
        anchorOrigin={{
          horizontal: 'left',
          vertical: 'bottom',
        }}
        transformOrigin={{
          horizontal: 'center',
          vertical: 'top',
        }}
      >
        <NoteTemplateDropdown
          options={getNoteTemplateOptions(props.appointmentType)}
          onClick={(item) => {
            setOpenPopover(false);
            props.onClick(item);
          }}
        />
      </MUIPopover>
    </>
  );
}

function getNoteTemplateOptions(
  appointmentType: ClinicalAppointmentType | AppointmentType | null,
): Option[] {
  if (
    appointmentType === ClinicalAppointmentType.PSYCHIATRY_INTAKE ||
    appointmentType === ClinicalAppointmentType.PSYCHIATRY_PROGRESS ||
    appointmentType === AppointmentType.PSYCHIATRY
  ) {
    return psychOptions;
  }
  return options;
}
