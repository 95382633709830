import { OutOfSessionNote } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/OutOfSessionNote';
import { TerminationNote } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/TerminationNote';
import { Metadata_NoteStatus as NoteStatus } from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/shared/Metadata';
import { decodeBase64VaultItems } from '@ginger.io/vault-core';
import { Base64 } from '@ginger.io/vault-core/dist/crypto/Base64';
import { VaultItem_SchemaType } from '@ginger.io/vault-core/dist/generated/protobuf-schemas/vault-core/VaultItem';
import { getClinicalCareTeamGroupId } from '@ginger.io/vault-core/dist/IdHelpers';
import { NotesUserMetadata } from '@ginger.io/vault-shared-care-notes/dist/generated/protobuf-schemas/vault-shared-care-notes/NotesUserMetadata';
import { GetMemberChartVaultItems_getPaginatedVaultItemsByTag_items as VaultItems } from '@headspace/carehub-graphql/dist/coach-member-chart/generated/GetMemberChartVaultItems';
import {
  UserRole,
  VaultItemPermissions,
  VaultItemSortField,
  VaultItemSortOrder,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetNonAppointmentNotesAndUserMetadata,
  GetNonAppointmentNotesAndUserMetadata_NotesUserMetadata,
  GetNonAppointmentNotesAndUserMetadataVariables,
} from '@headspace/carehub-graphql/dist/vault-hooks/generated/GetNonAppointmentNotesAndUserMetadata';
import { GetPaginatedVaultItemsByTag_getPaginatedVaultItemsByTag_items } from '@headspace/carehub-graphql/dist/vault-hooks/generated/GetPaginatedVaultItemsByTag';
import { getNonAppointmentNotesAndUserMetadata } from '@headspace/carehub-graphql/dist/vault-hooks/queries';
import { DecodedNotes } from 'app/coach/coach-notes/CoachNotesTypes';
import { decodeVaultItems } from 'app/coach/coach-notes/decodeVaultItems';
import {
  getMetadataVariables,
  getNotesVariables,
} from 'app/coach/coach-notes/notesQueryVariables';
import { useLogger } from 'app/state/log/useLogger';
import { StateSlice } from 'app/state/status/types/StateSlice';
import { VaultIds } from 'app/vault/api/VaultIds';
import { useMappedQuery } from 'app/vault/hooks/useMappedQuery';
import { isClinicianOrSupervisor } from 'utils';
import { formatDate, formatDateFromObject } from 'utils/dateTime';

export interface OutOfSessionNoteWithSchema {
  note: OutOfSessionNote;
  schema: VaultItem_SchemaType.vault_clinical_notes_out_of_session;
}

export interface TerminationNoteWithSchema {
  note: TerminationNote;
  schema: VaultItem_SchemaType.vault_clinical_notes_termination;
}

export interface OutOfSessionOrTerminationNote {
  __typename: 'OutOfSessionOrTerminationNote';
  vaultItemId: string;
  date: string;
  authorName: string;
  contents: OutOfSessionNoteWithSchema | TerminationNoteWithSchema;
  status: NoteStatus;
  createdAt: string;
  updatedAt: string;
}

export type DecodedNotesUserMetadata = {
  [id: string]: { metadataId: string; hasReadItem: boolean };
};

export interface NonAppointmentNotes {
  outOfSessionOrTerminationNotes: OutOfSessionOrTerminationNote[];
  sharedCoachNotes?: DecodedNotes;
  notesUserMetadata?: DecodedNotesUserMetadata;
}

export function useOutOfSessionAndTerminationNotes(params: {
  memberId: string;
  userId: string;
  vaultUserId: string;
  role: UserRole;
  timezone: string;
}): StateSlice<NonAppointmentNotes> {
  const { memberId, userId, role, timezone, vaultUserId } = params;
  const authData = { memberId, role, timezone, vaultUserId };
  const logger = useLogger();
  return useMappedQuery<
    GetNonAppointmentNotesAndUserMetadata,
    GetNonAppointmentNotesAndUserMetadataVariables,
    NonAppointmentNotes
  >(
    {
      query: getNonAppointmentNotesAndUserMetadata,
      variables: async () => await getVariables(memberId, userId, role),
    },
    async ({
      ClinicalNonAppointmentNotes: clinicalNonAppointmentNotes,
      CoachNotes: coachNotes,
      NotesUserMetadata: metadata,
    }) => {
      let sharedCoachNotes = {};
      if (coachNotes?.items.length) {
        const decodedItems = await decodeVaultItems({
          authData,
          logger,
          metadata,
          notesData: coachNotes,
        });
        sharedCoachNotes = decodedItems.notes;
      }
      const notesUserMetadata = await createNotesUserMetadataRecord(metadata);
      const outOfSessionOrTerminationNotes = await Promise.all(
        clinicalNonAppointmentNotes?.items.map((item: any) =>
          mapToOutOfSessionOrTerminationNote(item, timezone),
        ),
      );
      return {
        notesUserMetadata,
        outOfSessionOrTerminationNotes,
        sharedCoachNotes,
      };
    },
  );
}

const getOutOfSessionAndTerminationNotesVariables = async (
  memberId: string,
) => {
  const notesTag = VaultIds.memberNonAppointmentNotes(memberId);
  return {
    groupId: await Base64.hash(getClinicalCareTeamGroupId(memberId)),
    pagination: {
      cursor: null,
      maxItemsPerPage: 200,
      sortField: VaultItemSortField.CREATED_AT,
      sortOrder: VaultItemSortOrder.DESC,
    },
    tag: await Base64.hash(notesTag),
  };
};

export const getVariables = async (
  memberId: string,
  userId: string,
  role: UserRole,
): Promise<GetNonAppointmentNotesAndUserMetadataVariables> => ({
  clinicalNonAppointmentNotesInput: await getOutOfSessionAndTerminationNotesVariables(
    memberId,
  ),
  coachNotesInput: await getNotesVariables(memberId, role),
  includeCoachNotes: isClinicianOrSupervisor(role),
  metadataInput: await getMetadataVariables(memberId, userId),
});

async function createNotesUserMetadataRecord(
  metadata: GetNonAppointmentNotesAndUserMetadata_NotesUserMetadata,
): Promise<DecodedNotesUserMetadata> {
  return await metadata.items.reduce<Promise<DecodedNotesUserMetadata>>(
    async (
      metadataObj: Promise<VaultItems | DecodedNotesUserMetadata>,
      item,
    ) => {
      const obj = (await metadataObj) as DecodedNotesUserMetadata;
      const { encryptedData, id: metadataId } = item.encryptedItem;

      const metadataSchemaType =
        VaultItem_SchemaType.vault_shared_care_notes_user_metadata;
      const decodedItems = await decodeBase64VaultItems(
        [encryptedData.cipherText],
        {
          [metadataSchemaType]: NotesUserMetadata,
        },
      );

      if (decodedItems[metadataSchemaType].length > 0) {
        const { vaultItemId, hasReadItem } = decodedItems[
          metadataSchemaType
        ][0];
        return {
          ...obj,
          [vaultItemId]: { hasReadItem, metadataId },
        };
      }

      return obj;
    },
    Promise.resolve({} as DecodedNotesUserMetadata),
  );
}

async function mapToOutOfSessionOrTerminationNote(
  item: GetPaginatedVaultItemsByTag_getPaginatedVaultItemsByTag_items,
  timezone: string,
): Promise<OutOfSessionOrTerminationNote> {
  const {
    encryptedData,
    updatedAt,
    creator,
    createdAt,
    id,
    permissions,
  } = item.encryptedItem;
  const { firstName, lastName } = creator;

  const outOfSessionSchemaType =
    VaultItem_SchemaType.vault_clinical_notes_out_of_session;
  const terminationSchemaType =
    VaultItem_SchemaType.vault_clinical_notes_termination;
  const decodedItems = await decodeBase64VaultItems(
    [encryptedData.cipherText],
    {
      [outOfSessionSchemaType]: OutOfSessionNote,
      [terminationSchemaType]: TerminationNote,
    },
  );

  const schema =
    decodedItems[outOfSessionSchemaType].length > 0
      ? outOfSessionSchemaType
      : terminationSchemaType;
  const [note] = decodedItems[schema];
  let date = formatDate(createdAt, timezone);
  if (schema === outOfSessionSchemaType) {
    const outOfSession = note as OutOfSessionNote;
    date = formatDateFromObject(
      {
        ...outOfSession.dateOfContact!,
        ...outOfSession.durationOfContact?.startTime!,
      },
      timezone,
    );
  }

  return {
    __typename: 'OutOfSessionOrTerminationNote',
    authorName: `${firstName} ${lastName}`,
    contents: { note, schema } as
      | OutOfSessionNoteWithSchema
      | TerminationNoteWithSchema,
    createdAt: formatDate(createdAt, timezone),
    date,
    status:
      permissions === VaultItemPermissions.READ_ONLY
        ? NoteStatus.signed_and_locked_note
        : NoteStatus.draft_note,
    updatedAt: updatedAt ? formatDate(updatedAt, timezone) : '-',
    vaultItemId: id,
  };
}
