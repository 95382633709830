import { GetClinicianInboxTotal } from '@headspace/carehub-graphql/dist/queries/generated/GetClinicianInboxTotal';
import { GetCoachInboxTotal } from '@headspace/carehub-graphql/dist/queries/generated/GetCoachInboxTotal';
import {
  getClinicianInboxTotalQuery,
  getCoachInboxTotalQuery,
} from '@headspace/carehub-graphql/dist/queries/GetInboxTotal';
import { isClinicianOrSupervisor, isCoachOrSupervisor } from 'utils';

import { updateTodaysInboxTotalCount } from '../actions';
import {
  getTodaysClinicalInboxMemberCount,
  getTodaysCoachInboxMemberCount,
} from '../utils';
import { ActionHandler } from './types';

export const onRefreshTodaysInboxTotalCount = async ({
  action,
  redux,
  context,
}: ActionHandler<any>) => {
  // this action updates today's member count by re-fetching the following queries:
  // GetCoachInboxTotal and GetClinicianInboxTotal
  const { user } = redux.getState();
  const { logger, apollo } = context.services;

  let query;

  if (isCoachOrSupervisor(user.role)) {
    query = getCoachInboxTotalQuery;
  }

  if (isClinicianOrSupervisor(user.role)) {
    query = getClinicianInboxTotalQuery;
  }

  if (!query) return;

  const { data, error } = await apollo.query<
    GetCoachInboxTotal | GetClinicianInboxTotal
  >({
    fetchPolicy: 'network-only',
    query,
  });
  if (!error) {
    const todaysMemberCount = isCoachOrSupervisor(user.role)
      ? getTodaysCoachInboxMemberCount(
          (data as GetCoachInboxTotal).getCoachInboxTotal,
        )
      : getTodaysClinicalInboxMemberCount(
          (data as GetClinicianInboxTotal).getInboxTotal,
        );
    logger.info("refreshTodaysInboxTotalCount: today's member count", {
      getClinicianInboxTotal: (data as GetClinicianInboxTotal).getInboxTotal,
      getCoachInboxTotal: (data as GetCoachInboxTotal).getCoachInboxTotal,
      todaysMemberCount,
    });
    redux.dispatch(updateTodaysInboxTotalCount({ todaysMemberCount }));
  }
};
