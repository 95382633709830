import {
  appointmentListMSViewed,
  appointmentRecurringBiweeklyClick,
  appointmentRecurringClick,
  appointmentRecurringDoesNotRepeatClick,
  appointmentRecurringMonthlyClick,
  appointmentRecurringWeeklyClick,
  calendarFilterAppointmentsClick,
  calendarFilterAvailabilityClick,
  calendarFilterClick,
  calendarFilterEventsClick,
  clickAppointmentListAppointment,
  clickScheduleAppointmentListMemberFilterAction,
  clickScheduleAppointmentListNewAppointmentAction,
  clickScheduleCalendarNewAppointmentAction,
  clickScheduleCalendarNewEventAction,
  clickScheduleNewAppointmentSaveAction,
  createAppointmentAction,
  doubleClickedAppointment,
  doubleClickedEvent,
  updateAppointmentAction,
  viewAppointmentAction,
  viewAppointmentScreenAction,
  viewAppointmentsTableAction,
  viewPatientAppointmentTabAction,
  viewScheduleAppointmentListScreenAction,
  viewScheduleCalendarScreenAction,
} from 'app/state/amplitude/actions/appointments';
import {
  clickCareMetricsMonthlyPickerAction,
  clickFeedbackCarousalAction,
  clickGoogleCalendarAction,
  clickWeeklyBreakdownViewAction,
  viewClinicianAnalyticsAction,
} from 'app/state/amplitude/actions/careMetrics';
import {
  addSICoachButtonStatusChanged,
  careTeamAddMemberSupportClicked,
  careTeamClicked,
  careTeamEscalateToClinicalClicked,
  careTeamMemberProfileViewed,
  careTeamTogglePastMembersClicked,
} from 'app/state/amplitude/actions/careTeam';
import {
  careProviderReadMessagesEvent,
  careProviderSentSomeLinkEvent,
  careProviderTypingMessagesEvent,
  careProviderWriteMessageEvent,
  chatErrorAmplitudeEvent,
  textAreaFocusChangeEvent,
} from 'app/state/amplitude/actions/chat';
import {
  addContentCircleButtonClicked,
  addHomeworkButtonClicked,
  contentUrlPastedIntoModal,
  invalidContentUrlAdded,
  unassignButtonClicked,
  viewLibraryButtonClicked,
} from 'app/state/amplitude/actions/content';
import {
  documentDeleteClicked,
  documentDownloadClicked,
  documentEditClicked,
  documentFileUploadClicked,
  documentOpenDocumentClicked,
  documentPreviewClicked,
  documentPrintClicked,
  documentTabViewed,
  documentUploadClicked,
} from 'app/state/amplitude/actions/documents';
import * as actions from 'app/state/amplitude/actions/etc';
import { pageLoad } from 'app/state/amplitude/actions/etc';
import {
  clickScheduleEventUpdateAction,
  clickScheduleNewEventCreateAction,
  eventViewedAction,
  newEventViewedAction,
} from 'app/state/amplitude/actions/events';
import {
  clickInboxMenuAction,
  clickInboxTabAction,
  clickMemberChartTabAction,
  clickMemberChartTabClosedAction,
  clickMemberItemAction,
  inboxMemberSearch,
  loadMemberListAction,
  loadMoreMemberListAction,
  openedNewMemberChartTab,
} from 'app/state/amplitude/actions/inbox';
import {
  careProviderNotesEvent,
  clickedNoteListActionButtonNote,
  clickedSaveDraftOOSNote,
  clickedSaveDraftTerminationNote,
  clickedSignAndLockOOSNote,
  clickedSignAndLockTerminationNote,
  clinicalNoteInteractionCompleted,
  deletedCoachNote,
  deletedDraftClinicalNote,
  nonAppointmentNoteButtonClicked,
  noteTabViewed,
  psychiatryIntakeClick,
  psychiatryProgressClick,
  reasonForContactSelected,
  reasonForTerminationSelected,
  selectNonAppointmentNoteTemplateFromDropdown,
  signedAndLockedClinicalNote,
  therapyIntakeClick,
  therapyProgressClick,
  viewedClinicalNote,
  viewedCoachNote,
  viewOOSNote,
  viewTerminationNote,
  wroteClinicalNote,
  wroteCoachNote,
} from 'app/state/amplitude/actions/notes';
import {
  coachShiftStatusChanged,
  disableDropInNotificationDisplayed,
  enableDropInNotificationDisplayed,
} from 'app/state/amplitude/actions/structuredCoaching';
import {
  selectGAD7ChartDropdown,
  selectGAD7ChartHover,
  selectPHQ9ChartDropdown,
  selectPHQ9ChartHover,
  viewSurveyTabAction,
} from 'app/state/amplitude/actions/surveys';
import {
  markTaskAsCompleteAction,
  viewTaskScreenAction,
} from 'app/state/amplitude/actions/tasks';
import { Context } from 'app/state/context';
import { State } from 'app/state/schema';
import { createActionHandlers } from 'redux-reloaded';
import { logCareHubProviderIdleBrowserTabTimeout } from './actions/page';

export const handlers = createActionHandlers<Context, State>();

const actionsToTrack = [
  actions.viewIntakeTabAction,
  actions.viewSummaryTabAction,
  viewSurveyTabAction,
  viewAppointmentsTableAction,
  viewPatientAppointmentTabAction,
  selectGAD7ChartDropdown,
  selectPHQ9ChartDropdown,
  actions.selectPatientAction,
  selectPHQ9ChartHover,
  selectGAD7ChartHover,
  actions.viewMemberTabAction,
  actions.viewMemberListAction,
  viewAppointmentScreenAction,
  viewScheduleCalendarScreenAction,
  viewScheduleAppointmentListScreenAction,
  doubleClickedEvent,
  doubleClickedAppointment,
  viewTaskScreenAction,
  actions.viewMemberSearchPageAction,
  markTaskAsCompleteAction,
  actions.updateMemberProfileAction,
  actions.viewMemberProfileAction,
  viewAppointmentAction,
  clickScheduleCalendarNewAppointmentAction,
  clickScheduleCalendarNewEventAction,
  clickScheduleAppointmentListNewAppointmentAction,
  clickScheduleNewAppointmentSaveAction,
  clickScheduleNewEventCreateAction,
  createAppointmentAction,
  updateAppointmentAction,
  clickScheduleAppointmentListMemberFilterAction,
  newEventViewedAction,
  eventViewedAction,
  clickScheduleEventUpdateAction,
  calendarFilterClick,
  calendarFilterAppointmentsClick,
  calendarFilterAvailabilityClick,
  calendarFilterEventsClick,
  appointmentRecurringClick,
  appointmentRecurringDoesNotRepeatClick,
  appointmentRecurringWeeklyClick,
  appointmentRecurringBiweeklyClick,
  appointmentRecurringMonthlyClick,
  clickAppointmentListAppointment,
  appointmentListMSViewed,
  therapyIntakeClick,
  therapyProgressClick,
  psychiatryIntakeClick,
  psychiatryProgressClick,
  actions.memberListNewAppointmentClick,
  noteTabViewed,
  nonAppointmentNoteButtonClicked,
  selectNonAppointmentNoteTemplateFromDropdown,
  viewOOSNote,
  viewTerminationNote,
  reasonForContactSelected,
  reasonForTerminationSelected,
  clickedSignAndLockOOSNote,
  clickedSignAndLockTerminationNote,
  clickedNoteListActionButtonNote,
  clickedSaveDraftOOSNote,
  clickedSaveDraftTerminationNote,
  documentDeleteClicked,
  documentPreviewClicked,
  documentPrintClicked,
  documentEditClicked,
  documentDownloadClicked,
  documentTabViewed,
  documentFileUploadClicked,
  documentUploadClicked,
  documentOpenDocumentClicked,
  actions.memberScreenViewed,
  actions.navigationTabClicked,
  actions.tooltipViewed,
  actions.bannerLinkClicked,
  actions.itemModified,
  viewClinicianAnalyticsAction,
  clickWeeklyBreakdownViewAction,
  clickCareMetricsMonthlyPickerAction,
  clickGoogleCalendarAction,
  clickFeedbackCarousalAction,
  careProviderNotesEvent,
  viewedCoachNote,
  deletedCoachNote,
  wroteCoachNote,
  viewedClinicalNote,
  deletedDraftClinicalNote,
  signedAndLockedClinicalNote,
  wroteClinicalNote,
  careProviderReadMessagesEvent,
  careProviderTypingMessagesEvent,
  careProviderWriteMessageEvent,
  textAreaFocusChangeEvent,
  careProviderSentSomeLinkEvent,
  chatErrorAmplitudeEvent,
  addSICoachButtonStatusChanged,
  chatErrorAmplitudeEvent,
  clickInboxTabAction,
  clickMemberItemAction,
  clickMemberChartTabAction,
  openedNewMemberChartTab,
  clickMemberChartTabClosedAction,
  clickInboxMenuAction,
  loadMoreMemberListAction,
  loadMemberListAction,
  inboxMemberSearch,
  viewLibraryButtonClicked,
  addContentCircleButtonClicked,
  contentUrlPastedIntoModal,
  addHomeworkButtonClicked,
  invalidContentUrlAdded,
  unassignButtonClicked,
  actions.vaultLoginCompleted,
  careTeamClicked,
  careTeamAddMemberSupportClicked,
  careTeamEscalateToClinicalClicked,
  careTeamMemberProfileViewed,
  careTeamTogglePastMembersClicked,
  pageLoad,
  actions.userIdleTimeOutEvent,
  coachShiftStatusChanged,
  disableDropInNotificationDisplayed,
  enableDropInNotificationDisplayed,
  clinicalNoteInteractionCompleted,
  logCareHubProviderIdleBrowserTabTimeout,
];

actionsToTrack.forEach((_) => {
  handlers.on(_, async ({ action, context }) => {
    void context.services.amplitude.logEvent(action);
  });
});
