import {
  ClinicalAppointmentNoteType,
  NoteType,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { GetAppointmentById_getAppointmentById } from '@headspace/carehub-graphql/dist/vault/generated/GetAppointmentById';
import {
  DownloadableNoteType,
  generateNoteHeader,
} from 'app/appointments/DownloadClinicalNoteButton';
import {
  ClinicalNotePDF,
  ClinicalNotes,
} from 'app/notes-ui/pdf/ClinicalNotePDF';
import { PsychiatryIntakeNotePDF } from 'app/notes-ui/pdf/PsychiatryIntakeNotePDF';
import { PsychiatryProgressNotePDF } from 'app/notes-ui/pdf/PsychiatryProgressNotePDF';
import { TherapyIntakeNotePDF } from 'app/notes-ui/pdf/TherapyIntakeNotePDF';
import { TherapyProgressNotePDF } from 'app/notes-ui/pdf/TherapyProgressNotePDF';
import { convertPdfDocToBlobStream } from 'app/notes-ui/qa-checks/convertPdfDocumentToBlobStream';
import { EnumOption } from 'app/notes-ui/types';
import { ILogger } from 'app/state/log/Logger';
import { PsychiatryIntakeNote } from 'app/vault/api/PsychiatryIntakeNotesAPI';
import { PsychiatryProgressNote } from 'app/vault/api/PsychiatryProgressNotesAPI';
import { TherapyIntakeNote } from 'app/vault/api/TherapyIntakeNotesAPI';
import { TherapyProgressNote } from 'app/vault/api/TherapyProgressNotesAPI';
import Messages from 'i18n/en/appointment.json';

import { NotePDFBuilderImpl } from '../pdf/NotePDFBuilder';

export async function generateClinicalNotePdf(
  note:
    | TherapyIntakeNote
    | TherapyProgressNote
    | PsychiatryProgressNote
    | PsychiatryIntakeNote,
  diagnosisCodesOptions: EnumOption[],
  logger: ILogger,
  appointmentMetadata: GetAppointmentById_getAppointmentById,
  noteType: DownloadableNoteType,
  builder?: NotePDFBuilderImpl,
) {
  const noteHeader = generateNoteHeader(note, appointmentMetadata);
  let pdfBuilder: ClinicalNotePDF<ClinicalNotes>;
  const timezone: string = 'America/Los_Angeles';

  switch (noteType) {
    case ClinicalAppointmentNoteType.THERAPY_INTAKE:
    case NoteType.THERAPY_INTAKE: {
      pdfBuilder = new TherapyIntakeNotePDF(
        note as TherapyIntakeNote,
        noteHeader,
        diagnosisCodesOptions,
        logger,
        undefined,
        timezone,
        builder,
      );
      break;
    }
    case ClinicalAppointmentNoteType.THERAPY_PROGRESS:
    case NoteType.THERAPY_PROGRESS: {
      pdfBuilder = new TherapyProgressNotePDF(
        note as TherapyProgressNote,
        noteHeader,
        diagnosisCodesOptions,
        logger,
        undefined,
        timezone,
        builder,
      );
      break;
    }
    case ClinicalAppointmentNoteType.PSYCHIATRY_PROGRESS:
    case NoteType.PSYCHIATRY_PROGRESS: {
      pdfBuilder = new PsychiatryProgressNotePDF(
        note as PsychiatryProgressNote,
        noteHeader,
        diagnosisCodesOptions,
        logger,
        undefined,
        timezone,
        builder,
      );
      break;
    }
    case ClinicalAppointmentNoteType.PSYCHIATRY_INTAKE:
    case NoteType.PSYCHIATRY_INTAKE: {
      pdfBuilder = new PsychiatryIntakeNotePDF(
        note as PsychiatryIntakeNote,
        noteHeader,
        diagnosisCodesOptions,
        logger,
        undefined,
        timezone,
        builder,
      );
      break;
    }
    default:
      throw new Error(Messages.unsupportedNoteType);
  }
  const pdfDoc = pdfBuilder.getPDF();
  return convertPdfDocToBlobStream(pdfDoc);
}
