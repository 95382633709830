import {
  GetMemberSessionStats,
  GetMemberSessionStats_getMember_coverageDetails_psychiatry,
} from '@headspace/carehub-graphql/dist/appointments/generated/GetMemberSessionStats';
import { CreditCardStatus } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import moment from 'moment-timezone';
import { useEffect, useState } from 'react';

import { MemberSessionStats } from './MemberSessionStats';

type UseMemberSessionStatsProps = {
  data?: GetMemberSessionStats;
  timezone: string;
};

type UseMemberSessionStatusOutput = {
  memberSessionStats: MemberSessionStats;
  sessionCoverageExhausted: boolean;
};

export function useMemberSessionStats({
  data,
  timezone,
}: UseMemberSessionStatsProps): UseMemberSessionStatusOutput {
  const statData: GetMemberSessionStats = data ?? { getMember: null };
  const stats = getMemberCoverageDetailsSummary(statData);
  const renewalDate = statData.getMember?.coverageDetails?.renewalDate;
  const formattedRenewalDate = renewalDate
    ? moment.tz(renewalDate, timezone).format('MM/DD/YYYY')
    : undefined;
  const { sessionsCoveredByEmployer, sessionsCoveredUsed } = stats;
  const memberSessionStats: MemberSessionStats = {
    resetDate: formattedRenewalDate,
    sessionsAnticipated: stats?.sessionsAnticipated ?? undefined,
    sessionsCoveredByEmployer: stats?.sessionsCoveredByEmployer,
    sessionsCoveredUsed: stats?.sessionsCoveredUsed || 0,
    upcomingScheduled: statData.getMember?.coverageDetails?.upcomingScheduled,
  };
  const [sessionCoverageExhausted, setSessionCoverageExhausted] = useState<
    boolean
  >(false);

  useEffect(() => {
    if (
      sessionsCoveredByEmployer === undefined &&
      sessionsCoveredUsed === undefined
    ) {
      // If sessionsCoveredByEmployer is 0, it means that the employer does not have session coverage configured (or
      // configured incorrectly). Therefore, we cannot conclude that their sessions coverage is exhausted, so only
      // set sessionCoverageExhausted if sessionsCoveredByEmployer is > 0 and the used count matches (or exceeds) the allowed
      // value. Remembering that sessionsCoveredByEmployer equals null means unlimited sessions covered.
      // Additionally, only  if the user don't valid card, we doesn't allow he to continue adding sessions. Otherwise
      // the clinician can continue adding new sessions.
      setSessionCoverageExhausted(false);
      return;
    }

    if (
      sessionsCoveredByEmployer &&
      sessionsCoveredByEmployer - (sessionsCoveredUsed || 0) < 1 &&
      (statData.getMember?.billing?.creditCardStatus ===
        CreditCardStatus.MISSING ||
        statData.getMember?.billing?.creditCardStatus ===
          CreditCardStatus.EXPIRED ||
        statData.getMember?.billing?.creditCardStatus ===
          CreditCardStatus.GUARDIAN_EXPIRED)
    ) {
      setSessionCoverageExhausted(true);
    } else {
      setSessionCoverageExhausted(false);
    }
  }, [sessionsCoveredByEmployer, sessionsCoveredUsed]);

  return { memberSessionStats, sessionCoverageExhausted };
}

export function getMemberCoverageDetailsSummary(
  data: GetMemberSessionStats,
): Partial<GetMemberSessionStats_getMember_coverageDetails_psychiatry> {
  if (!data.getMember?.coverageDetails) {
    return {};
  }
  // Usage (sessionsCoveredUsed) can theoretically span psychiatry and therapy stats (e.g. patient has had both psychiatric
  // and therapy sessions).

  // Coverage (sessionsCoveredByEmployer), however, should be non-zero in only one of [ psychiatry, therapy, both ].
  // If sessionsCoveredByEmployer is non-zero in "therapy," then a number of therapy sessions are covered.
  // If sessionsCoveredByEmployer is non-zero in "psychiatry," then a number of psychiatry sessions are covered.
  // If sessionsCoveredByEmployer is non-zero in "both," then a number of EITHER therapy OR psychiatry sessions are covered (mix-
  //   and-match).
  // If sessionsCoveredByEmployer is null, then there are unlimited sessions covered.

  const { psychiatry, therapy, both } = data.getMember.coverageDetails;

  // The "both" property is unique in that its only useful property is sessionsCoveredByEmployer
  // both.sessionsCoveredUsed should always be 0 because one can't have a session that is both psychiatry and therapy
  // both.sessionAnticipated should always be null because that number only applies to either psychiatry or therapy
  const sessionsCoveredUsed =
    (psychiatry.sessionsCoveredUsed || 0) + (therapy.sessionsCoveredUsed || 0);
  const sessionsCoveredByEmployer =
    both.sessionsCoveredByEmployer === null ||
    psychiatry.sessionsCoveredByEmployer === null ||
    therapy.sessionsCoveredByEmployer === null
      ? null
      : both.sessionsCoveredByEmployer +
        psychiatry.sessionsCoveredByEmployer +
        therapy.sessionsCoveredByEmployer;
  // If both psychiatry and therapy stats do not have a sessionsAnticipated, return null (as opposed to 0) to be more
  // accurate.
  const noSessionsAnticipatedAvailable =
    psychiatry.sessionsAnticipated === null &&
    therapy.sessionsAnticipated === null;
  const sessionsAnticipated = noSessionsAnticipatedAvailable
    ? null
    : (psychiatry.sessionsAnticipated ?? 0) +
      (therapy.sessionsAnticipated ?? 0);

  return {
    __typename: 'CoverageDetails',
    sessionsAnticipated,
    sessionsCoveredByEmployer,
    sessionsCoveredUsed,
  };
}
