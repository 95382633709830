import { ApolloCache } from '@apollo/client';
import {
  CreateMemberChartVaultItems,
  CreateMemberChartVaultItems_createVaultItems as VaultItemWithKey,
} from '@headspace/carehub-graphql/dist/coach-member-chart/generated/CreateMemberChartVaultItems';
import {
  GetNonAppointmentNotesAndUserMetadata,
  GetNonAppointmentNotesAndUserMetadataVariables,
} from '@headspace/carehub-graphql/dist/vault-hooks/generated/GetNonAppointmentNotesAndUserMetadata';
import { getNonAppointmentNotesAndUserMetadata } from '@headspace/carehub-graphql/dist/vault-hooks/queries';

type UpdateCacheParams = {
  cache: ApolloCache<any>;
  data: CreateMemberChartVaultItems;
  variables: GetNonAppointmentNotesAndUserMetadataVariables;
};

/**
 * Updates the query cache after a create mutation is made to include the newly created VaultItems
 * in the query response body. Currently, this is only needed for the NotesUserMetadata items
 *
 * @param params
 * @returns
 */
export const updateCache = (params: UpdateCacheParams) => {
  const { cache, data, variables } = params;
  const items = cache.readQuery<
    GetNonAppointmentNotesAndUserMetadata,
    GetNonAppointmentNotesAndUserMetadataVariables
  >({
    query: getNonAppointmentNotesAndUserMetadata,
    variables,
  });
  const item: VaultItemWithKey[] = (data as CreateMemberChartVaultItems)
    .createVaultItems;

  const cachedClinicalNonAppointmentNotes =
    items?.ClinicalNonAppointmentNotes.items ?? [];
  const cachedCoachNotesItems = items?.CoachNotes.items ?? [];
  const cachedMetadataNotesItems = items?.NotesUserMetadata.items ?? [];

  cache.writeQuery<
    GetNonAppointmentNotesAndUserMetadata,
    GetNonAppointmentNotesAndUserMetadataVariables
  >({
    data: {
      ClinicalNonAppointmentNotes: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.CoachNotes,
        items: [...cachedClinicalNonAppointmentNotes],
      },
      CoachNotes: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.CoachNotes,
        items: [...cachedCoachNotesItems],
      },
      NotesUserMetadata: {
        __typename: 'GetVaultItemsByTagResponse',
        cursor: null,
        ...items?.NotesUserMetadata,
        items: [...item, ...cachedMetadataNotesItems],
      },
    },
    query: getNonAppointmentNotesAndUserMetadata,
    variables,
  });
};
