import { classNameCombiner } from 'utils';

import styles from './SectionContentItems.module.scss';

export enum SubContentTextColorEnum {
  critical = 'critical',
  primary = 'primary',
  default = 'default',
}

export const MainContentText = ({ label }: { label: string }) => {
  return <div className={styles.mainContentText}>{label}</div>;
};

export const SubContentText = ({
  label,
  textColor = SubContentTextColorEnum.default,
}: {
  label: string;
  textColor?: SubContentTextColorEnum;
}) => {
  return (
    <div
      className={classNameCombiner([styles.subContentText, styles[textColor]])}
    >
      {label}
    </div>
  );
};

export const CountBubble = ({ total }: { total: string | number }) => {
  return <div className={styles.countBubble}>{total}</div>;
};
