import {
  InboxSectionHeaderColor,
  InboxSectionIcon,
} from 'app/inbox/components/InboxSection';
import { InboxSections } from 'app/inbox/types';

import { InboxSectionItem } from '../InboxSectionItem';
import { InboxSectionWrapper } from '../InboxSectionWrapper';
import {
  MainContentText,
  SubContentText,
  SubContentTextColorEnum,
} from '../SectionContentItems';

const dummyResponse = [
  {
    additionalContent: 'Review Risk',
    name: 'Test',
    time: '10mo',
  },

  {
    additionalContent: 'Review Risk',
    name: 'Test 2',
    time: '1mo',
  },

  {
    additionalContent: 'Review Risk',
    name: 'Test 3',
    time: '1w',
  },
];

export const RiskSection = () => {
  return (
    <InboxSectionWrapper
      headerColor={InboxSectionHeaderColor.LIGHT_ROSE}
      sectionTitle="Risk Tasks"
      section={InboxSections.RISKS}
      sectionIcon={InboxSectionIcon.SUICIDE_RISK}
      sectionItemCount={dummyResponse.length}
    >
      {dummyResponse.map(({ name, time, additionalContent }) => (
        <InboxSectionItem
          key={`${name}-${time}`}
          topLeft={<MainContentText label={name} />}
          topRight={<SubContentText label={time} />}
          bottomLeft={
            <SubContentText
              label={additionalContent}
              textColor={SubContentTextColorEnum.critical}
            />
          }
          onClick={() => console.log('clicked')}
        />
      ))}
    </InboxSectionWrapper>
  );
};
