import { useEffect, useRef } from 'react';

export type ID = ReturnType<typeof setInterval>;

export function useInterval(
  callback: () => void,
  delay: number,
  enableInterval = true,
): ID | undefined {
  const savedCallback = useRef<() => void>();
  const idRef = useRef<ID>();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current!();
    }
    if (enableInterval) {
      idRef.current = setInterval(tick, delay);
    }
    return () => {
      if (idRef.current) clearInterval(idRef.current);
    };
  }, [delay, enableInterval]);

  return idRef.current;
}
