import {
  CoachShiftStatusEnum,
  UserRole,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';

export type State = Readonly<{
  userId: string | null;
  vaultUserId: string | null;
  firstName: string | null;
  lastName: string | null;
  role: UserRole | null;
  timezone: string | null;
  clinicianId: string | null;
  pubnubAuthKey: string | null;
  listenerId: string | null;
  coachinghubUsername: string | null;
  rpcToCoachChannelId: string | null;
  shiftStatus: CoachShiftStatusEnum | null;
  listenerUserId: string | null;
  showAuthorizationModal: boolean;
}>;

export function getInitialState(state?: Partial<State>): State {
  return {
    clinicianId: null,
    coachinghubUsername: null,
    firstName: null,
    lastName: null,
    listenerId: null,
    listenerUserId: null,
    pubnubAuthKey: null,
    role: null,
    rpcToCoachChannelId: null,
    shiftStatus: null,
    showAuthorizationModal: false,
    timezone: null,
    userId: null,
    vaultUserId: null,
    ...state,
  };
}
