import {
  booleanField,
  field,
  FieldDefinitions,
  stringField,
  useForm,
  validate as validateForm,
} from '@ginger.io/react-use-form';
import {
  ClinicianChecklist,
  ClinicianChecklist_Version,
  Location,
} from '@ginger.io/vault-clinical-notes/dist/generated/protobuf-schemas/vault-clinical-notes/therapy/progress/ClinicianChecklist';
import {
  ClinicianChecklistSection,
  TherapyProgressSectionName,
} from '@ginger.io/vault-clinical-notes/dist/TherapyProgressSection';
import {
  CheckboxWithDescriptionField,
  EnumRadioField,
  TextAreaField,
} from 'app/notes-ui/forms/fields';
import styles from 'app/notes-ui/forms/form-controls/FormNote.module.scss';
import { NoteFormControlLabelStyles } from 'app/notes-ui/forms/form-controls/NoteFormControlLabel';
import { RadioListOrientations } from 'app/notes-ui/forms/form-controls/RadioButtonGroup';
import PopulateAppointmentDurationContainer from 'app/notes-ui/populate-appointment-duration/PopulateAppointmentDurationContainer';
import {
  deprecatedField,
  optionalField,
} from 'app/notes-ui/shared/ValidationRules';
import Labels from 'app/notes-ui/strings/en.json';
import {
  NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
  requiredFieldIf,
} from 'app/notes-ui/utils';
import { useFeatureFlags } from 'hooks/useFeatureFlags';
import React from 'react';
import { Width } from 'types/StyleTypes';
import { classNameCombiner } from 'utils';

export const fieldDefs: FieldDefinitions<ClinicianChecklist> = {
  appointmentId: stringField(),
  currentLocation: stringField({
    default: '',
    rules: [
      (value, state: ClinicianChecklist) =>
        requiredFieldIf(
          value,
          state,
          'physicallyLocatedAtApptAddress',
          Location.located_outside_appt_address,
        ),
    ],
  }),
  introducedCoachingToMember: booleanField({
    rules: [
      (value) => {
        if (value === false) {
          return 'This field must be checked';
        }
      },
    ],
  }),
  memberGaveConsentForTelehealthAppointment: booleanField({
    default: true,
    rules: [deprecatedField],
  }),
  physicallyLocatedAtApptAddress: field<Location>({
    default: Location.undefined_location,
    rules: [
      (value, _) => {
        if (value === Location.undefined_location) {
          return 'This field must be selected';
        }
      },
    ],
  }),
  releaseOfInformationRequested: booleanField({
    default: true,
    rules: [deprecatedField],
  }),
  remarkableEntries: stringField({ rules: [optionalField] }),
  reviewedLastSessionEntries: booleanField({
    default: true,
    rules: [deprecatedField],
  }),
  version: field<ClinicianChecklist_Version>({
    default: ClinicianChecklist_Version.v0,
  }),
};

export const validate = (data: ClinicianChecklist | null) =>
  data !== null && validateForm(data, fieldDefs);

const formControlStyles: NoteFormControlLabelStyles = {
  checkbox: styles.checkbox,
  label: styles.label,
  root: styles.root,
  subtext: styles.subtext,
};

export type Props = {
  appointmentId: string;
  onSubmit: (checklist: ClinicianChecklistSection) => void;
  initialValue?: ClinicianChecklist; // used for read-only
  disabled?: boolean;
};

export function ClinicianChecklistFormV2(props: Props) {
  const { initialValue, appointmentId, disabled } = props;

  const { fields, getValue } = useForm<ClinicianChecklist>(
    fieldDefs,
    {
      ...initialValue,
      appointmentId,
      version: ClinicianChecklist_Version.v0,
    } as ClinicianChecklist,
    {
      delay: NOTES_EFFICIENCY_AUTOSAVE_THRESHOLD,
      onStateChange: async () => {
        await onSubmit();
      },
    },
  );

  const {
    enable_zoom_duration_button: enableZoomDurationButton,
  } = useFeatureFlags().transientFeatureFlags;

  const onSubmit = async () => {
    if (disabled) return;

    props.onSubmit({
      data: getValue(),
      name: TherapyProgressSectionName.CLINICIAN_CHECKLIST,
    });
  };

  const zoomDurationButton =
    enableZoomDurationButton && !disabled ? (
      <PopulateAppointmentDurationContainer
        field={fields.remarkableEntries}
        appointmentId={appointmentId}
      />
    ) : null;

  return (
    <div>
      {disabled &&
      fields.physicallyLocatedAtApptAddress.value ===
        Location.undefined_location ? null : (
        <div className={styles.formGroupV2}>
          <EnumRadioField
            disabled={disabled}
            testId="physicallyLocatedAtApptAddress"
            label="Member is physically located at address on file for this session"
            options={Location}
            field={fields.physicallyLocatedAtApptAddress}
            keyLabels={{
              located_at_appt_address: 'Yes',
              located_outside_appt_address: 'No',
            }}
            formControlStyles={formControlStyles}
            radioListOrientation={RadioListOrientations.HORIZONTAL}
          />
        </div>
      )}

      {fields.physicallyLocatedAtApptAddress.value ===
        Location.located_outside_appt_address && (
        <div
          className={classNameCombiner([
            styles.formGroupV2,
            styles.textAreaField,
          ])}
        >
          <TextAreaField
            testId="currentLocation"
            disabled={disabled}
            label="Provide current location information"
            placeholder="If member is unable to provide exact address, document known identifiers, such as a license plate number or name of a building / park"
            field={fields.currentLocation}
            width={Width.FULL}
            className={styles.textAreaField}
            formControlStyles={formControlStyles}
            autoExpand={true}
            rows={1}
          />
        </div>
      )}

      <div className={styles.formGroupV2}>
        <CheckboxWithDescriptionField
          disabled={disabled}
          title={Labels.CLINICIAN_REVIEWED_INFORMED_CONSENT}
          description="Verified member’s identity, reviewed informed consent, including telehealth expectations, and determined telehealth is appropriate for member"
          label="I attest to the above"
          field={fields.introducedCoachingToMember}
          tooltip={Labels.LEGAL_REQUIREMENTS_CONSENT}
          styles={formControlStyles}
        />
      </div>

      <div className={styles.formGroupV2}>
        <TextAreaField
          testId="remarkableEntries"
          disabled={disabled}
          label={Labels.REMARKABLE_ENTRIES}
          placeholder="Updates from care team, measure updates, homework, etc."
          field={fields.remarkableEntries}
          width={Width.FULL}
          autoExpand={true}
          rows={1}
          button={zoomDurationButton}
        />
      </div>
    </div>
  );
}
