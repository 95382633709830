import {
  AppointmentStatus,
  AppointmentType,
  ClinicalAppointmentNoteLocation,
  ClinicalAppointmentNoteStatus,
  ClinicalAppointmentNoteType,
  ClinicalAppointmentStatus,
  ClinicalAppointmentType,
  ClinicalNoteStatus,
  NoteLocation,
  NoteType,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import moment from 'moment-timezone';
import { Event, stringOrDate } from 'react-big-calendar';

import { EmptyFormValue } from './NewEventForm';

export type Appointment = {
  id: string;
  name: string;
  member: {
    id: string;
    name: string;
  };
  start: any;
  end: any;
  appointmentStatus: ClinicalAppointmentStatus | AppointmentStatus | null;
  appointmentType: ClinicalAppointmentType | AppointmentType | null;
  type: string;
  cancelledReason: string | null;
  noteStatus: string;
  noteUpdatedAt: string;
  clinicalNote: ClinicalNote;
  clinicianId: string;
  zoomUrl?: string;
};

export type ClinicalNote = {
  id: string;
  url: string;
  locked: boolean;
  status: ClinicalAppointmentNoteStatus | ClinicalNoteStatus;
  location: ClinicalAppointmentNoteLocation | NoteLocation | null;
  noteType: ClinicalAppointmentNoteType | NoteType | null;
  updatedAt: string | null;
};

export enum EventType {
  AVAILABILITY = 'Availability',
  APPOINTMENT = 'Appointment',
  EVENT = 'Event',
  MEETING = 'Meeting',
}

export enum EventAttendanceStatus {
  TENTATIVE = 'TENTATIVE',
  ACCEPTED = 'ACCEPTED',
  NEEDS_ACTION = 'NEEDS_ACTION',
  DECLINED = 'DECLINED',
}
export interface AppointmentGingerEvent extends BaseGingerEvent {
  appointmentStatus: ClinicalAppointmentStatus | null;
  appointmentType?: ClinicalAppointmentType | null;
  gingerType: EventType.APPOINTMENT;
}

export interface AvailabilityGingerEvent extends BaseGingerEvent {
  gingerType: EventType.AVAILABILITY;
}

export interface EventGingerEvent extends BaseGingerEvent {
  gingerType: EventType.EVENT;
}

export interface GoogleCalendarMeetingGingerEvent extends MeetingGingerEvent {
  attendanceStatus: string;
}

export interface MeetingGingerEvent extends BaseGingerEvent {
  gingerType: EventType.MEETING;
}

export interface BaseGingerEvent extends Event {
  id: string;
  title: string;
  start: Date;
  end: Date;
}

export type GingerEvent =
  | AppointmentGingerEvent
  | AvailabilityGingerEvent
  | EventGingerEvent
  | MeetingGingerEvent
  | GoogleCalendarMeetingGingerEvent;

export interface CalendarDateRange {
  start: stringOrDate;
  end: stringOrDate;
}

export enum CalendarView {
  DAY = 'day',
  WEEK = 'week',
  MONTH = 'month',
}

export type CalendarViewProps = {
  view: CalendarView;
  start: moment.Moment;
  end: moment.Moment;
};

export type UpdateAppointmentForm = {
  type: ClinicalAppointmentType | EmptyFormValue;
  appointmentStatus: ClinicalAppointmentStatus | EmptyFormValue;
  cancelledReason: string | EmptyFormValue;
  start: string;
  end: string;
  details: string;
};

export type DialogMessage = {
  success?: string;
  error?: string;
  label?: string;
  message: string;
};

export enum NoteAction {
  CREATE = 'start',
  EDIT = 'edit',
  VIEW = 'view',
  DOWNLOAD = 'download',
  REVIEW_DRAFT = 'review_draft',

  // the viewer might be able to see appointments but not notes
  NONE = 'none',
}
