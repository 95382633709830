import { GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments } from '@headspace/carehub-graphql/dist/patients/generated/GetClinicalAppointmentsForMember';
import { Link, Typography } from '@mui/material';
import { appointmentRoute } from 'app/top-nav/Routes';
import React from 'react';
import { formatAppointmentStatus } from 'utils';

import styles from './AppointmentAppStatusCard.module.scss';

export type AppointmentAppStatusProps = {
  appointment: GetClinicalAppointmentsForMember_getClinicalAppointmentsForMember_appointments;
};

export const AppointmentAppStatusCard = ({
  appointment,
}: AppointmentAppStatusProps) => {
  return (
    <article className={styles.row}>
      <Typography
        data-testid="appointment-status"
        component="div"
        className={styles.status}
      >
        {appointment.appointmentStatus
          ? formatAppointmentStatus(appointment.appointmentStatus)
          : '-'}
      </Typography>
      <Typography
        component="div"
        data-testid="appointment-status-action"
        className={styles.link}
      >
        <Link
          href={appointmentRoute({ appointmentId: appointment.id })}
          data-testId="appointment-edit-link"
        >
          Edit Appt
        </Link>
      </Typography>
    </article>
  );
};
