import {
  GetTeenDependantSurveyResponse,
  GetTeenDependantSurveyResponseVariables,
} from '@headspace/carehub-graphql/dist/patients-tabs-intake/generated/GetTeenDependantSurveyResponse';
import { getTeenDependantIntakeSurveyQuery } from '@headspace/carehub-graphql/dist/patients-tabs-intake/queries';
import * as Sentry from '@sentry/react';
import { intakeRouteV2 } from 'app/patients/Routes';
import { ClinicalIntakeTab } from 'app/patients/tabs/intake/dependent-intake/ClinicalIntakeTab';
import styles from 'app/patients/tabs/intake/dependent-intake/DependentReport.module.scss';
import { GuardianResponseTab } from 'app/patients/tabs/intake/dependent-intake/GuardianResponseTab';
import { TeenResponseTab } from 'app/patients/tabs/intake/dependent-intake/TeenResponseTab';
import { EmptyIntakeTab } from 'app/patients/tabs/intake/EmptyIntakeTab';
import { useAppState } from 'app/state';
import { viewDependentReport } from 'app/state/amplitude/actions/etc';
import { useLogger } from 'app/state/log/useLogger';
import { useMemberChartIndicators } from 'hooks/useMemberChartIndicators';
import { useQuery } from 'hooks/useQuery';
import React, { useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Redirect, Route } from 'react-router-dom';
import { NavigationTabs } from 'shared-components/tabs/NavigationTabs';

interface TeenIntakeContentProps {
  memberId: string;
  pathPrefix: string;
  teenIntake: GetTeenDependantSurveyResponse;
  'data-testid'?: string;
}
interface Props {
  memberId: string;
  pathPrefix: string;
}

function TeenIntakeContent(props: Readonly<TeenIntakeContentProps>) {
  const { memberId, pathPrefix, teenIntake, 'data-testid': testId } = props;
  const {
    updateIntakeIndicator,
    showIntakeIndicator,
  } = useMemberChartIndicators(memberId);

  useEffect(() => {
    if (showIntakeIndicator) {
      updateIntakeIndicator?.();
    }
  }, [showIntakeIndicator]);
  const SentryRoute = Sentry.withSentryRouting(Route);
  const { survey, dependant } = teenIntake;
  const guardianResponse = survey?.intakeSurvey?.guardianResponse;
  const tabs = [
    {
      label: 'Guardian Screener Responses',
      path: intakeRouteV2(pathPrefix, memberId, 'guardian-response'),
    },
    {
      label: 'Teen Responses',
      path: intakeRouteV2(pathPrefix, memberId, 'teen-response'),
    },
    {
      label: 'Clinical Intake',
      path: intakeRouteV2(pathPrefix, memberId, 'clinical-intake'),
    },
  ];

  return (
    <div data-testid={testId} className={styles.dependentReport}>
      <div className={styles.tab}>
        <NavigationTabs links={tabs} />
      </div>
      <div className={styles.tabContent}>
        <Route exact={true} path={intakeRouteV2(pathPrefix, memberId)}>
          <Redirect
            to={intakeRouteV2(pathPrefix, memberId, 'guardian-response')}
          />
        </Route>
        <SentryRoute
          exact={true}
          path={intakeRouteV2(pathPrefix, memberId, 'guardian-response')}
        >
          <GuardianResponseTab guardianResponse={guardianResponse!} />
        </SentryRoute>
        <SentryRoute
          exact={true}
          path={intakeRouteV2(pathPrefix, memberId, 'teen-response')}
        >
          <TeenResponseTab teenDemographics={dependant!} />
        </SentryRoute>
        <SentryRoute
          exact={true}
          path={intakeRouteV2(pathPrefix, memberId, 'clinical-intake')}
        >
          <ClinicalIntakeTab clinicalIntake={survey!} />
        </SentryRoute>
      </div>
    </div>
  );
}

export function TeenIntakeV2(props: Readonly<Props>) {
  const logger = useLogger();

  const { memberId, pathPrefix } = props;
  const dispatch = useDispatch();
  const role = useAppState((_) => _.user.role);
  useEffect(() => {
    if (role) dispatch(viewDependentReport({ memberId, role }));
  }, [dispatch, role]);

  return useQuery<
    GetTeenDependantSurveyResponse,
    GetTeenDependantSurveyResponseVariables
  >(
    (data) => {
      if (data.survey == null) {
        logger.info('IntakeTabV2: intake chart is null', { memberId });
        return <EmptyIntakeTab />;
      }

      return (
        <TeenIntakeContent
          data-testid="teenIntakeTabV2"
          teenIntake={data}
          memberId={memberId}
          pathPrefix={pathPrefix}
        />
      );
    },
    getTeenDependantIntakeSurveyQuery,
    {
      variables: { memberId },
    },
  );
}
