import { useLazyQuery } from '@apollo/client';
import { getHomeworkForMember as getHomeworkForMemberQuery } from '@headspace/carehub-graphql/dist/patients-tabs-content/ContentQueries';
import {
  getHomeworkForMember,
  getHomeworkForMemberVariables,
} from '@headspace/carehub-graphql/dist/patients-tabs-content/generated/getHomeworkForMember';
import { HomeworkStatus, HomeworkType } from 'app/patients/tabs/content/types';
import { setHomeworks } from 'app/state/content/actions';
import { useLogger } from 'app/state/log/useLogger';
import { useOnMount } from 'hooks/useOnMount';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { convertTaskToHomeworkType } from './utils';

export function useGetHomeworkForMemberQuery(memberId: string): boolean {
  const [loaded, setLoaded] = useState(false);
  const [homeworkQuery] = useLazyQuery<
    getHomeworkForMember,
    getHomeworkForMemberVariables
  >(getHomeworkForMemberQuery);
  const dispatch = useDispatch();
  const logger = useLogger();

  const loadContent = async (memberId: string) => {
    return await homeworkQuery({
      variables: {
        input: {
          memberId,
        },
      },
    });
  };

  useOnMount(() => {
    loadContent(memberId)
      .then((result) => {
        const tasks = result.data?.getHomeworkForMember?.tasks ?? [];
        const completedHomework: HomeworkType[] = [];
        const currentHomework: HomeworkType[] = [];
        if (result.error) {
          logger.error(
            new Error(
              'useGetHomeworkForMemberQuery: Error retrieving homework for member',
            ),
            {
              error: result.error,
            },
          );
        }

        tasks.forEach((task) => {
          if (!task) return;

          const homework = convertTaskToHomeworkType(task);
          switch (homework.status) {
            case HomeworkStatus.COMPLETED:
              completedHomework.push(homework);
              break;

            case HomeworkStatus.NOT_STARTED:
            case HomeworkStatus.IN_PROGRESS:
              currentHomework.push(homework);
              break;
          }
        });

        dispatch(
          setHomeworks({
            homeworks: {
              completedHomework,
              currentHomework,
            },
            userId: memberId,
          }),
        );
        setLoaded(true);
      })
      .catch((error) => {
        logger.error(
          new Error(
            'useGetHomeworkForMemberQuery: Error retrieving homework for member',
          ),
          {
            error,
          },
        );
      });
  });

  return loaded;
}
