import {
  ChannelIdMemberIdMap,
  ConversationState,
  ConversationStateMap,
  UnreadMessagesMap,
  UpdateUnreadMessagesPayload,
} from 'app/coach/chat/types';
import {
  InsertNewMessagePayload,
  MessageToDisplay,
  RefetchHistoryActionPayload,
  RPCEnvelope,
  ServerRPC,
  TextMessageEnvelope,
  UpdateConvosAfterRefetchPayload,
} from 'app/coach/pubnub/types';
import {
  InboxItem,
  InboxItemState,
  InboxSections,
  InboxTab,
  Results,
} from 'app/inbox/types';
import { ConvoPresence, State } from 'app/state/inbox/schema';
import { PresenceEvent } from 'pubnub';
import { createAction } from 'redux-reloaded';

export enum Actions {
  ADD_MESSAGE = 'ADD_MESSAGE',
  SET_CURRENT_CHANNEL = 'SET_CURRENT_CHANNEL',
  SET_MESSAGES = 'SET_MESSAGES',
  MARK_LAST_MESSAGE_READ_BY_MEMBER = 'MARK_LAST_MESSAGE_READ_BY_MEMBER',
  TOGGLE_INBOX_DRAWER = 'TOGGLE_INBOX_DRAWER',
  RESTORE_DRAWER_STATE = 'RESTORE_DRAWER_STATE',
  MAKE_SENT_MESSAGE_DELIVERED = 'MAKE_SENT_MESSAGE_DELIVERED',
  MARK_SENT_MESSAGE_BY_ERROR = 'MARK_SENT_MESSAGE_BY_ERROR',
  NEW_MESSAGE = 'NEW_MESSAGE',
  HANDLE_TEXT_MESSAGE = 'HANDLE_TEXT_MESSAGE',
  HANDLE_RPC = 'HANDLE_RPC',
  NEW_CONVERSATION = 'NEW_CONVERSATION_RPC',
  READ_ALL_MESSAGES = 'READ_ALL_MESSAGES',
  ADD_UNREAD_BY_LISTENER_MESSAGE = 'ADD_UNREAD_BY_LISTENER_MESSAGE',
  REFETCH_HISTORY = 'REFETCH_HISTORY',
  ON_NEW_MESSAGE_RPC = 'ON_NEW_MESSAGE_RPC',
  INSERT_NEW_MESSAGES = 'INSERT_NEW_MESSAGES',
  UPDATE_MESSAGES_STATUSES = 'UPDATE_MESSAGES_STATUSES',
  UPDATE_CONVERSATIONS_BASED_ON_REFETCH = 'UPDATE_CONVERSATIONS_BASED_ON_REFETCH',
  UPDATE_INBOX_ITEMS = 'UPDATE_INBOX_ITEMS',
  UPDATE_INBOX_SECTION_ITEMS = 'UPDATE_INBOX_SECTION_ITEMS',
  TRIGGER_PUBNUB_CHANNEL_SUBSCRIPTION = 'TRIGGER_PUBNUB_CHANNEL_SUBSCRIPTION',
  BULK_SUBSCRIBE_TO_TODAYS_MEMBER_CHANNELS = 'BULK_SUBSCRIBE_TO_TODAYS_MEMBER_CHANNELS',
  UPDATED_TO_PUBNUB_CHANNEL_LIST = 'UPDATED_TO_PUBNUB_CHANNEL_LIST',
  SET_INBOX_REFRESH_IN_PROGRESS = 'SET_INBOX_REFRESH_IN_PROGRESS',
  REFRESH_COACH_TODAYS_MEMBER_LIST = 'REFRESH_COACH_TODAYS_MEMBER_LIST',
  REFRESH_CLINICIAN_TODAYS_MEMBER_LIST = 'REFRESH_CLINICIAN_TODAYS_MEMBER_LIST',
  SORT_INBOX_SECTION = 'SORT_INBOX_SECTION',
  MARK_CONVERSATION_AS_DONE = 'MARK_CONVERSATION_AS_DONE',
  MOVE_INBOX_ITEM_BETWEEN_SECTIONS = 'MOVE_INBOX_ITEM_BETWEEN_SECTIONS',
  UPDATE_TAB_SECTION = 'UPDATE_TAB_SECTION',
  UPDATE_UNREAD_MESSAGES = 'UPDATE_UNREAD_MESSAGES',
  PRESENCE_CHANGE = 'PRESENCE_CHANGE',
  STATUS_RECEIVED = 'STATUS_RECEIVED',
  UPDATE_CONVERSATION_PRESENCE = 'UPDATE_CONVERSATION_PRESENCE',
  REFRESH_TODAYS_INBOX_TOTAL_COUNT = 'REFRESH_TODAYS_INBOX_TOTAL_COUNT',
  UPDATE_TODAYS_INBOX_TOTAL_COUNT = 'UPDATE_TODAYS_INBOX_TOTAL_COUNT',
  UPDATE_RISK_ALERT_INDICATOR = 'UPDATE_RISK_ALERT_INDICATOR',
  SET_SEARCH_RESULT = 'SET_SEARCH_RESULT',
  SET_SELECTED_TAB = 'SET_SELECTED_TAB',
  ADD_CONVERSATION_STATE_MAP = 'ADD_CONVERSATION_STATE_MAP',
  ADD_CHANNEL_ID_MEMBER_ID_MAP = 'ADD_CHANNEL_ID_MEMBER_ID_MAP',
  UPDATE_CONVERSATION_STATE = 'UPDATE_CONVERSATION_STATE',
  INIT_CONVERSATIONS_IN_INBOX = 'INIT_CONVERSATIONS_IN_INBOX',
  STOP_INBOX_POLLING = 'STOP_INBOX_POLLING',
  START_INBOX_POLLING = 'START_INBOX_POLLING',
  SET_INBOX_POLLING_ID = 'SET_INBOX_POLLING_ID',
  SET_UNREAD_MESSAGES = 'SET_UNREAD_MESSAGES',
  MAYBE_UPDATE_CONVERSATIONS_STATE = 'MAYBE_UPDATE_CONVERSATIONS_STATE',
}

export const setCurrentChannel = createAction(
  Actions.SET_CURRENT_CHANNEL,
  ({
    currentChannelId,
    currentMemberId,
  }): { currentChannelId: string; currentMemberId: string } => ({
    currentChannelId,
    currentMemberId,
  }),
);

export const updateInboxSectionItems = createAction(
  Actions.UPDATE_INBOX_SECTION_ITEMS,
  ({
    shouldSubscribeToPubnubChannels = false,
    openTabs = [],
    ...payload
  }: { [k in InboxSections]?: Results } & {
    shouldSubscribeToPubnubChannels?: boolean;
    openTabs?: InboxItem[];
  }) => ({
    openTabs,
    sections: payload,
    shouldSubscribeToPubnubChannels,
  }),
);
export const setSearchResult = createAction(
  Actions.SET_SEARCH_RESULT,
  ({
    ...payload
  }: {
    [k in
      | InboxSections.PAST
      | InboxSections.CLOSED
      | InboxSections.SCHEDULED]?: Results;
  }) => ({
    sections: payload,
  }),
);

export const updateInboxItems = createAction(
  Actions.UPDATE_INBOX_ITEMS,
  (inboxItems: InboxItemState[]) => ({
    inboxItems,
  }),
);

export const setMessages = createAction(
  Actions.SET_MESSAGES,
  ({
    messages,
    channelId,
  }: {
    messages: MessageToDisplay[];
    channelId: string;
  }) => ({
    channelId,
    messages,
  }),
);

export const addMessage = createAction(
  Actions.ADD_MESSAGE,
  (message: MessageToDisplay) => ({
    message,
  }),
);

export const onCallerReadMessageRPC = createAction(
  Actions.MARK_LAST_MESSAGE_READ_BY_MEMBER,
  ({
    channel,
    lastMemberReadTimetoken,
  }: {
    channel: string;
    lastMemberReadTimetoken: string | number;
  }) => ({
    channel,
    lastMemberReadTimetoken: lastMemberReadTimetoken.toString(),
  }),
);

export const makeSentMessageDelivered = createAction(
  Actions.MAKE_SENT_MESSAGE_DELIVERED,
  ({
    channelId,
    messageId,
    timetoken,
  }: {
    channelId: string;
    messageId: string;
    timetoken: string;
  }) => ({
    channelId,
    messageId,
    timetoken,
  }),
);

export const markSentMessageByError = createAction(
  Actions.MARK_SENT_MESSAGE_BY_ERROR,
  ({ channelId, messageId }: { channelId: string; messageId: string }) => ({
    channelId,
    messageId,
  }),
);

export const onNewMessage = createAction(
  Actions.NEW_MESSAGE,
  ({ envelope }: { envelope: TextMessageEnvelope | RPCEnvelope }) => ({
    envelope,
  }),
);

export const onPresenceChange = createAction(
  Actions.PRESENCE_CHANGE,
  ({ envelope }: { envelope: PresenceEvent }) => ({
    envelope,
  }),
);

export const onStatusReceived = createAction(
  Actions.STATUS_RECEIVED,
  ({ envelope }: { envelope: any }) => ({
    envelope,
  }),
);

export const handleTextMessage = createAction(
  Actions.HANDLE_TEXT_MESSAGE,
  ({ envelope }: { envelope: TextMessageEnvelope }) => ({ envelope }),
);

export const handleRPC = createAction(
  Actions.HANDLE_RPC,
  ({ envelope }: { envelope: RPCEnvelope }) => ({ envelope }),
);
export const onNewConversation = createAction(
  Actions.NEW_CONVERSATION,
  ({ envelope }: { envelope: RPCEnvelope }) => ({
    envelope,
  }),
);
export const restoreDrawerState = createAction(
  Actions.RESTORE_DRAWER_STATE,
  () => {},
);

export const toggleDrawer = createAction(Actions.TOGGLE_INBOX_DRAWER, () => {});

export const readAllMessages = createAction(
  Actions.READ_ALL_MESSAGES,
  ({ channelId }: { channelId: string }) => ({
    channelId,
  }),
);

export const addUnreadByListenerMessage = createAction(
  Actions.ADD_UNREAD_BY_LISTENER_MESSAGE,
  ({
    unreadMessage,
    channelId,
  }: {
    unreadMessage: MessageToDisplay | MessageToDisplay[];
    channelId: string;
  }) => ({
    channelId,
    unreadMessage,
  }),
);
export const updateConversationPresence = createAction(
  Actions.UPDATE_CONVERSATION_PRESENCE,
  ({ presences }: { presences: Record<string, Partial<ConvoPresence>> }) => ({
    presences,
  }),
);

export const refetchHistory = createAction(
  Actions.REFETCH_HISTORY,
  (refetchHistoryPayload: RefetchHistoryActionPayload) => refetchHistoryPayload,
);

export const onNewMessageRPC = createAction(
  Actions.ON_NEW_MESSAGE_RPC,
  ({ envelope }: { envelope: RPCEnvelope }) => ({
    envelope,
  }),
);

export const insertNewMessagesAndUpdateStatuses = createAction(
  Actions.INSERT_NEW_MESSAGES,
  (insertNewMessagePayload: InsertNewMessagePayload) => insertNewMessagePayload,
);

export const updateStatusesOfMessages = createAction(
  Actions.UPDATE_MESSAGES_STATUSES,
  (
    channelsAndTimetokens: {
      lastMemberReadTimeToken: string | null | undefined;
      channel: string;
    }[],
  ) => channelsAndTimetokens,
);

export const updateConversationsBasedOnRefetch = createAction(
  Actions.UPDATE_CONVERSATIONS_BASED_ON_REFETCH,
  ({ RPC, timetokensMap, memberIds }: UpdateConvosAfterRefetchPayload) => ({
    RPC,
    memberIds,
    timetokensMap,
  }),
);

export const updateUnreadMessages = createAction(
  Actions.UPDATE_UNREAD_MESSAGES,
  (inboxItemsData: UpdateUnreadMessagesPayload) => ({
    inboxItemsData,
  }),
);

export const updateTabSection = createAction(
  Actions.UPDATE_TAB_SECTION,
  (payload: {
    tabSections?: Partial<State['tabSections']>;
    search?: State['search'];
  }) => payload,
);

export const triggerPubnubChannelSubscription = createAction(
  Actions.TRIGGER_PUBNUB_CHANNEL_SUBSCRIPTION,
  (payload: { channelIds: string[] }) => payload,
);
export const bulkSubscribeToTodaysMemberChannels = createAction(
  Actions.BULK_SUBSCRIBE_TO_TODAYS_MEMBER_CHANNELS,
  (fallbackChannelIds: string[]) => fallbackChannelIds,
);

export const setInboxRefreshInProgress = createAction(
  Actions.SET_INBOX_REFRESH_IN_PROGRESS,
  (payload: { inProgress: boolean }) => payload,
);

export const refreshCoachTodaysMemberList = createAction(
  Actions.REFRESH_COACH_TODAYS_MEMBER_LIST,
  (payload: {
    RPC?: ServerRPC;
    envelope?: TextMessageEnvelope | RPCEnvelope;
    sections: InboxSections[];
    memberIds?: string[];
    rpcSource?: string;
    loadMessageHistory?: boolean;
    refreshCount?: number;
    initialRetryDelay?: number;
    shouldRefreshAgain?: (
      tabs: State['tabSections'],
      inboxItem: InboxItem[],
      refreshCount: number,
    ) => boolean;
  }) => payload,
);
export const refreshClinicianTodaysMemberList = createAction(
  Actions.REFRESH_CLINICIAN_TODAYS_MEMBER_LIST,
  (payload: { sections?: InboxSections[] }) => payload,
);
export const sortInboxSections = createAction(
  Actions.SORT_INBOX_SECTION,
  (payload: { sections: InboxSections[] }) => payload,
);

export const markConversationAsDone = createAction(
  Actions.MARK_CONVERSATION_AS_DONE,
  (payload: { memberId: string }) => payload,
);

export const updateConversationState = createAction(
  Actions.UPDATE_CONVERSATION_STATE,
  (payload: { updatedState: ConversationState; channelId: string }) => payload,
);

export const maybeUpdateConversationsState = createAction(
  Actions.MAYBE_UPDATE_CONVERSATIONS_STATE,
  (payload: {
    updatesOfStateMap: Record<string, Partial<ConversationState>>;
  }) => payload,
);

export const moveInboxItemBetweenSections = createAction(
  Actions.MOVE_INBOX_ITEM_BETWEEN_SECTIONS,
  (payload: { memberId: string; from: InboxSections; to: InboxSections }) =>
    payload,
);

export const refreshTodaysInboxTotalCount = createAction(
  Actions.REFRESH_TODAYS_INBOX_TOTAL_COUNT,
  () => {},
);

export const updateTodaysInboxTotalCount = createAction(
  Actions.UPDATE_TODAYS_INBOX_TOTAL_COUNT,
  (payload: { todaysMemberCount: number }) => payload,
);
export const updateRiskAlertIndicator = createAction(
  Actions.UPDATE_RISK_ALERT_INDICATOR,
  (payload: { memberId: string }) => payload,
);

export const setSelectedTab = createAction(
  Actions.SET_SELECTED_TAB,
  (payload: { inboxTab: InboxTab }) => payload,
);
export const setInboxPollingID = createAction(
  Actions.SET_INBOX_POLLING_ID,
  (pollingId: number | null) => pollingId,
);
export const startInboxPolling = createAction(Actions.START_INBOX_POLLING);
export const stopInboxPolling = createAction(Actions.STOP_INBOX_POLLING);

export const initConversationsInInbox = createAction(
  Actions.INIT_CONVERSATIONS_IN_INBOX,
  (payload: { inboxItems: InboxItem[] }) => payload,
);

export const addChannelMemberMap = createAction(
  Actions.ADD_CHANNEL_ID_MEMBER_ID_MAP,
  (payload: { channelMemberMap: ChannelIdMemberIdMap }) => payload,
);

export const addConversationStateMap = createAction(
  Actions.ADD_CONVERSATION_STATE_MAP,
  (payload: { conversationStateMap: ConversationStateMap }) => payload,
);

export const setUnreadMessages = createAction(
  Actions.SET_UNREAD_MESSAGES,
  (payload: { unreadMessagesMap: UnreadMessagesMap }) => payload,
);
