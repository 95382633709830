import { EventType } from 'app/events/EventType';
import { createAction } from 'redux-reloaded';

export const documentTabViewed = createAction(
  EventType.DOCUMENTS_TAB_VIEW,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const documentFileUploadClicked = createAction(
  EventType.DOCUMENTS_FILE_UPLOAD_CLICK,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const documentUploadClicked = createAction(
  EventType.DOCUMENTS_UPLOAD_CLICK,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const documentDownloadClicked = createAction(
  EventType.DOCUMENTS_DOWNLOAD_CLICK,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const documentEditClicked = createAction(
  EventType.DOCUMENTS_EDIT_CLICK,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const documentDeleteClicked = createAction(
  EventType.DOCUMENTS_DELETE_CLICK,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const documentPrintClicked = createAction(
  EventType.DOCUMENTS_PRINT_CLICK,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const documentOpenDocumentClicked = createAction(
  EventType.DOCUMENTS_OPEN_DOCUMENT_CLICK,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
export const documentPreviewClicked = createAction(
  EventType.DOCUMENTS_PREVIEW_CLICK,
  (payload: { patientId: string }) => ({
    payload,
  }),
);
