import { CoachingSessionModificationScope } from '@headspace/carehub-graphql/dist/generated/globalTypes';
import { Button } from '@mui/material';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogActions from '@mui/material/DialogActions';
import MuiDialogContent from '@mui/material/DialogContent';
import { RadioButtonGroup } from 'app/notes-ui/forms/form-controls/RadioButtonGroup';
import { DialogTitle } from 'app/notes-ui/LockNoteDialog';
import styles from 'app/notes-ui/LockNoteModal.module.scss';
import React, { useState } from 'react';

import { SessionOptions } from '../types';

export type DialogDetails = {
  dialogTitle: string;
  dialogContent: string;
};

export interface Props {
  sessionId: string;
  isSingleSession: boolean;
  open: boolean;
  onClose: () => void;
  onConfirm: (modificationScope: CoachingSessionModificationScope) => void;
  dialogDetails: DialogDetails;
}

export function CancelSessionModal(props: Props) {
  const { open, isSingleSession, dialogDetails, onConfirm } = props;
  const [modificationScope, setModificationScope] = useState<
    CoachingSessionModificationScope
  >(CoachingSessionModificationScope.THIS_ONLY);
  const onModificationScopeChange = (
    value: CoachingSessionModificationScope,
  ) => {
    setModificationScope(value);
  };

  const onClick = () => {
    onConfirm(modificationScope);
  };

  return (
    <MuiDialog
      data-testid="dialog"
      disableEscapeKeyDown={true}
      onClose={props.onClose}
      maxWidth="sm"
      aria-labelledby="Session Dialog"
      open={open}
      classes={{
        container: styles.container,
        paper: styles.paper,
        root: styles.root,
      }}
    >
      <DialogTitle data-testid="SessionDialogTitle" onClose={props.onClose}>
        {dialogDetails.dialogTitle}
      </DialogTitle>
      <MuiDialogContent className={styles.dialogContent}>
        {isSingleSession ? (
          <p data-testid="contentBody" className={styles.body}>
            {dialogDetails.dialogContent}
          </p>
        ) : (
          <>
            <RadioButtonGroup
              name="ModificationScope"
              label=""
              options={SessionOptions}
              formControlStyles={{ label: styles.label }}
              onChange={onModificationScopeChange}
              value={modificationScope}
            />
          </>
        )}
      </MuiDialogContent>
      <MuiDialogActions className={styles.dialogAction}>
        <Button
          data-testid="cancelBtn"
          className={`${styles.btn} ${styles.cancelBtn}`}
          onClick={props.onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="signLockBtn"
          onClick={onClick}
          className={`${styles.btn} ${styles.lockBtn}`}
        >
          confirm
        </Button>
      </MuiDialogActions>
    </MuiDialog>
  );
}
