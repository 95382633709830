/* eslint-disable react/jsx-one-expression-per-line */
import {
  CSSRSScore,
  CustomerType,
  GAD7Severity,
  GuardianRelationshipType,
  PHQ9Severity,
  PHQ9SuicidalIdeation,
  PSSScore,
} from '@headspace/carehub-graphql/dist/generated/globalTypes';
import {
  GetMemberById_getMember,
  GetMemberById_getMember_coverageDetails,
} from '@headspace/carehub-graphql/dist/queries/generated/GetMemberById';
import { MemberSessionUsage } from 'app/state/features/scheduler/types';
import moment from 'moment-timezone';
import React, { ReactNode } from 'react';
import { daysBetweenCurrentDate } from 'utils/dateTime';

import styles from './MemberHeaderBar.module.scss';

export const formatGuardianRelationship = (
  relationship: GuardianRelationshipType | null | undefined,
): string | undefined => {
  if (relationship === null || relationship === undefined) {
    return undefined;
  }
  return guardianRelationMap[relationship];
};

export const FUSION_D2C_TEXT = 'Fusion D2C';
export const D2C_COACHING_TEXT = 'D2C Coaching';

export const isD2cCoaching = (getMember: GetMemberById_getMember) => {
  const { billing, isD2c, isFusion } = getMember;
  const { customerType, employerName } = billing ?? {};

  return (
    isD2c &&
    isFusion &&
    customerType === CustomerType.employer &&
    employerName?.toLowerCase() === FUSION_D2C_TEXT.toLowerCase()
  );
};

export const formatCustomerType = (
  getMember: GetMemberById_getMember,
  enableD2cCoachingCoverageInfo?: boolean,
): string | undefined => {
  const { billing } = getMember;
  const { customerType, employerName } = billing ?? {};

  if (customerType === null || customerType === undefined) {
    return undefined;
  }

  if (isD2cCoaching(getMember) && enableD2cCoachingCoverageInfo) {
    return D2C_COACHING_TEXT;
  }

  if (customerType === CustomerType.employer && employerName) {
    return employerName;
  }
  return customerTypeMap[customerType];
};

export const formatD2cSessionInfo = (
  memberSessionUsage: MemberSessionUsage,
  coachTimeZone: string,
): ReactNode => {
  const { isAutorenew, chatSessions, sessionEndDate } = memberSessionUsage;
  const {
    numSessions: numChatSessions,
    usedSessions: usedChatSessions,
  } = chatSessions;
  const autoRenewText = isAutorenew ? 'Renews' : 'Ends';
  const formattedEndDate = sessionEndDate
    ? moment.tz(sessionEndDate, coachTimeZone).format('MMM D, YYYY')
    : null;
  const daysRemaining = sessionEndDate
    ? daysBetweenCurrentDate(sessionEndDate)
    : 0;

  return (
    <span>
      {formattedEndDate && (
        <>
          {autoRenewText} in <b>{daysRemaining} days</b> | {formattedEndDate} •{' '}
        </>
      )}
      <b>
        {usedChatSessions}/{numChatSessions}
      </b>{' '}
      Coaching Used
    </span>
  );
};

export const formatCoverageRenewalInfo = (
  coverageDetails?: GetMemberById_getMember_coverageDetails | null,
  nextEligibleVideoSessionDate?: string,
): ReactNode => {
  if (coverageDetails) {
    const {
      totalSessionsCovered,
      totalCoveredSessionsRemaining,
      renewalDate,
    } = coverageDetails;
    const { sessionsCoveredUsed: therapyUsed } = coverageDetails.therapy;
    const { sessionsCoveredUsed: psychiatryUsed } = coverageDetails.psychiatry;

    const renewal = renewalDate ? (
      <span>
        • Renews <b>{renewalDate}</b>
      </span>
    ) : undefined;
    let covered;
    if (
      totalSessionsCovered !== null &&
      totalCoveredSessionsRemaining !== null
    ) {
      const usedChatSessions =
        totalSessionsCovered - totalCoveredSessionsRemaining;
      covered = (
        <span>
          <b>
            {usedChatSessions}/{totalSessionsCovered}
          </b>{' '}
          Covered Sessions Used
        </span>
      );
    }
    const therapy = (
      <span>
        • <b>{therapyUsed}</b> Therapy Used
      </span>
    );
    const psychiatry = (
      <span>
        • <b>{psychiatryUsed}</b> Psychiatry Used
      </span>
    );
    return (
      <>
        {[covered, therapy, psychiatry, renewal].map((item, i) => {
          return (
            // eslint-disable-next-line react/no-array-index-key
            <span className={styles.tooltipText} key={i}>
              {i > 0 ? ' ' : undefined}
              {item}
            </span>
          );
        })}
        {nextEligibleVideoSessionDate && (
          <span>• {nextEligibleVideoSessionDate}</span>
        )}
      </>
    );
  }

  return null;
};

export const suicidalFlagLabels = {
  ideation: 'Suicidal Ideation',
  risk: 'Suicidal Risk',
};

export const determineFlagText = (
  suicidalIdeation: PHQ9SuicidalIdeation | null,
  selfHarm: number | null,
) => {
  const flagTextSI = `${suicidalFlagLabels.ideation} (${selfHarm})`;
  const flagTextSR = `${suicidalFlagLabels.risk} (${selfHarm})`;

  if (suicidalIdeation && suicidalIdeation === PHQ9SuicidalIdeation.ENDORSED) {
    return {
      flag: flagTextSI,
      tooltip: 'Endorsed suicidal thoughts',
    };
  }
  if (selfHarm && selfHarm > 0) {
    if (suicidalIdeation && suicidalIdeation === PHQ9SuicidalIdeation.DENIED) {
      return {
        flag: flagTextSR,
        tooltip: 'Denied suicidal thoughts',
      };
    }
    return {
      flag: flagTextSI,
      tooltip: 'Answer to suicidal thoughts unknown',
    };
  }
  return {
    flag: '',
    tooltip: '',
  };
};

const guardianRelationMap = {
  [GuardianRelationshipType.GUARDIAN]: 'Guardian',
  [GuardianRelationshipType.DEPENDENT]: 'Dependent',
  [GuardianRelationshipType.NONE]: undefined,
};

const customerTypeMap = {
  [CustomerType.legacy]: 'Legacy',
  [CustomerType.consumer]: 'Consumer',
  [CustomerType.employer]: 'Employer',
};

export const PHQ9SeverityMap = {
  [PHQ9Severity.MILD]: 'Mild',
  [PHQ9Severity.MINIMAL]: 'Minimal',
  [PHQ9Severity.MODERATE]: 'Moderate',
  [PHQ9Severity.MODERATELY_SEVERE]: 'Moderately Severe',
  [PHQ9Severity.SEVERE]: 'Severe',
};

export const GAD7SeverityMap = {
  [GAD7Severity.MILD]: 'Mild',
  [GAD7Severity.MINIMAL]: 'Minimal',
  [GAD7Severity.MODERATE]: 'Moderate',
  [GAD7Severity.SEVERE]: 'Severe',
};

export const PSSScoreMap = {
  [PSSScore.LOW]: 'Low',
  [PSSScore.MODERATE]: 'Moderate',
  [PSSScore.HIGH]: 'High',
};

export const CSSRSScoreMap = {
  [CSSRSScore.HIGH]: 'High risk',
  [CSSRSScore.MODERATE]: 'Moderate risk',
  [CSSRSScore.LOW]: 'Low risk',
  [CSSRSScore.NO_RISK]: 'No risk',
};

export const moreInfoText = 'More info...';
