import {
  useVaultAPI,
  VaultAuditLogEvent,
  VaultAuditLogEventType,
} from '@ginger.io/vault-ui';
import CloseIcon from '@mui/icons-material/Close';
import MuiDialog from '@mui/material/Dialog';
import MuiDialogContent from '@mui/material/DialogContent';
import MuiDialogTitle from '@mui/material/DialogTitle';
import IconButton from '@mui/material/IconButton';
import { ErrorState } from 'shared-components/error-state/ErrorState';
import { useAppState } from 'app/state';
import { formatEnumValue } from 'utils';
import { formatDate } from 'utils/dateTime';
import React, { ReactElement, useEffect, useRef, useState } from 'react';
import ClipLoader from 'react-spinners/ClipLoader';

import styles from './AuditLogEntryViewV2.module.scss';
import { useTabContext } from './contexts/TabContext';

export type AuditLog = {
  id: string;
  name: string;
  timestamp: string;
  eventType: VaultAuditLogEventType;
};

export type Props = {
  vaultItemId: string;
  vaultGroupId: string;
  isOpen: boolean;
  setIsOpen: (isOpen: boolean) => void;
};
interface DialogTitleProps {
  children: React.ReactNode;
  onClose: () => void;
}

export function AuditLogEntry(props: { logEntry: AuditLog; timezone: string }) {
  const { logEntry, timezone } = props;
  return (
    <li className={styles.log}>
      <div data-testid="title" className={styles.title}>
        {logEntry.name}
      </div>
      <div className={styles.detail}>
        <span data-testid="eventLogEntry">
          {`${formatEnumValue(logEntry.eventType)} ${formatDate(
            logEntry.timestamp,
            timezone,
          )}`}
        </span>
      </div>
    </li>
  );
}

function LogDialogTitle(props: DialogTitleProps) {
  const { children, onClose } = props;
  return (
    <MuiDialogTitle className={styles.dialogTitle}>
      <span data-testid="dialogTitle" className={styles.title}>
        {children}
      </span>
      <IconButton
        aria-label="close"
        data-testid="titleCloseBtn"
        className={styles.closeButton}
        onClick={onClose}
      >
        <CloseIcon />
      </IconButton>
    </MuiDialogTitle>
  );
}

export function AuditLogEntryViewV2({
  vaultItemId,
  vaultGroupId,
  isOpen,
  setIsOpen,
}: Props) {
  const [auditLogs, setAuditLogs] = useState<AuditLog[]>([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<string | null>(null);
  const timezone = useAppState((_) => _.user.timezone!);
  const api = useVaultAPI();
  const loadingRef = useRef<boolean>(false);
  const { activeTab, sectionName } = useTabContext();

  useEffect(() => {
    loadingRef.current = true;
    const callback = async () => {
      try {
        setLoading(true);
        const logs: VaultAuditLogEvent[] = await api.getAuditLogForVaultItem(
          vaultItemId,
          {
            groupId: vaultGroupId,
          },
        );

        const entries: AuditLog[] = logs.map((event) => ({
          ...event,
          name: event.actorName ?? '',
        }));
        if (loadingRef.current) setAuditLogs(entries);
      } catch (e) {
        if (loadingRef.current) setError(e);
      } finally {
        if (loadingRef.current) setLoading(false);
      }
    };

    void callback();
    return () => {
      loadingRef.current = false;
    };
  }, [vaultItemId, vaultGroupId, activeTab]);

  let component: ReactElement;
  if (loading) {
    component = (
      <div className={styles.loader}>
        <ClipLoader color="#4379b6" size={75} />
      </div>
    );
  } else if (error !== null) {
    component = <ErrorState error={new Error(error)} />;
  } else {
    component = (
      <ul data-testid={vaultItemId} className={styles.auditLogs}>
        {auditLogs.map((logEntry) => (
          <AuditLogEntry
            timezone={timezone}
            data-testid={`audit-log-${logEntry.id}`}
            key={logEntry.id}
            logEntry={logEntry}
          />
        ))}
      </ul>
    );
  }

  return (
    <MuiDialog
      data-testid="dialog"
      disableEscapeKeyDown={true}
      onClose={() => setIsOpen(false)}
      maxWidth="sm"
      fullWidth={true}
      aria-labelledby="Audit log Dialog"
      open={isOpen}
      classes={{
        container: styles.container,
        paper: styles.paper,
        root: styles.root,
      }}
    >
      <LogDialogTitle onClose={() => setIsOpen(false)}>
        Audit Logs {sectionName ? `- ${sectionName}` : ''}
      </LogDialogTitle>
      <MuiDialogContent className={styles.modalBody}>
        {sectionName ? component : 'Please select a section in the sidebar'}
      </MuiDialogContent>
    </MuiDialog>
  );
}
