import { UseForm } from '@ginger.io/react-use-form';
import { isEqual } from 'lodash';
import { useEffect } from 'react';

export function useResetFormWithValue<T>(
  value: T | undefined,
  form: UseForm<T>,
) {
  const { getValue, reset } = form;
  useEffect(() => {
    if (value != null && !isEqual(value, getValue())) {
      reset(value);
    }
  }, [value]);
}
